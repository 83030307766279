export const defaultError = 'Oops something went wrong.';

const errorMapper = (response?: Api.GraphQL.Errors | null): Api.ApiError => {
  const errorResponse: Api.ApiError = {
    error: true,
    message: defaultError,
  };
  if (response && response?.errors && response.errors?.length) {
    const errorStrings = response.errors.map((error) => {
      const code = error.extensions && error.extensions.code;
      if (!code) {
        return 'Internal error';
      }
      switch (code) {
        case 'InvalidCredentials':
          return 'Email or password is wrong.';

        case 'InvalidPassword':
          return 'Password should be min 10 characters including uppercase, lowercase letter and a number.';

        case 'PasswordNotMatch':
          return "Passwords don't match.";

        case 'NotRecognized':
          return 'This email is not recognized.';

        case 'InvalidMobile':
          return 'Please use a valid phone number format.';

        case 'AssigneeMobileExists':
          return 'You already have a contacts with this number.';

        case 'UserMobileExists':
          return 'This number is already in use.';

        case 'SMSSendingOnlyInSerbia':
          return 'Sorry, for now nagnag is only available in Serbia.';

        case 'InvalidVerificationCode':
          return 'Wrong verification code, please try again.';

        case 'RateLimitExceeded':
          return 'Too many requests. Please wait 15 minutes for new code.';

        case 'UserEmailExists':
          return 'This email is already in use.';

        case 'NonExistingUser':
          return 'This email is not recognized.';

        case 'AssigneeMobileOrEmailExists':
          return 'This number or email is already in your contacts list.';

        case 'InvalidTitle':
          return 'Title can be 30 maximum characters.';

        case 'InvalidEmail':
          return 'Invalid mail format.';

        case 'AssigneeEmailExists':
          return 'This email is already in your contact list.';

        case 'AssigneeUnsupported':
          return 'Please use a valid phone number format.';

        case 'CannotDisableAllNotOptions':
          return "You can't disable all notification options.";

        case 'NagRateLimitExceeded':
          return 'No spamming. You can only nag once in 5 minutes.';

        case 'CannotDeleteInStatus':
          return "You cannot delete a task while it's in review.";

        case 'EmailNotEnabled':
          return 'You have to enable email notifications in order to use daily reports.';

        default:
          return defaultError;
      }
    });
    errorResponse.message = errorStrings?.join('\r\n') || defaultError;
  }
  return errorResponse;
};

export default errorMapper;
