import { getMutationResponse } from '../../client';
import { gql } from '@apollo/client';
import { mapMe } from '../../../mappers/user/map-me';
import errorMapper from 'mappers/error-mapper';

interface UpdateProfileInput {
  firstName?: string;
  lastName?: string;
  email?: string;
  mobileNotifications?: boolean;
  emailNotifications?: boolean;
  timezone?: string;
  dailyReportTime?: string;
  dailyReport?: boolean;
}

const mutation = gql`
  mutation updateProfile($input: UpdateProfileInput!) {
    updateProfile(input: $input) {
      id
      firstName
      lastName
      email
      preference {
        mobileNotifications
        emailNotifications
        timezone
        dailyReportTime
        dailyReport
      }
    }
  }
`;

const updateProfile = async (
  token: string,
  args: UpdateProfileInput,
): Promise<User.Me | Api.ApiError> => {
  const response = await getMutationResponse(mutation, { input: args });
  if (response.data) {
    return mapMe(response.data.updateProfile);
  }
  return errorMapper(response as Api.GraphQL.Errors);
};

export default updateProfile;
