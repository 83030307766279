import { getMutationResponse } from '../../client';
import { gql } from '@apollo/client';
import { taskFragment } from '../../fragments';
import { mapTask } from '../../../mappers/tasks/map-task';
import errorMapper from '../../../mappers/error-mapper';

interface Arguments {
  id: string;
  value: {
    title?: string;
    deadline?: string;
    assigneeId?: string;
    description?: string;
  };
}

const mutation = gql`
  mutation updateTask(
    $id: UUID!
    $title: String
    $description: String
    $assigneeId: UUID
    $deadline: DateTime
  ) {
    updateTask(
      id: $id
      input: {
        title: $title
        description: $description
        assigneeId: $assigneeId
        deadline: $deadline
      }
    ) {
      ...TaskFragment
    }
  }
  ${taskFragment}
`;

const editTask = async (args: Arguments): Promise<Task | Api.ApiError> => {
  const response = await getMutationResponse(mutation, { ...args.value, id: args.id });
  if (response.data !== null) {
    return mapTask(response.data.updateTask);
  }
  return errorMapper(response as Api.GraphQL.Errors);
};

export default editTask;
