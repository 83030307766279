import React from 'react';
import './Shared.styles.scss';

export const CenteredParagraph = (paragraphProps: any) => (
  <p className="CenteredParagraph" {...paragraphProps} />
);

// TODO: Use semantic HTML.
export const Header = (divProps: any) => <div className="Header" {...divProps} />;

// TODO: Use semantic HTML.
export const Superheader = (divProps: any) => <div className="Superheader" {...divProps} />;
