export const SELECT_CONTACT = 'SELECT_CONTACT';

export const FETCH_CONTACT_DATA_REQUEST = 'FETCH_CONTACT_DATA_REQUEST';
export const FETCH_CONTACT_DATA_SUCCESS = 'FETCH_CONTACT_DATA_SUCCESS';
export const FETCH_CONTACT_DATA_FAILURE = 'FETCH_CONTACT_DATA_FAILURE';

export const FETCH_CONTACT_LIST_REQUEST = 'FETCH_CONTACT_LIST_REQUEST';
export const FETCH_CONTACT_LIST_SUCCESS = 'FETCH_CONTACT_LIST_SUCCESS';
export const FETCH_CONTACT_LIST_FAILURE = 'FETCH_CONTACT_LIST_FAILURE';

export const FETCH_CONTACT_BY_NUMBER_REQUEST = 'FETCH_CONTACT_BY_NUMBER_REQUEST';
export const FETCH_CONTACT_BY_NUMBER_SUCCESS = 'FETCH_CONTACT_BY_NUMBER_SUCCESS';
export const FETCH_CONTACT_BY_NUMBER_FAILURE = 'FETCH_CONTACT_BY_NUMBER_FAILURE';

export const SEARCH_CONTACTS_REQUEST = 'SEARCH_CONTACTS_REQUEST';
export const SEARCH_CONTACTS_SUCCESS = 'SEARCH_CONTACTS_SUCCESS';
export const SEARCH_CONTACTS_FAILURE = 'SEARCH_CONTACTS_FAILURE';

export const EDIT_CONTACT_REQUEST = 'EDIT_CONTACT_REQUEST';
export const EDIT_CONTACT_SUCCESS = 'EDIT_CONTACT_SUCCESS';
export const EDIT_CONTACT_FAILURE = 'EDIT_CONTACT_FAILURE';

export const ADD_CONTACT_REQUEST = 'ADD_CONTACT_REQUEST';
export const ADD_CONTACT_SUCCESS = 'ADD_CONTACT_SUCCESS';
export const ADD_CONTACT_FAILURE = 'ADD_CONTACT_FAILURE';

export const FETCH_MY_PROFILE_DATA_REQUEST = 'FETCH_MY_PROFILE_DATA_REQUEST';
export const FETCH_MY_PROFILE_DATA_SUCCESS = 'FETCH_MY_PROFILE_DATA_SUCCESS';
export const FETCH_MY_PROFILE_DATA_FAILURE = 'FETCH_MY_PROFILE_DATA_FAILURE';

export const FETCH_USER_STATS_REQUEST = 'FETCH_USER_STATS_REQUEST';
export const FETCH_USER_STATS_SUCCESS = 'FETCH_USER_STATS_SUCCESS';
export const FETCH_USER_STATS_FAILURE = 'FETCH_USER_STATS_FAILURE';

export const DELETE_CONTACT_REQUEST = 'DELETE_CONTACT_REQUEST';
export const DELETE_CONTACT_SUCCESS = 'DELETE_CONTACT_SUCCESS';
export const DELETE_CONTACT_FAILURE = 'DELETE_CONTACT_FAILURE';

export const ASSIGNEE_CREATED_EVENT = "ASSIGNEE_CREATED_EVENT";

export const ASSIGNEE_UPDATED_EVENT = "ASSIGNEE_UPDATED_EVENT";

export const ASSIGNEE_REMOVED_EVENT = "ASSIGNEE_REMOVED_EVENT";
