import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { FieldWrapper, Label } from '../../../input/input-components';
import TextArea from '../../../textarea/TextArea';
import { MAX_DESCRIPTION_LENGTH } from '../../../../utils/constants';

export const removeQuotes = (string: string) => {
  const removeNewLine = string.replace(/(\r\n|\n|\r)/gm, '');
  return removeNewLine.replace(/"/g, '\\"');
};

interface TaskDescriptionProps {
  isMobile?: boolean;
  editState: boolean;
  singlePage: boolean;
  editTaskData: any;
  originalDescription: string;
  fieldWrapperStyle: React.CSSProperties;
}
const TaskDescription = ({
  isMobile,
  editState,
  editTaskData,
  originalDescription,
  singlePage,
  fieldWrapperStyle,
}: TaskDescriptionProps) => {
  const [descriptionValue, setDescriptionValue] = useState(originalDescription);

  useEffect(() => {
    setDescriptionValue(originalDescription);
    // eslint-disable-next-line
  }, [editState]);

  return (
    <>
      {originalDescription.length < 30 && originalDescription.length > 0 && !editState && (
        <FieldWrapper style={fieldWrapperStyle}>
          <Label>Description:</Label>
          <DescriptionValue
            style={{
              width: '245px',
              paddingLeft: isMobile ? '0px' : '10px',
              maxHeight: singlePage ? '360px' : '150px',
            }}
          >
            {originalDescription}
          </DescriptionValue>
        </FieldWrapper>
      )}
      {originalDescription.length >= 30 && !editState && (
        <div>
          <Label>Description:</Label>
          <DescriptionValue
            style={{
              width: '100%',
              wordBreak: 'normal',
              maxHeight: singlePage ? '360px' : '150px',
              paddingLeft: '0px',
            }}
          >
            {originalDescription}
          </DescriptionValue>
        </div>
      )}
      {editState && (
        <div>
          <Label>Description:</Label>
          <TextArea
            value={descriptionValue}
            style={{ minHeight: '200px' }}
            onChange={(e: React.FormEvent<HTMLInputElement>) => {
              setDescriptionValue(e.currentTarget.value);
              editTaskData({ description: removeQuotes(e.currentTarget.value) });
            }}
            maxLength={MAX_DESCRIPTION_LENGTH}
          />
        </div>
      )}
    </>
  );
};

export default TaskDescription;

const DescriptionValue = styled.div`
  font-size: 13px;
  text-align: left;
  color: #676675;
  margin-top: 5px;
  line-height: 18px;
  max-height: 150px;
  overflow-y: auto;
  overflow-x: hidden;
  word-break: break-all;
  font-family: 'Nunito Sans', sans-serif;
  font-weight: 600;
  white-space: pre-wrap;
  overflow-wrap: anywhere;
`;
