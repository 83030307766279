import styled from 'styled-components';

export const SubTitle = styled.div`
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: bold;
  font-size: 14px;
  color: #9ba3ad;
  margin: 20px 0;
`;
export const ReadOnlyValue = styled.div`
  color: #5e6a7a;
  font-weight: bold;
  font-size: 13px;
  letter-spacing: 1px;
  width: 280px;
  text-align: left;
  padding-top: 6px;
`;
