import { ofType } from 'redux-observable';
import { map, mergeMap } from 'rxjs/operators';
import { from } from 'rxjs';
import api from '../../api';
import { AppEpic } from '../../store/epics';
import {
  RESEND_EMAIL_VERIFICATION_REQUEST,
  resendEmailVerification,
} from './VerifyEmailPrompt/redux/actions';
import { navigateTo } from '../../store/actions';
import { LOG_OUT_WITH_REDIRECT, fetchInitialData, logOut } from './Login/redux/actions';
import { VERIFY_EMAIL_REQUEST, verifyEmail } from './VerifyEmailConfirmation/redux/actions';
import { FETCH_INITIAL_DATA_REQUEST } from './Login/redux/actions';
import { toast } from 'react-toastify';
import routes from '../../lib/routes';
import {
  emailVerificationSentEvent,
  emailVerifiedEvent,
  userSignedInEvent,
} from 'api/amplitude/events';

export const resendEmailVerificationEpic: AppEpic = (action$, state$) =>
  action$.pipe(
    ofType(RESEND_EMAIL_VERIFICATION_REQUEST),
    mergeMap((action) =>
      from(api.resendEmailVerification(action.payload)).pipe(
        map((response) => {
          if ((response as Api.GraphQL.Errors).errors) {
            toast('Oops something went wrong.', { type: 'error' });
            return resendEmailVerification.failure(false);
          } else {
            emailVerificationSentEvent(state$.value.me.id);
            return resendEmailVerification.success(true);
          }
        }),
      ),
    ),
  );
// todo dont delete this
// export const unauthorizedSignOutEpic: AppEpic = (action$) =>
//     action$.pipe(
//         ofType("ERROR_FULFILLED"),
//         filter((action) => action.errors.errors.includes("UNAUTHORIZED")),
//         map((_) => logOutWithRedirect()),
//     );

export const unauthorizedSignOutRedirectEpic: AppEpic = (action$) =>
  action$.pipe(
    ofType(LOG_OUT_WITH_REDIRECT),
    map((_) => navigateTo(routes.login)),
  );

export const verifyEmailEpic: AppEpic = (action$, state$) =>
  action$.pipe(
    ofType(VERIFY_EMAIL_REQUEST),
    mergeMap((action) =>
      from(api.verifyEmail(action.payload)).pipe(
        map((response) => {
          if ((response as Api.ApiError).error) {
            verifyEmail.failure((response as Api.ApiError).message);
          } else {
            emailVerifiedEvent(state$.value.me.id);
            verifyEmail.success(response as boolean);
          }
        }),
      ),
    ),
  );

export const fetchInitialDataEpic: AppEpic = (action$, state$) =>
  action$.pipe(
    ofType(FETCH_INITIAL_DATA_REQUEST),
    mergeMap((action) =>
      from(api.getInitialData()).pipe(
        map((response) => {
          if ((response as Api.ApiError).error) {
            toast((response as Api.ApiError).message, { type: 'error' });
            return logOut();
          } else {
            if (action.payload.loggedIn) {
              const id: string = (response as User.Me).id;
              userSignedInEvent(id);
            }
            return fetchInitialData.success(response as User.Me);
          }
        }),
      ),
    ),
  );
