import React, { useEffect, useState } from 'react';
import TaskCard from '../task-components/task-card/TaskCard';
import styled from 'styled-components';
import { connect } from 'react-redux';
import {
  FetchContactTaskParams,
  fetchContactTasks,
} from '../../modules/Contacts/redux/contact-tasks/actions';
import { Dispatch } from 'redux';
import { fetchSingleTask } from '../../modules/Tasks/redux/actions';
import { AppState } from '../../store';
import isEmpty from 'lodash/isEmpty';
import { TaskFilter } from '../task-components/get-task-options';
import withWindowSize from '../with-window-size/withWindowSize';
import withPageSize from '../with-page-size/withPageSize';
import TasksFilterSwitch from '../task-components/tasks-filter-switch/tasks-filter-switch';
import { ContactTasksReducer } from '../../modules/Contacts/redux/contact-tasks/reducer';
import LoadMoreButton from '../task-components/tasks-grid/tasks-section/LoadMoreButton';
import { sortActiveTasks } from 'lib/sort-tasks';

interface ContactDetailTaskListProps {
  me: User.Me;
  selectedTaskId: string;
  fetchTasksList(filter: FetchContactTaskParams): void;
  fetchTask(id: string): void;
  selectedContactId: string;
  taskList: ContactTasksReducer;
  loadingTasks: boolean;
  hideLoadingActive: boolean;
  hideLoadingCompleted: boolean;
}

const initialTasks = {
  [TaskFilter.ACTIVE]: [],
  [TaskFilter.COMPLETED]: [],
};

const ContactDetailTaskList = ({
  me,
  selectedTaskId,
  fetchTasksList,
  fetchTask,
  selectedContactId,
  taskList,
  loadingTasks,
  hideLoadingActive,
  hideLoadingCompleted,
}: ContactDetailTaskListProps) => {
  const { isMobile } = withWindowSize();
  const { pageSize } = withPageSize(true);

  const [contactId, setContactId] = useState(selectedContactId);
  const [filter, setFilter] = useState(TaskFilter.ACTIVE);
  const [offsetActive, setOffsetActive] = useState(0);
  const [offsetCompleted, setOffsetCompleted] = useState(0);
  const [tasks, setTasks] = useState<{ [key: string]: Task[] }>(initialTasks);

  useEffect(() => {
    const _tasks: { [key: string]: Task[] } = {};
    _tasks[TaskFilter.ACTIVE] = sortActiveTasks(
      Object.keys(taskList[TaskFilter.ACTIVE]).map((key) => taskList[TaskFilter.ACTIVE][key]),
    );
    _tasks[TaskFilter.COMPLETED] = Object.keys(taskList[TaskFilter.COMPLETED]).map(
      (key) => taskList[TaskFilter.COMPLETED][key],
    );
    setTasks(_tasks);
  }, [taskList]);

  const onLoadMore = () => {
    if (filter === TaskFilter.ACTIVE) {
      setOffsetActive((offset) => offset + pageSize);
    } else if (filter === TaskFilter.COMPLETED) {
      setOffsetCompleted((offset) => offset + pageSize);
    }
  };

  useEffect(() => {
    if (selectedContactId !== contactId) {
      setContactId(selectedContactId);
      setOffsetActive(0);
      setOffsetCompleted(0);
    }
    // eslint-disable-next-line
  }, [selectedContactId]);

  useEffect(() => {
    fetchTasksList({
      id: contactId,
      filter: filter,
      first: pageSize,
      offset: filter === TaskFilter.ACTIVE ? offsetActive : offsetCompleted,
    });
    // eslint-disable-next-line
  }, [contactId, filter, offsetActive, offsetCompleted]);

  return (
    <div>
      <TasksFilterSwitch left isMobile={isMobile} filter={filter} setFilter={setFilter} />
      <>
        {isEmpty(taskList[filter]) && (
          <TaskListEmptyState>This user has no {filter} tasks.</TaskListEmptyState>
        )}

        <TasksHolder isMobile={isMobile} loadingTask={loadingTasks}>
          <TaskList>
            {tasks[filter].map((task) => (
              <div key={task?.id}>
                <TaskCard
                  // @ts-ignore
                  key={task?.id}
                  isSelected={selectedTaskId === task?.id}
                  onTaskClick={(e: Event, info: string) => {
                    e.stopPropagation();
                    fetchTask(info);
                  }}
                  me={me}
                  {...task}
                />
              </div>
            ))}
          </TaskList>
        </TasksHolder>
        {!isEmpty(taskList[filter]) &&
          ((filter === TaskFilter.ACTIVE && !hideLoadingActive) ||
            (filter === TaskFilter.COMPLETED && !hideLoadingCompleted)) && (
            <LoadMoreButton onClick={() => onLoadMore()} loading={loadingTasks} />
          )}
      </>
    </div>
  );
};

const mapStateToProps = (state: AppState) => ({
  selectedContactId: state.contacts.selectedContactId,
  taskList: state.contactTasks,
  loadingTasks: state.contactTasks.loading,
  me: state.me,
  selectedTaskId: state.selectedTask.id,
  selectedTask: state.selectedTask,
  hideLoadingActive: state.contactTasks.allActiveFetched,
  hideLoadingCompleted: state.contactTasks.allCompletedFetched,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchTask: (id: string) => dispatch(fetchSingleTask.request({ id })),
  fetchTasksList: ({ id, filter, first, offset }: FetchContactTaskParams) =>
    dispatch(fetchContactTasks.request({ id, filter, first, offset })),
});

export default connect(mapStateToProps, mapDispatchToProps)(ContactDetailTaskList);

const TaskListEmptyState = styled.div`
  font-size: 18px;
  font-weight: bold;
  color: #5d7289;
  width: 100%;
  text-align: center;
  margin-top: 60px;
`;
const TasksHolder = styled.div<{ isMobile: boolean; loadingTask: boolean }>`
  opacity: ${(props) => (!props.loadingTask ? 1 : 0)};
  transition: opacity 1s linear;
  padding: ${(props) => (props.isMobile ? '0 20px' : '0')};
`;

export const TaskList = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin: 10px 0;
`;
