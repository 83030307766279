import React from 'react';
import { ReactComponent as Arrow } from '../../assets/graphics/arrow.svg';
import { ReactComponent as Calendar } from '../../assets/graphics/calendar.svg';
import { ReactComponent as Clock } from '../../assets/graphics/clock.svg';
import { ReactComponent as Feed } from '../../assets/graphics/feather.svg';
import { ReactComponent as Tasks } from '../../assets/graphics/layers.svg';
import { ReactComponent as LinkIndicator } from '../../assets/graphics/linkIndicator.svg';
import { ReactComponent as Plus } from '../../assets/graphics/plus.svg';
import { ReactComponent as User } from '../../assets/graphics/user.svg';
import { ReactComponent as Users } from '../../assets/graphics/users.svg';
import { ReactComponent as Bell } from '../../assets/graphics/bell.svg';
import { ReactComponent as Close } from '../../assets/graphics/close.svg';
import { ReactComponent as NewTab } from '../../assets/graphics/newTab.svg';
import { ReactComponent as More } from '../../assets/graphics/more.svg';
import { ReactComponent as Search } from '../../assets/graphics/search.svg';
import { ReactComponent as Check } from '../../assets/graphics/check.svg';
import * as colors from '../../lib/colors';

interface IconProps {
  color?: string;
  style?: React.CSSProperties;
}

export const CalendarIcon = ({ color, style }: IconProps) => (
  <Calendar
    style={{
      ...style,
      fill: color || colors.base,
    }}
  />
);

export const ClockIcon = ({ color, style }: IconProps) => (
  <Clock
    style={{
      ...style,
      stroke: color || colors.base,
    }}
  />
);

export const DownArrowIcon = ({ color, style }: IconProps) => (
  <Arrow
    style={{
      ...style,
      stroke: color || colors.base,
      transform: 'rotate(180deg)',
      width: '24px',
    }}
  />
);

export const FeedIcon = ({ color, style }: IconProps) => (
  <Feed
    style={{
      ...style,
      fill: color || colors.base,
    }}
  />
);

export const LeftArrowIcon = ({ color, style }: IconProps) => (
  <Arrow
    style={{
      ...style,
      stroke: color || colors.base,
      transform: 'rotate(-90deg)',
      width: '24px',
    }}
  />
);

export const LinkIndicatorIcon = ({ color, style }: IconProps) => (
  <div
    style={{
      height: '12px', // TODO: No clue.
    }}
  >
    <LinkIndicator
      style={{
        ...style,
        fill: color || colors.base,
      }}
    />
  </div>
);

export const PlusIcon = ({ color, style }: IconProps) => (
  <Plus
    style={{
      ...style,
      fill: color || colors.base,
    }}
  />
);

export const TasksIcon = ({ color, style }: IconProps) => (
  <Tasks
    style={{
      ...style,
      fill: color || colors.base,
    }}
  />
);

export const UpArrowIcon = ({ color, style }: IconProps) => (
  <Arrow
    style={{
      ...style,
      stroke: color || colors.base,
      width: '24px',
    }}
  />
);

export const UserIcon = ({ color, style }: IconProps) => (
  <User
    style={{
      ...style,
      fill: color || colors.base,
    }}
  />
);

export const UsersIcon = ({ color, style }: IconProps) => (
  <div>
    <Users
      style={{
        ...style,
        fill: color || colors.base,
      }}
    />
  </div>
);

export const BellIcon = ({ color, style }: IconProps) => (
  <Bell style={{ ...style, fill: color || colors.base }} />
);

export const CloseIcon = ({ style, color }: IconProps) => (
  <Close style={{ ...style, fill: color || '#9BA3AD' }} />
);

export const NewTabIcon = ({ style, color }: IconProps) => (
  <NewTab style={{ ...style, fill: color || colors.base }} />
);

export const MoreIcon = ({ style, color }: IconProps) => (
  <More style={{ ...style, fill: color || colors.base }} />
);

export const SearchIcon = ({ style, color }: IconProps) => (
  <Search style={{ ...style, fill: color || colors.base }} />
);

export const CheckIcon = ({ style, color }: IconProps) => (
  <Check style={{ ...style, fill: color || colors.base }} />
);
