import { getMutationResponse } from '../../client';
import { gql } from '@apollo/client';
import { mapTask } from '../../../mappers/tasks/map-task';
import { taskCardFragment } from '../../fragments';
import errorMapper from '../../../mappers/error-mapper';

const mutation = gql`
  mutation markTaskAsSuccessful($taskId: UUID!) {
    markTaskAsSuccessful(id: $taskId) {
      ...Task
    }
  }
  ${taskCardFragment}
`;

const markSuccessful = async (args: string): Promise<Task | Api.ApiError> => {
  const response = await getMutationResponse(mutation, { taskId: args });
  if (response.data !== null) {
    return mapTask(response.data.markTaskAsSuccessful);
  }
  return errorMapper(response as Api.GraphQL.Errors);
};

export default markSuccessful;
