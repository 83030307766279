import { getMutationResponse } from '../../client';
import { gql } from '@apollo/client';
import { taskCardFragment } from '../../fragments';
import { mapTask } from '../../../mappers/tasks/map-task';
import errorMapper from '../../../mappers/error-mapper';

const mutation = gql`
  mutation rejectTask($taskId: UUID!) {
    rejectTask(id: $taskId) {
      ...Task
    }
  }
  ${taskCardFragment}
`;

const rejectTask = async (args: string): Promise<Task | Api.ApiError> => {
  const response = await getMutationResponse(mutation, { taskId: args });
  if (response.data !== null) {
    return mapTask(response.data.rejectTask);
  }
  return errorMapper(response as Api.GraphQL.Errors);
};

export default rejectTask;
