import React from 'react';
import styled from 'styled-components';

const WarningContentHeading = styled.div`
  font-size: 13px;
  line-height: 15px;
  color: #405267;
  font-weight: bold;
  margin-top: 23px;
`;

const WarningContent = styled.ul`
  margin-top: 23px;
`;

const WarningContentItem = styled.li`
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 24px;
  color: #9BA3AD;
  margin-left: -20px;
`;

export const DeleteModalContent = <>
  <WarningContentHeading>Warning, this action cannot be undone</WarningContentHeading>
    <WarningContent>
      <WarningContentItem>All user’s active tasks will be marked as rejected</WarningContentItem>
      <WarningContentItem>You will not be able to add new tasks for this user</WarningContentItem>
      <WarningContentItem>All of the contact's data will be deleted</WarningContentItem>
  </WarningContent>
</>
