import { getQueryResponse } from '../../client';
import { tasksArrayToTaskObject } from '../../../mappers/tasks/tasks-array-to-object';
import errorMapper from '../../../mappers/error-mapper';
import { FetchTasksResponse } from 'modules/Tasks/redux/actions';
import { gql } from "@apollo/client";
import { tasksFragment } from 'api/fragments';
import { TaskFilter } from 'components/task-components/get-task-options';

const getActiveTasks = gql`
  query activeTasks($first: Int, $offset: Int) {
    tasks: activeTasks(first: $first, offset: $offset) {
      ...TasksFragment
    }
  }
  ${tasksFragment}
`;

const getCompletedTasks = gql`
  query completedTasks($first: Int, $offset: Int) {
    tasks: completedTasks(first: $first, offset: $offset) {
      ...TasksFragment
    }
  }
  ${tasksFragment}
`;

export interface Tasks {
  [key: string]: Task;
}

interface Response {
  tasks: Array<Api.Task>;
}

export const fetchCompletedTasks = async (
  args: FetchTasksResponse,
): Promise<Tasks | Api.ApiError> => {
  const response = await getQueryResponse<Response>(getCompletedTasks, args);
  if (response?.data) {
    return tasksArrayToTaskObject(response.data.tasks);
  }
  return errorMapper(response);
};

export const fetchActiveTasks = async (token: string, args: any): Promise<Tasks | Api.ApiError> => {
  const response = await getQueryResponse<Response>(getActiveTasks, args);
  if (response?.data) {
    return tasksArrayToTaskObject(response.data.tasks);
  }
  return errorMapper(response);
};

export const fetchTasksByFilter = async (filter: TaskFilter, args: any): Promise<Tasks | Api.ApiError> => {
  const response = await getQueryResponse<Response>(
    filter === TaskFilter.ACTIVE ? getActiveTasks : getCompletedTasks,
    args
  );
  if (response?.data) {
    return tasksArrayToTaskObject(response.data.tasks);
  }
  return errorMapper(response);
};

