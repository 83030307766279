import { createAction } from 'typesafe-actions';

export const OPEN_MODAL = 'OPEN_MODAL';
export const CLOSE_MODAL = 'CLOSE_MODAL';

export interface ModalSettings {
  content?: any;
  title?: string;
  onConfirm?: ClickHandler;
  type?: ModalType;
  confirmButtonText?: string;
  template?: JSX.Element;
}

export enum ModalType {
  CONFIRM,
  WARNING,
  DELETE,
}

export const openModal = createAction(OPEN_MODAL)<ModalSettings>();
export const closeModal = createAction(CLOSE_MODAL)();

export type ModalActions = ReturnType<typeof openModal> | ReturnType<typeof closeModal>;
