import React, { useEffect, useState } from 'react';
import { Container, FieldsWrapperPadding } from '../add-new-contact-form/AddNewContact';
import { FieldWrapper, Label } from '../input/input-components';
import TimePicker from '../timepicker/TimePicker';
import TextArea from '../textarea/TextArea';
import Input from '../../components/input/Text-Input';
import withWindowSize from '../with-window-size/withWindowSize';
import Dropdown from '../dropdown/Dropdown';
import DateInput from '../date-input/DateInput';
import styled from 'styled-components';
import { AppState } from '../../store';
import { Dispatch } from 'redux';
import { fetchContactList } from '../../modules/Contacts/redux/actions';
import { connect } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import { closePopup } from '../popup/redux/actions';
import { addNewTask, IAddTask } from '../../modules/Tasks/redux/actions';
import { mapUsersToDropdown } from '../../mappers/contacts/contact-mappers';
import { createDateForTask, checkDeadline } from '../../mappers/date/date-mappers';
import AddNewTaskFooter from './AddNewTaskFooter';
import { removeQuotes } from '../task-components/task-full-data/task-edit-form/TaskDescription';
import { MAX_DESCRIPTION_LENGTH } from '../../utils/constants';

interface AddNewTaskProps {
  panel?: boolean;
  userId?: string;
  userName?: string;
  popupId: string;
  me: User.Me;
  contactList: {};
  actionInProgress: string[];
  fetchContacts(): void;
  closePopup(id: string): void;
  addNewTask(task: IAddTask, user: User.Me): void;
}

const MAX_INPUT_LENGTH = 30;

const AddNewTask = ({
  contactList,
  fetchContacts,
  popupId,
  userId = '',
  userName = '',
  closePopup,
  addNewTask,
  me,
  actionInProgress,
}: AddNewTaskProps) => {
  useEffect(() => {
    if (isEmpty(contactList)) {
      fetchContacts();
    }
    // eslint-disable-next-line
  }, []);

  const { isMobile } = withWindowSize();
  const InputStyle = { width: isMobile ? '100%' : '235px' };
  const DateInputStyle = { width: 'calc(50% - 5px)' };
  const filedWrapperStyle = { height: isMobile ? '75px' : '50px' };
  const descriptionWrapperStyle = { height: '100px' };

  const [titleValue, setTitleValue] = useState('');
  const [assigneeValue, setAssigneeValue] = useState({
    value: userId,
    label: userName,
  });
  const [dateValue, setDateValue] = useState(new Date());
  const [timeValue, setTimeValue] = useState();
  const [descriptionValue, setDescriptionValue] = useState('');

  const [timeError, setTimeError] = useState(false);

  const mandatoryFields: boolean =
    !timeError &&
    !(titleValue.length > MAX_INPUT_LENGTH) &&
    titleValue !== '' &&
    assigneeValue.value !== '' &&
    !actionInProgress.find((a) => a === popupId);

  const assignTask = () => {
    addNewTask(
      {
        userId: me?.id,
        popupId,
        title: titleValue,
        assigneeId: assigneeValue.value,
        deadline: createDateForTask(dateValue, timeValue as any),
        description: removeQuotes(descriptionValue),
      },
      me,
    );
  };

  const handleEnter = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && mandatoryFields) {
      assignTask();
    }
  };

  return (
    <Container isMobile={isMobile}>
      <FieldsWrapperPadding>
        <FieldWrapper breakViewParent={isMobile} style={filedWrapperStyle}>
          <Label>Title:*</Label>
          <Input
            small
            placeholder={'Title'}
            style={InputStyle}
            value={titleValue}
            error={!!(titleValue && titleValue.length > 30)}
            errorMessage={'Title can be maximum 30 characters.'}
            onChange={(event) => setTitleValue(event.target.value)}
            onKeyDown={handleEnter}
            maxLength={MAX_INPUT_LENGTH}
          />
        </FieldWrapper>

        <FieldWrapper breakViewParent={isMobile} style={filedWrapperStyle}>
          <Label>Assignee:*</Label>
          <div style={InputStyle}>
            <Dropdown
              small={!isMobile}
              onChange={(value) => setAssigneeValue(value)}
              value={assigneeValue}
              options={mapUsersToDropdown(contactList)}
            />
          </div>
        </FieldWrapper>

        <FieldWrapper breakViewParent={isMobile} style={filedWrapperStyle}>
          <Label>Due date:*</Label>
          <DateTimeHolders isMobile={isMobile}>
            <DateInput
              style={DateInputStyle}
              minDate={new Date()}
              smallInput
              onChange={(value) => {
                setDateValue(value);
                checkDeadline(timeValue, value, setTimeError);
              }}
              value={dateValue}
            />

            <TimePicker
              onBlur={(value) => {
                setTimeValue(value as any);
                checkDeadline(value, dateValue, setTimeError);
              }}
              smallInput={!isMobile}
              style={DateInputStyle}
              time={timeValue}
              error={timeError}
              errorMessage={'Please set at least 30 min for deadline'}
            />
          </DateTimeHolders>
        </FieldWrapper>

        <FieldWrapper breakViewParent={isMobile} style={descriptionWrapperStyle}>
          <Label>Description:</Label>
          <TextArea
            style={InputStyle}
            value={descriptionValue}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setDescriptionValue(e.target.value)
            }
            maxLength={MAX_DESCRIPTION_LENGTH}
          />
        </FieldWrapper>
      </FieldsWrapperPadding>

      <AddNewTaskFooter
        assignTask={assignTask}
        popupId={popupId}
        mandatoryFields={!mandatoryFields!}
        isMobile={isMobile}
        closePopup={closePopup}
      />
    </Container>
  );
};

const mapStateToProps = (state: AppState) => ({
  contactList: state.contacts.contacts,
  actionInProgress: state.contactTasks.actionInProgress,
  me: state.me,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchContacts: () => dispatch(fetchContactList.request()),
  closePopup: (popupId: string) => dispatch(closePopup(popupId)),
  addNewTask: (task: IAddTask, me: User.Me) => dispatch(addNewTask.request(task)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddNewTask);

export const DateTimeHolders = styled.div<{ isMobile: boolean }>`
  width: 245px;
  display: flex;
  justify-content: space-between;
`;
