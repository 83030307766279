import { ContactsLink, TasksLink } from './navigation-links/NavigationLinks';
import React from 'react';
import AddButton from '../buttons/add-new/AddButton';
import * as colors from '../../lib/colors';
import routes from '../../lib/routes';
import './Navigation.styles.scss';
import classnames from 'classnames';
import { AppState } from 'store';
import { Dispatch } from 'redux';
import { togglePanel } from 'components/panel/redux/action';
import { connect } from 'react-redux';

interface PropsFromDispatch {
  navigateTo(path: string): ClickHandler;
}
interface NavigationProps extends PropsFromDispatch {
  isMobile: boolean;
  path: string;
  panelOpened: boolean;
  setPanelClosed(): void;
  isLoggedIn?: boolean;
}

const Navigation = ({
  isMobile,
  navigateTo,
  path,
  panelOpened,
  setPanelClosed,
  isLoggedIn,
}: NavigationProps) => {
  const taskDetailsPage: boolean = window.location?.pathname?.search('tasks/') > 0 && !isLoggedIn;
  return !taskDetailsPage ? (
    <div
      className={classnames([
        'Navigation',
        {
          'Navigation-Mobile': isMobile,
          'Navigation-Desktop': !isMobile,
        },
      ])}
    >
      <TasksLink
        isMobile={isMobile}
        isActive={path.includes(routes.tasks)}
        onClick={navigateTo(routes.tasks)}
      />
      {isMobile && <AddButton path={path} isMobile={isMobile} color={colors.baseDarker} />}
      <ContactsLink
        isMobile={isMobile}
        isActive={path === routes.contacts}
        onClick={(event: any) => {
          navigateTo(routes.contacts)(event);
          if (panelOpened && isMobile) {
            setPanelClosed();
          }
        }}
      />
    </div>
  ) : null;
};

const mapStateToProps = (state: AppState) => ({
  panelOpened: state.panel.openPanel,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setPanelClosed: () => dispatch(togglePanel(false)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Navigation);
