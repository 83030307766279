import { getQueryResponse } from '../../client';
import { gql } from "@apollo/client";
import { mapMe } from '../../../mappers/user/map-me';
import errorMapper from '../../../mappers/error-mapper';

const query = gql`
  query GetInitialData {
    me {
      id
      firstName
      lastName
      email
      mobile
      emailVerified
      preference {
        mobileNotifications
        emailNotifications
        timezone
        dailyReportTime
        dailyReport
      }
    }
  }
`;

export interface FetchInitialDataResponse {
  me: Api.Me;
}
const getInitialData = async (): Promise<User.Me | Api.ApiError> => {
  const response = await getQueryResponse<FetchInitialDataResponse>(query);
  if (response?.data && response.data?.me) {
    return mapMe(response.data.me);
  }
  return errorMapper(response as Api.GraphQL.Errors);
};

export default getInitialData;
