import React, { useEffect, useState } from 'react';
import { ClockIcon } from '../icons/Icons';
import styled from 'styled-components';
import * as colors from '../../lib/colors';
import { inputColors } from '../input/input-colors';
import { validNumEvents } from '../verify-code/VerifyCode';
import moment from 'moment';

interface TimePickerProps {
  smallInput?: boolean;
  time?: string;
  hasIcon?: boolean;
  error?: boolean;
  largeInput?: boolean;
  active?: boolean;
  style?: React.CSSProperties;
  disabled?: boolean;
  onBlur(val: { minutes: string; hours: string }): void;
  errorMessage?: string;
}

const TIME_VALUE_LENGTH = 5;

export const getInputColor = (active?: boolean, error?: boolean, disabled?: boolean) => {
  if (active) {
    return inputColors.borderColorActive;
  } else if (disabled) {
    return inputColors.borderColorDisabled;
  } else if (error) {
    return inputColors.borderColorError;
  } else return inputColors.borderColor;
};

const handleArrowUp = (setFn: (v: string) => void, val: string, maxVal: number) => {
  if (val === String(maxVal - 1)) {
    setFn('00');
    return;
  }
  setFn(addZeroToSingleValue(String(Number(val) + 1)));
};

const handleArrowDown = (setFn: (v: string) => void, val: string, maxVal: number) => {
  if (val === '00') {
    setFn(String(maxVal - 1));
    return;
  }
  setFn(addZeroToSingleValue(String(Number(val) - 1)));
};

const handleBakspace = (
  event: React.KeyboardEvent<HTMLInputElement>,
  setTimeFn: (v: string) => void,
  val: string,
  maxVal: number,
) => {
  if (val.length > 0) {
    val = val.substring(0, val.length - 1);
  }
  setTimeFn(addZeroToSingleValue(val));
  event.preventDefault();
};

const mergeVal = (
  event: React.KeyboardEvent<HTMLInputElement>,
  setTimeFn: (v: string) => void,
  val: string,
  maxVal: number,
) => {
  if (val.length === 2) {
    const merge = addZeroToSingleValue(`${val[1]}${event.key}`);
    if (Number(merge) < maxVal && Number(merge) >= 0) {
      setTimeFn(addZeroToSingleValue(merge));
    } else {
      setTimeFn(addZeroToSingleValue(event.key));
    }
    return;
  } else {
    setTimeFn(addZeroToSingleValue(val));
  }
};

const setTimeValues = (
  event: React.KeyboardEvent<HTMLInputElement>,
  setTimeFn: (v: string) => void,
  val: string,
  maxVal: number,
) => {
  if (event.nativeEvent.code === 'Backspace') {
    handleBakspace(event, setTimeFn, val.toString(), maxVal);
  } else if (event.nativeEvent.code === 'ArrowUp') {
    handleArrowUp(setTimeFn, val.toString(), maxVal);
  } else if (event.nativeEvent.code === 'ArrowDown') {
    handleArrowDown(setTimeFn, val.toString(), maxVal);
  } else if (validNumEvents.filter((num) => num === event.key).length) {
    mergeVal(event, setTimeFn, val, maxVal);
  } else {
    event.preventDefault();
    return;
  }
};

export const addZeroToSingleValue = (value: string | number) => 
  value.toString().length === 1 ? value.toString().padStart(2, '0')
  : value.toString().length >= 3 ? value.toString().substr(0, 1)
  : value.toString();
;

const TimePicker = ({
  smallInput = true,
  time,
  hasIcon,
  error,
  largeInput,
  style,
  onBlur,
  errorMessage,
  disabled,
}: TimePickerProps) => {
  const [isActive, setIsActive] = useState(false);

  const [hoursValue, setHoursValue] = useState('');
  const [minutesValue, setMinutesValue] = useState('');

  const [initialHour, saveInitialHour] = useState('');
  const [initialMinute, saveInitialMinute] = useState('');

  useEffect(() => {
    if (!time) {
      let hours: string | number = new Date().getHours();
      let minutes: string | number = new Date().getMinutes();

      if (minutes + 35 >= 59) {
        hours = ((hours + 1) < 24 ? (hours + 1) : 0).toString();
        minutes = minutes.toString();
      } else {
        hours = hours.toString();
        minutes = (minutes + 35).toString();
      }

      minutes = addZeroToSingleValue(minutes);
      hours = addZeroToSingleValue(hours);

      setHoursValue(hours);
      setMinutesValue(minutes);
      saveInitialHour(hours);
      saveInitialMinute(minutes);
      onBlur({ hours, minutes });
    } else {
      if (time.length > TIME_VALUE_LENGTH) {
        const datetime = moment(time);
        setHoursValue(addZeroToSingleValue(datetime.hours().toString()));
        setMinutesValue(addZeroToSingleValue(datetime.minutes().toString()));
      } else {
        const hours: string = moment(time, "HH:mm").hours().toString();
        const minutes: string = moment(time, "HH:mm").minutes().toString();
        setHoursValue(addZeroToSingleValue(hours));
        setMinutesValue(addZeroToSingleValue(minutes));
      }
    }
    // eslint-disable-next-line
  }, []);

  const setValues = () => {
    let valuesObj = {
      hours: addZeroToSingleValue(hoursValue) || time || addZeroToSingleValue(initialHour),
      minutes: addZeroToSingleValue(minutesValue) || time || addZeroToSingleValue(initialMinute),
    };
    if (!minutesValue) {
      setMinutesValue(addZeroToSingleValue(initialMinute));
    }
    if (!hoursValue) {
      setHoursValue(addZeroToSingleValue(initialHour));
    }
    onBlur(valuesObj as any);
  };

  return (
    <TimePickerHolder
      onBlur={setValues}
      smallInput={smallInput}
      hasIcon={hasIcon}
      largeInput={largeInput}
      active={isActive}
      error={error}
    >
      <div>
        <ClockIcon
          style={{
            display: 'flex',
            alignItems: 'center',
            width: '16px',
            height: '16px',
            marginRight: '4px',
          }}
          color={getInputColor(isActive, error)}
        />
      </div>

      <TimePickerInput
        value={hoursValue}
        disabled={disabled}
        onKeyDown={(e) => setTimeValues(e, setHoursValue, addZeroToSingleValue(hoursValue), 24)}
        onChange={() => {}}
        onFocus={() => setIsActive(true)}
        onBlur={() => setIsActive(false)}
        side="right"
      />
      <FieldsDivider>:</FieldsDivider>
      <TimePickerInput
        value={minutesValue}
        disabled={disabled}
        onKeyDown={(e) => setTimeValues(e, setMinutesValue, addZeroToSingleValue(minutesValue), 60)}
        onChange={() => {}}
        onFocus={() => setIsActive(true)}
        onBlur={() => setIsActive(false)}
        side="left"
      />
      {/* todo add when other countries are enabled*/}
      {/*<TimePickerSelect  onFocus={() => setIsActive(true)}*/}
      {/*                   onBlur={() => setIsActive(false)}*/}
      {/*                   error={error}>*/}
      {/*    <option>AM</option>*/}
      {/*    <option>PM</option>*/}
      {/*</TimePickerSelect>*/}
      {error && <ErrorMessage>{errorMessage}</ErrorMessage>}
    </TimePickerHolder>
  );
};
export default TimePicker;

TimePicker.defaultPropd = {
  disabled: false,
}

interface TimePickerInputProps {
  side?: string;
}

const TimePickerHolder = styled.div<TimePickerProps>`
  height: ${(props) => (props.smallInput ? '28px' : props.largeInput ? '40px' : '36px')};
  width: 90px;
  border-radius: ${(props) => (props.smallInput ? '15px' : '20px')};
  border: 1px solid;
  border-color: ${(props) => getInputColor(props.active, props.error)};
  display: flex;
  color: #979797;
  align-items: center;
  justify-content: space-between;
  padding: 0 7px;
  position: relative;
  background-color: white;
  transition: border-color 0.3s;
`;

const TimePickerInput = styled.input<TimePickerInputProps>`
  border: none;
  outline: none;
  background: transparent;
  outline: none;
  color: #979797;
  padding: 0 1px;
  width: 90%;
  text-align: ${(props) => (props.side === 'left' ? 'left' : 'right')};
  font-family: Nunito Sans, sans-serif;
  font-weight: bold;
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
  }
`;

const FieldsDivider = styled.div`
  font-size: 14px;
  height: 100%;
  display: flex;
  align-items: center;
  margin: 0 1px;
  font-family: Nunito Sans, sans-serif;
  font-weight: bold;
`;
export const ErrorMessage = styled.div`
  text-align: right;
  color: ${colors.inputError};
  position: absolute;
  right: 0;
  bottom: -16px;
  width: 240px;
  font-size: 12px;
`;
