import { useState, useEffect } from 'react';
const WithWindowSize = () => {
  const [screenWidth, setWidth] = useState(document.documentElement.clientWidth);
  const [screenHeight, setHeight] = useState(document.documentElement.clientHeight);
  const [isMobile, setIsMobile] = useState(document.documentElement.clientWidth < 600);
  useEffect(() => {
    const listenerFunction = () => {
      setWidth(document.documentElement.clientWidth);
      setHeight(document.documentElement.clientHeight);
      setIsMobile(document.documentElement.clientWidth < 600);
    };
    window.addEventListener('resize', listenerFunction);
    return () => window.removeEventListener('resize', listenerFunction);
  }, []);

  return {
    screenWidth,
    screenHeight,
    isMobile,
  };
};
export default WithWindowSize;
