import React, { useEffect, useState } from 'react';
import alphabet from './alphabet';
import { SearchIcon } from '../icons/Icons';
import { connect } from 'react-redux';
import {
  fetchContactList,
  fetchMyProfileData,
  searchContacts,
  selectContact,
} from '../../modules/Contacts/redux/actions';
import { Dispatch } from 'redux';
import { openPopup, IPopupSettings } from '../popup/redux/actions';
import { AppState } from '../../store';
import LoadingIndicator from '../loading/LoadingIndicator';
import ContactDetail from '../contact-detail/ContactDetail';
import withWindowSize from '../with-window-size/withWindowSize';
import findKey from 'lodash/findKey';
import { scrollToLetter } from './utils';
import MyContact from './MyContact';

import {
  List,
  Alphabet,
  ContactHolder,
  ContactListHolder,
  Letter,
  SearchHolder,
  SearchInput,
  Loading,
} from './contact-list-components';
import { ContactListFrame } from './ContactListFrame';
import { contactsSearchedEvent } from 'api/amplitude/events';

interface ContactListProps {
  contactList: any;
  openContactPopup(settings: IPopupSettings): void;
  selectContactAction(id: string): void;
  fetchContactListAction(): void;
  loadingContactsList: boolean;
  selectedContactId?: string;
  searchContacts(value: string): void;
  searchedContacts: undefined;
  me: User.Me;
  fetchMyProfileDataFn(): void;
}

export const checkForItemsInList = (list: any) => {
  return findKey(list, (value) => {
    return value.length > 0;
  });
};

const ContactList = ({
  contactList,
  openContactPopup,
  selectContactAction,
  fetchContactListAction,
  loadingContactsList,
  selectedContactId,
  searchContacts,
  searchedContacts,
  me,
  fetchMyProfileDataFn,
}: ContactListProps) => {
  const [searchValue, changeInputValue] = useState('');
  const [activeId, setActiveContact] = useState(selectedContactId as string);
  const { isMobile } = withWindowSize();

  const launchEvent = () => {
    contactsSearchedEvent(me.id);
  };

  useEffect(() => {
    if (!checkForItemsInList(contactList)) {
      fetchContactListAction();
    }
    // eslint-disable-next-line
  }, []);

  const contactPopupSettings: IPopupSettings = {
    limitToOne: true,
    children: (
      <div
        style={{
          minHeight: 'calc(100vh - 30px)',
          paddingBottom: '60px',
          height: '100%',
          overflowY: 'auto',
        }}
      >
        <ContactDetail />
      </div>
    ),
  };

  const selectContactId = (id: string) => {
    if (id !== activeId) {
      setActiveContact(id);
      id && selectContactAction(id);
    }
    // timeout to prevent ui jump while loading user
    isMobile && setTimeout(() => openContactPopup(contactPopupSettings), 300);
  };

  const selectMeAsContact = (id: string) => {
    if (id !== activeId) {
      setActiveContact(id);
      id && fetchMyProfileDataFn();
    }
    isMobile && setTimeout(() => openContactPopup(contactPopupSettings), 300);
  };

  return (
    <ContactListHolder isMobile={isMobile}>
      <SearchHolder>
        <SearchIcon style={{ position: 'absolute', top: '22px', left: '30px' }} />
        <SearchInput
          placeholder={'Search contacts'}
          value={searchValue}
          onBlur={launchEvent}
          onChange={(event) => {
            changeInputValue(event.target.value);
            event.target.value && searchContacts(event.target.value);
          }}
        />
      </SearchHolder>
      <MyContact me={me} active={activeId === me.id} selectMe={() => selectMeAsContact(me.id)} />
      <ContactHolder>
        <List>
          {ContactListFrame(
            searchValue,
            contactList,
            isMobile,
            selectContactId,
            openContactPopup,
            searchedContacts,
            activeId,
          )}
        </List>
        {loadingContactsList ? (
          <Loading>
            <LoadingIndicator />
          </Loading>
        ) : null}
        <Alphabet>
          {alphabet.map((letter, index) => (
            <Letter key={letter} id={letter} onClick={() => scrollToLetter(letter, index)}>
              {letter}
            </Letter>
          ))}
        </Alphabet>
      </ContactHolder>
    </ContactListHolder>
  );
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  selectContactAction: (id: string) => dispatch(selectContact(id)),
  openContactPopup: (popupSettings: IPopupSettings) => dispatch(openPopup(popupSettings)),
  fetchContactListAction: () => dispatch(fetchContactList.request()),
  searchContacts: (value: string) => dispatch(searchContacts.request(value)),
  fetchMyProfileDataFn: () => dispatch(fetchMyProfileData.request()),
});

const mapStateToProps = (state: AppState) => ({
  contactList: state.contacts.contacts,
  selectedContactId: state.contacts.selectedContactId,
  loadingContactsList: state.contacts.loadingContactsList,
  searchedContacts: state.contacts.searchedContacts,
  me: state.me,
});

export default connect(mapStateToProps, mapDispatchToProps)(ContactList);
