import React from 'react';
import Select from 'react-select';
import { inputColors } from '../input/input-colors';

interface IDropdownProps {
  styles?: {};
  options: any[];
  small?: boolean;
  onChange(value: any): void;
  placeholder?: string;
  search?: boolean;
  value?: any;
  customOption?: any;
  isMenuOpen?: boolean;
  width?: string;
}
const Dropdown = ({
  styles,
  small,
  onChange,
  options,
  placeholder,
  search,
  value,
  customOption,
  isMenuOpen,
  width,
}: IDropdownProps) => {
  const customStyles = {
    ...styles,
    container: (provided: any) => ({
      ...provided,
      height: small ? '28px' : '36px',
      width: width ? width : '100%',
    }),
    control: (provided: any, state: any) => ({
      ...provided,
      minHeight: small ? '28px' : '36px !important',
      height: small ? '28px' : '36px !important',
      fontSize: small ? '13px' : '14px',
      fontFamily: `"Nunito Sans", sans-serif`,
      fontWeight: 'bold',
      paddingLeft: '5px',
      borderRadius: 20,
      outline: 'none',
      boxShadow: '0 !important',
      transition: 'border-color 200ms linear',
      color: `${inputColors.fontColor}`,
      borderColor:
        state.isFocused || state.menuIsOpen
          ? inputColors.borderColorActive
          : inputColors.borderColor,
      '&:hover': {
        border: `1px solid ${inputColors.borderColorActive}`,
      },
    }),
    option: (provided: any, state: any) => ({
      ...provided,
      backgroundColor: state.isSelected ? 'rgba(100,172,226,0.31)' : 'white',
      color: '#757575',
      fontSize: small ? '13px' : '14px',
      fontFamily: `"Nunito Sans", sans-serif`,
      fontWeight: 'bold',
      '&:hover, &:active, &:focus': {
        backgroundColor: '#f3f3f3',
      },
    }),
    singleValue: (provided: any) => ({
      ...provided,
      top: small ? '40%' : '47%',
      color: `${inputColors.fontColor}`,
      fontFamily: `"Nunito Sans", "sans-serif"`,
      fontWeight: 'bold',
    }),
    placeholder: (provided: any) => ({
      ...provided,
      top: small ? '40%' : '47%',
      color: `${inputColors.placeholderColor}`,
    }),
    indicatorSeparator: (provided: any) => ({
      display: 'none',
    }),
    indicatorsContainer: (provided: any) => ({
      ...provided,
      height: small ? '28px' : '36px',
    }),
    input: () => ({
      paddingTop: '3px',
      paddingBottom: '1px',
      height: small ? '28px' : '36px',
    }),
    noOptionsMessage: (provided: any) => ({
      ...provided,
      fontSize: small ? '13px' : '14px',
    }),
  };
  return (
    <Select
      value={value}
      isSearchable={search}
      placeholder={placeholder}
      onChange={onChange}
      menuIsOpen={isMenuOpen}
      styles={customStyles}
      components={customOption && { Option: customOption }}
      options={options}
    />
  );
};
export default Dropdown;
