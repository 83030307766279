import classnames from 'classnames';
import React from 'react';
import './Toggle.styles.scss';

interface Props {
  label?: string;
  active: boolean;
  callback?(value: boolean): void;
}

class Toggle extends React.Component<Props, {}> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  render() {
    const { label, callback, active } = this.props;

    const toggle = () => {
      if (callback) {
        callback(!active);
      }
    }

    return (
      <div className="Toggle">
        <div className="Toggle-slider" onClick={toggle}>
          <div
            className={classnames({
              'Toggle-slider-circle': true,
              'Toggle-slider-circle-active': active,
              'Toggle-slider-circle-inactive': !active,
            })}
          />
        </div>
        <div className="Toggle-label">{label}</div>
      </div>
    );
  }
}

export default Toggle;
