import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { FieldWrapper, Label } from '../../../components/input/input-components';
import withWindowSize from '../../../components/with-window-size/withWindowSize';
import Input from '../../../components/input/Text-Input';
import { connect } from 'react-redux';
import { AppState } from '../../../store';
import InputPhone from '../../../components/input-phone/InputPhone';
import { updateUserSettings } from '../redux/actions';
import { Dispatch } from 'redux';
import { closeModal } from '../../../components/modal/redux/actions';

export interface IProfileSettings {
  me: User.Me;
  updateSettings(settings: any): void;
  closeThisModal(): void;
}

const ProfileSettings = ({ me, updateSettings, closeThisModal }: IProfileSettings) => {
  const { isMobile, screenWidth } = withWindowSize();
  const breakView = screenWidth < 750;
  const InputStyle = { width: isMobile ? '100%' : '280px' };
  const filedWrapperStyle = { height: !breakView ? '50px' : '65px' };

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [emptyError, setEmptyError] = useState(false);
  const [emailError, setEmailError] = useState(false);

  useEffect(() => {
    if (me) {
      setFirstName(me.firstName);
      setLastName(me.lastName);
      setPhone(me.phone);
      setEmail(me.email || '');
    }
  }, [me]);

  const updateFirstName = () => {
    if (!firstName) {
      setFirstName(me.firstName);
      setEmptyError(false);
      return;
    } else if (firstName && firstName !== me.firstName) {
      updateSettings({ firstName: firstName });
    }
  };

  const updateLastName = () => {
    if (!lastName) {
      setLastName(me.lastName);
      setEmptyError(false);
      return;
    } else if (lastName && lastName !== me.lastName) {
      updateSettings({ lastName: lastName });
    }
  };

  const updateEmail = () => {
    const emailRegex = /\S+@\S+\.\S+/;
    setEmailError(!!(email && !emailRegex.test(email)));
    if (!email) {
      setEmail(me.email);
      setEmptyError(false);
      return;
    } else if (email && !emailError && email !== me.email) {
      updateSettings({ email: email });
    }
  };

  const getErrorMessage = (): string => {
    if (emptyError) {
      return 'Email can not be empty';
    } else if (emailError) {
      return 'Please enter your email in a valid format';
    }
    return '';
  };

  return (
    <div>
      <Wrapper isMobile={isMobile}>
        <FieldWrapper breakViewParent={isMobile} style={filedWrapperStyle}>
          <Label large>First name:</Label>
          <Input
            error={!firstName && emptyError}
            errorMessage={'Name can not be empty'}
            style={InputStyle}
            small
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setFirstName(event.target.value);
              setEmptyError(!event.target.value);
            }}
            placeholder="First name"
            value={firstName}
            onBlur={updateFirstName}
          />
        </FieldWrapper>
        <FieldWrapper breakViewParent={isMobile} style={filedWrapperStyle}>
          <Label large>Last name:</Label>
          <Input
            error={!lastName && emptyError}
            errorMessage={'Last name can not be empty'}
            style={InputStyle}
            small
            onChange={(event) => {
              setLastName(event.target.value);
              setEmptyError(!event.target.value);
            }}
            placeholder="Last name"
            value={lastName}
            onBlur={updateLastName}
          />
        </FieldWrapper>
        <FieldWrapper breakViewParent={isMobile} style={filedWrapperStyle}>
          <Label large>Email: </Label>
          <Input
            error={emptyError || emailError}
            errorMessage={getErrorMessage()}
            onChange={(event) => {
              setEmail(event.target.value);
              setEmptyError(!event.target.value);
            }}
            placeholder="Email"
            style={InputStyle}
            small
            value={email}
            onBlur={updateEmail}
          />
        </FieldWrapper>
        <FieldWrapper breakViewParent={isMobile} style={filedWrapperStyle}>
          <Label large>Phone</Label>
          <InputPhone value={phone} disabled={true} style={InputStyle} smallInput={!isMobile} />
        </FieldWrapper>
      </Wrapper>
    </div>
  );
};
const mapStateToProps = (state: AppState) => ({
  me: state.me,
});
const mapDispatchToProps = (dispatch: Dispatch) => ({
  updateSettings: (settings: any) => dispatch(updateUserSettings.request(settings)),
  closeThisModal: () => dispatch(closeModal()),
});
export default connect(mapStateToProps, mapDispatchToProps)(ProfileSettings);

const Wrapper = styled.div<{ isMobile: boolean }>`
  width: ${(props) => (props.isMobile ? '100%' : '380px')};
  margin-top: 30px;
  position: relative;
  height: 80;
`;
