import { getMutationResponse } from '../../client';
import { gql } from '@apollo/client';
import errorMapper from '../../../mappers/error-mapper';

const mutation = gql`
  mutation deleteTask($taskId: UUID!) {
    deleteTask(id: $taskId)
  }
`;

const deleteTask = async (args: string): Promise<string | Api.ApiError> => {
  const response = await getMutationResponse(mutation, { taskId: args });
  if (response.data !== null) {
    return args;
  }
  return errorMapper(response as Api.GraphQL.Errors);
};

export default deleteTask;
