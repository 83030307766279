import { getQueryResponse } from '../../client';
import { gql } from "@apollo/client";
import { mapContact } from '../../../mappers/contacts/contact-mappers';
import errorMapper from '../../../mappers/error-mapper';

interface IQuery {
  query: string;
}

type SearchContactsResponse = {
  assignees: Api.Contacts.ContactRaw[];
};

const querySearchContacts = gql`
  query searchUsers($query: String!) {
    assignees(criteria: $query){
        kind: __typename,
        ... on Contact {
        firstName
        lastName
        id
        numberDetails {
          firstName
          lastName
        }
      }
    }
  }
`;

const searchContacts = async (
  args: IQuery,
): Promise<Contacts.ContactInList[] | Api.ApiError> => {
  const response = await getQueryResponse<SearchContactsResponse>(querySearchContacts, args);
  if (response?.data) {
    const searchResponse = response.data as SearchContactsResponse;
    return searchResponse.assignees.map((item: any) => mapContact(item));
  }
  return errorMapper(response);
};

export default searchContacts;
