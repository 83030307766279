import React from 'react';
import './LoggedOutFormContainer.styles.scss';
import ButtonRound from '../../../components/buttons/ButtonRound';

interface Props extends ParentProps {
  disabled?: boolean;
  onSubmit(): void;
  submitButtonLabel: string;
  title?: string;
  style?: React.CSSProperties;
}

const LoggedOutFormContainer = ({
  children,
  disabled,
  onSubmit,
  submitButtonLabel,
  title,
  style,
}: Props) => (
  <Form onSubmit={(event) => event.preventDefault()}>
    {title && <Title>{title}</Title>}
    <div style={{ textAlign: 'center', ...style }}>
      {children}
      <ButtonRound style={{ marginTop: 15 }} width={'100%'} disabled={disabled} onClick={onSubmit}>
        {submitButtonLabel}
      </ButtonRound>
    </div>
  </Form>
);

const Form = (formProps: FormProps) => (
  <form className="LoggedOutFormContainer-Form" {...formProps} />
);

const Title = (headingProps: HeadingProps) => (
  // eslint-disable-next-line
  <h1 className="LoggedOutFormContainer-Title" {...headingProps} />
);

export default LoggedOutFormContainer;
