import React from 'react';
import styled from 'styled-components';
import * as colors from '../../../../lib/colors';
import Timestamp from '../../../Timestamp';

const TaskDataHolder = styled.div`
  height: 96px;
  padding: 10px;
`;

const TaskDataTitle = styled.div<PropsTaskDataTitle>`
  font-size: 13px;
  height: 14px;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
  text-overflow: ellipsis;
  color: ${(props) => (props.isSelected ? `${colors.baseDarker}` : `${colors.textLink}`)};
  text-decoration: ${(props) => (props.isSelected ? 'underline' : 'none')};
  text-transform: uppercase;
  font-weight: 800;
  cursor: pointer;
  &:hover {
    color: ${colors.baseDarker};
    text-decoration: underline;
  }
`;
const TaskDataStatus = styled.div`
  color: #9ba3ad;
  text-transform: uppercase;
  font-size: 12px;
  margin: 8px 0;
  font-weight: 600;
  letter-spacing: 2px;
`;

const Label = styled.div`
  color: #9ba3ad;
  display: inline;
`;

const Value = styled.div`
  color: #5d7289;
  display: inline;
  font-family: 'Roboto', sans-serif;
`;

const TaskDataInfo = styled.div`
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 4px;
`;

interface PropsTaskDataTitle {
  isSelected?: boolean;
}

interface PropsTaskData {
  title: string;
  status: TaskStatus;
  user: any;
  date?: string;
  iAmAuthor: boolean;
  isSelected?: boolean;
  isActive: boolean;
}

const TaskData = ({
  title,
  status,
  user,
  date,
  iAmAuthor,
  isSelected,
  isActive,
}: PropsTaskData) => {
  return (
    <TaskDataHolder>
      <TaskDataTitle isSelected={isSelected} title={title}>
        {title}
      </TaskDataTitle>
      <TaskDataStatus>{status.replace(/_/g, ' ')}</TaskDataStatus>
      <TaskDataInfo>
        <Label>{iAmAuthor ? 'Assignee: ' : 'Author: '}</Label>
        {user && (
          <Value>
            {user.firstName} {user.lastName}
          </Value>
        )}
      </TaskDataInfo>
      <TaskDataInfo>
        <Label>{isActive ? 'Due date: ' : 'Completed: '}</Label>
        <Value>
          <Timestamp year time={date ? date : 'Unknown'} />
        </Value>
      </TaskDataInfo>
    </TaskDataHolder>
  );
};
export default TaskData;
