import { createAction } from 'typesafe-actions';

export const OPEN_POPUP = 'OPEN_POPUP';
export const CLOSE_POPUP = 'CLOSE_POPUP';
export const CLOSE_ALL_POPUPS = 'CLOSE_ALL_POPUPS';

export interface IPopupSettings {
  id?: string;
  limitToOne: boolean;
  title?: string;
  children: any;
}

export const openPopup = createAction(OPEN_POPUP)<IPopupSettings>();
export const closePopup = createAction(CLOSE_POPUP)<string>();
export const closeAllPopups = createAction(CLOSE_ALL_POPUPS)();

export type PopupActions =
  | ReturnType<typeof openPopup>
  | ReturnType<typeof closePopup>
  | ReturnType<typeof closeAllPopups>;
