import { getQueryResponse } from '../../client';
import { gql } from "@apollo/client";
import errorMapper from '../../../mappers/error-mapper';

const query = gql`
  query profileStats {
    countOfSMSMessages
    userStatistic(statsType: PROFILE) {
      status
      count
    }
  }
`;

const fetchLoggedInUserProfileStats = async (): Promise<User.ProfileStats | Api.ApiError> => {
  const response = await getQueryResponse<User.ProfileStats>(query);
  if (response?.data) {
    const { data } = response;
    return {
      ...data,
    };
  }
  return errorMapper(response);
};

export default fetchLoggedInUserProfileStats;
