import { createAsyncAction, createAction } from 'typesafe-actions';

export const LOG_IN_REQUEST = 'LOG_IN_REQUEST';
export const LOG_IN_SUCCESS = 'LOG_IN_SUCCESS';
export const LOG_IN_FAILURE = 'LOG_IN_FAILURE';

export const LOG_OUT_WITH_REDIRECT = 'LOG_OUT_WITH_REDIRECT';
export const LOG_OUT = 'LOG_OUT';

export const LOGGED_OUT_USER_TOKEN = 'LOGGED_OUT_USER_TOKEN';

export const FETCH_INITIAL_DATA_REQUEST = 'FETCH_INITIAL_DATA_REQUEST';
export const FETCH_INITIAL_DATA_SUCCESS = 'FETCH_INITIAL_DATA_SUCCESS';
export const FETCH_INITIAL_DATA_FAILURE = 'FETCH_INITIAL_DATA_FAILURE';

interface ILoginData {
  email: string;
  password: string;
}

export const logIn = createAsyncAction(LOG_IN_REQUEST, LOG_IN_SUCCESS, LOG_IN_FAILURE)<
  ILoginData,
  string,
  string
>();

export const fetchInitialData = createAsyncAction(
  FETCH_INITIAL_DATA_REQUEST,
  FETCH_INITIAL_DATA_SUCCESS,
  FETCH_INITIAL_DATA_FAILURE,
)<{ loggedIn?: boolean }, User.Me, { loggedIn?: boolean }>();

export const logOutWithRedirect = createAction(LOG_OUT_WITH_REDIRECT)();

export const logOut = createAction(LOG_OUT)();
export const loggedOutUserToken = createAction(LOGGED_OUT_USER_TOKEN)<string>();
