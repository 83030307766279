import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import ContactList from '../../components/contact-list/ContactList';
import withWindowSize from '../../components/with-window-size/withWindowSize';
import ContactDetail from '../../components/contact-detail/ContactDetail';
import { connect } from 'react-redux';
import { AppState } from '../../store';
import { togglePanel } from '../../components/panel/redux/action';
import { Dispatch } from 'redux';
import { fetchFullContactData } from './redux/actions';
import { ReactComponent as PhoneBook } from '../../assets/graphics/empty-cnt.svg';
import Panel from '../../components/panel/Panel';

const EmptyStateSelectedContact = () => (
  <EmptyState>
    <PhoneBook />
    <EmptyStateText>Select a contact to see more details.</EmptyStateText>
  </EmptyState>
);
interface IContactsPageProps {
  selectedContactId?: string;
  selectedContact: any;
  deletedContact: boolean;
  requestFullContactDetail(id: string): void;
  contactList: any[];
  loadingContactsList: boolean;
  togglePanel(): void;
}

const Contacts = ({
  selectedContactId,
  selectedContact,
  deletedContact,
  requestFullContactDetail,
  togglePanel,
}: IContactsPageProps) => {
  const { isMobile } = withWindowSize();
  const ref = useRef(null);

  useEffect(() => {
    selectedContactId && requestFullContactDetail(selectedContactId as string);
    // eslint-disable-next-line
  }, [selectedContactId, ref.current]);

  return (
    <ContactsPage
      isMobile={isMobile}
      onClick={(e) => {
        e.stopPropagation();
        togglePanel();
      }}
    >
      <Panel />
      <ContactList />
      {!isMobile && (
        <ContactDetailHolder ref={ref} id={'scroll_element'}>
          <>{selectedContact && !deletedContact ? <ContactDetail /> : <EmptyStateSelectedContact />}</>
        </ContactDetailHolder>
      )}
    </ContactsPage>
  );
};

const mapStateToProps = (state: AppState) => ({
  contactList: state.contacts.contacts,
  selectedContactId: state.contacts.selectedContactId,
  selectedContact: state.contacts.selectedContact,
  deletedContact: state.contacts.selectedContactDeleted,
  loadingContactsList: state.contacts.loadingContactsList,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  requestFullContactDetail: (id: string) => dispatch(fetchFullContactData.request(id)),
  togglePanel: () => dispatch(togglePanel(false)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Contacts);
const ContactsPage = styled.div<{ isMobile: boolean }>`
  display: flex;
  flex-direction: row;
  overflow: hidden;
  height: ${(props) => (props.isMobile ? 'calc(100vh - 90px)' : 'calc(100vh - 50px)')};
`;
const ContactDetailHolder = styled.div`
  padding: 20px;
  width: 100%;
  overflow-y: auto;
`;
export const EmptyState = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: calc(100vh - 110px);
`;

export const EmptyStateText = styled.div`
  font-size: 20px;
  color: #5d7289;
  font-weight: bold;
  margin-bottom: 20px;
  margin-top: 25px;
  text-align: center;
`;
