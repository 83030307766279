import React, { useState } from 'react';
import TasksFilterSwitch from '../tasks-filter-switch/tasks-filter-switch';
import { TaskFilter } from '../get-task-options';
import styled from 'styled-components';
import ActiveGrid from './active-grid/ActiveGrid';
import CompletedGrid from './completed-grid/CompletedGrid';
import withWindowSize from '../../with-window-size/withWindowSize';
import LoadingIndicator from '../../loading/LoadingIndicator';
import { AppState } from '../../../store';
import { connect } from 'react-redux';

const loaderStyle = {
  position: 'absolute' as 'absolute',
  width: 60,
  left: 'calc(50% - 30px)',
  top: 65,
  zIndex: 12,
};
export enum TaskGridType {
  ASSIGNED = 'assigned',
  ASSIGNED_PENDING = 'assignedPending',
  ASSIGNED_PROGRESS = 'assignedProgress',
  NEXT_24H = 'next24h',
  NEXT_7DAYS = 'next7days',
  UPCOMING = 'upcoming',
  LAST_24H = 'last24h',
  LAST_7DAYS = 'last7days',
  LAST_MONTH = 'lastMonth',
  ARCHIVED = 'archived',
}

export const TaskGridFilter = {
  ASSIGNED: {
    type: 'assigned',
    title: 'assigned',
  },
  ASSIGNED_PROGRESS: {
    type: 'assignedProgress',
    title: 'assigned',
  },
  ASSIGNED_PENDING: {
    type: 'assignedPending',
    title: 'assigned',
  },
  NEXT_24H: {
    type: 'next24h',
    title: 'next 24 hours',
  },
  NEXT_7DAYS: {
    type: 'next7days',
    title: 'next 7 days',
  },
  UPCOMING: {
    type: 'upcoming',
    title: 'upcoming',
  },
  LAST_24H: {
    type: 'last24h',
    title: 'last 24 hours',
  },
  LAST_7DAYS: {
    type: 'last7days',
    title: 'last 7 days',
  },
  LAST_MONTH: {
    type: 'lastMonth',
    title: 'last month',
  },
  ARCHIVED: {
    type: 'archived',
    title: 'archived',
  },
};

const TaskGrid = ({ loadingPage }: { loadingPage: boolean }) => {
  const [filter, setFilter] = useState(TaskFilter.ACTIVE);
  const { isMobile } = withWindowSize();
  return (
    <TaskGridHolder>
      <TasksFilterSwitch isMobile={isMobile} filter={filter} setFilter={setFilter} />
      {loadingPage && <LoadingIndicator style={loaderStyle} />}
      {filter === TaskFilter.ACTIVE && <ActiveGrid />}
      {filter === TaskFilter.COMPLETED && <CompletedGrid />}
    </TaskGridHolder>
  );
};
const mapStateToProps = (state: AppState) => ({
  loadingPage: state.tasks.loadingPage,
});
export default connect(mapStateToProps)(TaskGrid);

const TaskGridHolder = styled.div`
  width: 100%;
  position: relative;
`;
