import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import TaskDetails from '../../components/task-components/task-full-data/task-readonly-components/TaskDetails';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { AppState } from '../../store';
import withWindowSize from '../../components/with-window-size/withWindowSize';
import { Dispatch } from 'redux';
import { loggedOutUserToken } from '../SignUp&Login/Login/redux/actions';

const SingleTaskPage = ({ sendToken, urlToken, token }: any) => {
  const { id } = useParams();
  const { screenWidth } = withWindowSize();
  const url = window.location;
  const tokenUrlParams = new URLSearchParams(url.search).get('token');

  useEffect(() => {
    tokenUrlParams && sendToken(tokenUrlParams);
    // eslint-disable-next-line
  }, [id, tokenUrlParams]);

  return (
    <SingleTaskPageHolder
      style={{ width: screenWidth < 600 ? '100%' : '800px' }}
    >
      {(id && urlToken) || (id && token) ? <TaskDetails taskId={id} singlePage={true} /> : null}
    </SingleTaskPageHolder>
  );
};
const mapStateToProps = (state: AppState) => ({
  selectedTask: state.selectedTask,
  urlToken: state.urlToken,
  token: state.token,
});
const mapDispatchToProps = (dispatch: Dispatch) => ({
  sendToken: (token: string) => dispatch(loggedOutUserToken(token)),
});
export default connect(mapStateToProps, mapDispatchToProps)(SingleTaskPage);

const SingleTaskPageHolder = styled.div`
  position: relative;
  margin: 0 auto;
  height: calc(100vh - 50px);
`;
