import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import CenteringContainer from '../../../components/centering-container/CenteringContainer';
import LoggedOutFormContainer from '../../../forms/form-container/logged-out-container/LoggedOutFormContainer';
import { resetPassword as resetPasswordAction } from './redux/actions';
import { LoginFieldWrapper } from '../../../components/input/input-components';
import Input from '../../../components/input/Text-Input';
import resetPassword, { ResetPasswordResponse } from '../../../api/mutations/user/reset-password';
import errorMapper from '../../../mappers/error-mapper';

interface PropsFromDispatch {
  resetPasswordSuccess(): void;
}

const mapDispatchToProps = (dispatch: Dispatch): PropsFromDispatch => ({
  resetPasswordSuccess: () => dispatch(resetPasswordAction.success("", "")),
});

interface Props extends PropsFromDispatch {
  token: string;
}

export const ResetPassword = ({ resetPasswordSuccess, token }: Props) => {
  const [password, setPassword] = useState('');
  const [repeatedPassword, setRepeatPassword] = useState('');
  const [passwordMatchError, setError] = useState('');

  const checkPasswordMatch = () => {
    if (password === repeatedPassword || !password || !repeatedPassword) {
      setError('');
    } else {
      setError('Passwords don\'t match.');
    }
  };

  const resetPasswordFn = async () => {
    const response = (await resetPassword({ password, repeatedPassword, token })as Api.GraphQL.Response<ResetPasswordResponse>);
    if (response.data) {
      resetPasswordSuccess();
      setError('');
    } else if (response.errors) {
      setError(errorMapper(response).message);
    }
  };

  return (
    <CenteringContainer>
      <LoggedOutFormContainer
        style={{ width: 280 }}
        disabled={!!passwordMatchError}
        onSubmit={() => resetPasswordFn()}
        submitButtonLabel="Reset password"
        title="Reset password"
      >
        <LoginFieldWrapper>
          <Input
            style={{ width: 280 }}
            onChange={(e) => setPassword(e.target.value)}
            value={password}
            placeholder="New Password"
            type={'password'}
          />
        </LoginFieldWrapper>

        <LoginFieldWrapper>
          <Input
            style={{ width: 280 }}
            onChange={(e) => setRepeatPassword(e.target.value)}
            value={repeatedPassword}
            placeholder="Confirm Password"
            type={'password'}
            onBlur={() => checkPasswordMatch()}
            error={!!passwordMatchError}
            errorMessage={passwordMatchError}
          />
        </LoginFieldWrapper>
      </LoggedOutFormContainer>
    </CenteringContainer>
  );
};

export default connect(null, mapDispatchToProps)(ResetPassword);
