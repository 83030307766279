import React, { useState } from 'react';
import styled from 'styled-components';
import { FieldWrapper, Label } from '../../../components/input/input-components';
import { SubTitle, ReadOnlyValue } from '../general-components';
import Toggle from '../../../components/toggle/Toggle';
import { connect } from 'react-redux';
import { AppState } from '../../../store';
import { updateUserSettings } from '../redux/actions';
import { Dispatch } from 'redux';
import { closeModal } from '../../../components/modal/redux/actions';
import TimePicker, { addZeroToSingleValue } from 'components/timepicker/TimePicker';
import moment from 'moment-timezone';
import withWindowSize from '../../../components/with-window-size/withWindowSize';
import { resendEmailVerification } from 'modules/SignUp&Login/VerifyEmailPrompt/redux/actions';
import VerifyLink from 'components/verify-banner/VerifyLink';
import { notificationsSettingsEvent } from 'api/amplitude/events';

export interface INotificationsSettings {
  me: User.Me;
  updateSettings(settings: any): void;
  closeThisModal(): void;
  sendVerifyEmail(email: string): void;
  emailSent: boolean;
  dailyReport: boolean;
  smsNotifications: boolean;
  emailNotifications: boolean;
}

const NotificationsSettings = ({
  me,
  updateSettings,
  sendVerifyEmail,
  emailSent,
  dailyReport,
  smsNotifications,
  emailNotifications,
}: INotificationsSettings) => {
  const { isMobile } = withWindowSize();
  const [timeValue, setTimeValue] = useState(me.preference.dailyReportTime);
  const DateInputStyle = { width: 'calc(50% - 5px)' };

  notificationsSettingsEvent(me.id);

  const timeChanged = (time: { hours: string; minutes: string }) => {
    if (
      addZeroToSingleValue(time.hours) + ':' + addZeroToSingleValue(time.minutes) !==
        me.preference.dailyReportTime &&
      timeValue
    ) {
      updateSettings({
        dailyReportTime:
          addZeroToSingleValue(time.hours) + ':' + addZeroToSingleValue(time.minutes),
        timezone: moment.tz.guess(),
      });
    }
  };

  return (
    <Holder>
      <div style={{ width: 250 }}>
        <SubTitle>daily reports</SubTitle>
        <FieldWrapper breakViewParent={isMobile} style={{ height: isMobile ? '70px' : '50px' }}>
          <Label large>Prefered time{!timeValue ? ' (not set yet):' : ':'}</Label>
          <TimePicker
            onBlur={(value) => {
              setTimeValue(value as any);
              timeChanged(value);
            }}
            smallInput={!isMobile}
            style={DateInputStyle}
            time={timeValue || '09:00'}
            disabled={!dailyReport}
          />
          </FieldWrapper>
          <FieldWrapper breakViewParent={isMobile} style={{ height: isMobile ? '70px' : '50px' }}>
          <Label large>Enable reports: </Label>
          <Toggle
            active={dailyReport}
            callback={(value: boolean) => updateSettings({dailyReport: value})}
          />
        </FieldWrapper>
        <SubTitle>notifications</SubTitle>
        <FieldWrapper>
          <Label large>SMS notifications: </Label>
          <Toggle
            active={smsNotifications}
            callback={(value: boolean) => updateSettings({mobileNotifications: value})}
          />
        </FieldWrapper>
        <FieldWrapper>
          <Label large>Email notifications: </Label>
          <Toggle
            active={emailNotifications}
            callback={(value: boolean) => updateSettings({emailNotifications: value})}
          />
        </FieldWrapper>
      </div>
      <SubTitle>usage</SubTitle>
      <SubTitleSmall>number of sms notifications sent</SubTitleSmall>
      <FieldWrapper>
        <Label large>Monthly: </Label>
        <ReadOnlyValue>
          {me.profileStatsData && me.profileStatsData.countOfSMSMessages}
        </ReadOnlyValue>
      </FieldWrapper>
      <FieldWrapper>
        {me.emailVerified === false ? (
          <VerifyLink me={me} emailSent={emailSent} verify={() => sendVerifyEmail(me.email)} />
        ) : null}
      </FieldWrapper>
    </Holder>
  );
};
const mapStateToProps = (state: AppState) => ({
  me: state.me,
  emailSent: state.verifyYourEmail.emailSent,
  dailyReport: state.me.preference.dailyReport,
  smsNotifications: state.me.preference.mobileNotifications,
  emailNotifications: state.me.preference.emailNotifications,
});
const mapDispatchToProps = (dispatch: Dispatch) => ({
  updateSettings: (settings: any) => dispatch(updateUserSettings.request(settings)),
  closeThisModal: () => dispatch(closeModal()),
  sendVerifyEmail: (email: string) => dispatch(resendEmailVerification.request(email)),
});
export default connect(mapStateToProps, mapDispatchToProps)(NotificationsSettings);

const Holder = styled.div`
  width: 380px;
  margin-top: 30px;
`;
const SubTitleSmall = styled.div`
  font-size: 11px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: bold;
  color: #9ba3ad;
  margin: 20px 0;
`;
