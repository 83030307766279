import React, { useEffect, useState } from 'react';
import InputPhone from '../../../components/input-phone/InputPhone';
import ButtonRound from '../../../components/buttons/ButtonRound';
import { InputHolder, ThinTitle, LargeTitle, Step } from './Register';
import checkPhone from '../../../api/mutations/user/check-phone';
import errorMapper from '../../../mappers/error-mapper';
import withWindowSize from '../../../components/with-window-size/withWindowSize';
import { signupPagePhoneNumberEvent } from '../../../api/amplitude/events';

interface IStep1 {
  getLeftPosition(step: number): React.CSSProperties;
  setActiveStep(step: number): void;
  setUserData(data: any): void;
}

const Step1 = ({ getLeftPosition, setActiveStep, setUserData }: IStep1) => {
  const MINIMAL_PHONE_LENGTH = 10;

  const { isMobile } = withWindowSize();
  const buttonStyle = isMobile ? { marginTop: 40, width: '100%' } : { marginLeft: 20 };
  const [phoneValue, setPhoneValue] = useState('');
  const [phoneError, setPhoneError] = useState('');

  useEffect(() => {
    return signupPagePhoneNumberEvent();
  });

  const checkIsPhoneTaken = async () => {
    const response = (await checkPhone(`+${phoneValue}`)) as Api.GraphQL.Response<{
      sendMobileVerificationCode: string;
    }>;
    if (response.data && response.data.sendMobileVerificationCode) {
      setActiveStep(1);
      setPhoneError('');
      setUserData({ mobile: phoneValue });
    } else if (!response.data) {
      setPhoneError(errorMapper(response).message);
    }
  };

  return (
    <Step style={getLeftPosition(0)}>
      <ThinTitle>Welcome to nagnag</ThinTitle>
      <LargeTitle>Register to start nagging</LargeTitle>
      <InputHolder isMobile={isMobile}>
        <InputPhone
          setValue={(value) => setPhoneValue(value)}
          value={phoneValue}
          style={{ textAlign: 'left' }}
          onlyCountries={['rs', 'us', 'ca']}
          country={'rs'}
          error={!!phoneError}
          errorMessage={phoneError}
          onKeyDown={(e) =>
            e.nativeEvent.key === 'Enter' && phoneValue ? checkIsPhoneTaken() : null
          }
        />
        <ButtonRound
          style={buttonStyle}
          disabled={phoneValue.length < MINIMAL_PHONE_LENGTH}
          onClick={() => checkIsPhoneTaken()}
        >
          send sms
        </ButtonRound>
      </InputHolder>
    </Step>
  );
};
export default Step1;
