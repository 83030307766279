import { ofType } from 'redux-observable';
import {
  ADD_TASK_REQUEST,
  ARCHIVE_TASK_REQUEST,
  archiveTask,
  COMMENT_ON_TASK_REQUEST,
  commentOnTask,
  DELETE_TASK_REQUEST,
  deleteTask,
  EDIT_TASK_REQUEST,
  editTask,
  FETCH_SINGLE_TASK_REQUEST,
  fetchedAllActiveTasks,
  fetchedAllCompletedTasks,
  fetchSingleTask,
  fetchActiveTasks,
  fetchCompletedTasks,
  openEditAssignee,
  REASSIGN_TASK_REQUEST,
  reassignTask,
  FETCH_TASK_TIMELINE_REQUEST,
  fetchTaskTimeline,
  refreshTaskTimeline,
  REFRESH_TASK_TIMELINE_REQUEST,
  FETCH_ACTIVE_TASKS_REQUEST,
  FETCH_COMPLETED_TASKS_REQUEST,
  FETCHED_ALL_COMPLETED_TASKS,
  FETCHED_ALL_ACTIVE_TASKS,
} from './actions';
import { mergeMap } from 'rxjs/operators';
import { from, merge, of } from 'rxjs';
import api from '../../../api';
import { toast } from 'react-toastify';
import { addNewTask } from './actions';
import { closePopup } from '../../../components/popup/redux/actions';
import { AppEpic } from '../../../store/epics';
import { isApiError } from '../../Contacts/redux/epics';
import { togglePanel } from '../../../components/panel/redux/action';
import isEmpty from 'lodash/isEmpty';
import { Tasks } from '../../../api/queries/tasks/fetch-tasks';
import {
  taskAddedEvent,
  taskCommentAddedEvent,
  taskEditedEvent,
  taskOpenedEvent,
  taskReassignedEvent,
} from 'api/amplitude/events';
import { TASK_DELETED_EVENT } from './task-card/actions';

export const addTaskEpic: AppEpic = (action$, state$) =>
  action$.pipe(
    ofType(ADD_TASK_REQUEST),
    mergeMap((action) => {
      const popupId = action.payload.popupId;
      const userId = action.payload.userId;
      const assigneeId = action.payload.assigneeId;
      return from(api.createTask(action.payload)).pipe(
        mergeMap((response) => {
          if (isApiError(response)) {
            toast(response.message, { type: 'error' });
            return merge(of(addNewTask.failure({ popupId })));
          } else {
            toast(`Task successfully added`, { type: 'success' });
            taskAddedEvent(userId!, (response as Task).id, assigneeId);
            return merge(of(addNewTask.success({...response as Task, popupId})), of(closePopup(popupId)));
          }
        }),
      );
    }),
  );

export const fetchSingleTaskEpic: AppEpic = (action$, state$) =>
  action$.pipe(
    ofType(FETCH_SINGLE_TASK_REQUEST),
    mergeMap((action) => {
      const { triggerReassign } = action.payload;
      const userId = state$.value.me.id;
      const taskId = action.payload.id;
      return from(api.fetchTask(action.payload.id as string)).pipe(
        mergeMap((response) => {
          if (!response) {
            return of(fetchSingleTask.failure());
          } else if (response && isApiError(response)) {
            return of(fetchSingleTask.failure());
          } else {
            taskOpenedEvent(userId, taskId, (response as Task).assignee.id, (response as Task).author.id);
            return merge(
              of(fetchSingleTask.success(response as Task)),
              of(fetchTaskTimeline.request({ id: response.id, offset: 0, fetchMore: false })),
              of(openEditAssignee(!!triggerReassign)),
              of(togglePanel(true)),
            );
          }
        }),
      );
    }),
  );

export const commentOnTaskEpic: AppEpic = (action$, state$) =>
  action$.pipe(
    ofType(COMMENT_ON_TASK_REQUEST),
    mergeMap((action) => {
      const taskId = action.payload.id;
      const assigneeId = state$.value.selectedTask.assignee.id;
      const authorId = state$.value.selectedTask.author.id;
      const userId = state$.value.me.id;
      const commenter = assigneeId === userId ? 'Assignee' : 'Author';
      return from(api.createComment(action.payload)).pipe(
        mergeMap((response) => {
          if (isApiError(response)) {
            toast('Oops, something went wrong while submitting your comment.', { type: 'error' });
            return of(commentOnTask.failure());
          } else {
            const commentId = (response as TaskComment).id;
            taskCommentAddedEvent(userId, taskId, assigneeId, authorId, commentId, commenter);
            return merge(
              of(commentOnTask.success(response as TaskComment)),
            );
          }
        }),
      );
    }),
  );

export const editTaskEpic: AppEpic = (action$, state$) =>
  action$.pipe(
    ofType(EDIT_TASK_REQUEST),
    mergeMap((action) => {
      const userId = state$.value.me.id;
      const task = state$.value.selectedTask;
      const taskId = state$.value.selectedTask.id;
      return from(api.editTask(action.payload)).pipe(
        mergeMap((response) => {
          if (isApiError(response)) {
            toast(response.message, { type: 'error' });
            return of(editTask.failure({ id: taskId }));
          } else {
            toast('Task updated.', { type: 'success' });
            taskEditedEvent(userId, task.id, task.assignee.id, action.payload);
            return merge(
              of(editTask.success(response as Task)),
            );
          }
        }),
      );
    }),
  );

export const reassignTaskEpic: AppEpic = (action$, state$) =>
  action$.pipe(
    ofType(REASSIGN_TASK_REQUEST),
    mergeMap((action) => {
      const userId = state$.value.me.id;
      const { oldAssigneeId } = action.payload;
      const task = state$.value.selectedTask;
      return from(api.editTask(action.payload)).pipe(
        mergeMap((response) => {
          if (isApiError(response)) {
            toast(response.message, { type: 'error' });
            return of(reassignTask.failure());
          } else {
            toast('Task updated.', { type: 'success' });
            taskReassignedEvent(userId, task.id, oldAssigneeId, response.assignee.id);
            return of(reassignTask.success({ oldAssigneeId, response }));
          }
        }),
      );
    }),
  );

export const fetchTaskTimelineEpic: AppEpic = (action$, state$) =>
  action$.pipe(
    ofType(FETCH_TASK_TIMELINE_REQUEST),
    mergeMap((action) => {
      const taskId = action.payload.id;
      return from(api.fetchTaskTimeline(action.payload)).pipe(
        mergeMap((response) => {
          if (isApiError(response)) {
            toast(response.message, { type: 'error' });
            return of(fetchTaskTimeline.failure());
          } else {
            return of(
              fetchTaskTimeline.success({
                taskId,
                response,
                fetchMore: action.payload.fetchMore,
                loadedFullTimeline: response.length < 20,
              }),
            );
          }
        }),
      );
    }),
  );

export const refreshTaskTimelineEpic: AppEpic = (action$, state$) =>
  action$.pipe(
    ofType(REFRESH_TASK_TIMELINE_REQUEST),
    mergeMap((action) => {
      const taskId = action.payload.id;
      return from(api.fetchTaskTimeline(action.payload)).pipe(
        mergeMap((response) => {
          if (isApiError(response)) {
            toast(response.message, { type: 'error' });
            return of(refreshTaskTimeline.failure());
          } else {
            return of(
              refreshTaskTimeline.success({
                taskId,
                response,
                fetchMore: action.payload.fetchMore,
                loadedFullTimeline: response.length < 20,
              }),
            );
          }
        }),
      );
    }),
  );

export const archiveTaskEpic: AppEpic = (action$, state$) =>
  action$.pipe(
    ofType(ARCHIVE_TASK_REQUEST),
    mergeMap((action) => {
      const id = action.payload;
      return from(api.archiveTask(action.payload)).pipe(
        mergeMap((response) => {
          if (isApiError(response)) {
            toast(response.message, { type: 'error' });
            return of(archiveTask.failure({ id }));
          } else {
            toast('Task archived.', { type: 'success' });
            return of(archiveTask.success(id));
          }
        }),
      );
    }),
  );

export const deleteTaskEpic: AppEpic = (action$, state$) =>
  action$.pipe(
    ofType(DELETE_TASK_REQUEST),
    mergeMap((action) => {
      const id = action.payload;
      const isSelectedTask = state$.value.selectedTask?.id === id;
      return from(api.deleteTask(action.payload)).pipe(
        mergeMap((response) => {
          if (isApiError(response)) {
            toast(response.message, { type: 'error' });
            return of(deleteTask.failure({ id }));
          } else {
            if (!isSelectedTask) {
              toast('Task deleted.', { type: 'success' });
            }
            return merge(of(deleteTask.success(id)), of(togglePanel(false)));
          }
        }),
      );
    }),
  );

  export const onTaskDeleteEpic: AppEpic = (action$, state$) =>
    action$.pipe(
      ofType(TASK_DELETED_EVENT),
      mergeMap((action) => {
        const isSelectedTask = state$.value.selectedTask?.id === action.payload.taskId;
        if (isSelectedTask) {
          toast('Task deleted.', { type: 'success' });
          return of(togglePanel(false));
        }
        return of({ type: "", payload: "" });
      }),
  );

export const fetchCompletedTasksPageEpic: AppEpic = (action$, state$) =>
  action$.pipe(
    ofType(FETCH_COMPLETED_TASKS_REQUEST),
    mergeMap((action) => {
      const { first, loadMore } = action.payload;
      return from(api.fetchCompletedTasks(action.payload)).pipe(
        mergeMap((response) => {
          if (isApiError(response)) return of(fetchCompletedTasks.failure());
          else {
            if (
              isEmpty(response) ||
              (Object.keys(response).length > 0 && Object.keys(response).length < first)
            ) {
              return merge(
                of(fetchCompletedTasks.success({ tasks: response as Tasks, loadedMore: loadMore })),
                of(fetchedAllCompletedTasks(FETCHED_ALL_COMPLETED_TASKS)),
              );
            } else {
              return of(
                fetchCompletedTasks.success({ tasks: response as Tasks, loadedMore: loadMore }),
              );
            }
          }
        }),
      );
    }),
  );

export const fetchActiveTasksPageEpic: AppEpic = (action$, state$) =>
  action$.pipe(
    ofType(FETCH_ACTIVE_TASKS_REQUEST),
    mergeMap((action) => {
      const { first, loadMore } = action.payload;
      return from(api.fetchActiveTasks(state$.value.token, action.payload)).pipe(
        mergeMap((response) => {
          if (isApiError(response)) return of(fetchActiveTasks.failure());
          else {
            if (
              isEmpty(response) ||
              (Object.keys(response).length > 0 && Object.keys(response).length < first)
            ) {
              return merge(
                of(fetchActiveTasks.success({ tasks: response as Tasks, loadedMore: loadMore })),
                of(fetchedAllActiveTasks(FETCHED_ALL_ACTIVE_TASKS)),
              );
            } else {
              return of(
                fetchActiveTasks.success({ tasks: response as Tasks, loadedMore: loadMore }),
              );
            }
          }
        }),
      );
    }),
  );
