import { ofType } from 'redux-observable';
import {
  ADD_CONTACT_REQUEST,
  ASSIGNEE_CREATED_EVENT,
  ASSIGNEE_REMOVED_EVENT,
  ASSIGNEE_UPDATED_EVENT,
  DELETE_CONTACT_REQUEST,
  EDIT_CONTACT_REQUEST,
  FETCH_CONTACT_BY_NUMBER_REQUEST,
  FETCH_CONTACT_DATA_REQUEST,
  FETCH_CONTACT_LIST_REQUEST,
  FETCH_MY_PROFILE_DATA_REQUEST,
  SEARCH_CONTACTS_REQUEST,
} from './consts';
import { map, mergeMap } from 'rxjs/operators';
import { from, merge, of } from 'rxjs';
import api from '../../../api';
import { toast } from 'react-toastify';
import {
  addContact,
  editContact,
  fetchContactList,
  fetchFullContactData,
  fetchContactByNumber,
  searchContacts,
  fetchMyProfileData,
  deleteContact,
} from './actions';
import { closePopup } from '../../../components/popup/redux/actions';
import { AppEpic } from '../../../store/epics';
import { contacAddedEvent, contactOpenedEvent } from 'api/amplitude/events';

export const isApiError = <T>(response: Api.ApiError | T): response is Api.ApiError =>
  (response as Api.ApiError)?.error !== undefined;

export const fetchContactListEpic: AppEpic = (action$, state$) =>
  action$.pipe(
    ofType(FETCH_CONTACT_LIST_REQUEST, ASSIGNEE_CREATED_EVENT, ASSIGNEE_UPDATED_EVENT, ASSIGNEE_REMOVED_EVENT),
    mergeMap(() =>
      from(api.fetchContactList()).pipe(
        map((response) => !isApiError(response) ? fetchContactList.success(response) : fetchContactList.failure()),
      ),
    ),
  );

export const fetchFullContactDataEpic: AppEpic = (action$, state$) =>
  action$.pipe(
    ofType(FETCH_CONTACT_DATA_REQUEST),
    mergeMap((action) => {
      const userId = state$.value.me.id;
      const request = action.payload !== userId 
        ? api.fetchFullContactData({ id: action.payload })
        : api.fetchMyProfileData();
      return from(request).pipe(
        map((response) => {
          if (isApiError<Contacts.FullContact>(response)) {
            return fetchFullContactData.failure();
          } else {
            contactOpenedEvent(state$.value.me.id, action.payload);
            return fetchFullContactData.success(response);
          }
        }),
      )},
    ),
  );

export const fetchContactByNumberEpic: AppEpic = (action$, state$) =>
  action$.pipe(
    ofType(FETCH_CONTACT_BY_NUMBER_REQUEST),
    mergeMap((action) => {
      return from(api.fetchContactByNumber(action.payload)).pipe(
        map((response) => {
          if (isApiError<LinkedContact | null>(response)) {
            return fetchContactByNumber.failure();
          } else {
            return fetchContactByNumber.success(response);
          }
        }),
      )},
    ),
  );

export const searchContactsEpic: AppEpic = (action$, state$) =>
  action$.pipe(
    ofType(SEARCH_CONTACTS_REQUEST),
    mergeMap((action) =>
      from(api.searchContacts({ query: action.payload })).pipe(
        map((response) => {
          if (isApiError(response)) {
            toast('Something went wrong.', { type: 'error' });
          } else {
            return searchContacts.success(response);
          }
        }),
      ),
    ),
  );

export const editContactEpic: AppEpic = (action$, state$) => {
  return action$.pipe(
    ofType(EDIT_CONTACT_REQUEST),
    mergeMap((action) => {
      const oldValue: string | undefined = action.payload && action.payload.oldValue;
      return from(api.editContact(action.payload)).pipe(
        mergeMap((response) => {
          if (isApiError(response)) {
            const errorRsp = response as Api.ApiError;
            toast(errorRsp.message, { type: 'error' });
            return of(editContact.failure(errorRsp.message));
          } else {
            const updatedContact = response as Contacts.ContactInList;
            toast('Contact updated', { type: 'success' });
            return of(editContact.success({ oldValue, updatedContact }));
          }
        }),
      );
    }),
  );
};

export const createContactEpic: AppEpic = (action$, state$) =>
  action$.pipe(
    ofType(ADD_CONTACT_REQUEST),
    mergeMap((action) => {
      const popupId = action.payload.popupId;
      const userId = action.payload.userId;
      return from(api.createContact(action.payload)).pipe(
        mergeMap((response) => {
          if (isApiError(response)) {
            const errorRes = response as Api.ApiError;
            const error: string = 'Please use a valid phone number format.';
            toast(errorRes.message.match(error) ? error : errorRes.message, { type: 'error' });
            return merge(of(addContact.failure({ popupId })));
          } else {
            toast(`Contact ${(response as Contacts.ContactInList).firstName} successfully added`, {
              type: 'success',
            });
            const contactId = response.id;
            contacAddedEvent(userId, contactId);
            return merge(of(addContact.success({...response, popupId })), of(closePopup(popupId)));
          }
        }),
      );
    }),
  );

  export const deleteContactEpic: AppEpic = (action$, state$) =>
  action$.pipe(
    ofType(DELETE_CONTACT_REQUEST),
    mergeMap((action) => {
      const {id, firstName, lastname} = action.payload;
      return from(api.deleteAssignee(id)).pipe(
        mergeMap((response) => {
          if (isApiError(response)) {
            toast(response.message, { type: 'error' });
            return of(deleteContact.failure());
          } else {
            toast('Contact deleted.', { type: 'success' });
            return merge(of(deleteContact.success({id, firstName, lastname})), of(fetchContactList.request()));
          }
        }),
      );
    }),
  );

export const fetchMyProfileDataEpic: AppEpic = (action$, state$) =>
  action$.pipe(
    ofType(FETCH_MY_PROFILE_DATA_REQUEST),
    mergeMap(() =>
      from(api.fetchMyProfileData()).pipe(
        map((response) => {
          if (isApiError(response)) {
            toast('Something went wrong when fetching your data.', { type: 'error' });
          } else {
            return fetchMyProfileData.success(response);
          }
        }),
      ),
    ),
  );
