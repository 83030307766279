import isEmpty from 'lodash/isEmpty';
import { ReassignTaskResponse } from '../../Tasks/redux/actions';

export const addContactToList = (newContact: Contacts.ContactInList, oldContactsList?: any) => {
  const contactsObject: any = { ...oldContactsList };
  const newContactLetter = newContact.firstName.charAt(0).toUpperCase();

  if (contactsObject && !isEmpty(contactsObject)) {
    if (contactsObject.hasOwnProperty(newContactLetter)) {
      contactsObject[newContactLetter] = [...contactsObject[newContactLetter], newContact];
    } else {
      contactsObject[newContactLetter] = [newContact];
    }
  } else {
    contactsObject[newContactLetter] = [newContact];
  }

  return contactsObject;
};

export const updateContactInList = (
  contactList: any,
  responseUser: Contacts.ContactInList,
  newLetter: string,
  oldLetter?: string,
) => {
  if (oldLetter && oldLetter !== newLetter) {
    const index = contactList[oldLetter].findIndex((item: any) => {
      return item.id === responseUser.id;
    });
    const newItem = { ...contactList[oldLetter][index], ...responseUser };
    // remove old
    contactList[oldLetter].splice(index, 1);
    // push to new
    contactList[newLetter].push(newItem);
  } else {
    const index = contactList[newLetter].findIndex((item: any) => {
      return item.id === responseUser.id;
    });
    contactList[newLetter][index] = { ...contactList[newLetter][index], ...responseUser };
  }
  return contactList;
};

interface LetterObj {
  [key: string]: string;
}

export const findAndUpdateContactInList = (payload: any, oldContactsList: any) => {
  const letterUpdate = payload.updatedContact.lastName
    ? payload.updatedContact.lastName.charAt(0).toUpperCase()
    : '';
  const oldLetter = payload.oldValue && payload.oldValue.charAt(0).toUpperCase();
  const letterRegex = /[a-zA-Z]+/;
  const charMap: LetterObj = {
    ö: 'o',
    ç: 'c',
    ş: 's',
    ı: 'i',
    ğ: 'g',
    ü: 'u',
    č: 'c',
    ć: 'c',
    š: 's',
    đ: 'd',
    ž: 'z',
  };

  const replaceSpecialLetterOld =
    oldLetter && (charMap[oldLetter.toLowerCase()] || oldLetter).toUpperCase();
  const replaceSpecialLetterNew = (
    charMap[letterUpdate.toLowerCase()] || letterUpdate
  ).toUpperCase();

  if (!letterRegex.test(replaceSpecialLetterNew) || !letterRegex.test(replaceSpecialLetterOld)) {
    const replaceWithPoundNew = !letterRegex.test(replaceSpecialLetterNew)
      ? '#'
      : replaceSpecialLetterNew;
    const replaceWithPoundOld = !letterRegex.test(replaceSpecialLetterOld)
      ? '#'
      : replaceSpecialLetterOld;
    return updateContactInList(
      oldContactsList,
      payload.updatedContact,
      replaceWithPoundNew,
      replaceWithPoundOld,
    );
  }
  return updateContactInList(
    oldContactsList,
    payload.updatedContact,
    replaceSpecialLetterNew,
    replaceSpecialLetterOld,
  );
};

export const updateSearchedContacts = (payload: any, searchedContactsList: any) => {
  if (!searchedContactsList) {
    return;
  }
  return searchedContactsList.map((item: Contacts.ContactInList) => {
    if (item.id === payload.updatedContact.id) {
      item = { ...item, ...payload.updatedContact };
    }
    return item;
  });
};

export const findTaskInList = (contact: Contacts.FullContact, newTask: Task) => {
  // if(contact.tasks.length){
  //     contact.tasks.forEach((oldTasks: Task, index:number) => {
  //         if(oldTasks.id === newTask.id){
  //             contact.tasks[index] = newTask;
  //         }
  //     });
  // }
  return contact;
};

export const updateAndReassignTask = (
  contact: Contacts.FullContact,
  reassignTaskResponse: ReassignTaskResponse,
) => {
  //todo
  return contact;
};
