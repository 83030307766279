import { OPEN_MODAL, CLOSE_MODAL } from './actions';
import { AppAction } from '../../../store/actions';

const initialState = {
  openModal: false,
  modalSettings: {},
};

const reducer = (state = initialState, action: AppAction) => {
  switch (action.type) {
    case OPEN_MODAL:
      return {
        ...state,
        openModal: true,
        modalSettings: action.payload,
      };
    case CLOSE_MODAL:
      return {
        ...state,
        openModal: false,
        modalSettings: {},
      };
    default:
      return state;
  }
};

export default reducer;
