import React, { useEffect, useState } from 'react';
import { FieldWrapper, Label } from '../../../input/input-components';
import Dropdown from '../../../dropdown/Dropdown';
import { mapUsersToDropdown } from '../../../../mappers/contacts/contact-mappers';
import { Value } from './TaskForm';

interface IEditTaskProps {
  assigneeId: string;
}

interface ITaskAuthorProps {
  firstName: string;
  lastName: string;
}

interface TaskAssigneeProps {
  fieldWrapperStyle: React.CSSProperties;
  editState: boolean;
  inputStyle: React.CSSProperties;
  isMobile: boolean;
  editTaskData(val: IEditTaskProps): void;
  initialAssigneeValue: any;
  contactList: any;
  editAssignee: boolean;
  taskAuthor: ITaskAuthorProps;
}

const TaskAssignee = ({
  fieldWrapperStyle,
  inputStyle,
  isMobile,
  editTaskData,
  editState,
  initialAssigneeValue,
  contactList,
  editAssignee,
  taskAuthor,
}: TaskAssigneeProps) => {
  const assVal = taskAuthor
    ? {
        label: `${taskAuthor.firstName || ''} ${taskAuthor.lastName || ''}`,
      }
    : {
        value: initialAssigneeValue.id,
        key: initialAssigneeValue.id,
        label: `${initialAssigneeValue.firstName || ''} ${initialAssigneeValue.lastName || ''}`,
      };
  const [assigneeValue, setAssigneeValue] = useState(assVal);
  const [editDropdownState, setEditDropdownState] = useState(editState || editAssignee);

  useEffect(() => {
    setEditDropdownState(editState || editAssignee);
    // eslint-disable-next-line
  }, [editState, editAssignee]);

  useEffect(() => {
    setAssigneeValue(assVal);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialAssigneeValue]);

  return (
    <FieldWrapper style={fieldWrapperStyle}>
      <Label>{taskAuthor ? 'Created by:' : 'Assignee:'}</Label>
      <div style={inputStyle}>
        {editDropdownState ? (
          <Dropdown
            small={!isMobile}
            width={isMobile ? '100%' : '245px'}
            onChange={(value) => {
              setAssigneeValue(value);
              editTaskData({ assigneeId: value.value });
            }}
            value={assigneeValue}
            options={mapUsersToDropdown(contactList)}
          />
        ) : (
          <Value isMobile={isMobile}>{!!assigneeValue && assigneeValue.label}</Value>
        )}
      </div>
    </FieldWrapper>
  );
};

export default TaskAssignee;
