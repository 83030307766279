import React, { useEffect, useState } from 'react';
import { ThinTitle, LargeTitle, Step, IUserRegisterData } from './Register';
import Input from '../../../components/input/Text-Input';
import ButtonRound from '../../../components/buttons/ButtonRound';
import register, { RegisterResponse } from '../../../api/mutations/user/register';
import errorMapper from '../../../mappers/error-mapper';
import { sigUp as signUpAction } from './redux/actions';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { logIn } from '../Login/redux/actions';
import login from '../../../api/queries/user/login';
import styled from 'styled-components';
import Tooltip from 'rc-tooltip';
import 'rc-tooltip/assets/bootstrap.css';
import { signupPageLoginDetailsEvent, userSignedUpEvent } from '../../../api/amplitude/events';
import { saveState } from 'localStorage';

const tooltipMessage =
  'Password should be min 10 characters including uppercase, lowercase letter and a number.';

interface IStep1 {
  getLeftPosition(step: number): React.CSSProperties;
  setUserData(data: any): void;
  userData: IUserRegisterData;
  signUpSuccess?(id: string): void;
  logInSuccess(token: string): void;
}

const Step4 = ({ getLeftPosition, setUserData, userData, logInSuccess }: IStep1) => {
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [serverError, setServerError] = useState<string>('');
  const [isDisabled, setIsDisabled] = useState<boolean>(false);

  useEffect(() => {
    return signupPageLoginDetailsEvent();
  });

  // TODO move this to a separate file or epic
  const finishRegister = async () => {
    setIsDisabled(true);
    try {
      const newUser = {
        ...userData,
        email,
        password,
      };
      setUserData({ email, password });
      const registerResponse = (await register(newUser)) as Api.GraphQL.Response<RegisterResponse>;
      if (registerResponse.data && registerResponse.data.register.id) {
        const loginResponse = await login({ email, password });
        userSignedUpEvent(registerResponse.data.register.id);
        if (loginResponse.data && loginResponse.data.login) {
          saveState({ token: loginResponse.data.login.access });
          logInSuccess(loginResponse.data.login.access);
        } else {
          setServerError(errorMapper().message);
        }
      } else if ((registerResponse as Api.GraphQL.Errors).errors) {
        setServerError(errorMapper(registerResponse as Api.GraphQL.Errors).message);
      }
    } finally {
      setIsDisabled(false);
    }
  };

  return (
    <Step style={getLeftPosition(3)}>
      <ThinTitle>4 OUT OF 4</ThinTitle>
      <LargeTitle>Login details</LargeTitle>
      <div style={{ width: '280px' }}>
        <Input
          value={email}
          placeholder={'Email'}
          onChange={(e) => {
            setEmail(e.target.value);
            setServerError('');
          }}
        />

        <div style={{ marginTop: 20, minHeight: 54, position: 'relative' }}>
          <Input
            type={'password'}
            style={{ paddingRight: 38 }}
            value={password}
            placeholder={'Password'}
            onChange={(e) => {
              setPassword(e.target.value);
              setServerError('');
            }}
            onKeyDown={(e) =>
              e.nativeEvent.key === 'Enter' && password && email && finishRegister()
            }
            error={!!serverError}
            errorMessage={serverError}
          />
          <Tooltip placement="top" trigger={'hover'} overlay={<span>{tooltipMessage}</span>}>
            <IconInfo>i</IconInfo>
          </Tooltip>
        </div>

        <ButtonRound
          style={{ marginTop: 20, width: '100%' }}
          disabled={
            !email || !password || (password && password.length < 10) || isDisabled || !!serverError
          }
          onClick={finishRegister}
        >
          Get started
        </ButtonRound>
      </div>
    </Step>
  );
};
const mapDispatchToProps = (dispatch: Dispatch) => ({
  signUpSuccess: (id: string) => dispatch(signUpAction(id)),
  logInSuccess: (token: string) => dispatch(logIn.success(token)),
});
export default connect(null, mapDispatchToProps)(Step4);

const IconInfo = styled.div`
  width: 20px;
  height: 20px;
  background: #38485c;
  border-radius: 50%;
  position: absolute;
  right: -30px;
  top: 8px;
  color: white;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
`;
