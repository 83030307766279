import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import CenteringContainer from '../../../components/centering-container/CenteringContainer';
import LoggedOutFormContainer from '../../../forms/form-container/logged-out-container/LoggedOutFormContainer';
import { logIn, fetchInitialData } from './redux/actions';
import './Login.styles.scss';
import login from '../../../api/queries/user/login';
import Input from '../../../components/input/Text-Input';
import { LoginFieldWrapper } from '../../../components/input/input-components';
import errorMapper from '../../../mappers/error-mapper';
import { saveState } from 'localStorage';

interface PropsFromDispatch {
  logInSuccess(token: string): void;
  fetchData(): void;
}

const mapDispatchToProps = (dispatch: Dispatch): PropsFromDispatch => ({
  logInSuccess: (token: string) => dispatch(logIn.success(token)),
  fetchData: () => dispatch(fetchInitialData.request({ loggedIn: true })),
});

interface Props extends PropsFromDispatch {
  onForgotPasswordClick: ClickHandler;
  onSignUpClick: ClickHandler;
}

export const Login = ({ logInSuccess, onForgotPasswordClick, fetchData }: Props) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const shouldPreventSubmission = isSubmitting || !email || !password;

  const [loginError, setLogIInError] = useState('');

  // full log in functionality with errors directly in component
  const loginFn = async () => {
    setIsSubmitting(true);
    try {
      const response = await login({ email, password });
      setIsSubmitting(false);
      if (response.data && response.data.login) {
        saveState({ token: response.data.login.access });
        logInSuccess(response.data.login.access);
        fetchData();
      } else if ((response as Api.GraphQL.Errors).errors) {
        setLogIInError(errorMapper(response as Api.GraphQL.Errors).message);
      }
    } catch (e) {
      setLogIInError('Oops something went wrong.');
    }
  };

  return (
    <CenteringContainer>
      <LoggedOutFormContainer
        disabled={shouldPreventSubmission}
        onSubmit={() => loginFn()}
        submitButtonLabel="Log in"
        title="Log in"
        style={{ width: '280px' }}
      >
        <LoginFieldWrapper>
          <Input
            style={{ width: 280 }}
            error={!!(email && !!loginError)}
            value={email}
            placeholder="Email"
            onChange={(e) => {
              setEmail(e.target.value);
              setLogIInError('');
            }}
          />
        </LoginFieldWrapper>

        <LoginFieldWrapper>
          <Input
            style={{ width: 280 }}
            value={password}
            placeholder="Password"
            onChange={(e) => {
              setPassword(e.target.value);
              setLogIInError('');
            }}
            type={'password'}
            error={!!(email && !!loginError)}
            errorMessage={email && loginError}
            onKeyDown={(e) => e.key === 'Enter' && password && loginFn()}
          />
        </LoginFieldWrapper>
      </LoggedOutFormContainer>

      <div className="Login-form-forgot-password">
        <span className="Login-form-clickable-span" onClick={onForgotPasswordClick}>
          Forgot password?
        </span>
      </div>
    </CenteringContainer>
  );
};

export default connect(null, mapDispatchToProps)(Login);
