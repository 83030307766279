import {
  FETCH_CONTACT_LIST_SUCCESS,
  SELECT_CONTACT,
  FETCH_CONTACT_DATA_SUCCESS,
  FETCH_CONTACT_LIST_FAILURE,
  FETCH_CONTACT_LIST_REQUEST,
  SEARCH_CONTACTS_SUCCESS,
  SEARCH_CONTACTS_REQUEST,
  ADD_CONTACT_SUCCESS,
  EDIT_CONTACT_SUCCESS,
  FETCH_MY_PROFILE_DATA_SUCCESS,
  ADD_CONTACT_REQUEST,
  ADD_CONTACT_FAILURE,
  DELETE_CONTACT_SUCCESS,
  ASSIGNEE_REMOVED_EVENT,
  FETCH_CONTACT_BY_NUMBER_REQUEST,
  FETCH_CONTACT_BY_NUMBER_FAILURE,
  FETCH_CONTACT_BY_NUMBER_SUCCESS,
} from './consts';
import { UPDATE_USER_SETTINGS_SUCCESS } from '../../UserSettings/redux/actions';
import { LOG_OUT } from '../../SignUp&Login/Login/redux/actions';
import { AppAction } from '../../../store/actions';
import {
  addContactToList,
  findAndUpdateContactInList,
  updateSearchedContacts,
} from './reducer-helpers';

export interface IContactsState {
  contacts: any;
  selectedContactId?: string;
  selectedContact?: Contacts.FullContact;
  selectedContactDeleted?: boolean;
  loadingContactsList: boolean;
  loadingSingleContact: boolean;
  searchedContacts?: Contacts.ContactInList[];
  actionInProgress: string[];
  linkedContact: LinkedContact | { number?: string } | null;
}

const initialState: IContactsState = {
  contacts: [],
  selectedContactId: '',
  selectedContact: undefined,
  selectedContactDeleted: false,
  loadingContactsList: false,
  loadingSingleContact: false,
  searchedContacts: undefined,
  actionInProgress: [],
  linkedContact: null,
};

const reducer = (state = initialState, action: AppAction): IContactsState => {
  switch (action.type) {
    case FETCH_CONTACT_LIST_REQUEST:
      return {
        ...state,
        loadingContactsList: true,
      };
    case FETCH_CONTACT_LIST_FAILURE:
      return {
        ...state,
        loadingContactsList: false,
      };
    case FETCH_CONTACT_LIST_SUCCESS:
      return {
        ...state,
        contacts: action.payload,
        loadingContactsList: false,
      };
    case SELECT_CONTACT:
      return {
        ...state,
        selectedContactId: action.payload,
      };
    case FETCH_CONTACT_DATA_SUCCESS:
      return {
        ...state,
        selectedContact: action.payload,
      };
    case FETCH_MY_PROFILE_DATA_SUCCESS:
      return {
        ...state,
        selectedContactDeleted: false,
        selectedContact: action.payload,
        selectedContactId: action.payload.id,
      };
    case SEARCH_CONTACTS_REQUEST:
      return {
        ...state,
        loadingContactsList: true,
      };

    case FETCH_CONTACT_BY_NUMBER_REQUEST:
      return {
        ...state,
        linkedContact: { number: action.payload },
      };

    case FETCH_CONTACT_BY_NUMBER_FAILURE:
      return {
        ...state,
        linkedContact: null,
      };

    case FETCH_CONTACT_BY_NUMBER_SUCCESS:
      return {
        ...state,
        linkedContact: action.payload?.id
          ? {
              ...action.payload,
              number: state.linkedContact?.number || '',
            }
          : null,
      };

    case SEARCH_CONTACTS_SUCCESS:
      return {
        ...state,
        searchedContacts: action.payload,
        loadingContactsList: false,
      };

    case ADD_CONTACT_FAILURE:
      return {
        ...state,
        actionInProgress: state.actionInProgress.filter((a) => a !== action.payload.popupId),
      };
    case ADD_CONTACT_REQUEST:
      return {
        ...state,
        actionInProgress: [...state.actionInProgress, action.payload.popupId],
      };

    case ADD_CONTACT_SUCCESS:
      return {
        ...state,
        actionInProgress: state.actionInProgress.filter((a) => a !== action.payload.popupId),
        contacts: addContactToList(action.payload, { ...state.contacts }),
      };

    case EDIT_CONTACT_SUCCESS:
      return {
        ...state,
        selectedContact: {
          ...(state.selectedContact as unknown as Contacts.FullContact),
          ...action.payload.updatedContact,
        } as Contacts.FullContact,
        searchedContacts: updateSearchedContacts(action.payload, state.searchedContacts),
        contacts: findAndUpdateContactInList(action.payload, { ...state.contacts }),
      };

    case DELETE_CONTACT_SUCCESS:
      return {
        ...state,
        selectedContactDeleted: action.payload.id === state.selectedContact?.id,
      };

    case ASSIGNEE_REMOVED_EVENT:
      return {
        ...state,
        selectedContactDeleted: action.payload.assigneeId === state.selectedContact?.id,
      };

    case UPDATE_USER_SETTINGS_SUCCESS:
      if (state.selectedContact && state.selectedContact.id === action.payload.id) {
        return {
          ...state,
          selectedContact: {
            ...(state.selectedContact as unknown as Contacts.FullContact),
            firstName: action.payload.firstName,
            lastName: action.payload.lastName,
          } as Contacts.FullContact,
        };
      }
      return { ...state };

    case LOG_OUT:
      return {
        contacts: [],
        selectedContactId: '',
        selectedContact: undefined,
        loadingContactsList: false,
        loadingSingleContact: false,
        searchedContacts: undefined,
        actionInProgress: [],
        linkedContact: null,
      };

    default:
      return state;
  }
};

export default reducer;
