import React from 'react';
import styled from 'styled-components';
import ButtonText, { ButtonType } from '../../../buttons/ButtonText';
import { getTaskActions } from '../../get-task-options';
import ButtonRound from '../../../buttons/ButtonRound';
import { Dispatch } from 'redux';
import {
  acceptTask,
  markTaskFailed,
  markTaskInReview,
  markTaskSuccessful,
  nag,
  rejectTask,
  triggerReassignTask,
} from '../../../../modules/Tasks/redux/task-card/actions';
import { connect } from 'react-redux';
import withWindowSize from '../../../with-window-size/withWindowSize';
import { AppState } from 'store';
import { archiveTask, deleteTask } from 'modules/Tasks/redux/actions';

export const Divider = styled.span`
  width: 1px;
  height: 100%;
  background-color: #dadada;
`;

const TaskActionsHolder = styled.div<StyleProps>`
  height: ${(props) => (props.panel ? '70px' : '30px')};
  background-color: ${(props) => (props.panel ? 'white' : 'transparent')};
  padding: ${(props) => (props.panel ? '15px 30px' : '0')};
  width: ${(props) => ((props.panel && props.isMobile) || !props.isSinglePage ? '100%' : 'auto')};
  z-index: 3;
  cursor: pointer;
  display: flex;
  border-top: ${(props) => (props.panel && !props.isMobile ? 'none' : '1px solid #DADADA')};
  justify-content: ${(props) => (props.panel ? 'flex-end' : 'center')};
  margin-left: ${(props) => (props.panel ? '0' : '-7px')};
  position: ${(props) => (props.panel ? 'absolute' : 'static')};
  bottom: 0;
  right: ${(props) => (props.panel && !props.isMobile && props.isSinglePage ? '54%' : '0')};
`;

interface StyleProps {
  panel: boolean;
  isMobile: boolean;
  isSinglePage?: boolean;
}
interface ITaskActionsProps {
  status: TaskStatus;
  assignee?: string;
  iAmAuthor: boolean;
  isSinglePage?: boolean;
  panel?: boolean;
  id: string;
  isArchived: boolean;
  actionInProgress: string[];
  nag(id: string, assignee: string): void;
  reassign(id: string): void;
  reject(id: string): void;
  accept(id: string): void;
  completed(id: string, assignee: string): void;
  failed(id: string, assignee: string): void;
  markReady(id: string): void;
  archive(id: string): void;
  remove(id: string): void;
}
export interface ITaskActions {
  text: string;
  buttonType: ButtonType;
}
const TaskActions = ({
  status,
  assignee,
  iAmAuthor,
  panel = false,
  isSinglePage = false,
  id,
  actionInProgress,
  isArchived,
  nag,
  reassign,
  reject,
  accept,
  completed,
  failed,
  markReady,
  remove,
  archive,
}: ITaskActionsProps) => {
  const { isMobile } = withWindowSize();
  const actions = getTaskActions(status, iAmAuthor, isArchived);
  if (!actions || (actions && actions.length < 1)) {
    return null;
  }

  interface MapActions {
    [key: string]: any;
  }
  const mapActions: MapActions = {
    nag,
    reassign,
    reject,
    accept,
    completed,
    failed,
    markready: markReady,
    archive,
    delete: remove,
  };

  const sendAction = (type: string) => {
    const formatString = type.replace(/ /g, '');
    mapActions[formatString](id);
  };

  return (
    <TaskActionsHolder panel={panel} isMobile={isMobile} isSinglePage={isSinglePage ?? false}>
      {!panel ? (
        <>
          {actions.map((action: ITaskActions, index) => (
            <React.Fragment key={action.text}>
              <ButtonText
                key={action.text}
                disabled={!!actionInProgress.find((a) => a === id)}
                buttonType={action.buttonType}
                onClick={(e) => {
                  e.stopPropagation();
                  sendAction(action.text);
                }}
              >
                {action.text}
              </ButtonText>
              {actions.length > 1 && index !== actions.length - 1 ? <Divider /> : null}
            </React.Fragment>
          ))}
        </>
      ) : (
        <>
          {actions &&
            actions.map((action: ITaskActions) => (
              <ButtonRound
                inverted
                disabled={!!actionInProgress.find((a) => a === id)}
                style={{ marginLeft: 20 }}
                key={action.text}
                buttonType={action.buttonType}
                onClick={(e) => {
                  e.stopPropagation();
                  sendAction(action.text);
                }}
              >
                {action.text}
              </ButtonRound>
            ))}
        </>
      )}
    </TaskActionsHolder>
  );
};

const mapStateToProps = (state: AppState) => ({
  actionInProgress: state.contactTasks.actionInProgress,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  reassign: (id: string) => dispatch(triggerReassignTask(id)),
  nag: (id: string, assignee: string) => dispatch(nag.request({ id, assignee })),
  reject: (id: string) => dispatch(rejectTask.request(id)),
  accept: (id: string) => dispatch(acceptTask.request(id)),
  completed: (id: string, assignee: string) =>
    dispatch(markTaskSuccessful.request({ id, assignee })),
  failed: (id: string, assignee: string) => dispatch(markTaskFailed.request({ id, assignee })),
  markReady: (id: string) => dispatch(markTaskInReview.request(id)),
  archive: (id: string) => dispatch(archiveTask.request(id)),
  remove: (id: string) => dispatch(deleteTask.request(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TaskActions);
