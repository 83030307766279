import React, { useEffect } from 'react';
import styled from 'styled-components';
import withWindowSize from '../../components/with-window-size/withWindowSize';
import TabSwitch from '../../components/tab-switch/TabSwitch';
import NotificationsSettings from './tabs/NotificationsSettings';
import ProfileSettings from './tabs/ProfileSettings';
import { SubTitle } from './general-components';
import { connect } from 'react-redux';
import { AppState } from '../../store';
import { closeModal } from '../../components/modal/redux/actions';
import { CloseIcon } from '../../components/icons/Icons';
import { fetchProfileStats } from './redux/actions';
import { Dispatch } from 'redux';

const SettingsTabs = () => {
  return (
    <>
      <ProfileSettings />
      <SubTitle>Notifications</SubTitle>
      <NotificationsSettings />
    </>
  );
};
interface IUserSettingsProps {
  me: User.Me;
  closeSettingsModal(): void;
  fetchUserProfileStats(): void;
}

const UserSettings = ({ me, closeSettingsModal, fetchUserProfileStats }: IUserSettingsProps) => {
  const { isMobile } = withWindowSize();

  const handleKeyword = (event: KeyboardEvent) => {
    if (event.which === 27) {
      document.removeEventListener('keydown', handleKeyword);
      closeSettingsModal();
    }
  };

  document.addEventListener('keydown', handleKeyword);

  useEffect(() => {
    fetchUserProfileStats();
    // eslint-disable-next-line
  }, []);

  const tabs = [
    {
      title: 'profile',
      content: <ProfileSettings />,
    },
    {
      title: 'notifications',
      content: <NotificationsSettings />,
    },
  ];

  return (
    <SettingsPageHolder isMobile={isMobile}>
      {!isMobile && <Title>Account settings</Title>}
      <UserName>{`${me.firstName} ${me.lastName}`}</UserName>
      <Close onClick={() => closeSettingsModal()}>
        <CloseIcon />
      </Close>
      {isMobile ? <SettingsTabs /> : <TabSwitch tabs={tabs} />}
    </SettingsPageHolder>
  );
};
const mapStateToProps = (state: AppState) => ({
  me: state.me,
});
const mapDispatchToProps = (dispatch: Dispatch) => ({
  closeSettingsModal: () => dispatch(closeModal()),
  fetchUserProfileStats: () => dispatch(fetchProfileStats.request()),
});
export default connect(mapStateToProps, mapDispatchToProps)(UserSettings);

interface IStyledProps {
  isMobile: boolean;
}
const mobileStyle = {
  margin: '7px 5px 7px 5px',
  height: 'calc(100% - 14px)',
  width: '100%',
  border: 'none',
  padding: '0 30px 60px 30px',
};

const desktopStyle = {
  margin: '40px auto 15px auto',
  height: 'calc(100vh - 240px)',
  width: '600px',
  border: '1px solid #DEDFE3',
  padding: '30px',
};

const SettingsPageHolder = styled.div<IStyledProps>`
  background-color: white;
  border-radius: 4px;
  overflow-y: auto;
  position: relative;
  ${(props) => (props.isMobile ? mobileStyle : desktopStyle)}
`;
export const defaultFont = `
    text-transform: uppercase;
    letter-spacing: 1.5px;
    font-weight: bold;
`;
const Title = styled.div`
  ${defaultFont};
  color: #9ba3ad;
  font-size: 14px;
`;
const UserName = styled.div`
  ${defaultFont};
  color: #38485c;
  font-weight: 18px;
  margin: 20px 0;
`;

const Close = styled.div`
  position: absolute;
  cursor: pointer;
  top: 20px;
  right: 20px;
  &:hover {
    opacity: 0.5;
  }
`;
