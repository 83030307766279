import React, { useState } from 'react';
import styled from 'styled-components';

interface ITab {
  title: string;
  content: any;
}
interface ITabsSwitch {
  tabs: ITab[];
}
const TabSwitch = ({ tabs }: ITabsSwitch) => {
  const [activeTab, setActiveTab] = useState(0);
  return (
    <div>
      <TabsHeader>
        {tabs.map((tab: ITab, index) => (
          <TabButton
            key={index}
            active={index === activeTab}
            onClick={() => setActiveTab(index)}
          >
            {tab.title}
          </TabButton>
        ))}
      </TabsHeader>
      <TabContent>{tabs[activeTab].content}</TabContent>
    </div>
  );
};
export default TabSwitch;
const TabsHeader = styled.div`
  height: 30px;
  border-bottom: 1px solid #dadada;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  padding-bottom: 6px;
`;
const TabContent = styled.div``;
interface ITabButton {
  active: boolean;
}
const TabButton = styled.div<ITabButton>`
    color: ${(props) => (props.active ? '#38485C' : '#979797')};
    font-size: 12px;
    line-height: 14px;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    font-weight: 900;
    margin: 0 7px;
    cursor: pointer;
    &:hover {
        opacity: 0.7
    }
`;
