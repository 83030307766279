import alphabet from '../../components/contact-list/alphabet';
import { FetchFullContact } from '../../api/queries/contacts/fetch-full-contact';

type DropdownContact = {
  value: string;
  key: string;
  label: string;
};
export const mapUsersToDropdown = (letters: any) => {
  const dropdownContacts: DropdownContact[] = [];
  for (let key in letters) {
    if (letters.hasOwnProperty(key)) {
      for (let i = 0; i < letters[key].length; i++) {
        dropdownContacts.push({
          value: letters[key][i].id,
          key: letters[key][i].id,
          label: `${letters[key][i].firstName} ${letters[key][i].lastName || ''}`,
        });
      }
    }
  }
  return dropdownContacts;
};

export const contactListToObject = (arr: Api.Contacts.ContactLetterRaw[]) => {
  const contactsObj: any = {};
  // add all letters to contacts list, so when new letter is added it will
  // appear in the correct alphabetical order
  // and not at the end
  alphabet.forEach((letter) => {
    contactsObj[letter] = [];
  });
  arr.map((item: Api.Contacts.ContactLetterRaw) => {
    return (contactsObj[item.letter] = item.assignees.map((assagnee: Api.Contacts.ContactRaw) =>
      mapContact(assagnee),
    ));
  });
  return contactsObj;
};

export const mapContact = (contact: Api.Contacts.ContactRaw): Contacts.ContactInList => ({
  id: contact.id,
  registeredUser: !!contact.firstName,
  firstName: contact.firstName ? contact.firstName : contact.numberDetails.firstName,
  lastName: contact.lastName ? contact.lastName : contact.numberDetails.lastName,
  email: contact.numberDetails.email,
});

export const mapPublicProfile = (
  publicProfile: Api.Contacts.PublicProfile,
): Contacts.PublicProfile => ({
  id: publicProfile.id,
  firstName: publicProfile._firstName,
  lastName: publicProfile._lastName,
});

export const mapFullContactData = (data: FetchFullContact): Contacts.FullContact => {
  return {
    id: data.users[0].id,
    firstName: data.users[0].firstName || data.users[0].numberDetails.firstName,
    lastName: data.users[0].lastName || data.users[0].numberDetails.lastName,
    email: data.users[0].numberDetails.email,
    phone: data.users[0].numberDetails.mobile,
    registeredUser: !!data.users[0].firstName,
    userStatistic: data.userStatistic,
  };
};

export const mapMeAsContact = (meFullProfile: any): Contacts.FullContact => {
  return {
    id: meFullProfile.me.id,
    firstName: meFullProfile.me.firstName,
    lastName: meFullProfile.me.lastName,
    email: meFullProfile.me.email,
    phone: meFullProfile.me.mobile,
    registeredUser: false,
    userStatistic: meFullProfile.userStatistic,
  };
};
