import React, { useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import routes from '../../lib/routes';
import { AppState } from '../../store';
import { navigateTo } from '../../store/actions';
import { togglePanel } from '../panel/redux/action';
import { logOut } from '../../modules/SignUp&Login/Login/redux/actions';
import { openModal, ModalSettings } from '../modal/redux/actions';
import { closeAllPopups } from '../popup/redux/actions';
import AddButton from '../buttons/add-new/AddButton';
import * as colors from '../../lib/colors';
import { ReactComponent as Logo } from '../../assets/graphics/logo.svg';
import Navigation from '../navigation/Navigation';
import './Header.styles.scss';
import RegisterButtons from '../register-buttons/RegisterButtons';
import classnames from 'classnames';
import withWindowSize from '../with-window-size/withWindowSize';
import styled from 'styled-components';
import UserSettings from '../../modules/UserSettings/UserSettings';
import { Dispatch } from 'redux';
import { navigationEvent, profileSettingsEvent, userLoggedOutEvent } from 'api/amplitude/events';
import { saveState } from 'localStorage';
import { fetchContactList } from 'modules/Contacts/redux/actions';
import { useLocation } from 'react-router';

interface PropsFromState {
  isLoggedIn: boolean;
  me: User.Me;
  isModalOpen: boolean;
}

interface PropsFromDispatch {
  logOutFn(me: User.Me): void;
  navigateTo(path: string, me: User.Me): ClickHandler;
  openAccountModal(modalSettings: ModalSettings): void;
}

interface Props extends PropsFromState, PropsFromDispatch {}

const Header = ({ isLoggedIn, me, logOutFn, navigateTo, openAccountModal }: Props) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const path = location?.pathname;
  const { isMobile } = withWindowSize();
  const modalSettings: ModalSettings = {
    template: <UserSettings />,
  };
  const [menuOpen, toggleMenu] = useState(false);

  const assigneesFetched = useSelector((state: AppState) => state.tasks.assigneesFetched);

  useEffect(() => {
    if (isLoggedIn && !assigneesFetched) {
      dispatch(dispatch(fetchContactList.request()));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn]);

  return (
    <div className={classnames(['HeaderBar', { 'HeaderBar-mobile': isMobile }])}>
      <div className="Logo">
        <Logo
          onClick={isLoggedIn ? navigateTo(routes.tasks, me) : navigateTo(routes.landing, me)}
        />
      </div>
      {isLoggedIn && (
        <>
          {!isMobile && (
            <Navigation
              path={path}
              isMobile={isMobile}
              navigateTo={(path) => navigateTo(path, me)}
              isLoggedIn={isLoggedIn}
            />
          )}
          <RightHeaderComponents>
            {!isMobile && <AddButton path={path} isMobile={isMobile} color={colors.baseDarker} />}
            <Name
              onClick={() => toggleMenu(!menuOpen)}
              onBlur={() => toggleMenu(false)}
              readOnly={true}
              value={`${me.firstName.charAt(0)}${me.lastName.charAt(0)}`}
            />
            <DropdownHolder open={menuOpen}>
              <SimpleDropdown>
                <NameOption style={{ borderRadius: '4px 4px 0 0' }}>
                  {me.firstName} {me.lastName}
                </NameOption>
                <Option
                  onMouseDown={() => {
                    profileSettingsEvent(me.id);
                    openAccountModal(modalSettings);
                  }}
                >
                  Account
                </Option>
                <Option
                  style={{ borderRadius: '0 0 4px 4px' }}
                  onMouseDown={() => {
                    logOutFn(me);
                    toggleMenu(false);
                  }}
                >
                  Log out
                </Option>
              </SimpleDropdown>
            </DropdownHolder>
          </RightHeaderComponents>
        </>
      )}
      {!isLoggedIn && <RegisterButtons navigateTo={(path: string) => navigateTo(path, me)} />}
    </div>
  );
};

const mapStateToProps = (state: AppState): PropsFromState => ({
  isLoggedIn: state.token !== '',
  me: state.me,
  isModalOpen: state.modal.openModal,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  navigateTo: (path: string, me: User.Me) => () => {
    dispatch(navigateTo(path));
    dispatch(togglePanel(false));
    navigationEvent(me.id, path);
  },
  logOutFn: (me: User.Me) => {
    saveState({ token: '' });
    dispatch(logOut());
    dispatch(navigateTo(routes.login));
    dispatch(closeAllPopups());
    dispatch(togglePanel(false));
    userLoggedOutEvent(me.id);
  },
  openAccountModal: (settings: ModalSettings) => dispatch(openModal(settings)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);

const DropdownHolder = styled.div<{ open: boolean }>`
  font-size: 13px;
  max-width: 150px;
  height: 0;
  text-align: right;
  overflow: ${(props) => (props.open ? 'visible' : 'hidden')};
  position: fixed;
  top: 10px;
  right: 20px;
`;

const Name = styled.input`
  height: 30px;
  width: 30px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  line-height: 30px;
  cursor: pointer;
  outline: none;
  border: 1px solid ${colors.baseDarker};
  text-align: center;
  font-weight: bold;
  background-color: white;
  border-radius: 50%;
  color: ${colors.baseDarker};
  caret-color: transparent;
  position: fixed;
  top: 10px;
  right: 15px;
  transition: opacity 200ms ease-in-out;
  &:hover {
    opacity: 0.6;
  }
`;

const SimpleDropdown = styled.div`
  margin-top: 40px;
  background-color: white;
  border-radius: 5px;
  text-align: left;
  box-shadow: 0 0 0 1px hsla(0, 0%, 0%, 0.1), 0 4px 11px hsla(0, 0%, 0%, 0.1);
`;

export const Option = styled.div`
  font-size: 13px;
  height: 36px;
  width: 150px;
  padding: 10px 15px;
  line-height: 14px;
  color: #757575;
  font-weight: 500;
  cursor: pointer;
  &:hover {
    background-color: #f3f3f3;
  }
`;

const NameOption = styled(Option)`
  text-decoration: underline;
  pointer-events: none;
  font-weight: light;
  color: black;
  min-height: 36px;
  height: auto;
  line-height: 20px;
  overflow-x: hidden !important;
  text-overflow: ellipsis;
`;

const RightHeaderComponents = styled.div`
  width: 80px;
  position: absolute;
  right: 10px;
  align-items: center;
`;
