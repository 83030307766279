import React, { useEffect } from 'react';
import { FieldsWrapperPadding } from '../../../add-new-contact-form/AddNewContact';
import withWindowSize from '../../../with-window-size/withWindowSize';
import { AppState } from '../../../../store';
import { Dispatch } from 'redux';
import { fetchContactList } from '../../../../modules/Contacts/redux/actions';
import { connect } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import styled from 'styled-components';
import TaskTitle from './TaskTitle';
import TaskAssignee from './TaskAssignee';
import TaskDeadline from './TaskDeadline';
import TaskDescription from './TaskDescription';
import LoadingIndicator from '../../../loading/LoadingIndicator';
import { SelectedTaskProps } from '../task-readonly-components/TaskDetails';

interface EditTaskFormProps {
  contactList: any;
  fetchContacts(): void;
  task: SelectedTaskProps;
  editTaskData: any;
  editState: boolean;
  editAssignee: boolean;
  singlePage: boolean;
  iAmAuthor: boolean;
}
const EditTaskForm = ({
  contactList,
  fetchContacts,
  task,
  editTaskData,
  editState,
  editAssignee,
  singlePage,
  iAmAuthor,
}: EditTaskFormProps) => {
  const { isMobile } = withWindowSize();
  const headerStyle = { width: '100%', paddingLeft: '10px' };
  const InputStyle = { width: isMobile ? '100%' : '245px' };
  const fieldWrapperStyle = { height: !editState ? (isMobile ? 70 : 36) : isMobile ? 80 : 50 };

  useEffect(() => {
    if (isEmpty(contactList) && editState) {
      fetchContacts();
    }
    // eslint-disable-next-line
  }, [task, editState]);

  return (
    <div>
      <TaskTitle
        fieldWrapperStyle={fieldWrapperStyle}
        inputStyle={headerStyle}
        isMobile={isMobile}
        editState={editState}
        editTaskData={editTaskData}
        originalTitle={task.title}
        singlePage={singlePage}
      />

      <FieldsWrapperPadding>
        <TaskAssignee
          fieldWrapperStyle={fieldWrapperStyle}
          inputStyle={InputStyle}
          isMobile={isMobile}
          editState={editState}
          editAssignee={editAssignee}
          editTaskData={editTaskData}
          taskAuthor={!iAmAuthor ? task.author : ''}
          initialAssigneeValue={task.assignee}
          contactList={contactList}
        />

        <TaskDeadline
          fieldWrapperStyle={fieldWrapperStyle}
          isMobile={isMobile}
          editState={editState}
          editTaskData={editTaskData}
          originalDeadline={[task.deadline, task.completed]}
        />

        <TaskDescription
          fieldWrapperStyle={fieldWrapperStyle}
          singlePage={singlePage}
          editState={editState}
          editTaskData={editTaskData}
          originalDescription={task.description}
          isMobile={isMobile}
        />

        {task.loading && <LoadingIndicator />}
      </FieldsWrapperPadding>
    </div>
  );
};
const mapStateToProps = (state: AppState) => ({
  contactList: state.contacts.contacts,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchContacts: () => dispatch(fetchContactList.request()),
});
export default connect(mapStateToProps, mapDispatchToProps)(EditTaskForm);

export const Value = styled.div<{ isMobile: boolean }>`
  font-size: 13px;
  text-align: left;
  color: #676675;
  width: 245px;
  height: 28px;
  line-height: 28px;
  padding-left: ${(props) => (props.isMobile ? '0' : '10px')};
  font-family: 'Nunito Sans', sans-serif;
  font-weight: 600;
`;
