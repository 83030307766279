import { Set } from 'immutable';
import { filter, map } from 'rxjs/operators';
import routes from '../lib/routes';
import { navigateTo } from './actions';
import { FORGOT_PASSWORD_SUCCESS } from '../modules/SignUp&Login/ForgotPassword/ForgotPassword.actions';
import { SIGN_UP_SUCCESS } from '../modules/SignUp&Login/Register/redux/actions';
import { RESET_PASSWORD_SUCCESS } from '../modules/SignUp&Login/ResetPassword/redux/actions';
import { AppEpic } from './epics';

const redirectingActionTypes: Set<string> = Set([
  FORGOT_PASSWORD_SUCCESS,
  RESET_PASSWORD_SUCCESS,
  SIGN_UP_SUCCESS,
]);

const isAt = (path: string): boolean => window.location.pathname === path;

const navigationEpic: AppEpic = (action$) =>
  action$.pipe(
    filter((action) => redirectingActionTypes.contains(action.type)),
    map((action) => {
      switch (action.type) {
        case FORGOT_PASSWORD_SUCCESS:
          if (isAt(routes.forgotPassword)) {
            return navigateTo(routes.emailSent);
          }
          break;
        case RESET_PASSWORD_SUCCESS:
          if (isAt(routes.resetPassword)) {
            return navigateTo(routes.passwordChanged);
          }
          break;
      }
      return null;
    }),
  );

export default navigationEpic;
