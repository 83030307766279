import React, { useEffect, useReducer, useState, useMemo, useCallback } from 'react';
import StatusIndicator from '../../status-indicator/StatusIndicator';
import TaskTimeline from '../../task-timeline/TaskTimeline';
import { Dispatch } from 'redux';
import {
  archiveTask,
  openEditAssignee,
  commentOnTask,
  deleteTask,
  editTask,
  EditTaskProps,
  fetchSingleTask,
  reassignTask,
  ReassignTaskProps,
  fetchTaskTimeline,
} from '../../../../modules/Tasks/redux/actions';
import { connect } from 'react-redux';
import { AppState } from '../../../../store';
import withWindowSize from '../../../with-window-size/withWindowSize';
import TaskForm from '../task-edit-form/TaskForm';
import { isTaskActive, TaskStatusEnum } from '../../get-task-options';
import { TaskHolder } from './task-details-components';
import styled from 'styled-components';
import LoadingIndicator from '../../../loading/LoadingIndicator';
import TaskDetailsFooter from './TaskDetailsFooter';
import TaskDetailsHeader from './TaskDetailsHeader';
import reducer from './edit-form-reducer';
import isEmpty from 'lodash/isEmpty';
import TaskActions from '../../task-card/task-actions/TaskActions';
import LoggedOut404 from 'modules/SignUp&Login/LoggedOut404/LoggedOut404';
import routes from 'lib/routes';
import { navigateTo } from 'store/actions';

interface Comment {
  id: string;
  comment: string;
}

export interface SelectedTaskProps extends Task {
  loading: boolean;
  stashCommentValue: string;
  loadedFullTimeline: boolean;
}

interface TaskDetailsProps {
  taskId: string;
  selectedTask: SelectedTaskProps;
  me: User.Me;
  singlePage?: boolean;
  loadingTask: boolean;
  loadingComment: boolean;
  loadingEdit: boolean;
  nonExistentTask: boolean;
  editAssignee: boolean;
  fetchTaskDetails(id: string): void;
  submitComment(comment: Comment): void;
  archiveTaskFn(id: string): void;
  deleteTaskFn(id: string): void;
  editTaskFn(task: EditTaskProps): void;
  reassignTaskAndEditFn(taskReassign: ReassignTaskProps): void;
  openEditAssignee(val: boolean): void;
  loadMoreTimeline(id: string, offset: number): void;
  urlToken?: string;
  navigateTo(path: string): ClickHandler;
}

const statusIndicatorStyleMobile = {
  left: '1px',
  top: 0,
  borderRadius: 0,
  width: 5,
  height: '100%',
  zIndex: 12,
};

const TaskDetails = ({
  taskId,
  selectedTask,
  singlePage,
  fetchTaskDetails,
  me,
  submitComment,
  editTaskFn,
  archiveTaskFn,
  deleteTaskFn,
  reassignTaskAndEditFn,
  loadingTask,
  loadingComment,
  loadingEdit,
  nonExistentTask,
  editAssignee,
  openEditAssignee,
  loadMoreTimeline,
  navigateTo,
  urlToken,
}: TaskDetailsProps) => {
  const { isMobile } = withWindowSize();
  const [taskFetched, setTaskFetched] = useState(false);
  const [editState, setEditState] = useState(false);
  const [editTaskData, setEditTaskData] = useReducer(reducer, {});

  useEffect(() => {
    if (!taskFetched) {
      singlePage && fetchTaskDetails(taskId);
      setTaskFetched(true);
    }
    setEditState(false);
    // eslint-disable-next-line
  }, [taskId, me]);

  const meMemo = useMemo(() => {
    return me;
  }, [me]);

  const selectedTaskMemo = useMemo(() => {
    return selectedTask;
    // eslint-disable-next-line
  }, [selectedTask.timeline]);

  const submitCommentCallback = useCallback((submitComment: (comment: Comment) => void) => {
    return submitComment;
  }, []);

  const loadMoreCallback = useCallback((loadMore: (id: string, offset: number) => void) => {
    return loadMore;
  }, []);

  if (nonExistentTask && singlePage) {
    return (
      <LoggedOut404 onGoHomeClick={navigateTo(routes.landing)} />
    );
  }

  if ((loadingTask && singlePage) || !selectedTask.id || !me) {
    return <LoadingIndicator style={{ top: '100px', position: 'absolute' }} />;
  }

  // add options to task menu
  const options = [{ cb: () => deleteTaskFn(taskId), name: 'Delete' }];
  if (
    !selectedTask.archived &&
    selectedTask.status === TaskStatusEnum.PENDING &&
    isTaskActive(selectedTask.status)
  ) {
    options.unshift({ cb: () => setEditState(true), name: 'Edit' });
  }
  if (!selectedTask.archived && !isTaskActive(selectedTask.status)) {
    options.push({ cb: () => archiveTaskFn(taskId), name: 'Archive' });
  }
  const iAmAuthor = me.id === selectedTask.author.id;

  const showTaskOptions =
    isTaskActive(selectedTask.status) && selectedTask.status !== TaskStatusEnum.IN_REVIEW;

  const submitEditTask = () => {
    // detect any changes
    if (isEmpty(editTaskData)) {
      return;
    }

    if (editTaskData.assigneeId) {
      reassignTaskAndEditFn({
        oldAssigneeId: selectedTask.assignee.id,
        id: taskId,
        value: editTaskData,
      });
    } else {
      editTaskFn({ id: taskId, value: editTaskData });
    }
  };

  return (
    <>
      <TaskHolder style={{
        height: editState || (!isMobile && editAssignee) ? '90%' :
                    isMobile && !editState ? '95%' :
                        '100%'
          }}>
        <StatusIndicator
          style={isMobile ? statusIndicatorStyleMobile : { zIndex: 12 }}
          iAmAuthor={iAmAuthor}
          status={selectedTask.status}
          archived={selectedTask.archived}
        />

        <TaskDataWrapper
          sideWidth={'47%'}
          singlePage={!!singlePage}
          isMobile={isMobile}
          style={{ marginBottom: singlePage ? 0 : 20 }}
        >
          <TaskDetailsHeader
            iAmAuthor={iAmAuthor}
            taskId={taskId}
            singlePage={!!singlePage}
            status={selectedTask.status}
            showTaskOptions={showTaskOptions}
            options={options}
          />

          <TaskForm
            iAmAuthor={iAmAuthor}
            singlePage={!!singlePage}
            editAssignee={editAssignee}
            editTaskData={setEditTaskData}
            task={selectedTask}
            editState={editState}
          />

          {!editState && (
            <TaskActions
              panel
              id={selectedTask.id}
              iAmAuthor={iAmAuthor}
              status={selectedTask.status}
              isArchived={selectedTask.archived}
              isSinglePage={!!singlePage}
            />
          )}
        </TaskDataWrapper>

        <TaskDataWrapper
          sideWidth={'52%'}
          isMobile={isMobile}
          style={{
            paddingRight: singlePage && !isMobile ? '10px' : 0,
            height: 'auto',
            marginTop:
              (isMobile && !!singlePage && !editState) || (isMobile && !editState)
                ? '20px' : 0
          }}
          singlePage={!!singlePage}
        >
          <TaskTimeline
            singlePage={!!singlePage}
            urlToken={urlToken}
            bottomPadding={(!!singlePage && isMobile) || !singlePage}
            submitComment={submitCommentCallback(submitComment)}
            me={meMemo}
            taskId={taskId}
            loadMoreTimeline={loadMoreCallback(loadMoreTimeline)}
            selectedTask={selectedTaskMemo}
          />
        </TaskDataWrapper>
      </TaskHolder>

      {editState || editAssignee ? (
        <TaskDetailsFooter
          openEditAssignee={openEditAssignee}
          submitEditTask={submitEditTask}
          setEditState={setEditState}
        />
      ) : null}
    </>
  );
};

const mapStateToProps = (state: AppState) => ({
  selectedTask: state.selectedTask,
  editAssignee: state.selectedTask.editAssignee,
  loadingTask: state.selectedTask.loadingTask,
  loadingComment: state.selectedTask.loadingComment,
  loadingEdit: state.selectedTask.loadingEdit,
  nonExistentTask: state.selectedTask.nonExistentTask,
  me: state.me,
  urlToken: state.urlToken,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  openEditAssignee: (val: boolean) => dispatch(openEditAssignee(val)),
  fetchTaskDetails: (id: string) => {
    dispatch(fetchSingleTask.request({ id }));
  },
  submitComment: (comment: Comment) => comment.comment.length > 0 && dispatch(commentOnTask.request(comment)),
  loadMoreTimeline: (id: string, offset: number) =>
    dispatch(fetchTaskTimeline.request({ id, offset, fetchMore: true })),
  editTaskFn: (task: EditTaskProps) => dispatch(editTask.request(task)),
  deleteTaskFn: (id: string) => dispatch(deleteTask.request(id)),
  archiveTaskFn: (id: string) => dispatch(archiveTask.request(id)),
  reassignTaskAndEditFn: (reassignedTask: ReassignTaskProps) =>
    dispatch(reassignTask.request(reassignedTask)),
  navigateTo: (path: string) => () => dispatch(navigateTo(path)),
});

export default React.memo(connect(mapStateToProps, mapDispatchToProps)(TaskDetails));
const TaskDataWrapper = styled.div<{ singlePage: boolean; isMobile: boolean; sideWidth: string }>`
  width: ${(props) => (props.isMobile ? '100%' : props.singlePage ? props.sideWidth : '100%')};
  float: left;
`;
