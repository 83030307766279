import React from 'react';
import {
  OpenInNewTab,
  StatusTitle,
  TaskOptions,
  TaskOptionsHolder,
} from './task-details-components';
import { NewTabIcon } from '../../../icons/Icons';
import MoreOptions from './MoreOptions';
import Tooltip from 'rc-tooltip';

interface TaskDetailsHeaderProps {
  status: TaskStatus;
  singlePage: boolean;
  iAmAuthor: boolean;
  taskId: string;
  options: { name: string; cb(): void }[];
  showTaskOptions: boolean;
}

const TaskDetailsHeader = ({
  status,
  singlePage,
  taskId,
  options,
  iAmAuthor,
  showTaskOptions,
}: TaskDetailsHeaderProps) => {
  const baseUrl = window.location.origin;

  return (
    <TaskOptions>
      <TaskOptionsHolder>
        <StatusTitle>{status.replace(/_/g, ' ')}</StatusTitle>
        {!singlePage && (
          <Tooltip
            placement="top"
            trigger={'hover'}
            overlayInnerStyle={{ backgroundColor: '#38485C' }}
            overlay={<span>{'Open task in new tab'}</span>}
          >
            <OpenInNewTab to={`${baseUrl}/tasks/${taskId}`} target={'_blank'}>
              <NewTabIcon />
            </OpenInNewTab>
          </Tooltip>
        )}
      </TaskOptionsHolder>
      {iAmAuthor && showTaskOptions ? <MoreOptions optionsList={options} /> : null}
    </TaskOptions>
  );
};
export default TaskDetailsHeader;
