import {
  LOG_IN_SUCCESS,
  LOGGED_OUT_USER_TOKEN,
} from '../../modules/SignUp&Login/Login/redux/actions';
import { AppAction } from '../actions';
import { SIGN_UP_SUCCESS } from '../../modules/SignUp&Login/Register/redux/actions';

type State = string;

const initialState: State = '';

const reducer = (state: State | undefined = initialState, action: AppAction): State => {
  switch (action.type) {
    case LOGGED_OUT_USER_TOKEN:
      return action.payload;
    case LOG_IN_SUCCESS:
    case SIGN_UP_SUCCESS:
      return "";
    default:
      return state;
  }
};

export default reducer;
