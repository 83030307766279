import React from 'react';
import routes from '../../lib/routes';
import './RegisterButtons.styles.scss';
import Button from '../buttons/ButtonRound';
interface Props {
  navigateTo(path: string): ClickHandler;
}
const RegisterButtons = ({ navigateTo }: Props) => {
  return (
    <div className="RegisterButtons-holder">
      <Button inverted onClick={navigateTo(routes.login)}>
        Log in
      </Button>
      <Button onClick={navigateTo(routes.landing)}>sign up</Button>
    </div>
  );
};
export default RegisterButtons;
