import { getQueryResponse } from '../../client';
import { gql } from "@apollo/client";
import errorMapper from '../../../mappers/error-mapper';
import { mapTimeline } from '../../../mappers/tasks/map-timeline';
import { timelineFragment } from '../../fragments';

interface Response {
  task: {
    timeline: Array<Api.TaskTimelineEvent>;
  };
}

const getTaskTimeline = gql `
  query TaskTimeline($id: UUID!, $offset: Int) {
    task(id: $id) {
    id
    timeline (first: 20, offset: $offset)
      {${timelineFragment}}
    }
  }
`;

const fetchTaskTimeline = async (
  args: { id: string; offset: number },
): Promise<Array<any> | Api.ApiError> => {
  const response = await getQueryResponse<Response>(getTaskTimeline, args);
  if (response?.data) {
    return response.data.task.timeline.map((event) => mapTimeline(event));
  }
  return errorMapper(response);
};

export default fetchTaskTimeline;
