import { ButtonType } from './ButtonText';

export const getTextColor = (type?: ButtonType) => {
  switch (type) {
    case ButtonType.warning:
      return '#F97878';
    case ButtonType.confirm:
      return '#64ACE2';
    case ButtonType.cancel:
      return '#9BA3AD';
    case ButtonType.disabled:
      return 'rgba(56, 72, 92, 0.6)';
    case ButtonType.delete:
      return '#F97878';
    default:
      return '#38485C';
  }
};

export const getBorderColor = (type?: ButtonType) => {
  switch (type) {
    case ButtonType.warning:
      return '#F97878';
    case ButtonType.confirm:
      return '#64ACE2';
    case ButtonType.cancel:
      return '#9BA3AD';
    case ButtonType.disabled:
      return 'rgba(56, 72, 92, 0.6)';
    case ButtonType.delete:
      return '#F97878';
    default:
      return '#38485C';
  }
};

export const getBackgroundColor = (type?: ButtonType) => {
  switch (type) {
    case ButtonType.warning:
      return '#F97878';
    case ButtonType.confirm:
      return '#64ACE2';
    case ButtonType.cancel:
      return '#9BA3AD';
    case ButtonType.disabled:
      return 'rgba(56, 72, 92, 0.6)';
    case ButtonType.delete:
      return 'white';
    default:
      return '#38485C';
  }
};
