import { combineReducers } from 'redux';
import { combineEpics } from 'redux-observable';

import * as contactEpic from '../modules/Contacts/redux/epics';
import * as contactTasksEpic from '../modules/Contacts/redux/contact-tasks/epics';
import * as userSettingsEpic from '../modules/UserSettings/redux/epics';
import * as loginEpic from '../modules/SignUp&Login/epics';
import * as taskEpics from '../modules/Tasks/redux/epics';
import * as taskCardEpics from '../modules/Tasks/redux/task-card/epics';
import navigationEpic from './navigationEpic';

// REDUCERS
import me from './reducers/me';
import token from './reducers/token';
import urlToken from './reducers/url-token';
import popup from '../components/popup/redux/reducer';
import modal from '../components/modal/redux/modal';
import panel from '../components/panel/redux/panel';
import selectedTask from '../modules/Tasks/redux/selected-task';

import contacts from '../modules/Contacts/redux/reducer';
import contactTasks from '../modules/Contacts/redux/contact-tasks/reducer';
import tasks from '../modules/Tasks/redux/reducer';

import verifyEmail from '../modules/SignUp&Login/VerifyEmailPrompt/redux/reducer';
import verifyYourEmail from '../modules/SignUp&Login/VerifyEmailPrompt/redux/reducer';

// export type AppState = ReturnType<ReturnType<typeof createRootReducer>>;
// todo fix state
export type AppState = any;

export const createRootReducer = (routerReducer: any) =>
  combineReducers({
    router: routerReducer,
    me,
    tasks,
    token,
    urlToken,
    popup,
    modal,
    panel,
    verifyEmail,
    verifyYourEmail,
    contacts,
    contactTasks,
    selectedTask,
  } as any);

export const rootEpic = combineEpics(
  taskEpics.addTaskEpic,
  taskEpics.fetchSingleTaskEpic,
  taskEpics.commentOnTaskEpic,
  taskEpics.editTaskEpic,
  taskEpics.fetchTaskTimelineEpic,
  taskEpics.refreshTaskTimelineEpic,
  taskEpics.reassignTaskEpic,
  taskEpics.deleteTaskEpic,
  taskEpics.onTaskDeleteEpic,
  taskEpics.archiveTaskEpic,
  taskEpics.fetchActiveTasksPageEpic,
  taskEpics.fetchCompletedTasksPageEpic,

  taskCardEpics.triggerReassignTaskEpic,
  taskCardEpics.acceptTaskEpic,
  taskCardEpics.rejectTaskEpic,
  taskCardEpics.markTaskInReviewEpic,
  taskCardEpics.markTaskSuccessfulEpic,
  taskCardEpics.markTaskFailedEpic,
  taskCardEpics.nagEpic,

  // login & signup
  loginEpic.fetchInitialDataEpic,
  loginEpic.resendEmailVerificationEpic,

  // loginEpic.unauthorizedSignOutEpic,
  loginEpic.unauthorizedSignOutRedirectEpic,
  loginEpic.verifyEmailEpic,

  // contacts page
  contactEpic.fetchContactListEpic,
  contactEpic.fetchContactByNumberEpic,
  contactEpic.fetchFullContactDataEpic,
  contactEpic.searchContactsEpic,
  contactEpic.editContactEpic,
  contactEpic.createContactEpic,
  contactEpic.fetchMyProfileDataEpic,
  contactEpic.deleteContactEpic,

  // contacts page task list
  contactTasksEpic.fetchContactTasksEpic,

  // edit profile
  userSettingsEpic.editUserSettingsEpic,
  userSettingsEpic.fetchProfileStatsEpic,

  navigationEpic,
);
