import { createAsyncAction, createAction } from 'typesafe-actions';

export const RESEND_EMAIL_VERIFICATION_REQUEST = 'RESEND_EMAIL_VERIFICATION_REQUEST';
export const RESEND_EMAIL_VERIFICATION_SUCCESS = 'RESEND_EMAIL_VERIFICATION_SUCCESS';
export const RESEND_EMAIL_VERIFICATION_FAILURE = 'RESEND_EMAIL_VERIFICATION_FAILURE';

export const EMAIL_VERIFIED_EVENT = 'EMAIL_VERIFIED_EVENT';

export const emailVerifiedEvent = createAction(EMAIL_VERIFIED_EVENT)<Api.EmailVerifiedNotification>();

export const resendEmailVerification = createAsyncAction(
  RESEND_EMAIL_VERIFICATION_REQUEST,
  RESEND_EMAIL_VERIFICATION_SUCCESS,
  RESEND_EMAIL_VERIFICATION_FAILURE,
)<string, boolean, boolean>();
