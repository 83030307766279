import React, { useEffect, useMemo, useState } from 'react';
import TasksSection from '../tasks-section/TasksSection';
import { useDispatch, useSelector } from 'react-redux';
import { TaskGridFilter } from '../TaskGrid';
import { AppState } from 'store';
import { fetchCompletedTasks } from 'modules/Tasks/redux/actions';
import LoadMoreButton from '../tasks-section/LoadMoreButton';
import { inLast24h, inLast7Days } from '../tasks-section/metrics';
import { SortedTasks } from '../active-grid/ActiveGrid';

let initTasksData = (): SortedTasks => ({
  last24h: [],
  last7days: [],
  lastMonth: [],
  archived: [],
});

const sortTasks = (taskList: any, tasks: any) => {
  if (taskList.tasks) {
    Object.keys(taskList.tasks).forEach((key) => {
      if (taskList.tasks[key].archived || !taskList.tasks[key].completed) {
        tasks.archived.push(taskList.tasks[key]);
      } else if (taskList.tasks[key].completed && inLast24h(taskList.tasks[key].completed)) {
        tasks.last24h.push(taskList.tasks[key]);
      } else if (taskList.tasks[key].completed && inLast7Days(taskList.tasks[key].completed)) {
        tasks.last7days.push(taskList.tasks[key]);
      } else {
        tasks.lastMonth.push(taskList.tasks[key]);
      }
    });
  }
  return tasks;
};

const CompletedGrid = () => {
  const [offset, setOffset] = useState(0);

  const dispatch = useDispatch();

  const me = useSelector((state: AppState) => state.me);

  const completedTasks = useSelector((state: AppState) => state.tasks.completedTasks);
  const { loadingPage, loadedAll, initiallyLoaded } = completedTasks;

  const sortedTasks = useMemo(() => {
    return sortTasks(completedTasks, initTasksData());
  }, [completedTasks]);

  const fetchTasks = ({ first, offset, loadedMore }: any) =>
    dispatch(fetchCompletedTasks.request({ first, offset, loadMore: loadedMore }));

  useEffect(() => {
    if (me?.id && !initiallyLoaded) {
      fetchTasks({
        offset,
        first: 50,
        loadedMore: false,
      });
    }
    // eslint-disable-next-line
  }, [me]);

  const loadMoreTasks = () => {
    const newOffset = offset + 12;
    setOffset(newOffset);
    fetchTasks({
      offset: newOffset,
      first: 50,
      loadedMore: true,
    });
  };

  return (
    <div>
      <TasksSection filter={TaskGridFilter.LAST_24H} taskList={sortedTasks.last24h} />
      <TasksSection filter={TaskGridFilter.LAST_7DAYS} taskList={sortedTasks.last7days} />
      <TasksSection filter={TaskGridFilter.LAST_MONTH} taskList={sortedTasks.lastMonth} />
      <TasksSection filter={TaskGridFilter.ARCHIVED} taskList={sortedTasks.archived} />
      {!loadedAll && <LoadMoreButton onClick={loadMoreTasks} loading={loadingPage} />}
    </div>
  );
};

export default CompletedGrid;
