import React, { useEffect, useState } from 'react';
import { FieldWrapper, Label } from '../../../input/input-components';
import { DateTimeHolders } from '../../../add-new-task-form/AddNewTask';
import DateInput from '../../../date-input/DateInput';
import { checkDeadline, createDateForTask } from '../../../../mappers/date/date-mappers';
import TimePicker from '../../../timepicker/TimePicker';
import Timestamp from '../../../Timestamp';
import { Value } from './TaskForm';

const DateInputStyle = { width: 'calc(50% - 5px)' };

interface TaskDeadlineProps {
  fieldWrapperStyle: React.CSSProperties;
  editState: boolean;
  isMobile: boolean;
  editTaskData(val: any): void;
  originalDeadline: any[];
}
const TaskDeadline = ({
  fieldWrapperStyle,
  editState,
  editTaskData,
  isMobile,
  originalDeadline,
}: TaskDeadlineProps) => {
  const dueDate = originalDeadline[0];
  const completedDate = originalDeadline[1];

  const dateToShow = completedDate ? completedDate : dueDate;

  const valueHours = new Date(dateToShow).getHours();
  const valueMinutes = new Date(dateToShow).getMinutes();

  const [dateValue, setDateValue] = useState(new Date(dateToShow));
  const [timeValue, setTimeValue] = useState({ hours: valueHours, minutes: valueMinutes });
  const [timeError, setTimeError] = useState(false);

  useEffect(() => {
    setDateValue(new Date(dateToShow));
    setTimeValue({ hours: valueHours, minutes: valueMinutes });
    // eslint-disable-next-line
  }, [editState]);

  return (
    <FieldWrapper style={fieldWrapperStyle}>
      <Label>{completedDate ? 'Completed: ' : 'Due date:'}</Label>
      <DateTimeHolders isMobile={isMobile}>
        {editState ? (
          <>
            <DateInput
              style={DateInputStyle}
              minDate={new Date()}
              smallInput
              onChange={(value) => {
                setDateValue(value);
                checkDeadline(timeValue, value, setTimeError);
                editTaskData({
                  deadline: createDateForTask(value, timeValue as any).toISOString(),
                });
              }}
              value={dateValue}
            />

            <TimePicker
              onBlur={(value) => {
                setTimeValue(value as any);
                checkDeadline(value, dateValue, setTimeError);
                editTaskData({
                  deadline: createDateForTask(dateValue, value as any).toISOString(),
                });
              }}
              smallInput={!isMobile}
              style={DateInputStyle}
              time={dateToShow}
              error={timeError}
              errorMessage={'Please set at least 30 min for deadline'}
            />
          </>
        ) : (
          <Value isMobile={isMobile}>
            <Timestamp year time={dateToShow} />
          </Value>
        )}
      </DateTimeHolders>
    </FieldWrapper>
  );
};
export default TaskDeadline;
