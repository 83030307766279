//@ts-nocheck
import React from 'react';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';
// @ts-ignore - doesnt have ts
import PhoneInput from 'react-phone-input-2';

import 'react-phone-input-2/lib/style.css';
import { ErrorMessage } from '../timepicker/TimePicker';
import { inputColors } from '../input/input-colors';
import withWindowSize from '../with-window-size/withWindowSize';
import './input-style.scss';
import { ErrorFormat } from '../input/Text-Input';

const PhoneInputHolder = styled.div<PhoneInputProps>`
    position: relative;
    color: #979797;
    width: ${(props) => (props.style && props.style.width) || '245px'};
    font-size: 12px;
    height: ${(props) => (props.smallInput || props.isMobile ? '28px' : '36px')};
    pointer-events: ${(props) => (props.disabled ? 'none' : 'default')};
    div {
        background-color: "transparent";
        outline: none;
    }
    input {
        border-radius: 20px;
        outline: none;
        font-family: "Nunito Sans", sans-serif;
        font-weight: bold;
        letter-spacing: 1px;
        color: #979797;
        border: 1px solid;
        transition: border-color 200ms linear;
        border-color: ${(props) =>
          props.error
            ? inputColors.borderColorError
            : props.disabled
            ? inputColors.borderColorDisabled
            : inputColors.borderColor};
        pointer-events: ${(props) => (props.disabled ? 'none' : 'default')}
        &:focus, &:hover {
            border-color: ${(props) =>
              props.error ? inputColors.borderColorError : inputColors.borderColorActive};
        }
        &:disabled {
            background-color: $white;
        }
    }
`;

interface PhoneInputProps {
  error?: boolean;
  smallInput?: boolean;
  isActive?: boolean;
  isMobile?: boolean;
  style?: React.CSSProperties;
  value?: string;
  disabled?: boolean;
  setValue?(value: string): void;
  saveValue?(): void;
  onBlur?(param?: React.FocusEvent<HTMLInputElement>): void;
  errorMessage?: string;
  format?: boolean;
  country?: string;
  onlyCountries?: string[];
  preferredCountries?: string[];
  onKeyDown?(value: React.KeyboardEvent<HTMLInputElement>): void;
}

const InputPhone = ({
  error,
  smallInput,
  style,
  value,
  disabled,
  setValue,
  errorMessage,
  onBlur,
  format,
  country,
  onlyCountries,
  preferredCountries,
  onKeyDown,
}: PhoneInputProps) => {
  const { isMobile } = withWindowSize();

  const inputStyle = {
    width: '100%',
    color: '#979797',
    borderRadius: '20px',
    height: smallInput ? '28px' : '36px',
    fontSize: smallInput && !isMobile ? '12px' : '14px',
    backgroundColor: disabled ? '#f3f5fb' : 'white',
  };

  return (
    <PhoneInputHolder
      id={uuidv4()}
      isMobile={isMobile}
      style={style}
      error={error}
      disabled={disabled}
      smallInput={smallInput}
    >
      <PhoneInput
        className={disabled && 'disabled-input'}
        inputProps={{ id: uuidv4(), autoComplete: 'off', name: uuidv4() }}
        disabled={disabled}
        value={value}
        autoFormat={format || true}
        containerStyle={{
          borderRadius: '20px 0 0 20px',
        }}
        inputStyle={inputStyle}
        dropdownStyle={{ width: isMobile ? '400px' : '245px', fontSize: '13px' }}
        onChange={setValue}
        onBlur={onBlur}
        onKeyDown={onKeyDown}
        placeholder={''}
        country={country}
        onlyCountries={onlyCountries}
        preferredCountries={preferredCountries}
      />
      {error && (
        <ErrorMessage style={{ top: !smallInput ? 40 : 30 }}>
          <ErrorFormat small={!!smallInput}>{errorMessage}</ErrorFormat>
        </ErrorMessage>
      )}
    </PhoneInputHolder>
  );
};
export default InputPhone;
