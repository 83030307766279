// @ts-nocheck
import { AppAction } from '../../../../store/actions';
import {
  FETCH_CONTACT_TASKS_FAILURE,
  FETCH_CONTACT_TASKS_REQUEST,
  FETCH_CONTACT_TASKS_SUCCESS,
  NO_MORE_CONTACT_TASKS,
} from './actions';
import {
  ADD_TASK_REQUEST,
  ADD_TASK_SUCCESS,
  ADD_TASK_FAILURE,
  ARCHIVE_TASK_SUCCESS,
  ARCHIVE_TASK_REQUEST,
  ARCHIVE_TASK_FAILURE,
  DELETE_TASK_SUCCESS,
  DELETE_TASK_REQUEST,
  DELETE_TASK_FAILURE,
  EDIT_TASK_FAILURE,
  EDIT_TASK_REQUEST,
  EDIT_TASK_SUCCESS,
  FETCH_SINGLE_TASK_SUCCESS,
  REASSIGN_TASK_SUCCESS,
  REASSIGN_TASK_REQUEST,
  REASSIGN_TASK_FAILURE,
} from '../../../Tasks/redux/actions';
import {
  TaskFilter,
  TaskStatusEnum,
} from '../../../../components/task-components/get-task-options';
import {
  ACCEPT_TASK_REQUEST,
  ACCEPT_TASK_SUCCESS,
  ACCEPT_TASK_FAILURE,
  MARK_TASK_FAILED_SUCCESS,
  MARK_TASK_FAILED_REQUEST,
  MARK_TASK_FAILED_FAILURE,
  MARK_TASK_IN_REVIEW_REQUEST,
  MARK_TASK_IN_REVIEW_SUCCESS,
  MARK_TASK_IN_REVIEW_FAILURE,
  MARK_TASK_SUCCESSFUL_SUCCESS,
  MARK_TASK_SUCCESSFUL_REQUEST,
  MARK_TASK_SUCCESSFUL_FAILURE,
  REJECT_TASK_SUCCESS,
  REJECT_TASK_REQUEST,
  REJECT_TASK_FAILURE,
  TASK_CREATED_EVENT,
  TASK_ACCEPTED_EVENT,
  TASK_REJECTED_EVENT,
  TASK_MARKED_IN_REVIEW_EVENT,
  TASK_MARKED_AS_SUCCESSFUL_EVENT,
  TASK_MARKED_AS_FAILED_EVENT,
  TASK_ARCHIVED_EVENT,
  TASK_ASSIGNED_EVENT,
  TASK_ASSIGNMENT_REMOVED_EVENT,
  TASK_DEADLINE_EXPIERED_EVENT,
  TASK_DELETED_EVENT,
  TASK_UPDATED_EVENT,
} from '../../../Tasks/redux/task-card/actions';
import { FETCH_INITIAL_DATA_SUCCESS, LOG_OUT } from 'modules/SignUp&Login/Login/redux/actions';
import {
  ASSIGNEE_UPDATED_EVENT,
  ASSIGNEE_CREATED_EVENT,
  FETCH_CONTACT_LIST_SUCCESS,
  ASSIGNEE_REMOVED_EVENT,
} from '../consts';

export interface ContactTasks {
  [key: string]: Task;
}

export interface ContactTasksReducer {
  id?: string;
  active: ContactTasks;
  allActiveFetched: boolean;
  completed: ContactTasks;
  allCompletedFetched: boolean;
  loading: boolean;
  actionInProgress: string[];
  me: User.Me | null;
  assignees: Contacts.ContactInList[];
}

let initialState: ContactTasksReducer = {
  id: undefined,
  active: {},
  allActiveFetched: false,
  completed: {},
  allCompletedFetched: false,
  loading: false,
  actionInProgress: [],
  me: null,
  assignees: [],
};

const reducer = (state = initialState, action: AppAction): ContactTasksReducer => {
  const genTaskFromEvent = (event: Api.TaskEvent, status: TaskStatusEnum): Task => {
    let task = {
      ...state.active[event.taskId],
    };
    if (task?.id) {
      task = {
        ...task,
        status,
        completed: new Date().toISOString(),
      };
    } else {
      const assignee =
        event.assigneeId === state.me?.id
          ? { ...state.me, registeredUser: false }
          : { ...state.assignees.find((a) => a.id === event.assigneeId) };
      const author =
        event.authorId === state.me?.id
          ? { ...state.me, registeredUser: false }
          : { ...state.assignees.find((a) => a.id === event.authorId) };
      task = {
        id: event.taskId,
        title: event.title,
        description: event.description || '',
        deadline: event.deadline || '',
        created: '',
        archived: false,
        assignee,
        author,
        status: status,
        completed: new Date().toISOString(),
      };
    }
    return task;
  };

  switch (action.type) {
    case FETCH_CONTACT_TASKS_REQUEST:
      if (state.id !== action.payload.id) {
        return {
          ...state,
          loading: true,
          allActiveFetched: false,
          allCompletedFetched: false,
        };
      }
      return {
        ...state,
        loading: true,
      };

    case FETCH_CONTACT_TASKS_SUCCESS:
      let newState = { ...state }; // Create a new state object
      if (!state.id || state.id !== action.payload.id) {
        newState.id = action.payload.id;
        newState[TaskFilter.ACTIVE] = {};
        newState[TaskFilter.COMPLETED] = {};
        newState[action.payload.filter] = action.payload.tasks as ContactTasks;
      } else if (state.id && state.id === action.payload.id) {
        newState[action.payload.filter] = {
          ...state[action.payload.filter],
          ...action.payload.tasks,
        };
      }
      return {
        ...newState,
        loading: false,
      };
    case FETCH_CONTACT_TASKS_FAILURE:
      return {
        ...state,
        loading: false,
      };

    case NO_MORE_CONTACT_TASKS:
      if (action.payload === TaskFilter.ACTIVE) {
        return {
          ...state,
          allActiveFetched: true,
        };
      } else {
        return {
          ...state,
          allCompletedFetched: true,
        };
      }
    case FETCH_SINGLE_TASK_SUCCESS:
      // refreshes the task card on panel open
      if (state[TaskFilter.ACTIVE].hasOwnProperty([action.payload.id])) {
        // eslint-disable-next-line
        for (let key in state[TaskFilter.ACTIVE][action.payload.id]) {
          state[TaskFilter.ACTIVE][action.payload.id].key = action.payload.key;
        }
      }
      if (state[TaskFilter.COMPLETED].hasOwnProperty([action.payload])) {
        // eslint-disable-next-line
        for (let key in state[TaskFilter.COMPLETED][action.payload.id]) {
          state[TaskFilter.COMPLETED][action.payload.id].key = action.payload.key;
        }
      }
      return { ...state };

    case EDIT_TASK_REQUEST:
    case ACCEPT_TASK_REQUEST:
    case MARK_TASK_IN_REVIEW_REQUEST:
    case REJECT_TASK_REQUEST:
    case MARK_TASK_FAILED_REQUEST:
    case MARK_TASK_SUCCESSFUL_REQUEST:
    case ARCHIVE_TASK_REQUEST:
    case DELETE_TASK_REQUEST:
    case REASSIGN_TASK_REQUEST: {
      const id = typeof action.payload === 'string' ? action.payload : action.payload.id;
      return {
        ...state,
        actionInProgress: id ? [...state.actionInProgress, id] : state.actionInProgress,
      };
    }
    case ADD_TASK_REQUEST: {
      return {
        ...state,
        actionInProgress: [...state.actionInProgress, action.payload.popupId],
      };
    }
    case ADD_TASK_FAILURE: {
      return {
        ...state,
        actionInProgress: [...state.actionInProgress, action.payload.popupId],
      };
    }
    case EDIT_TASK_FAILURE:
    case ACCEPT_TASK_FAILURE:
    case MARK_TASK_IN_REVIEW_FAILURE:
    case REJECT_TASK_FAILURE:
    case MARK_TASK_FAILED_FAILURE:
    case MARK_TASK_SUCCESSFUL_FAILURE:
    case ARCHIVE_TASK_FAILURE:
    case DELETE_TASK_FAILURE:
    case REASSIGN_TASK_FAILURE: {
      const id = typeof action.payload === 'string' ? action.payload : action.payload?.id;
      return {
        ...state,
        actionInProgress: state.actionInProgress.filter((a) => a !== id),
      };
    }
    case EDIT_TASK_SUCCESS:
    case ACCEPT_TASK_SUCCESS:
    case MARK_TASK_IN_REVIEW_SUCCESS: {
      const payload = action.payload;
      const activeTasks = { ...state[TaskFilter.ACTIVE] };

      if (state.id && state.id === payload.author.id) {
        activeTasks[payload.id] = payload;
      }

      return {
        ...state,
        [TaskFilter.ACTIVE]: activeTasks,
        actionInProgress: state.actionInProgress.filter((a) => a !== payload.id),
      };
    }

    case TASK_CREATED_EVENT: {
      const task = state[TaskFilter.ACTIVE][action.payload.taskId];
      if (state.id && !task && action.payload.assigneeId === state.id) {
        const newTask = genTaskFromEvent(action.payload, TaskStatusEnum.PENDING);
        const newActiveTasks = {
          ...state[TaskFilter.ACTIVE],
          [action.payload.taskId]: newTask,
        };
        return {
          ...state,
          [TaskFilter.ACTIVE]: newActiveTasks,
        };
      }
      return { ...state };
    }

    case TASK_ASSIGNED_EVENT: {
      if (state.id && action.payload.assigneeId === state.id) {
        state[TaskFilter.ACTIVE][action.payload.taskId] = genTaskFromEvent(
          action.payload,
          TaskStatusEnum.PENDING,
        );
        return { ...state };
      }
      return state;
    }

    case TASK_UPDATED_EVENT: {
      let task = state[TaskFilter.ACTIVE][action.payload.taskId];
      if (
        state.id &&
        task &&
        (action.payload.assigneeId === state.id || action.payload.authorId === state.id)
      ) {
        if (action.payload.updatedDeadline) {
          task.deadline = action.payload.updatedDeadline;
        }
        if (action.payload.description) {
          task.description = action.payload.description;
        }
        if (action.payload.updatedTitle) {
          task.title = action.payload.updatedTitle;
        }
        if (action.payload.assigneeId && action.payload.assigneeId !== task.assignee.id) {
          task.assignee = state.assignees.find((a) => a.id === action.payload.assigneeId);
        }
        state[TaskFilter.ACTIVE][action.payload.taskId] = task;
      }
      return { ...state };
    }

    case TASK_ASSIGNMENT_REMOVED_EVENT: {
      if (
        state.id &&
        state[TaskFilter.ACTIVE][action.payload.taskId]?.assignee?.id === action.payload.assigneeId
      ) {
        delete state[TaskFilter.ACTIVE][action.payload.taskId];
      }
      return { ...state };
    }

    case TASK_ACCEPTED_EVENT: {
      if (state.id && action.payload.authorId === state.id) {
        const newTask = genTaskFromEvent(action.payload, TaskStatusEnum.IN_PROGRESS);
        const newActiveTasks = {
          ...newTask,
          ...state[TaskFilter.ACTIVE],
        };
        return {
          ...state,
          [TaskFilter.ACTIVE]: newActiveTasks,
        };
      } else if (state.id && action.payload.assigneeId === state.id) {
        const taskId = action.payload.taskId;
        const activeTasks = { ...state[TaskFilter.ACTIVE] };
        activeTasks[taskId] = {
          ...activeTasks[taskId],
          status: TaskStatusEnum.IN_PROGRESS,
        };
        return {
          ...state,
          [TaskFilter.ACTIVE]: activeTasks,
        };
      }
      return { ...state };
    }

    case TASK_REJECTED_EVENT:
    case TASK_DEADLINE_EXPIERED_EVENT: {
      if (
        state.id &&
        (action.payload.authorId === state.id || action.payload.assigneeId === state.id)
      ) {
        const task: { [key: string]: Task } = {};
        task[action.payload.taskId] = genTaskFromEvent(action.payload, TaskStatusEnum.REJECTED);
        state[TaskFilter.COMPLETED] = {
          ...task,
          ...state[TaskFilter.COMPLETED],
        };
        delete state[TaskFilter.ACTIVE][action.payload.taskId];
      }
      return { ...state };
    }

    case TASK_MARKED_IN_REVIEW_EVENT: {
      if (state.id && state.id === action.payload.assigneeId && state.id === state.me?.id) {
        delete state[TaskFilter.ACTIVE][action.payload.taskId];
      } else if (
        state.id &&
        (state.id === action.payload.assigneeId || state.id === action.payload.authorId) &&
        state.id !== state.me?.id
      ) {
        state[TaskFilter.ACTIVE][action.payload.taskId].status = TaskStatusEnum.IN_REVIEW;
      }
      return { ...state };
    }

    case TASK_MARKED_AS_SUCCESSFUL_EVENT: {
      if (
        state.id &&
        (state.id === action.payload.assigneeId || state.id === action.payload.authorId)
      ) {
        const tasks: { [key: string]: Task } = {};
        tasks[action.payload.taskId] = genTaskFromEvent(action.payload, TaskStatusEnum.SUCCESSFUL);
        state[TaskFilter.COMPLETED] = {
          ...tasks,
          ...state[TaskFilter.COMPLETED],
        };
        if (state[TaskFilter.ACTIVE][action.payload.taskId]) {
          delete state[TaskFilter.ACTIVE][action.payload.taskId];
        }
        return { ...state };
      }
      return state;
    }

    case TASK_MARKED_AS_FAILED_EVENT: {
      if (
        state.id &&
        (state.id === action.payload.assigneeId || state.id === action.payload.authorId)
      ) {
        const tasks: { [key: string]: Task } = {};
        tasks[action.payload.taskId] = genTaskFromEvent(action.payload, TaskStatusEnum.FAILED);
        state[TaskFilter.COMPLETED] = {
          ...tasks,
          ...state[TaskFilter.COMPLETED],
        };
        if (state[TaskFilter.ACTIVE][action.payload.taskId]) {
          delete state[TaskFilter.ACTIVE][action.payload.taskId];
        }
        return { ...state };
      }
      return state;
    }

    case TASK_ARCHIVED_EVENT:
      if (state[TaskFilter.COMPLETED][action.payload.taskId]) {
        state[TaskFilter.COMPLETED][action.payload.taskId].archived = true;
      }
      return { ...state };

    case TASK_DELETED_EVENT:
      if (state[TaskFilter.ACTIVE][action.payload.taskId]) {
        delete state[TaskFilter.ACTIVE][action.payload.taskId];
      } else if (state[TaskFilter.COMPLETED][action.payload.taskId]) {
        delete state[TaskFilter.COMPLETED][action.payload.taskId];
      }
      return { ...state };

    case REJECT_TASK_SUCCESS:
    case MARK_TASK_FAILED_SUCCESS:
    case MARK_TASK_SUCCESSFUL_SUCCESS:
      if (state.id && state.id === action.payload.assignee.id) {
        const updatedActiveTasks = { ...state[TaskFilter.ACTIVE] };
        delete updatedActiveTasks[action.payload.id];

        const updatedCompletedTasks = {
          ...state[TaskFilter.COMPLETED],
          [action.payload.id]: action.payload,
        };

        return {
          ...state,
          [TaskFilter.ACTIVE]: updatedActiveTasks,
          [TaskFilter.COMPLETED]: updatedCompletedTasks,
          actionInProgress: state.actionInProgress.filter((a) => a !== action.payload.id),
        };
      }
      return state;

    case ARCHIVE_TASK_SUCCESS: {
      const updatedCompletedTasks = { ...state[TaskFilter.COMPLETED] };
      const taskId = action.payload;

      if (updatedCompletedTasks.hasOwnProperty(taskId)) {
        updatedCompletedTasks[taskId] = {
          ...updatedCompletedTasks[taskId],
          archived: true,
        };
      }

      return {
        ...state,
        [TaskFilter.COMPLETED]: updatedCompletedTasks,
        actionInProgress: state.actionInProgress.filter((a) => a !== taskId),
      };
    }

    case DELETE_TASK_SUCCESS:
      const updatedActiveTasks = { ...state[TaskFilter.ACTIVE] };
      const updatedCompletedTasks = { ...state[TaskFilter.COMPLETED] };

      if (updatedActiveTasks.hasOwnProperty(action.payload)) {
        delete updatedActiveTasks[action.payload];
      }
      if (updatedCompletedTasks.hasOwnProperty(action.payload)) {
        delete updatedCompletedTasks[action.payload];
      }

      return {
        ...state,
        [TaskFilter.ACTIVE]: updatedActiveTasks,
        [TaskFilter.COMPLETED]: updatedCompletedTasks,
        actionInProgress: state.actionInProgress.filter((a) => a !== action.payload),
      };

    case ADD_TASK_SUCCESS:
      if (state.id === action.payload.assignee?.id) {
        const updatedActiveTasks = {
          ...state[TaskFilter.ACTIVE],
          [action.payload.id]: {
            ...state[TaskFilter.ACTIVE][action.payload.id],
            ...action.payload,
          },
        };
        return {
          ...state,
          [TaskFilter.ACTIVE]: updatedActiveTasks,
          actionInProgress: state.actionInProgress.filter((a) => a !== action.payload.popupId),
        };
      }
      return state;

    case REASSIGN_TASK_SUCCESS: {
      const assignedTask = action.payload.response;
      const activeTasks = { ...state[TaskFilter.ACTIVE] };

      if (state.id === assignedTask.assignee.id) {
        activeTasks[assignedTask.id] = assignedTask;
      }

      if (activeTasks.hasOwnProperty(assignedTask.id) && state.id !== assignedTask.assignee.id) {
        delete activeTasks[assignedTask.id];
      }

      return {
        ...state,
        [TaskFilter.ACTIVE]: activeTasks,
        actionInProgress: state.actionInProgress.filter((a) => a !== assignedTask.id),
      };
    }

    case FETCH_CONTACT_LIST_SUCCESS: {
      const assignees: Contacts.ContactInList[] = [];
      for (const [, value] of Object.entries(action.payload)) {
        if (Array.isArray(value)) {
          assignees.push(...(value as Contacts.ContactInList[]));
        }
      }
      return {
        ...state,
        assignees,
      };
    }

    case ASSIGNEE_CREATED_EVENT: {
      const assignee = action.payload;
      return {
        ...state,
        assignees: [
          {
            id: assignee.assigneeId,
            firstName: assignee.firstName,
            lastName: assignee.assigneeLastName || '',
            email: assignee.assigneeEmail || '',
            registeredUser: false,
          },
          ...state.assignees,
        ],
      };
    }

    case ASSIGNEE_UPDATED_EVENT: {
      const assignee = action.payload;
      return {
        ...state,
        assignees: state.assignees.map((a) =>
          a.id === assignee.assigneeId
            ? {
                ...a,
                firstName: assignee.updatedFirstName || a.firstName,
                lastName: assignee.updatedLastName || a.lastName,
                email: assignee.updatedEmail || a.email,
              }
            : a,
        ),
      };
    }

    case ASSIGNEE_REMOVED_EVENT: {
      const assignee = action.payload;
      return {
        ...state,
        assignees: state.assignees.filter((a) => a.id !== assignee.assigneeId),
      };
    }

    case FETCH_INITIAL_DATA_SUCCESS:
      return {
        ...state,
        me: action.payload,
      };

    case LOG_OUT:
      return {
        id: undefined,
        active: {},
        allActiveFetched: false,
        completed: {},
        allCompletedFetched: false,
        loading: false,
        actionInProgress: [],
        me: null,
        assignees: [],
      };

    default:
      return state;
  }
};
export default reducer;
