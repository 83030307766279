import React, { useEffect, useState } from 'react';
import { ThinTitle, LargeTitle, Step, IUserRegisterData } from './Register';
import VerifyCode from '../../../components/verify-code/VerifyCode';
import verifyPhone, { VerifyMobileResponse } from '../../../api/mutations/user/verify-mobile';
import errorMapper from '../../../mappers/error-mapper';
import checkPhone from '../../../api/mutations/user/check-phone';
import { toast } from 'react-toastify';
import { signupPagePhoneNumberVerificationEvent } from '../../../api/amplitude/events';

interface IStep2 {
  getLeftPosition(step: number): React.CSSProperties;
  setActiveStep(step: number): void;
  userData: IUserRegisterData;
  setUserData(data: any): void;
}

const Step2 = ({ getLeftPosition, setActiveStep, userData, setUserData }: IStep2) => {
  const [verifyCodeError, setError] = useState<string>('');

  useEffect(() => {
    signupPagePhoneNumberVerificationEvent();
  }, []);

  const resendCode = async () => {
    try {
      const response = (await checkPhone(userData.mobile)) as Api.GraphQL.Response<{
        sendMobileVerificationCode: string;
      }>;
      if (response.data) {
        toast('Code has been resent.', { type: 'success' });
      } else {
        setError(errorMapper(response).message);
      }
    } catch (e) {
      setError(errorMapper().message);
    }
  };

  const checkVerificationCode = async (code: string) => {
    try {
      const response = (await verifyPhone({
        phone: userData.mobile,
        code: code,
      })) as Api.GraphQL.Response<VerifyMobileResponse>;
      if (response.data && response.data.verifyMobile) {
        setActiveStep(2);
        setUserData({ code });
      } else if ((response as Api.GraphQL.Errors).errors) {
        setError(errorMapper(response as Api.GraphQL.Errors).message);
        setUserData({ code });
      }
    } catch (e) {
      setError(errorMapper().message);
    }
  };

  return (
    <Step style={getLeftPosition(1)}>
      <ThinTitle>2 OUT OF 4</ThinTitle>
      <LargeTitle>Verify phone</LargeTitle>
      <VerifyCode
        onVerify={(code: string) => checkVerificationCode(code)}
        resendCode={resendCode}
        serverError={verifyCodeError}
      />
    </Step>
  );
};
export default Step2;
