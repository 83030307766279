import { createAsyncAction, createAction } from 'typesafe-actions';

export const ADD_TASK_FAILURE = 'ADD_TASK_FAILURE';
export const ADD_TASK_REQUEST = 'ADD_TASK_REQUEST';
export const ADD_TASK_SUCCESS = 'ADD_TASK_SUCCESS';

export const FETCH_ACTIVE_TASKS_REQUEST = 'FETCH_ACTIVE_TASKS_REQUEST';
export const FETCH_ACTIVE_TASKS_SUCCESS = 'FETCH_ACTIVE_TASKS_SUCCESS';
export const FETCH_ACTIVE_TASKS_FAILURE = 'FETCH_ACTIVE_TASKS_FAILURE';

export const FETCH_COMPLETED_TASKS_REQUEST = 'FETCH_COMPLETED_TASKS_REQUEST';
export const FETCH_COMPLETED_TASKS_SUCCESS = 'FETCH_COMPLETED_TASKS_SUCCESS';
export const FETCH_COMPLETED_TASKS_FAILURE = 'FETCH_COMPLETED_TASKS_FAILURE';

export const FETCH_SINGLE_TASK_REQUEST = 'FETCH_SINGLE_TASK_REQUEST';
export const FETCH_SINGLE_TASK_SUCCESS = 'FETCH_SINGLE_TASK_SUCCESS';
export const FETCH_SINGLE_TASK_FAILURE = 'FETCH_SINGLE_TASK_FAILURE';

export const EDIT_TASK_REQUEST = 'EDIT_TASK_REQUEST';
export const EDIT_TASK_SUCCESS = 'EDIT_TASK_SUCCESS';
export const EDIT_TASK_FAILURE = 'EDIT_TASK_FAILURE';

export const REASSIGN_TASK_REQUEST = 'REASSIGN_TASK_REQUEST';
export const REASSIGN_TASK_SUCCESS = 'REASSIGN_TASK_SUCCESS';
export const REASSIGN_TASK_FAILURE = 'REASSIGN_TASK_FAILURE';

export const ARCHIVE_TASK_REQUEST = 'ARCHIVE_TASK_REQUEST';
export const ARCHIVE_TASK_SUCCESS = 'ARCHIVE_TASK_SUCCESS';
export const ARCHIVE_TASK_FAILURE = 'ARCHIVE_TASK_FAILURE';

export const DELETE_TASK_REQUEST = 'DELETE_TASK_REQUEST';
export const DELETE_TASK_SUCCESS = 'DELETE_TASK_SUCCESS';
export const DELETE_TASK_FAILURE = 'DELETE_TASK_FAILURE';

export const COMMENT_ON_TASK_REQUEST = 'COMMENT_ON_TASK_REQUEST';
export const COMMENT_ON_TASK_SUCCESS = 'COMMENT_ON_TASK_SUCCESS';
export const COMMENT_ON_TASK_FAILURE = 'COMMENT_ON_TASK_FAILURE';

export const FETCH_TASK_TIMELINE_REQUEST = 'FETCH_TASK_TIMELINE_REQUEST';
export const FETCH_TASK_TIMELINE_SUCCESS = 'FETCH_TASK_TIMELINE_SUCCESS';
export const FETCH_TASK_TIMELINE_FAILURE = 'FETCH_TASK_TIMELINE_FAILURE';

export const REFRESH_TASK_TIMELINE_REQUEST = 'REFRESH_TASK_TIMELINE_REQUEST';
export const REFRESH_TASK_TIMELINE_SUCCESS = 'REFRESH_TASK_TIMELINE_SUCCESS';
export const REFRESH_TASK_TIMELINE_FAILURE = 'REFRESH_TASK_TIMELINE_FAILURE';

export const OPEN_EDIT_ASSIGNEE = 'OPEN_EDIT_ASSIGNEE';

export const FETCHED_ALL_ACTIVE_TASKS = 'FETCHED_ALL_ACTIVE_TASKS';

export const FETCHED_ALL_COMPLETED_TASKS = 'FETCHED_ALL_COMPLETED_TASKS';

export const FETCH_TASKS_INITIAL_REQUEST = 'FETCH_TASKS_INITIAL_REQUEST';

export const COMMENT_CREATED_EVENT = "COMMENT_CREATED_EVENT";

export const COMMENT_UPDATED_EVENT = "COMMENT_UPDATED_EVENT";

export interface IAddTask {
  popupId: string;
  title: string;
  assigneeId: string;
  deadline: Date;
  description?: string;
  userId?: string;
}

type PopupId = {
  popupId: string;
};

export const addNewTask = createAsyncAction(ADD_TASK_REQUEST, ADD_TASK_SUCCESS, ADD_TASK_FAILURE)<
  IAddTask,
  Task & PopupId,
  PopupId
>();

export interface FetchTasksParams {
  id: string;
  filter: any;
  first: number;
  offset: number;
}
interface Tasks {
  [key: string]: Task;
}

export interface FetchTasksResponse {
  first: number;
  offset: number;
  loadMore: boolean;
}

interface FetchCompletedActiveTasksResponse {
  tasks: Tasks;
  loadedMore: boolean;
}

export const fetchActiveTasks = createAsyncAction(
  FETCH_ACTIVE_TASKS_REQUEST,
  FETCH_ACTIVE_TASKS_SUCCESS,
  FETCH_ACTIVE_TASKS_FAILURE,
)<FetchTasksResponse, FetchCompletedActiveTasksResponse, void>();

export const fetchCompletedTasks = createAsyncAction(
  FETCH_COMPLETED_TASKS_REQUEST,
  FETCH_COMPLETED_TASKS_SUCCESS,
  FETCH_COMPLETED_TASKS_FAILURE,
)<FetchTasksResponse, FetchCompletedActiveTasksResponse, void>();

export const fetchSingleTask = createAsyncAction(
  FETCH_SINGLE_TASK_REQUEST,
  FETCH_SINGLE_TASK_SUCCESS,
  FETCH_SINGLE_TASK_FAILURE,
)<{ id: string; triggerReassign?: boolean }, Task, void>();

export interface EditTaskProps {
  id: string;
  value: {
    title?: string;
    description?: string;
    assigneeId?: string;
    deadline?: string;
  };
}
export interface ReassignTaskProps extends EditTaskProps {
  oldAssigneeId: string;
}

export interface ReassignTaskResponse {
  oldAssigneeId: string;
  response: Task;
}

export const editTask = createAsyncAction(EDIT_TASK_REQUEST, EDIT_TASK_SUCCESS, EDIT_TASK_FAILURE)<
  EditTaskProps,
  Task,
  { id: string }
>();

export const reassignTask = createAsyncAction(
  REASSIGN_TASK_REQUEST,
  REASSIGN_TASK_SUCCESS,
  REASSIGN_TASK_FAILURE,
)<ReassignTaskProps, ReassignTaskResponse, void>();

export const commentOnTask = createAsyncAction(
  COMMENT_ON_TASK_REQUEST,
  COMMENT_ON_TASK_SUCCESS,
  COMMENT_ON_TASK_FAILURE,
)<{ id: string; comment: string }, { id: string; comment: string }, void>();

export const fetchTaskTimeline = createAsyncAction(
  FETCH_TASK_TIMELINE_REQUEST,
  FETCH_TASK_TIMELINE_SUCCESS,
  FETCH_TASK_TIMELINE_FAILURE,
)<{ id: string; offset: number; fetchMore: boolean }, any, void>();

export const refreshTaskTimeline = createAsyncAction(
  REFRESH_TASK_TIMELINE_REQUEST,
  REFRESH_TASK_TIMELINE_SUCCESS,
  REFRESH_TASK_TIMELINE_FAILURE,
)<{ id: string; offset: number; fetchMore: boolean }, any, void>();

export const archiveTask = createAsyncAction(
  ARCHIVE_TASK_REQUEST,
  ARCHIVE_TASK_SUCCESS,
  ARCHIVE_TASK_FAILURE,
)<string, string, { id: string }>();

export const deleteTask = createAsyncAction(
  DELETE_TASK_REQUEST,
  DELETE_TASK_SUCCESS,
  DELETE_TASK_FAILURE,
)<string, string, { id: string }>();

export const openEditAssignee = createAction(OPEN_EDIT_ASSIGNEE)<boolean>();

export const fetchedAllActiveTasks = createAction(FETCHED_ALL_ACTIVE_TASKS)<string>();

export const fetchedAllCompletedTasks = createAction(FETCHED_ALL_COMPLETED_TASKS)<string>();

export const fetchTasksInitial = createAction(FETCH_TASKS_INITIAL_REQUEST)();

export const commentCreatedEvent = createAction(COMMENT_CREATED_EVENT)<Api.CommentCreatedNotification>();

export const commentUpdatedEvent = createAction(COMMENT_UPDATED_EVENT)<Api.CommentEditedNotification>();

export type TaskActions =
  | ReturnType<typeof addNewTask.request>
  | ReturnType<typeof addNewTask.success>
  | ReturnType<typeof addNewTask.failure>
  | ReturnType<typeof fetchActiveTasks.request>
  | ReturnType<typeof fetchActiveTasks.success>
  | ReturnType<typeof fetchActiveTasks.failure>
  | ReturnType<typeof fetchCompletedTasks.request>
  | ReturnType<typeof fetchCompletedTasks.success>
  | ReturnType<typeof fetchCompletedTasks.failure>
  | ReturnType<typeof fetchSingleTask.request>
  | ReturnType<typeof fetchSingleTask.success>
  | ReturnType<typeof fetchSingleTask.failure>
  | ReturnType<typeof editTask.request>
  | ReturnType<typeof editTask.success>
  | ReturnType<typeof editTask.failure>
  | ReturnType<typeof reassignTask.request>
  | ReturnType<typeof reassignTask.success>
  | ReturnType<typeof reassignTask.failure>
  | ReturnType<typeof archiveTask.request>
  | ReturnType<typeof archiveTask.success>
  | ReturnType<typeof archiveTask.failure>
  | ReturnType<typeof deleteTask.request>
  | ReturnType<typeof deleteTask.success>
  | ReturnType<typeof deleteTask.failure>
  | ReturnType<typeof commentOnTask.request>
  | ReturnType<typeof commentOnTask.success>
  | ReturnType<typeof commentOnTask.failure>
  | ReturnType<typeof fetchTaskTimeline.request>
  | ReturnType<typeof fetchTaskTimeline.success>
  | ReturnType<typeof fetchTaskTimeline.failure>
  | ReturnType<typeof refreshTaskTimeline.request>
  | ReturnType<typeof refreshTaskTimeline.success>
  | ReturnType<typeof refreshTaskTimeline.failure>
  | ReturnType<typeof openEditAssignee>
  | ReturnType<typeof fetchedAllActiveTasks>
  | ReturnType<typeof fetchedAllCompletedTasks>
  | ReturnType<typeof fetchTasksInitial>
  | ReturnType<typeof commentCreatedEvent>
  | ReturnType<typeof commentUpdatedEvent>;
  
