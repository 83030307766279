import { getQueryResponse } from '../../client';
import { gql } from "@apollo/client";

interface Arguments {
  email: string;
  password: string;
}

interface Response {
  login: {
    access: string;
  };
}

const query = gql`
  query Login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      access
    }
  }
`;

// login functionality is moved to login component
// in order to log errors to the ui component and
// remove layer of complexity with redux and epic
const login = async (args: Arguments): Promise<Api.GraphQL.Response<Response>> => {
  const response = await getQueryResponse<Response>(query, args);
  
  return response;
};

export default login;
