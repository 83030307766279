const genCharArray = (charA: string, charZ: string) => {
  let a = [],
    i = charA.charCodeAt(0),
    j = charZ.charCodeAt(0);
  for (; i <= j; ++i) {
    a.push(String.fromCharCode(i));
  }
  return a;
};

const alphabet = genCharArray('A', 'Z');
const characters = [...alphabet, ...['#']];

export default characters;
