import React from 'react';
import styled from 'styled-components';
import { IVerifyBanner } from './VerifyBanner';

const VerifyLink = ({ verify, me, emailSent }: IVerifyBanner) => {
  if (me.id && me.emailVerified === false && emailSent) {
    return <VerifiedLabel>Email sent!</VerifiedLabel>;
  } else if (me.id && me.emailVerified === false) {
    return <Link onClick={verify}>Verify email</Link>;
  } else {
    return null;
  }
};
export default VerifyLink;

const Link = styled.a`
  font-size: 12px;
  font-weight: bold;
  text-decoration: underline;
  letter-spacing: 1px;
  color: #64ace2;
  margin: 20px 0;
  cursor: pointer;
`;

const VerifiedLabel = styled.a`
  font-size: 11px;
  letter-spacing: 1px;
  color: #64ace2;
  margin: 20px 0;
`;

export const Send = styled.div`
  color: white;
  text-transform: uppercase;
  font-size: 10px;
  line-height: 14px;
  font-weight: bold;
  visibility: hidden;
  transition: visibility 200ms ease, width 200 ease;
`;
