import { getMutationResponse } from '../../client';
import { gql } from '@apollo/client';
import errorMapper from '../../../mappers/error-mapper';

const mutation = gql`
  mutation nagAssignee($taskId: UUID!) {
    nagAssignee(taskId: $taskId)
  }
`;

const nag = async (args: string): Promise<boolean | Api.ApiError> => {
  const response = await getMutationResponse(mutation, { taskId: args });
  if (response.data !== null) {
    return response.data.nagAssignee;
  }
  return errorMapper(response as Api.GraphQL.Errors);
};

export default nag;
