//@ts-nocheck
import React from 'react';
import styled from 'styled-components';
import { getTextColor, getBorderColor, getBackgroundColor } from './get-button-color';
import { ButtonType } from './ButtonText';
import ButtonBase from './ButtonBase';

export enum ButtonSizes {
  SMALL,
  MEDIUM,
}

interface ICustomButtonProps {
  buttonType?: ButtonType;
  children: string;
  onClick?: ClickHandler;
  disabled?: boolean;
  inverted?: boolean;
  size?: ButtonSizes;
  width?: string;
  style?: React.CSSProperties;
}

const ButtonHolder = styled(ButtonBase)`
  display: inline-block;
  border: 1px solid;
  border-radius: 20px;
  text-align: center;
`;

const ButtonRoundHolder = styled(ButtonHolder)<ICustomButtonProps>`
  background-color: ${(props) => (props.inverted ? 'white' : getBackgroundColor(props.buttonType))};
  border-color: ${(props) => getBorderColor(props.buttonType)};
  color: ${(props) => (props.inverted ? getTextColor(props.buttonType) : 'white')};
  width: ${(props) => props.width || 'auto'};
  padding: ${(props) => getPaddingBasedOnSize(props.size)};
  font-size: ${(props) => (props.size === ButtonSizes.SMALL ? '10px' : '12px')};
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    background-color: ${(props) => props.inverted && '#eeeeee'};
    opacity: 0.8;
  }
`;

export const getPaddingBasedOnSize = (size?: ButtonSizes) => {
  switch (size) {
    case ButtonSizes.SMALL:
      return '5px 12px';
    case ButtonSizes.MEDIUM:
      return '10px 20px';
    default:
      return '10px 20px';
  }
};

const ButtonRound = ({
  buttonType,
  children,
  disabled,
  inverted,
  size,
  width,
  onClick,
  style,
}: ICustomButtonProps) => {
  return (
    <ButtonRoundHolder
      style={style}
      onClick={onClick}
      buttonType={disabled ? ButtonType.disabled : buttonType}
      inverted={inverted}
      size={size}
      width={width}
      disabled={disabled}
    >
      {children}
    </ButtonRoundHolder>
  );
};
export default ButtonRound;
