import { getQueryResponse } from '../../client';
import { DocumentNode, gql } from "@apollo/client";
import { FetchContactTaskParams } from '../../../modules/Contacts/redux/contact-tasks/actions';
import { tasksArrayToTaskObject } from '../../../mappers/tasks/tasks-array-to-object';
import { TaskFilter } from '../../../components/task-components/get-task-options';
import errorMapper from '../../../mappers/error-mapper';
import { tasksFragment } from 'api/fragments';

interface Tasks {
  [key: string]: Task;
}

const getActiveContactTasks = gql`
  query activeTasks($id: UUID!, $first: Int, $offset: Int) {
    tasks: activeTasks(assigneeId: $id, first: $first, offset: $offset) {
      ...TasksFragment
    }
  }
  ${tasksFragment}
`;

const getCompletedContactTasks = gql`
  query completedTasks($id: UUID!, $first: Int, $offset: Int) {
    tasks: completedTasks(assigneeId: $id, first: $first, offset: $offset) {
      ...TasksFragment
    }
  }
  ${tasksFragment}
`;

const query = (filter: TaskFilter): DocumentNode => {
  return filter === TaskFilter.ACTIVE
    ? getActiveContactTasks
    : getCompletedContactTasks;
};

const fetchContactTasks = async (
  args: FetchContactTaskParams,
): Promise<Tasks | Api.ApiError> => {
  const response = await getQueryResponse<Tasks>(query(args.filter), args);
  if (response?.data) {
    return tasksArrayToTaskObject(response.data.tasks);
  }
  return errorMapper(response);
};

export default fetchContactTasks;
