import React from 'react';
import styled from 'styled-components';
import { PieChart } from 'react-minimal-pie-chart';
import * as colors from '../../lib/colors';
import forEach from 'lodash/forEach';

interface IGraph {
  stats?: Contacts.TaskStats[];
}
interface IFormatData {
  color: string;
  title: string;
  value: number;
}

const toCamelCase = (myString: string) =>
  myString.replace(/_([a-z])/g, function (g) {
    return g[1].toUpperCase();
  });

const Graph = ({ stats }: IGraph) => {
  const graphData: IFormatData[] = [];
  if (!stats || (stats && stats.length) === 0) {
    // temporary empty state
    graphData.push({ color: 'lightgray', title: 'no stats', value: 0.1 });
  } else {
    forEach(stats, (stat: Contacts.TaskStats) => {
      let statusString = stat.status.toLowerCase();
      let colorString = toCamelCase(statusString);
      let titleString = statusString.replace(/_/g, ' ');
      const newItem = {
        // @ts-ignore ignore graph props
        color:
          // @ts-ignore ignore graph props
          colors.status.complete[colorString] ||
          // @ts-ignore ignore graph props
          colors.status.active[colorString],
        title: titleString,
        value: stat.count,
      };
      graphData.push(newItem);
    });
  }

  return (
    <GraphHolder>
      <TaskPercentages>
        <PieChart
          animate={true}
          animationDuration={!stats ? 500 : 800}
          animationEasing="ease-out"
          data={graphData}
          lengthAngle={360}
          lineWidth={10}
        />
      </TaskPercentages>
      <Legend>
        {graphData.map((item: IFormatData) => (
          <Row key={item.color}>
            <Dot color={item.color} />
            <PercentLabel>
              {item.value > 0.1 && item.value} {item.title}
            </PercentLabel>
          </Row>
        ))}
      </Legend>
    </GraphHolder>
  );
};

export default Graph;

const GraphHolder = styled.div`
  display: flex;
  margin-top: 30px;
  flex-direction: row;
  align-items: center;
`;

const TaskPercentages = styled.div`
  width: 138px;
  margin-right: 30px;
`;

const Legend = styled.div`
  display: flex;
  flex-direction: column;
`;

const PercentLabel = styled.div`
  font-size: 12px;
  color: #9ba3ad;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 2px;
`;

const Dot = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: ${(props) => props.color};
  margin-right: 12px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  align-items: center;
  min-width: 150px;
`;
