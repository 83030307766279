import React from 'react';
import styled from 'styled-components';
import TaskData from './task-data/TaskData';
import TaskActions from './task-actions/TaskActions';
import StatusIndicator from '../status-indicator/StatusIndicator';
import { isTaskActive, TaskStatusEnum } from '../get-task-options';
import withWindowSize from '../../with-window-size/withWindowSize';
import Helmet from 'react-helmet';
import OGimage from '../../../images/OGimage.png';

enum TaskCardHeight {
  SMALL = '96px',
  LARGE = '128px',
}

interface ITaskCardProps extends Task {
  me: User.Me;
  onTaskClick?: Function;
  isSelected?: boolean;
}

const TaskCardHolder = styled.div`
  background-color: white;
  border-radius: 5px;
  position: relative;
  border: 1px solid #dedfe3;
  padding-left: 7px;
`;

const TaskCard = ({
  status,
  id,
  archived,
  title,
  deadline,
  // @ts-ignore
  completed,
  author,
  assignee,
  onTaskClick,
  me,
  isSelected,
}: ITaskCardProps) => {
  const iAmAuthor = me.id === author.id;
  const user = iAmAuthor ? assignee : author;
  const { isMobile } = withWindowSize();
  const isActive = isTaskActive(status);
  const showTaskOptions = isActive && !(status === TaskStatusEnum.IN_REVIEW && !iAmAuthor);
  return (
    <div style={{ width: isMobile ? '100%' : undefined }}>
      <Helmet>
        <meta property="og:title" content={title} />
        <meta property="og:image" content={OGimage} />
      </Helmet>
      <TaskCardHolder
        style={{
          boxShadow: isSelected ? '3px 7px 23px rgba(71, 71, 71, 0.2)' : 'none',
          margin: isMobile ? '7px 0' : '7px 5px',
          width: isMobile ? '100%' : '300px',
          height: showTaskOptions ? TaskCardHeight.LARGE : TaskCardHeight.SMALL,
        }}
      >
        <StatusIndicator status={status} isCard={true} iAmAuthor={iAmAuthor} archived={archived} />

        <div onClick={(e) => onTaskClick && onTaskClick(e, id)}>
          <TaskData
            isActive={isActive}
            isSelected={isSelected}
            iAmAuthor={iAmAuthor}
            title={title}
            user={user}
            date={isActive ? deadline : completed}
            status={status}
          />
        </div>

        {showTaskOptions ? (
          <TaskActions
            id={id}
            status={status}
            assignee={assignee}
            iAmAuthor={iAmAuthor}
            isArchived={archived}
          />
        ) : null}
      </TaskCardHolder>
    </div>
  );
};

export default React.memo(TaskCard);
