import React from 'react';
import * as ReactDOMClient from 'react-dom/client';
import { HistoryRouter as Router } from 'redux-first-history/rr6';
import { createBrowserHistory } from 'history';
import { Provider } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';
import { createReduxHistoryContext } from 'redux-first-history';
import { createEpicMiddleware } from 'redux-observable';
import App from './App';
import { AppState, createRootReducer, rootEpic } from './store';
import { AppAction } from './store/actions';
import './styles/index.scss';
import WebFont from 'webfontloader';
import { ApolloProvider } from '@apollo/client';
import { client } from 'api/client';
import Subscription from 'components/Subscription';
import { loadState } from 'localStorage';

import './styles/normalize.css';

const isDev = process.env.NODE_ENV === 'development';
const root = ReactDOMClient.createRoot(document.getElementById('root') as HTMLElement);
const epicMiddleware = createEpicMiddleware<AppAction, AppAction, AppState>();

const { createReduxHistory, routerMiddleware, routerReducer } = createReduxHistoryContext({
  history: createBrowserHistory(),
});

export const store = configureStore({
  reducer: createRootReducer(routerReducer),
  preloadedState: loadState(),
  devTools: isDev,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false })
      .prepend(epicMiddleware)
      .concat(routerMiddleware),
});

export const history = createReduxHistory(store);

epicMiddleware.run(rootEpic);

const render = (Component: any) => {
  WebFont.load({
    google: {
      families: [
        'Montserrat:300,400,600,700,800, 900',
        'Nunito Sans:300,400,600,700,800, 900',
        'sans-serif',
      ],
    },
  });

  root.render(
    <ApolloProvider client={client}>
      <Provider store={store}>
        <Router history={history}>
          <Component />
          <Subscription />
        </Router>
      </Provider>
    </ApolloProvider>,
  );
};

render(App);

if (isDev && module.hot) {
  module.hot.accept('./App', () => {
    const NextApp = require('./App').default;
    render(NextApp);
  });
}
