import React from 'react';
import ButtonRound from '../ButtonRound';
import { ButtonType } from '../ButtonText';

interface Props {
  id: string;
  openPopupWithSettings(id: string): void;
  disabled?: boolean;
}

export const AssignTaskButton = ({ id, openPopupWithSettings, disabled = false }: Props) => (
  <ButtonRound onClick={() => openPopupWithSettings(id)} buttonType={ButtonType.confirm} disabled={disabled} >
    Assign a Task
  </ButtonRound>
);
