import React from 'react';
import { ButtonFooter } from '../add-new-contact-form/AddNewContact';
import ButtonText, { ButtonType } from '../buttons/ButtonText';
import ButtonRound from '../buttons/ButtonRound';

interface IAddNEwTaskFooter {
  isMobile: boolean;
  popupId?: string;
  closePopup(is: string): void;
  mandatoryFields: boolean;
  assignTask(): void;
}

const AddNewTaskFooter = ({
  isMobile,
  popupId,
  closePopup,
  mandatoryFields,
  assignTask,
}: IAddNEwTaskFooter) => (
  <>
    {isMobile ? (
      <ButtonFooter>
        {popupId && (
          <ButtonText onClick={() => closePopup(popupId)} buttonType={ButtonType.cancel}>
            cancel
          </ButtonText>
        )}
        <ButtonText disabled={mandatoryFields} onClick={assignTask} buttonType={ButtonType.confirm}>
          Assign
        </ButtonText>
      </ButtonFooter>
    ) : (
      <ButtonFooter style={{ paddingRight: 20 }}>
        {popupId && (
          <ButtonRound
            style={{ marginRight: '15px' }}
            inverted
            onClick={() => closePopup(popupId)}
            buttonType={ButtonType.cancel}
          >
            cancel
          </ButtonRound>
        )}
        <ButtonRound
          disabled={mandatoryFields}
          buttonType={ButtonType.confirm}
          onClick={assignTask}
        >
          Assign
        </ButtonRound>
      </ButtonFooter>
    )}
  </>
);

export default AddNewTaskFooter;
