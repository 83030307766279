import { assigneeCreatedEvent, assigneeRemovedEvent, assigneeUpdatedEvent } from "modules/Contacts/redux/actions";
import { emailVerifiedEvent } from "modules/SignUp&Login/VerifyEmailPrompt/redux/actions";
import { commentCreatedEvent, commentUpdatedEvent } from "modules/Tasks/redux/actions";
import { preferenceUpdatedEvent, profileUpdatedEvent } from "modules/UserSettings/redux/actions";
import { Dispatch } from "react";
import {
  taskAcceptedEvent,
  taskRejectedEvent,
  taskMarkedInReviewEvent,
  taskMarkedAsSuccessfulEvent,
  taskMarkedAsFailedEvent,
  taskCreatedEvent,
  taskUpdatedEvent,
  taskAssignedEvent,
  taskAssignmentRemovedEvent,
  taskDeadlineExpieredEvent,
  taskArchivedEvent,
  taskDeletedEvent,
} from "../modules/Tasks/redux/task-card/actions";

export const handleNotification = (dispatch: Dispatch<any>, notification: Api.Notification): void => {
  switch (notification.kind) {
    case 'TaskCreatedEvent':
      dispatch(taskCreatedEvent(notification));
      return;
    case 'TaskAcceptedEvent':
      dispatch(taskAcceptedEvent(notification));
      return;
    case 'TaskRejectedEvent':
      dispatch(taskRejectedEvent(notification));
      return;
    case 'TaskReadyForReviewEvent':
      dispatch(taskMarkedInReviewEvent(notification));
      return;
    case 'TaskSuccessfullyFinishedEvent':
      dispatch(taskMarkedAsSuccessfulEvent(notification));
      return;
    case 'TaskFailedEvent':
      dispatch(taskMarkedAsFailedEvent(notification));
      return;
    case 'TaskUpdatedEvent':
      dispatch(taskUpdatedEvent(notification));
      return;
    case 'TaskAssignedEvent':
      dispatch(taskAssignedEvent(notification));
      return;
    case 'TaskAssignmentRemovedEvent':
      dispatch(taskAssignmentRemovedEvent(notification));
      return;
    case 'DeadlineExpiredEvent':
      dispatch(taskDeadlineExpieredEvent(notification));
      return;
    case 'TaskArchivedEvent':
      dispatch(taskArchivedEvent(notification));
      return;
    case 'TaskDeletedEvent':
      dispatch(taskDeletedEvent(notification));
      return;
    case 'CommentCreatedEvent':
      dispatch(commentCreatedEvent(notification));
      return;
    case 'CommentEditedEvent':
      dispatch(commentUpdatedEvent(notification));
      return;
    case 'AssigneeCreatedEvent':
      dispatch(assigneeCreatedEvent(notification));
      return;
    case 'AssigneeUpdatedEvent':
      dispatch(assigneeUpdatedEvent(notification));
      return;
    case 'AssigneeRemovedEvent':
      dispatch(assigneeRemovedEvent(notification));
      return;
    case 'EmailVerifiedEvent':
      dispatch(emailVerifiedEvent(notification));
      return;
    case 'PreferenceUpdatedEvent':
      dispatch(preferenceUpdatedEvent(notification));
      return;
    case 'ProfileUpdatedEvent':
      dispatch(profileUpdatedEvent(notification));
      return;
    default:
      return;
  }
};
