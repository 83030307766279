import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { togglePanel } from '../../components/panel/redux/action';
import styled from 'styled-components';
import withWindowSize from '../../components/with-window-size/withWindowSize';
import Panel from '../../components/panel/Panel';
import TaskGrid from '../../components/task-components/tasks-grid/TaskGrid';

interface TasksProps {
  togglePanel(): void;
}

export const Tasks = ({ togglePanel }: TasksProps) => {
  const { isMobile } = withWindowSize();
  return (
    <TasksPage
      isMobile={isMobile}
      onClick={(e) => {
        e.stopPropagation();
        togglePanel();
      }}
    >
      <Panel />
      <TaskGrid />
    </TasksPage>
  );
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  togglePanel: () => dispatch(togglePanel(false)),
});

export default connect(null, mapDispatchToProps)(Tasks);

const TasksPage = styled.div<{ isMobile: boolean }>`
  padding: ${(props) => (props.isMobile ? '10px' : '10px 4px 10px 10px')};
`;
