import { createAsyncAction, createAction } from 'typesafe-actions';
import { ContactTasks } from './reducer';

export const FETCH_CONTACT_TASKS_REQUEST = 'FETCH_CONTACT_TASKS_REQUEST';
export const FETCH_CONTACT_TASKS_SUCCESS = 'FETCH_CONTACT_TASKS_SUCCESS';
export const FETCH_CONTACT_TASKS_FAILURE = 'FETCH_CONTACT_TASKS_FAILURE';

export const NO_MORE_CONTACT_TASKS = 'NO_MORE_CONTACT_TASKS';

export interface FetchContactTaskParams {
  id: string;
  filter: any;
  first: number;
  offset: number;
}
export interface ContactTaskResponse {
  tasks: ContactTasks;
  id: string;
  filter: any;
}
export const fetchContactTasks = createAsyncAction(
  FETCH_CONTACT_TASKS_REQUEST,
  FETCH_CONTACT_TASKS_SUCCESS,
  FETCH_CONTACT_TASKS_FAILURE,
)<FetchContactTaskParams, ContactTaskResponse, void>();

export const noMoreContactTasks = createAction(NO_MORE_CONTACT_TASKS)<string>();

export type ContactTaskActions =
  | ReturnType<typeof fetchContactTasks.request>
  | ReturnType<typeof fetchContactTasks.success>
  | ReturnType<typeof fetchContactTasks.failure>
  | ReturnType<typeof noMoreContactTasks>;
