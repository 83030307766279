import {
  SELECT_CONTACT,
  FETCH_CONTACT_DATA_REQUEST,
  FETCH_CONTACT_DATA_SUCCESS,
  FETCH_CONTACT_DATA_FAILURE,
  FETCH_CONTACT_LIST_FAILURE,
  FETCH_CONTACT_LIST_SUCCESS,
  FETCH_CONTACT_LIST_REQUEST,
  FETCH_CONTACT_BY_NUMBER_REQUEST,
  FETCH_CONTACT_BY_NUMBER_SUCCESS,
  FETCH_CONTACT_BY_NUMBER_FAILURE,
  SEARCH_CONTACTS_REQUEST,
  SEARCH_CONTACTS_SUCCESS,
  SEARCH_CONTACTS_FAILURE,
  EDIT_CONTACT_REQUEST,
  EDIT_CONTACT_SUCCESS,
  EDIT_CONTACT_FAILURE,
  ADD_CONTACT_REQUEST,
  ADD_CONTACT_SUCCESS,
  ADD_CONTACT_FAILURE,   
  FETCH_MY_PROFILE_DATA_REQUEST,
  FETCH_MY_PROFILE_DATA_SUCCESS,
  FETCH_MY_PROFILE_DATA_FAILURE,
  FETCH_USER_STATS_REQUEST,
  FETCH_USER_STATS_SUCCESS,
  FETCH_USER_STATS_FAILURE,
  DELETE_CONTACT_REQUEST,
  DELETE_CONTACT_SUCCESS,
  DELETE_CONTACT_FAILURE,
  ASSIGNEE_CREATED_EVENT,
  ASSIGNEE_UPDATED_EVENT,
  ASSIGNEE_REMOVED_EVENT,
} from './consts';
import { createAsyncAction, createAction } from 'typesafe-actions';

export const selectContact = createAction(SELECT_CONTACT)<string>();

export const assigneeCreatedEvent = createAction(ASSIGNEE_CREATED_EVENT)<Api.AssigneeCreatedNotification>();

export const assigneeUpdatedEvent = createAction(ASSIGNEE_UPDATED_EVENT)<Api.AssigneeUpdatedNotification>();

export const assigneeRemovedEvent = createAction(ASSIGNEE_REMOVED_EVENT)<Api.AssigneeRemovedNotification>();

// todo MISSING TYPE !!
export const fetchContactList = createAsyncAction(
  FETCH_CONTACT_LIST_REQUEST,
  FETCH_CONTACT_LIST_SUCCESS,
  FETCH_CONTACT_LIST_FAILURE,
)<undefined, any, void>();

export const fetchContactByNumber = createAsyncAction(
  FETCH_CONTACT_BY_NUMBER_REQUEST,
  FETCH_CONTACT_BY_NUMBER_SUCCESS,
  FETCH_CONTACT_BY_NUMBER_FAILURE,
)<string, (LinkedContact | null), void>();

export const fetchFullContactData = createAsyncAction(
  FETCH_CONTACT_DATA_REQUEST,
  FETCH_CONTACT_DATA_SUCCESS,
  FETCH_CONTACT_DATA_FAILURE,
)<string, Contacts.FullContact, void>();

export const searchContacts = createAsyncAction(
  SEARCH_CONTACTS_REQUEST,
  SEARCH_CONTACTS_SUCCESS,
  SEARCH_CONTACTS_FAILURE,
)<string, Contacts.ContactInList[], string>();

interface IEditContact {
  id: string;
  value: object;
  oldValue?: string;
}

interface IEditContactSuccess {
  oldValue?: string;
  updatedContact: Contacts.ContactInList;
}

export const editContact = createAsyncAction(
  EDIT_CONTACT_REQUEST,
  EDIT_CONTACT_SUCCESS,
  EDIT_CONTACT_FAILURE,
)<IEditContact, IEditContactSuccess, string>();

interface IAddContact {
  popupId: string;
  firstName: string;
  lastName: string;
  mobile: string;
  email: string;
  userId: string;
}
export const addContact = createAsyncAction(
  ADD_CONTACT_REQUEST,
  ADD_CONTACT_SUCCESS,
  ADD_CONTACT_FAILURE,
)<IAddContact, any, { popupId: string }>();

export const fetchMyProfileData = createAsyncAction(
  FETCH_MY_PROFILE_DATA_REQUEST,
  FETCH_MY_PROFILE_DATA_SUCCESS,
  FETCH_MY_PROFILE_DATA_FAILURE,
)<undefined, Contacts.FullContact, void>();

export const fetchUserStats = createAsyncAction(
  FETCH_USER_STATS_REQUEST,
  FETCH_USER_STATS_SUCCESS,
  FETCH_USER_STATS_FAILURE,
)<string, Contacts.TaskStats[], void>();

export const deleteContact = createAsyncAction(
  DELETE_CONTACT_REQUEST,
  DELETE_CONTACT_SUCCESS,
  DELETE_CONTACT_FAILURE,
)<{id: string, firstName: string, lastname: string}, {id: string, firstName: string, lastname: string}, void>();

export type ContactActions =
  | ReturnType<typeof selectContact>
  | ReturnType<typeof fetchContactList.request>
  | ReturnType<typeof fetchContactList.success>
  | ReturnType<typeof fetchContactList.failure>
  | ReturnType<typeof fetchContactByNumber.request>
  | ReturnType<typeof fetchContactByNumber.success>
  | ReturnType<typeof fetchContactByNumber.failure>
  | ReturnType<typeof fetchFullContactData.request>
  | ReturnType<typeof fetchFullContactData.success>
  | ReturnType<typeof fetchFullContactData.failure>
  | ReturnType<typeof searchContacts.request>
  | ReturnType<typeof searchContacts.success>
  | ReturnType<typeof searchContacts.failure>
  | ReturnType<typeof editContact.request>
  | ReturnType<typeof editContact.success>
  | ReturnType<typeof editContact.failure>
  | ReturnType<typeof addContact.request>
  | ReturnType<typeof addContact.success>
  | ReturnType<typeof addContact.failure>
  | ReturnType<typeof fetchMyProfileData.request>
  | ReturnType<typeof fetchMyProfileData.success>
  | ReturnType<typeof fetchMyProfileData.failure>
  | ReturnType<typeof fetchUserStats.request>
  | ReturnType<typeof fetchUserStats.success>
  | ReturnType<typeof fetchUserStats.failure>
  | ReturnType<typeof deleteContact.request>
  | ReturnType<typeof deleteContact.success>
  | ReturnType<typeof deleteContact.failure>
  | ReturnType<typeof assigneeCreatedEvent>
  | ReturnType<typeof assigneeUpdatedEvent>
  | ReturnType<typeof assigneeRemovedEvent>;
