import React, { useEffect, useState } from 'react';
import { FieldWrapper } from '../../../input/input-components';
import Input from '../../../input/Text-Input';
import { Value } from './TaskForm';
import { inputColors } from '../../../input/input-colors';

interface TaskTitleProps {
  fieldWrapperStyle: React.CSSProperties;
  editState: boolean;
  inputStyle: React.CSSProperties;
  isMobile: boolean;
  editTaskData(val: any): void;
  originalTitle: string;
  singlePage: boolean;
}

const TaskTitle = ({
  editState,
  inputStyle,
  editTaskData,
  isMobile,
  originalTitle,
  singlePage,
}: TaskTitleProps) => {
  const [titleValue, setTitleValue] = useState(originalTitle);

  useEffect(() => {
    setTitleValue(originalTitle);
    // eslint-disable-next-line
  }, [editState]);

  return (
    <FieldWrapper style={inputStyle}>
      {editState ? (
        <Input
          header
          small
          placeholder={'Title'}
          style={{
            width: isMobile ? 'calc(100% - 30px)' : singlePage ? '345px' : '370px',
            marginLeft: isMobile ? '10px' : '0px',
          }}
          value={titleValue}
          error={(!!titleValue && titleValue.length > 30) || !titleValue}
          onBlur={() => !titleValue && setTitleValue(originalTitle)}
          errorMessage={titleValue ? 'Title can be maximum 30 characters.' : 'Title is mandatory.'}
          onChange={(event) => {
            setTitleValue(event.target.value);
            editTaskData({ title: event.target.value });
          }}
        />
      ) : (
        <Value
          isMobile={isMobile}
          style={{
            color: inputColors.headingColor,
            fontWeight: 'bold',
            textTransform: 'uppercase',
            fontSize: '18px',
            marginLeft: isMobile ? '10px' : '0',
          }}
        >
          {originalTitle}
        </Value>
      )}
    </FieldWrapper>
  );
};
export default TaskTitle;
