import React, { useEffect, useRef, useState } from 'react';
import format from 'date-fns/format';
import Calendar from 'react-calendar';
import styled from 'styled-components';

import { CalendarIcon } from '../icons/Icons';
import { InputComponent } from '../input/Text-Input';
import withWindowSize from '../with-window-size/withWindowSize';
import { getInputColor } from '../timepicker/TimePicker';

import 'react-calendar/dist/Calendar.css';
import './calendar-style.css';

const DATE_FORMAT = 'dd/MM/yyyy';

interface Props {
  onChange(date: Date): void;
  value: Date;
  minDate?: Date;
  maxDate?: Date;
  label?: string;
  smallInput?: boolean;
  style?: React.CSSProperties;
  hideIcon?: boolean;
  error?: boolean;
}

const DateInput = ({ onChange, value, minDate, maxDate, smallInput, style }: Props) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { isMobile } = withWindowSize();
  const calendarRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = (event: any): void => {
    // Hack for closing datepicker when clicking outside of Calendar and DateTrigger component
    // sc-gEvEer - className from DateTrigger
    const target = event.target;
    if (
      calendarRef.current &&
      !calendarRef.current.contains(target) &&
      !target.classList.contains('sc-gEvEer')
    ) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    window.addEventListener('mousedown', handleClickOutside);

    return () => window.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const iconPlacement = {
    width: '16px',
    position: 'absolute' as 'absolute',
    left: 8,
    top: 2,
  };

  return (
    <DateInputHolder style={style}>
      <DateTrigger
        isMobile={isMobile}
        onChange={() => null}
        small={!!smallInput}
        value={format(value, DATE_FORMAT)}
        onClick={() => setIsOpen(true)}
      />
      <div>
        <CalendarIcon style={iconPlacement} color={getInputColor(isOpen)} />
      </div>

      {isOpen && (
        <DateInputMenu smallInput={!!smallInput && !isMobile} ref={calendarRef}>
          <Calendar
            maxDate={maxDate}
            minDate={minDate}
            onChange={(val: any) => {
              onChange(val);
              setIsOpen(false);
            }}
            value={value}
          />
        </DateInputMenu>
      )}
    </DateInputHolder>
  );
};

export default DateInput;
const DateInputHolder = styled.div`
  position: relative;
`;

interface IDateInputProps {
  smallInput?: boolean;
  isMobile?: boolean;
}
const DateInputMenu = styled.div<IDateInputProps>`
  position: absolute;
  z-index: 1;
  left: -50px;
`;
const DateTrigger = styled(InputComponent)<IDateInputProps>`
  padding-left: 30px;
  font-size: 13px;
  width: 130px;
  font-family: Nunito Sans, sans-serif;
  font-weight: bold;
`;
