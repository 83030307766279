export const inputColors = {
  borderColor: '#c6c6c6',
  borderColorActive: '#9096a0',
  borderColorDisabled: '#dfe3ea',
  borderColorError: '#F97878',
  fontColor: '#949494',
  fontColorError: '#F97878',
  placeholderColor: '#b6beca',
  labelColor: '#9BA3AD',
  headingColor: '#38485C',
};
