import React, { useEffect, useState, useMemo, useCallback } from 'react';
import styled from 'styled-components';
import TaskTimelineEvent from './TaskTimelineEvent';
import Input from '../../input/Text-Input';
import withWindowSize from '../../with-window-size/withWindowSize';
import { Task } from '../../../types/task';
interface Props {
  me: User.Me;
  taskId: string;
  singlePage: boolean;
  // eslint-disable-next-line
  submitComment({}): void;
  bottomPadding: boolean;
  urlToken?: string;
  loadMoreTimeline(id: string, offset: number): void;
  selectedTask: any;
}

interface StyleProps {
  isMobile: boolean;
  singlePage: boolean;
}

const ActivitySectionStyle = styled.div<StyleProps>`
  color: #38485c;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 14px;
  margin-left: ${(props) => props.isMobile || !props.singlePage ? '20px' : '10px'};
  padding-bottom: 20px;
`;

const Title = styled.div<StyleProps>`
  color: #979797;
  font-size: 13px;
  font-weight: 600;
  margin: ${(props) => props.isMobile || !props.singlePage ? '0 10px 10px 20px' : '0 10px 10px 10px'};
`;

const LoadMore = styled.div`
  text-align: center;
  color: #9ba3ad;
  font-size: 13px;
  font-weight: 600;
  margin: 10px;
  text-transform: uppercase;
  opacity: 0.8;
  cursor: pointer;
  &:hover {
    opacity: 1;
    text-decoration: underline;
  }
`;

const Timeline = styled.div`
  margin: 10px;
  padding: 10px 12px;
  height: auto;
  background-color: #f2f3f3;
  border-radius: 5px;
`;

const TaskTimeline = ({
  me,
  submitComment,
  taskId,
  singlePage,
  loadMoreTimeline,
  bottomPadding,
  urlToken,
  selectedTask,
}: Props) => {
  const { loadedFullTimeline, timeline, archived } = selectedTask;

  const [commentValue, setCommentValue] = useState('');
  const [timelineOffset, setTimelineOffset] = useState(0);
  const { isMobile } = withWindowSize();

  useEffect(() => {
    timelineOffset > 0 && loadMoreTimeline(taskId, timelineOffset);
    // eslint-disable-next-line
  }, [timelineOffset]);

  const CommentInputStyle = {
    backgroundColor: '#f2f3f3',
    margin: isMobile
      ? '0 10px 15px 15px'
      : !singlePage
      ? '10px 10px 10px 10px'
      : '10px 10px 15px 0px',
    width: isMobile || !singlePage ? 'calc(100% - 25px)' : '100%',
    height: 'auto',
    lineHeight: isMobile ? '36px' : '28px',
  };

  const meMemo = useMemo(() => {
    return me;
  }, [me]);

  const eventMemo = useCallback((event: Task.TaskTimeLineEvent) => {
    return event;
  }, []);

  const sendComment = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.nativeEvent.key === 'Enter') {
      submitComment({ id: taskId, comment: commentValue });
      setCommentValue('');
    }
  };

  const loadMore = () => {
    setTimelineOffset((offset) => offset + 20);
  };

  return (
    <>
      {!archived && !urlToken && (
        <>
          <ActivitySectionStyle
            isMobile={isMobile}
            singlePage={singlePage}
            >
              Activity
          </ActivitySectionStyle>
          <Title
            isMobile={isMobile}
            singlePage={singlePage}
            >
              Add a comment
          </Title>
          <Input
            onChange={(event) => setCommentValue(event.target.value)}
            onKeyDown={sendComment}
            value={commentValue}
            placeholder="Submit on enter"
            style={CommentInputStyle}
          />
        </>
      )}
      {timeline && timeline.length > 0 && (
        <Timeline style={{ marginBottom: bottomPadding && isMobile ? 25 : bottomPadding && !isMobile ? 60 : 0 }}>
          {timeline.map((event: any, index: number) => (
            <div key={index} style={{ margin: index !== 0 ? '7px 2px' : '0 2px 7px 2px' }}>
              <TaskTimelineEvent me={meMemo} event={eventMemo(event)} />
            </div>
          ))}
          {!loadedFullTimeline && <LoadMore onClick={() => loadMore()}>Older</LoadMore>}
        </Timeline>
      )}
    </>
  );
};
export default React.memo(TaskTimeline);
