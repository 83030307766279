import filter from 'lodash/filter';
import { OPEN_POPUP, CLOSE_POPUP, CLOSE_ALL_POPUPS } from './actions';
import { AppAction } from '../../../store/actions';
import { PopupProps } from '../Popup';

const initialState = {
  popupList: [] as PopupProps[],
};

const reducer = (state = initialState, action: AppAction) => {
  switch (action.type) {
    case OPEN_POPUP:
      if (
        (action.payload.limitToOne && state.popupList.length > 0) ||
        state.popupList.length === 3
      ) {
        return {
          ...state,
        };
      } else {
        const newPopup = { ...action.payload };
        const updatedPopupList = [...state.popupList, newPopup];
        return {
          ...state,
          popupList: updatedPopupList,
        };
      }
    case CLOSE_POPUP:
      return {
        ...state,
        popupList: removePopupFromList(state.popupList, action.payload),
      };
    case CLOSE_ALL_POPUPS:
      return {
        ...state,
        popupList: [],
      };
    default:
      return state;
  }
};

export default reducer;

const removePopupFromList = (list: any, itemToRemoveId: string) => {
  return filter(list, (listItem: any) => listItem.id !== itemToRemoveId);
};
