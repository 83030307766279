import { useEffect, useState } from 'react';
import withWindowSize from '../with-window-size/withWindowSize';

const WithPageSize = (isContactPage?: boolean) => {
  const { isMobile } = withWindowSize();
  const width = isContactPage
    ? Math.floor((document.documentElement.clientWidth - 300) / 300)
    : Math.floor(document.documentElement.clientWidth / 300);

  const calc = isMobile ? 6 : width * 3;
  const [pageSize, setPageSize] = useState(calc);

  useEffect(() => {
    const calc = isMobile ? 6 : width * 3;
    setPageSize(calc);
  }, [width, isMobile]);
  return {
    pageSize,
  };
};
export default WithPageSize;
