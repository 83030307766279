import {
  ARCHIVE_TASK_SUCCESS,
  OPEN_EDIT_ASSIGNEE,
  COMMENT_ON_TASK_FAILURE,
  COMMENT_ON_TASK_REQUEST,
  COMMENT_ON_TASK_SUCCESS,
  DELETE_TASK_SUCCESS,
  EDIT_TASK_FAILURE,
  EDIT_TASK_REQUEST,
  EDIT_TASK_SUCCESS,
  FETCH_SINGLE_TASK_FAILURE,
  FETCH_SINGLE_TASK_REQUEST,
  FETCH_SINGLE_TASK_SUCCESS,
  FETCH_TASK_TIMELINE_SUCCESS,
  COMMENT_CREATED_EVENT,
  REFRESH_TASK_TIMELINE_SUCCESS,
} from './actions';
import { TOGGLE_PANEL } from '../../../components/panel/redux/action';
import { AppAction } from '../../../store/actions';
import {
  ACCEPT_TASK_SUCCESS,
  MARK_TASK_FAILED_SUCCESS,
  MARK_TASK_IN_REVIEW_SUCCESS,
  MARK_TASK_SUCCESSFUL_SUCCESS,
  REJECT_TASK_SUCCESS,
  TASK_ACCEPTED_EVENT,
  TASK_ARCHIVED_EVENT,
  TASK_DEADLINE_EXPIERED_EVENT,
  TASK_DELETED_EVENT,
  TASK_MARKED_AS_FAILED_EVENT,
  TASK_MARKED_AS_SUCCESSFUL_EVENT,
  TASK_MARKED_IN_REVIEW_EVENT,
  TASK_REJECTED_EVENT,
  TASK_UPDATED_EVENT,
} from './task-card/actions';
import {
  ASSIGNEE_CREATED_EVENT,
  ASSIGNEE_REMOVED_EVENT,
  ASSIGNEE_UPDATED_EVENT,
  FETCH_CONTACT_LIST_SUCCESS,
} from 'modules/Contacts/redux/consts';
import { FETCH_INITIAL_DATA_SUCCESS } from 'modules/SignUp&Login/Login/redux/actions';

type TimelineUser = {
  id?: string;
  firstName?: string;
  lastName?: string;
};

type EventType = {
  created: string;
  comment?: string;
  id: string;
  reason: 'DEADLINE_PASSED' | 'USER_FAILED' | null;
  type: string;
  user: TimelineUser;
};

type TaskGenProps = {
  type?:
    | TaskStatus
    | 'ARCHIVED'
    | 'COMMENT_CREATED'
    | 'TASK_REDEFINED'
    | 'DEADLINE_UPDATED'
    | 'TASK_UPDATED';
  comment?: string;
  userId: string;
  reason?: 'DEADLINE_PASSED' | 'USER_FAILED' | null;
};

type State = {
  id: string;
  stashCommentValue: string;
  loadingTask: boolean;
  loadingComment: boolean;
  loadingEdit: boolean;
  nonExistentTask: boolean;
  editAssignee: boolean;
  loadedFullTimeline: boolean;
  archived: boolean;
  timeline: EventType[];
  me: User.Me | null;
  description: string | null;
  deadline: string | null;
  title: string;
  status: TaskStatus | '';
  assignee: any;
  assignees: Contacts.ContactInList[];
  completed: string | null;
};

const initialState: State = {
  id: '',
  stashCommentValue: '',
  loadingTask: true,
  loadingComment: false,
  loadingEdit: false,
  nonExistentTask: false,
  editAssignee: false,
  loadedFullTimeline: false,
  archived: false,
  timeline: [],
  status: '',
  description: '',
  title: '',
  me: null,
  deadline: null,
  assignees: [],
  assignee: null,
  completed: null,
};

const reducer = (state = initialState, action: AppAction): State => {
  const genEvent = ({ type, userId, comment, reason }: TaskGenProps): EventType | null => {
    const user: TimelineUser =
      userId === state.me?.id
        ? { ...state.me }
        : { ...state.assignees.find((a) => a.id === userId) };
    const event = {
      created: new Date().toISOString(),
      id: state.timeline.length.toString(),
      user,
      reason: reason || null,
    };
    switch (type) {
      case 'FAILED':
        return {
          ...event,
          type: 'marked task as failed.',
        };
      case 'IN_PROGRESS':
        return {
          ...event,
          type: 'accepted the task.',
        };
      case 'IN_REVIEW':
        return {
          ...event,
          type: 'marked task as ready for review.',
        };
      case 'REJECTED':
        return {
          ...event,
          type:
            reason === 'DEADLINE_PASSED'
              ? 'Task failed due to missed deadline.'
              : 'rejected the task.',
        };
      case 'SUCCESSFUL':
        return {
          ...event,
          type: 'marked task as successful.',
        };
      case 'ARCHIVED':
        return {
          ...event,
          type: 'archived the task.',
        };
      case 'COMMENT_CREATED':
        return {
          ...event,
          type: 'commented:',
          comment,
        };
      case 'TASK_REDEFINED':
        return {
          ...event,
          type: 'redefined the task.',
          comment,
        };
      case 'DEADLINE_UPDATED':
        return {
          ...event,
          type: 'updated the deadline.',
          comment,
        };
      case 'TASK_UPDATED':
        return {
          ...event,
          type: 'updated the task.',
          comment,
        };
      default:
        return null;
    }
  };

  switch (action.type) {
    case FETCH_SINGLE_TASK_REQUEST:
      return {
        ...state,
        loadingTask: true,
      };
    case FETCH_SINGLE_TASK_SUCCESS:
      return {
        ...state,
        ...action.payload,
        loadingTask: false,
      };

    case FETCH_SINGLE_TASK_FAILURE:
      return {
        ...state,
        loadingTask: false,
        nonExistentTask: true,
      };

    case TOGGLE_PANEL:
      if (!action.open) {
        return {
          ...state,
          id: '',
          editAssignee: false,
        };
      }
      return { ...state };

    case COMMENT_ON_TASK_REQUEST:
      return {
        ...state,
        stashCommentValue: action.payload.comment,
        loadingComment: true,
      };

    case COMMENT_ON_TASK_SUCCESS:
      return {
        ...state,
        stashCommentValue: '',
        loadingComment: false,
      };

    case COMMENT_ON_TASK_FAILURE:
      return {
        ...state,
        loadingComment: false,
      };

    case EDIT_TASK_REQUEST:
      return {
        ...state,
        loadingEdit: true,
      };

    case EDIT_TASK_SUCCESS:
    case ACCEPT_TASK_SUCCESS:
    case REJECT_TASK_SUCCESS:
    case MARK_TASK_FAILED_SUCCESS:
    case MARK_TASK_SUCCESSFUL_SUCCESS:
    case MARK_TASK_IN_REVIEW_SUCCESS:
      if (state.id === action.payload.id) {
        return {
          ...state,
          ...action.payload,
          loadingEdit: false,
          editAssignee: false,
          timeline: state.timeline,
        };
      } else {
        return {
          ...state,
          loadingEdit: false,
          editAssignee: false,
        };
      }

    case EDIT_TASK_FAILURE:
      return {
        ...state,
        loadingEdit: false,
      };

    case ARCHIVE_TASK_SUCCESS: {
      const id = action.payload;
      if (state.id === id) {
        return {
          ...state,
          archived: true,
        };
      }
      return { ...state };
    }

    case FETCH_TASK_TIMELINE_SUCCESS: {
      if (action.payload.fetchMore === true) {
        return {
          ...state,
          loadedFullTimeline: action.payload.loadedFullTimeline,
          timeline:
            state.timeline && state.timeline.length > 0
              ? [...state.timeline, ...action.payload.response]
              : action.payload.response,
        };
      } else {
        return {
          ...state,
          loadedFullTimeline: action.payload.loadedFullTimeline,
          timeline: action.payload.response,
        };
      }
    }
    case REFRESH_TASK_TIMELINE_SUCCESS:
      return {
        ...state,
        loadedFullTimeline: action.payload.loadedFullTimeline,
        timeline: action.payload.response,
      };
    case DELETE_TASK_SUCCESS:
      if (state.id === action.payload) {
        return {
          ...state,
          nonExistentTask: true,
        };
      }
      return { ...state };

    case OPEN_EDIT_ASSIGNEE:
      return {
        ...state,
        editAssignee: action.payload,
      };

    case TASK_UPDATED_EVENT: {
      const events: EventType[] = [];
      if (state.id === action.payload.taskId) {
        if (action.payload.description) {
          state.description = action.payload.description;
          const event = genEvent({
            type: 'TASK_REDEFINED',
            userId: action.payload.authorId,
          });
          event && events.unshift(event);
        }
        if (action.payload.updatedTitle && action.payload.updatedTitle !== state.title) {
          state.title = action.payload.updatedTitle;
          const event = genEvent({
            type: 'TASK_REDEFINED',
            userId: action.payload.authorId,
          });
          event && events.length === 0 && events.unshift(event);
        }
        if (action.payload.updatedDeadline) {
          state.deadline = action.payload.updatedDeadline;
          const event = genEvent({
            type: 'DEADLINE_UPDATED',
            userId: action.payload.authorId,
          });
          event && events.unshift(event);
        }
        if (action.payload.assigneeId && action.payload.assigneeId !== state.assignee?.id) {
          state.assignee = state.assignees.find((a) => a.id === action.payload.assigneeId);
          const event = genEvent({
            type: 'TASK_UPDATED',
            userId: action.payload.authorId,
          });
          event && events.unshift(event);
        }
        return { ...state, timeline: [...events, ...state.timeline] };
      }
      return state;
    }

    case COMMENT_CREATED_EVENT: {
      if (state.id === action.payload.taskId) {
        const event = genEvent({
          type: 'COMMENT_CREATED',
          userId: action.payload.authorId,
          comment: action.payload.content,
        });
        if (event) {
          return { ...state, timeline: [event, ...state.timeline] };
        }
      }
      return state;
    }

    case TASK_ACCEPTED_EVENT: {
      if (state.id === action.payload.taskId) {
        const event = genEvent({
          type: 'IN_PROGRESS',
          userId: action.payload.assigneeId,
        });
        if (event) {
          return {
            ...state,
            status: 'IN_PROGRESS',
            timeline: [event, ...state.timeline],
          };
        }
      }
      return { ...state };
    }

    case TASK_REJECTED_EVENT:
      if (state.id === action.payload.taskId) {
        const event = genEvent({
          type: 'REJECTED',
          userId: action.payload.assigneeId,
        });
        if (event) {
          return {
            ...state,
            status: 'REJECTED',
            completed: new Date().toISOString(),
            timeline: [event, ...state.timeline],
          };
        }
      }
      return state;

    case TASK_DEADLINE_EXPIERED_EVENT:
      if (state.id === action.payload.taskId) {
        const event = genEvent({
          type: 'REJECTED',
          userId: action.payload.assigneeId,
          reason: 'DEADLINE_PASSED',
        });
        if (event) {
          return {
            ...state,
            status: 'REJECTED',
            completed: new Date().toISOString(),
            timeline: [event, ...state.timeline],
          };
        }
      }
      return state;

    case TASK_MARKED_IN_REVIEW_EVENT: {
      if (state.id === action.payload.taskId) {
        const event = genEvent({
          type: 'IN_REVIEW',
          userId: action.payload.assigneeId,
        });
        if (event) {
          return {
            ...state,
            status: 'IN_REVIEW',
            timeline: [event, ...state.timeline],
          };
        }
      }
      return state;
    }

    case TASK_MARKED_AS_SUCCESSFUL_EVENT: {
      if (state.id === action.payload.taskId) {
        const event = genEvent({
          type: 'SUCCESSFUL',
          userId: action.payload.authorId,
        });
        if (event) {
          return {
            ...state,
            status: 'SUCCESSFUL',
            completed: new Date().toISOString(),
            timeline: [event, ...state.timeline],
          };
        }
      }
      return state;
    }

    case TASK_MARKED_AS_FAILED_EVENT: {
      if (state.id === action.payload.taskId) {
        const event = genEvent({
          type: 'FAILED',
          userId: action.payload.authorId,
        });
        if (event) {
          return {
            ...state,
            status: 'FAILED',
            completed: new Date().toISOString(),
            timeline: [event, ...state.timeline],
          };
        }
      }
      return state;
    }

    case TASK_ARCHIVED_EVENT: {
      if (state.id && state.id === action.payload.taskId) {
        const event = genEvent({
          type: 'ARCHIVED',
          userId: action.payload.authorId,
        });
        if (event) {
          return {
            ...state,
            archived: true,
            timeline: [event, ...state.timeline],
          };
        }
      }
      return state;
    }

    case TASK_DELETED_EVENT:
      if (state.id === action.payload.taskId) {
        return { ...state, nonExistentTask: true };
      }
      return state;

    case FETCH_CONTACT_LIST_SUCCESS: {
      const assignees: Contacts.ContactInList[] = [];
      for (const [, value] of Object.entries(action.payload)) {
        if (Array.isArray(value)) {
          assignees.push(...(value as Contacts.ContactInList[]));
        }
      }
      return {
        ...state,
        assignees,
      };
    }

    case ASSIGNEE_CREATED_EVENT: {
      const assignee = action.payload;
      return {
        ...state,
        assignees: [
          {
            id: assignee.assigneeId,
            firstName: assignee.firstName,
            lastName: assignee.assigneeLastName || '',
            email: assignee.assigneeEmail || '',
            registeredUser: false,
          },
          ...state.assignees,
        ],
      };
    }

    case ASSIGNEE_UPDATED_EVENT: {
      const assignee = action.payload;
      return {
        ...state,
        assignees: state.assignees.map((a) =>
          a.id === assignee.assigneeId
            ? {
                ...a,
                firstName: assignee.updatedFirstName || a.firstName,
                lastName: assignee.updatedLastName || a.lastName,
                email: assignee.updatedEmail || a.email,
              }
            : a,
        ),
      };
    }

    case ASSIGNEE_REMOVED_EVENT: {
      const assignee = action.payload;
      return {
        ...state,
        assignees: state.assignees.filter((a) => a.id !== assignee.assigneeId),
      };
    }

    case FETCH_INITIAL_DATA_SUCCESS:
      return {
        ...state,
        me: action.payload,
      };

    default:
      return state;
  }
};

export default reducer;
