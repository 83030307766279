import { push } from 'redux-first-history';
import { UserSettingsActions } from '../modules/UserSettings/redux/actions';
import { ContactActions } from '../modules/Contacts/redux/actions';
import { LoginAndSignUpActions } from '../modules/SignUp&Login/action-types';
import { ModalActions } from '../components/modal/redux/actions';
import { TaskActions } from '../modules/Tasks/redux/actions';
import { PopupActions } from '../components/popup/redux/actions';
import { PanelActions } from '../components/panel/redux/action';
import { ContactTaskActions } from '../modules/Contacts/redux/contact-tasks/actions';
import { TaskCardActions } from '../modules/Tasks/redux/task-card/actions';

export const navigateTo = (path: string) => push(path);

export type AppAction =
  | ContactActions
  | ContactTaskActions
  | TaskActions
  | TaskCardActions
  | LoginAndSignUpActions
  | UserSettingsActions
  | ModalActions
  | PopupActions
  | PanelActions;
