import {
  ADD_TASK_SUCCESS,
  ARCHIVE_TASK_SUCCESS,
  DELETE_TASK_SUCCESS,
  EDIT_TASK_SUCCESS,
  FETCH_ACTIVE_TASKS_FAILURE,
  FETCH_ACTIVE_TASKS_REQUEST,
  FETCH_ACTIVE_TASKS_SUCCESS,
  FETCH_COMPLETED_TASKS_FAILURE,
  FETCH_COMPLETED_TASKS_REQUEST,
  FETCH_COMPLETED_TASKS_SUCCESS,
  FETCHED_ALL_ACTIVE_TASKS,
  FETCHED_ALL_COMPLETED_TASKS,
  REASSIGN_TASK_SUCCESS,
} from './actions';
import { AppAction } from '../../../store/actions';
import { FETCH_INITIAL_DATA_SUCCESS, LOG_OUT } from '../../SignUp&Login/Login/redux/actions';
import {
  ACCEPT_TASK_SUCCESS,
  MARK_TASK_FAILED_SUCCESS,
  MARK_TASK_IN_REVIEW_SUCCESS,
  MARK_TASK_SUCCESSFUL_SUCCESS,
  REJECT_TASK_SUCCESS,
  TASK_CREATED_EVENT,
  TASK_ACCEPTED_EVENT,
  TASK_REJECTED_EVENT,
  TASK_MARKED_IN_REVIEW_EVENT,
  TASK_MARKED_AS_SUCCESSFUL_EVENT,
  TASK_MARKED_AS_FAILED_EVENT,
  TASK_ARCHIVED_EVENT,
  TASK_ASSIGNED_EVENT,
  TASK_ASSIGNMENT_REMOVED_EVENT,
  TASK_DEADLINE_EXPIERED_EVENT,
  TASK_DELETED_EVENT,
  TASK_UPDATED_EVENT,
} from './task-card/actions';
import {
  ASSIGNEE_CREATED_EVENT,
  ASSIGNEE_REMOVED_EVENT,
  ASSIGNEE_UPDATED_EVENT,
  FETCH_CONTACT_LIST_SUCCESS,
} from 'modules/Contacts/redux/consts';
import { TaskStatusEnum } from 'components/task-components/get-task-options';

interface TasksProps {
  tasks: { [key: string]: Task };
  loadingPage: boolean;
  loadedAll: boolean;
  initiallyLoaded: boolean;
}

interface TasksReducer {
  completedTasks: TasksProps;
  activeTasks: TasksProps;
  loadingSection: boolean;
  me: User.Me | null;
  assignees: Contacts.ContactInList[];
  assigneesFetched: boolean;
}

const initialState: TasksReducer = {
  completedTasks: {
    tasks: {},
    loadingPage: true,
    loadedAll: false,
    initiallyLoaded: false,
  },
  activeTasks: {
    tasks: {},
    loadingPage: true,
    loadedAll: false,
    initiallyLoaded: false,
  },
  loadingSection: false,
  me: null,
  assignees: [],
  assigneesFetched: false,
};

const reducer = (state: TasksReducer = initialState, action: AppAction): TasksReducer => {
  const genTaskFromEvent = (event: Api.TaskEvent, status: TaskStatusEnum): Task => {
    let task = {
      ...state.activeTasks.tasks[event.taskId],
    };
    if (task?.id) {
      task = {
        ...task,
        status,
        completed: new Date().toISOString(),
      };
    } else {
      const assignee =
        event.assigneeId === state.me?.id
          ? { ...state.me, registeredUser: false }
          : { ...state.assignees.find((a) => a.id === event.assigneeId) };
      const author =
        event.authorId === state.me?.id
          ? { ...state.me, registeredUser: false }
          : { ...state.assignees.find((a) => a.id === event.authorId) };
      task = {
        id: event.taskId,
        title: event.title,
        description: event.description || '',
        deadline: event.deadline || '',
        created: '',
        archived: false,
        assignee,
        author,
        status: status,
        completed: new Date().toISOString(),
      };
    }
    return task;
  };

  switch (action.type) {
    case FETCH_ACTIVE_TASKS_REQUEST:
      return {
        ...state,
        activeTasks: {
          ...state.activeTasks,
          loadingPage: true,
          loadedAll: false,
        },
      };

    case FETCH_ACTIVE_TASKS_SUCCESS:
      return {
        ...state,
        activeTasks: {
          ...state.activeTasks,
          loadingPage: false,
          initiallyLoaded: true,
          tasks: {
            ...state.activeTasks.tasks,
            ...action.payload.tasks,
          },
        },
        loadingSection: false,
      };

    case FETCH_COMPLETED_TASKS_REQUEST:
      return {
        ...state,
        completedTasks: {
          ...state.completedTasks,
          loadingPage: true,
          loadedAll: false,
        },
      };

    case FETCH_COMPLETED_TASKS_SUCCESS:
      return {
        ...state,
        completedTasks: {
          ...state.completedTasks,
          loadingPage: false,
          initiallyLoaded: true,
          tasks: {
            ...state.completedTasks.tasks,
            ...action.payload.tasks,
          },
        },
        loadingSection: false,
      };

    case FETCHED_ALL_COMPLETED_TASKS:
      return {
        ...state,
        completedTasks: {
          ...state.completedTasks,
          loadedAll: true,
        },
      };
    case FETCHED_ALL_ACTIVE_TASKS:
      return {
        ...state,
        activeTasks: {
          ...state.activeTasks,
          loadedAll: true,
        },
      };
    case FETCH_ACTIVE_TASKS_FAILURE:
      return {
        ...state,
        activeTasks: {
          ...state.activeTasks,
          loadingPage: false,
        },
      };
    case FETCH_COMPLETED_TASKS_FAILURE:
      return {
        ...state,
        completedTasks: {
          ...state.completedTasks,
          loadingPage: false,
        },
      };
    case ACCEPT_TASK_SUCCESS: {
      const tasks = {
        ...state.activeTasks.tasks,
        [action.payload.id]: action.payload,
      };

      return {
        ...state,
        activeTasks: {
          ...state.activeTasks,
          tasks,
        },
      };
    }
    case REJECT_TASK_SUCCESS:
    case MARK_TASK_IN_REVIEW_SUCCESS:
      const updatedTaskInReview = { ...state.activeTasks.tasks };
      delete updatedTaskInReview[action.payload.id];

      return {
        ...state,
        activeTasks: {
          ...state.activeTasks,
          tasks: updatedTaskInReview,
        },
      };
    case EDIT_TASK_SUCCESS: {
      const { id } = action.payload;
      if (state.activeTasks.tasks.hasOwnProperty(id)) {
        state.activeTasks.tasks[id] = action.payload;
      } else if (state.completedTasks.tasks.hasOwnProperty(id)) {
        state.completedTasks.tasks[id] = action.payload;
      }
      return { ...state };
    }
    case REASSIGN_TASK_SUCCESS: {
      const id = action.payload.response.id;
      const updatedActiveTasks = state.activeTasks?.tasks ? { ...state.activeTasks.tasks } : {};
      const updatedCompletedTasks = state.completedTasks?.tasks
        ? { ...state.completedTasks.tasks }
        : {};

      if (state.activeTasks && state.activeTasks.tasks && updatedActiveTasks.hasOwnProperty(id)) {
        updatedActiveTasks[id] = action.payload.response;
      } else if (
        state.completedTasks &&
        state.completedTasks.tasks &&
        updatedCompletedTasks.hasOwnProperty(id)
      ) {
        updatedCompletedTasks[id] = action.payload.response;
      }

      return {
        ...state,
        activeTasks: {
          ...state.activeTasks,
          tasks: updatedActiveTasks,
        },
        completedTasks: {
          ...state.completedTasks,
          tasks: updatedCompletedTasks,
        },
      };
    }
    case MARK_TASK_FAILED_SUCCESS:
    case MARK_TASK_SUCCESSFUL_SUCCESS: {
      const id = action.payload.id;
      const updatedTasks = { ...state.activeTasks.tasks };

      if (updatedTasks.hasOwnProperty(id)) {
        delete updatedTasks[id];
      }

      return {
        ...state,
        activeTasks: {
          ...state.activeTasks,
          tasks: updatedTasks,
        },
      };
    }
    case DELETE_TASK_SUCCESS: {
      const id = action.payload;
      const updatedActiveTasks = { ...state.activeTasks.tasks };
      const updatedCompletedTasks = { ...state.completedTasks.tasks };

      if (updatedActiveTasks.hasOwnProperty(id)) {
        delete updatedActiveTasks[id];
      } else if (updatedCompletedTasks.hasOwnProperty(id)) {
        delete updatedCompletedTasks[id];
      }

      return {
        ...state,
        activeTasks: {
          ...state.activeTasks,
          tasks: updatedActiveTasks,
        },
        completedTasks: {
          ...state.completedTasks,
          tasks: updatedCompletedTasks,
        },
      };
    }
    case ARCHIVE_TASK_SUCCESS: {
      const id = action.payload;
      const updatedCompletedTasks = { ...state.completedTasks.tasks };

      if (updatedCompletedTasks.hasOwnProperty(id)) {
        updatedCompletedTasks[id] = {
          ...updatedCompletedTasks[id],
          archived: true,
        };
      }

      return {
        ...state,
        completedTasks: {
          ...state.completedTasks,
          tasks: updatedCompletedTasks,
        },
      };
    }
    case ADD_TASK_SUCCESS:
      const updatedTasks = {
        [action.payload.id]: action.payload,
        ...state.activeTasks.tasks,
      };

      return {
        ...state,
        activeTasks: {
          ...state.activeTasks,
          tasks: updatedTasks,
        },
      };

    case TASK_CREATED_EVENT:
    case TASK_ASSIGNED_EVENT: {
      const taskId = action.payload.taskId;
      if (!state.activeTasks.tasks[taskId]) {
        const newTask = genTaskFromEvent(action.payload, TaskStatusEnum.PENDING);
        const newTasks = {
          ...state.activeTasks.tasks,
          [taskId]: newTask,
        };
        const newActiveTasks = {
          ...state.activeTasks,
          tasks: newTasks,
        };
        return {
          ...state,
          activeTasks: newActiveTasks,
        };
      }
      return state;
    }

    case TASK_ASSIGNMENT_REMOVED_EVENT: {
      if (
        state.activeTasks.tasks[action.payload.taskId] &&
        action.payload.assigneeId === state.me?.id
      ) {
        delete state.activeTasks.tasks[action.payload.taskId];
      }
      return { ...state };
    }

    case TASK_UPDATED_EVENT: {
      let task = state.activeTasks.tasks[action.payload.taskId];
      if (
        state.me?.id &&
        task &&
        (action.payload.assigneeId === state.me?.id || action.payload.authorId === state.me?.id)
      ) {
        if (action.payload.updatedDeadline) {
          task.deadline = action.payload.updatedDeadline;
        }
        if (action.payload.description) {
          task.description = action.payload.description;
        }
        if (action.payload.updatedTitle) {
          task.title = action.payload.updatedTitle;
        }
        if (action.payload.assigneeId && action.payload.assigneeId !== task.assignee.id) {
          task.assignee = state.assignees.find((a) => a.id === action.payload.assigneeId);
        }
        state.activeTasks.tasks[action.payload.taskId] = task;
      }
      return { ...state };
    }

    case TASK_ACCEPTED_EVENT: {
      const task = genTaskFromEvent(action.payload, TaskStatusEnum.IN_PROGRESS);
      const newTasks = {
        ...state.activeTasks.tasks,
        [action.payload.taskId]: task,
      };
      const newActiveTasks = {
        ...state.activeTasks,
        tasks: newTasks,
      };
      return {
        ...state,
        activeTasks: newActiveTasks,
      };
    }

    case TASK_REJECTED_EVENT:
    case TASK_DEADLINE_EXPIERED_EVENT: {
      const task: { [key: string]: Task } = {};
      task[action.payload.taskId] = genTaskFromEvent(action.payload, TaskStatusEnum.REJECTED);
      state.completedTasks.tasks = {
        ...task,
        ...state.completedTasks.tasks,
      };
      if (state.activeTasks.tasks[action.payload.taskId]) {
        delete state.activeTasks.tasks[action.payload.taskId];
      }
      return { ...state };
    }

    case TASK_MARKED_IN_REVIEW_EVENT: {
      const task = genTaskFromEvent(action.payload, TaskStatusEnum.IN_REVIEW);
      if (task.author.id === state.me?.id) {
        state.activeTasks.tasks[action.payload.taskId] = task;
      } else if (task.assignee.id === state.me?.id) {
        delete state.activeTasks.tasks[action.payload.taskId];
      }
      return { ...state };
    }

    case TASK_MARKED_AS_SUCCESSFUL_EVENT: {
      const tasks: { [key: string]: Task } = {};
      tasks[action.payload.taskId] = genTaskFromEvent(action.payload, TaskStatusEnum.SUCCESSFUL);
      state.completedTasks.tasks = {
        ...tasks,
        ...state.completedTasks.tasks,
      };
      if (state.activeTasks.tasks[action.payload.taskId]) {
        delete state.activeTasks.tasks[action.payload.taskId];
      }
      return { ...state };
    }

    case TASK_MARKED_AS_FAILED_EVENT: {
      const tasks: { [key: string]: Task } = {};
      tasks[action.payload.taskId] = genTaskFromEvent(action.payload, TaskStatusEnum.FAILED);
      state.completedTasks.tasks = {
        ...tasks,
        ...state.completedTasks.tasks,
      };
      if (state.activeTasks.tasks[action.payload.taskId]) {
        delete state.activeTasks.tasks[action.payload.taskId];
      }
      return { ...state };
    }

    case TASK_ARCHIVED_EVENT:
      if (state.completedTasks.tasks[action.payload.taskId]) {
        state.completedTasks.tasks[action.payload.taskId].archived = true;
      }
      return { ...state };

    case TASK_DELETED_EVENT:
      if (state.activeTasks.tasks[action.payload.taskId]) {
        delete state.activeTasks.tasks[action.payload.taskId];
      } else if (state.completedTasks.tasks[action.payload.taskId]) {
        delete state.completedTasks.tasks[action.payload.taskId];
      }
      return { ...state };

    case FETCH_CONTACT_LIST_SUCCESS: {
      const assignees: Contacts.ContactInList[] = [];
      for (const [, value] of Object.entries(action.payload)) {
        if (Array.isArray(value)) {
          assignees.push(...(value as Contacts.ContactInList[]));
        }
      }
      return {
        ...state,
        assignees,
        assigneesFetched: true,
      };
    }

    case ASSIGNEE_CREATED_EVENT: {
      const assignee = action.payload;
      return {
        ...state,
        assignees: [
          {
            id: assignee.assigneeId,
            firstName: assignee.firstName,
            lastName: assignee.assigneeLastName || '',
            email: assignee.assigneeEmail || '',
            registeredUser: false,
          },
          ...state.assignees,
        ],
      };
    }

    case ASSIGNEE_UPDATED_EVENT: {
      const assignee = action.payload;
      return {
        ...state,
        assignees: state.assignees.map((a) =>
          a.id === assignee.assigneeId
            ? {
                ...a,
                firstName: assignee.updatedFirstName || a.firstName,
                lastName: assignee.updatedLastName || a.lastName,
                email: assignee.updatedEmail || a.email,
              }
            : a,
        ),
      };
    }

    case ASSIGNEE_REMOVED_EVENT: {
      const assignee = action.payload;
      return {
        ...state,
        assignees: state.assignees.filter((a) => a.id !== assignee.assigneeId),
      };
    }

    case FETCH_INITIAL_DATA_SUCCESS:
      return {
        ...state,
        me: action.payload,
      };

    case LOG_OUT:
      return {
        completedTasks: {
          loadingPage: true,
          loadedAll: false,
          initiallyLoaded: false,
          tasks: {},
        },
        activeTasks: {
          loadingPage: true,
          loadedAll: false,
          initiallyLoaded: false,
          tasks: {},
        },
        loadingSection: false,
        assignees: [],
        assigneesFetched: false,
        me: null,
      };

    default:
      return state;
  }
};
export default reducer;
