import { getMutationResponse } from '../../client';
import { gql } from '@apollo/client';
import { taskFragment } from '../../fragments';
import { mapTask } from '../../../mappers/tasks/map-task';

interface Arguments {
  title: string;
  description?: string;
  assigneeId: string;
  deadline: Date;
}

const mutation = gql`
  mutation createTask(
    $title: String!
    $description: String
    $assigneeId: UUID!
    $deadline: DateTime!
  ) {
    createTask(
      input: {
        title: $title
        description: $description
        assigneeId: $assigneeId
        deadline: $deadline
      }
    ) {
      ...TaskFragment
    }
  }
  ${taskFragment}
`;

const createTask = async (args: Arguments): Promise<Task | Api.GraphQL.Errors> => {
  const response = await getMutationResponse(mutation, args);
  if (response.data !== null) {
    return mapTask(response.data.createTask);
  }
  return response as Api.GraphQL.Errors;
};

export default createTask;
