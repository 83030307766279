import React from 'react';
import styled from 'styled-components';

interface IViewSwitcher {
  active: boolean;
  children: any;
  onClick: ClickHandler;
}

const ViewSwitcherButton = styled.button<IViewSwitcher>`
  border: none;
  outline: none;
  text-transform: uppercase;
  font-size: 13px;
  color: ${(props: IViewSwitcher) => (props.active ? '$base-darker' : '#979797')};
  background: none;
  padding: 10px;
  font-weight: 600;
  cursor: pointer;
  &:hover {
    color: $base-darker;
  }
`;
const ViewSwitcher = ({ active, children, onClick }: IViewSwitcher) => (
  <ViewSwitcherButton active={active} onClick={onClick}>
    {children}
  </ViewSwitcherButton>
);
export default ViewSwitcher;
