import { getQueryResponse } from '../../client';
import { gql } from "@apollo/client";
import { contactListToObject } from '../../../mappers/contacts/contact-mappers';
import errorMapper from '../../../mappers/error-mapper';
import { assigneeDisplayFragment } from 'api/fragments';

const queryFullContacts = gql`
  query FetchFullContact {
    contactList {
        letter
        assignees ${assigneeDisplayFragment}
    }
  }
`;

type ContactListResponse = {
  contactList: Api.Contacts.ContactLetterRaw[];
};

export let listOfAvailableLetters: string[] = [];

const fetchContactList = async (): Promise<ContactListResponse | Api.ApiError> => {
  const response = await getQueryResponse<Response>(queryFullContacts);
  if (response?.data) {
    const convertedResponse = response.data as unknown as ContactListResponse;
    listOfAvailableLetters = convertedResponse.contactList.map(
      (l: Api.Contacts.ContactLetterRaw) => l.letter,
    );
    return contactListToObject(convertedResponse.contactList);
  }
  return errorMapper(response);
};

export default fetchContactList;
