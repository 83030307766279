import { TOGGLE_PANEL } from './action';
import { AppAction } from '../../../store/actions';

const initialState = {
  openPanel: false,
};

const reducer = (state = initialState, action: AppAction) => {
  switch (action.type) {
    case TOGGLE_PANEL:
      return {
        ...state,
        openPanel: action.open,
      };
    default:
      return state;
  }
};

export default reducer;
