import React, { useState } from 'react';
import styled from 'styled-components';

import { getInputColor } from '../timepicker/TimePicker';

interface TextAreaProps {
  isActive?: boolean;
  style?: React.CSSProperties;
  value?: string;
  onChange: any;
  maxLength?: number | null;
}

const TextArea = ({ style, value, onChange, maxLength = null }: TextAreaProps): JSX.Element => {
  const [isActive, setIsActive] = useState<boolean>(false);

  return (
    <TextAreaHolder
      style={style}
      isActive={isActive}
      value={value}
      onChange={onChange}
      onFocus={() => setIsActive(true)}
      onBlur={() => setIsActive(false)}
      maxLength={maxLength || undefined}
    />
  );
};

export default TextArea;

const TextAreaHolder = styled.textarea<TextAreaProps>`
  border: 1px solid;
  border-color: ${(props) => getInputColor(props.isActive, false)};
  border-radius: 5px;
  font-size: 13px;
  color: #979797;
  outline: none;
  width: 100%;
  height: 100px;
  padding: 10px;
  resize: none;
  font-family: Nunito Sans, sans-serif;
  font-weight: bold;
`;
