import { getMutationResponse } from '../../client';
import { gql } from '@apollo/client';

type VerifyMobileArguments = {
  phone: string;
  code: string;
};

export type VerifyMobileResponse = {
  verifyMobile: boolean;
};

const mutation = gql`
  mutation verifyMobile($phone: String!, $code: String!) {
    verifyMobile(mobile: $phone, code: $code)
  }
`;

const verifyPhone = async (
  args: VerifyMobileArguments,
): Promise<Api.GraphQL.Response<VerifyMobileResponse> | Api.ApiError> => {
  const response = await getMutationResponse(mutation, { ...args, phone: `+${args.phone}` });
  return response as Api.GraphQL.Response<VerifyMobileResponse> | Api.ApiError;
};
export default verifyPhone;
