import React, { useEffect } from 'react';
import { Route, Routes } from 'react-router';

import routes from '../../../lib/routes';

// modules
import VerifyEmail from '../../SignUp&Login/VerifyEmailConfirmation/VerifyEmailConfirmation';
import Contacts from '../../../modules/Contacts/Contacts';
import Tasks from '../../../modules/Tasks/Tasks';

import 'react-toastify/dist/ReactToastify.css';
import '../../../App.styles.scss';
import SingleTaskPage from '../../../modules/Tasks/SingleTask';

interface LoggedInSwitchProps {
  getInitialData(): void;
  navigateTo(path: string, me: User.Me): ClickHandler;
  isLoggedIn: boolean;
  me: User.Me;
}
export const LoggedInSwitch = ({
  getInitialData,
  navigateTo,
  isLoggedIn,
  me,
}: LoggedInSwitchProps) => {
  useEffect(() => {
    isLoggedIn && getInitialData();
    // eslint-disable-next-line
  }, [isLoggedIn]);

  return (
    <Routes>
      <Route path={routes.tasks} element={<Tasks />} />
      <Route path={routes.contacts} element={<Contacts />} />
      <Route path={`${routes.tasks}/:id`} element={<SingleTaskPage />} />
      <Route
        path={routes.verifyEmail}
        element={<VerifyEmail onLogInClick={navigateTo(routes.tasks, me)} />}
      />
      <Route path="*" element={<Tasks />} />
    </Routes>
  );
};
