import React from 'react';
import * as colors from '../../../lib/colors';
import { LinkIndicatorIcon, TasksIcon, UsersIcon } from '../../icons/Icons';
import './NavigationLinks.styles.scss';
import classnames from 'classnames';

interface Props {
  isActive: boolean;
  onClick: ClickHandler;
  count?: number;
  isMobile: boolean;
}

export const TasksLink = ({ isActive, onClick, isMobile }: Props) => (
  <NavigationLink
    isMobile={isMobile}
    color={colors.blue}
    icon={
      <TasksIcon
        color={colors.baseDarker}
        style={{
          transitionDuration: '0.2s',
          transitionProperty: 'fill',
        }}
      />
    }
    isActive={isActive}
    label="Tasks"
    onClick={onClick}
  />
);

export const ContactsLink = ({ isActive, onClick, isMobile }: Props) => (
  <NavigationLink
    isMobile={isMobile}
    color={colors.red}
    icon={
      <UsersIcon
        color={colors.baseDarker}
        style={{
          transitionDuration: '0.2s',
          transitionProperty: 'fill',
        }}
      />
    }
    isActive={isActive}
    label="Contacts"
    onClick={onClick}
  />
);

interface NavigationLinkProps {
  color: string;
  icon: React.ReactNode;
  isActive: boolean;
  label: string;
  onClick: ClickHandler;
  count?: number;
  isMobile: boolean;
}

interface NavigationLinkState {
  isHovering: boolean;
}

class NavigationLink extends React.Component<NavigationLinkProps, NavigationLinkState> {
  constructor(props: NavigationLinkProps) {
    super(props);
    this.state = {
      isHovering: false,
    };
    this.handleMouseEnter = this.handleMouseEnter.bind(this);
    this.handleMouseLeave = this.handleMouseLeave.bind(this);
  }

  handleMouseEnter() {
    this.setState({
      isHovering: true,
    });
  }

  handleMouseLeave() {
    this.setState({
      isHovering: false,
    });
  }

  render() {
    const { color, icon, isActive, label, onClick, count, isMobile } = this.props;
    const { isHovering } = this.state;
    const shouldShowIndicator = isActive || isHovering;

    return (
      <div
        className={classnames({ NavigationLink: true, 'NavigationLink-mobile': isMobile })}
        onClick={onClick}
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
      >
        {icon}
        <div className="NavigationLink-label">{label}</div>
        {count !== undefined && (
          <div className="NavigationLink-counter" style={{ backgroundColor: color }}>
            {count}
          </div>
        )}
        <div
          className="NavigationLink-indicator"
          style={{ bottom: shouldShowIndicator ? '1px' : '-20px' }}
        >
          <LinkIndicatorIcon color={color} />
        </div>
      </div>
    );
  }
}
