export const mapMe = (me: Api.Me): User.Me => ({
  id: me.id,
  firstName: me.firstName,
  lastName: me.lastName,
  email: me.email,
  phone: me.mobile,
  preference: me.preference,
  emailVerified: me.emailVerified,
  profileStatsData: {
    userStatistic: [],
    countOfSMSMessages: 0,
  },
});
