import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'store';
import { handleNotification } from '../lib/notificationActions';

const subscription = `subscription All {
  all {
    kind: __typename
    ... on TaskCreatedEvent {
      taskId
      title
      description
      deadline
      authorId
      assigneeId
    }
    ... on TaskArchivedEvent {
      taskId
      title
      description
      authorId
      assigneeId
    }
    ... on TaskAcceptedEvent {
      taskId
      title
      description
      authorId
      assigneeId
    }
    ... on TaskRejectedEvent {
      taskId
      title
      description
      authorId
      assigneeId
    }
    ... on TaskAssignedEvent {
      taskId
      title
      description
      deadline
      authorId
      assigneeId
    }
    ... on TaskAssignmentRemovedEvent {
      taskId
      title
      description
      deadline
      authorId
    }
    ... on TaskReadyForReviewEvent {
      taskId
      title
      description
      authorId
      assigneeId
    }
    ... on TaskSuccessfullyFinishedEvent {
      taskId
      title
      description
      authorId
      assigneeId
    }
    ... on TaskFailedEvent {
      taskId
      title
      description
      authorId
      assigneeId
    }
    ... on DeadlineExpiredEvent {
      taskId
      title
      description
      deadline
      authorId
      assigneeId
    }
    ... on TaskUpdatedEvent {
      taskId
      updatedTitle: title
      description
      updatedDeadline: deadline
      authorId
    }
    ... on TaskDeletedEvent {
      taskId
      title
      description
      authorId
      updatedAssigneeId: assigneeId
    }
    ... on CommentCreatedEvent {
      taskId
      content
      taskTitle
      authorId
      taskAuthorId
      taskAssigneeId
    }
    ... on CommentEditedEvent {
      taskId
      commentId
      content
    }
    ... on EmailVerifiedEvent {
      email
    }
    ... on AssigneeCreatedEvent {
      assigneeId
      firstName
      assigneeLastName: lastName
      assigneeEmail: email
      mobile
    }
    ... on AssigneeUpdatedEvent {
      assigneeId
      updatedFirstName: firstName
      updatedLastName: lastName
      updatedEmail: email
      mobile
    }
    ... on AssigneeRemovedEvent {
      assigneeId
      deletedFirstName: firstName
      deletedLastName: lastName
      deletedEmail: email
      mobile
    }
    ... on ProfileUpdatedEvent {
      firstName
      lastName
    }
    ... on PreferenceUpdatedEvent {
      mobileNotifications
      emailNotifications
    }
  }
}`;

const WSS_URL = `wss://${process.env.REACT_APP_DOMAIN}/ws`;

const Subscription = () => {
  const dispatch = useDispatch();
  const wsClient = useRef<WebSocket | null>(null);
  const isLogged: boolean = useSelector((state: AppState) => state.token.length > 0);
  const token: string = useSelector((state: AppState) => state.token);

  const openSocket = (): void => {
    if (!wsClient.current && isLogged) {
      wsClient.current = new WebSocket(WSS_URL);
      wsClient.current.addEventListener('open', onSocketOpened);
      wsClient.current.addEventListener('message', onNotificationRecieved);
      wsClient.current.addEventListener('close', onSocketClosed);
    } else {
      wsClient.current = null;
    }
  };

  const closeSocket = (): void => {
    if (wsClient.current) {
      wsClient.current.removeEventListener('open', onSocketOpened);
      wsClient.current.removeEventListener('message', onNotificationRecieved);
      wsClient.current.removeEventListener('close', onSocketClosed);
      wsClient.current.close();
    }
  };

  const onSocketOpened = (): void => {
    if (isLogged && wsClient?.current) {
      wsClient.current.send(
        JSON.stringify({
          authorization: `Bearer ${token}`,
        }),
      );
    }
  };

  const onNotificationRecieved = (notification: any): void => {
    const data = JSON.parse(notification.data);
    if (data?.message === 'Successfully authorized.' && token && wsClient?.current) {
      wsClient.current.send(
        JSON.stringify({
          query: subscription,
        }),
      );
    } else {
      const event = data?.data?.all;
      if (event) {
        handleNotification(dispatch, event);
      }
    }
  };

  const onSocketClosed = (): void => {
    if (isLogged) {
      openSocket();
    } else {
      wsClient.current = null;
    }
  };

  useEffect(() => {
    if (token?.length) {
      openSocket();
    } else {
      closeSocket();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  return null;
};

export default Subscription;
