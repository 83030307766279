import { getMutationResponse } from '../../client';
import { gql } from '@apollo/client';
import errorMapper from '../../../mappers/error-mapper';

interface Arguments {
  comment: string;
  id: string;
}

const mutation = gql`
  mutation createComment($taskId: UUID!, $content: String!) {
    createComment(input: { taskId: $taskId, content: $content }) {
      id
      content
    }
  }
`;

const createComment = async (args: Arguments): Promise<TaskComment | Api.ApiError> => {
  const response = await getMutationResponse(mutation, { taskId: args.id, content: args.comment });
  if (response.data !== null) {
    return {
      id: response.data.createComment.id,
      comment: response.data.createComment.content,
    };
  }
  return errorMapper(response as Api.GraphQL.Errors);
};

export default createComment;
