import { getMutationResponse } from '../../client';
import { gql } from '@apollo/client';

const mutation = gql`
  mutation checkPhone($mobile: String!) {
    sendMobileVerificationCode(mobile: $mobile)
  }
`;

type VerificationCodeResponse = {
  sendMobileVerificationCode: string;
};
const checkPhone = async (
  args: string,
): Promise<Api.GraphQL.Response<VerificationCodeResponse> | Api.ApiError> => {
  const response = await getMutationResponse(mutation, { mobile: args });
  return response as Api.GraphQL.Response<VerificationCodeResponse> | Api.ApiError;
};

export default checkPhone;
