import React from 'react';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';

import withWindowSize from '../with-window-size/withWindowSize';
import { inputColors } from './input-colors';

interface IInputProps {
  header?: boolean;
  small?: boolean;
  value: string;
  onChange(value: React.ChangeEvent<HTMLInputElement>): void;
  disabled?: boolean;
  onBlur?(): void;
  style?: React.CSSProperties;
  placeholder?: string;
  error?: boolean;
  errorMessage?: string;
  breakViewParent?: boolean;
  editable?: boolean;
  type?: string;
  maxLength?: number;
  onKeyDown?(value: React.KeyboardEvent<HTMLInputElement>): void;
}
const Input = ({
  header,
  small,
  value,
  onChange,
  disabled,
  onBlur,
  placeholder,
  style,
  error,
  errorMessage,
  breakViewParent,
  type,
  onKeyDown,
  maxLength = undefined,
}: IInputProps) => {
  const { isMobile } = withWindowSize();
  const breakView = breakViewParent || isMobile;

  return (
    <InputWrapper>
      <InputComponent
        type={type || 'text'}
        autocomplete={'off'}
        id={uuidv4()}
        name={uuidv4()}
        placeholder={placeholder}
        header={header}
        style={style}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        disabled={disabled}
        onKeyUp={(e) => (e.nativeEvent.key === 'Enter' && onBlur ? onBlur() : null)}
        onKeyDown={onKeyDown}
        error={!!error}
        small={!!small}
        isMobile={breakView}
        maxLength={maxLength ? maxLength : undefined}
      />

      {error && (
        <ErrorMessage>
          <ErrorFormat small={!!small}>{errorMessage}</ErrorFormat>
        </ErrorMessage>
      )}
    </InputWrapper>
  );
};
export default Input;

interface IInputStyleProps {
  header?: boolean;
  isMobile: boolean;
  small: boolean;
  error?: boolean;
  disabled?: boolean;
  autocomplete?: string;
}

export const getHeight = (isMobile: boolean, isSmall: boolean) => {
  if (isMobile) {
    return '36px';
  } else if (isSmall) {
    return '28px';
  } else return '36px';
};

const InputWrapper = styled.div`
  position: relative;
`;

export const InputComponent = styled.input<IInputStyleProps>`
  color: ${(props) => (props.header ? inputColors.headingColor : inputColors.fontColor)};
  font-family: 'Nunito Sans', sans-serif;
  ${(props) => (props.header ? 'text-transform: uppercase;' : '')};
  padding: 0 12px;
  height: ${(props) => getHeight(props.isMobile, props.small)};
  width: 100%;
  line-height: ${(props) => getHeight(props.isMobile, props.small)};
  font-size: ${(props) =>
    props.small && !props.isMobile && !props.header ? '13px' : props.header ? '18px' : '14px'};
  border: 1px solid;
  box-shadow: none;
  outline: none;
  border-radius: 20px;
  font-weight: bold;
  border-color: ${(props) =>
    props.error
      ? inputColors.borderColorError
      : props.disabled
      ? inputColors.borderColorDisabled
      : inputColors.borderColor};
  transition: border-color 200ms linear;
  pointer-events: ${(props) => (props.disabled ? 'none' : 'default')};
  background-color: ${(props) => (props.disabled ? '#f3f5fb' : 'white')};
  &:focus,
  &:hover {
    border-color: ${(props) =>
      props.error ? inputColors.borderColorError : inputColors.borderColorActive};
  }
  &:disabled {
    background-color: $white;
  }
  &::placeholder {
    color: ${inputColors.placeholderColor};
    font-weight: normal;
  }
`;
interface IErrorStyledProps {
  small: boolean;
}

export const ErrorMessage = styled.div`
    color: ${inputColors.fontColorError}
    margin-top: 2px;
    text-align: right
`;

export const ErrorFormat = styled.pre<IErrorStyledProps>`
  margin: 0;
  padding: 0;
  font-family: 'Nunito Sans', sans-serif;
  font-weight: 600;
  font-size: ${(props) => (props.small ? '12px' : '14px')};
`;
