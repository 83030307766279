import classnames from 'classnames';
import React from 'react';
import { connect } from 'react-redux';
import { ToastContainer } from 'react-toastify';

import { Dispatch } from 'redux';
import { AppState } from './store';

// components
import Navigation from './components/navigation/Navigation';
import Header from './components/header/Header';
import PopupContainer from './components/popup/PopupContainer';
import Modal from './components/modal/Modal';
import VerifyBanner from './components/verify-banner/VerifyBanner';
import { NotificationCloseIcon } from './components/toast-notifications/Notifications';

import withWindowSize from './components/with-window-size/withWindowSize';
import { navigateTo } from './store/actions';
import { fetchInitialData } from './modules/SignUp&Login/Login/redux/actions';
import { resendEmailVerification } from './modules/SignUp&Login/VerifyEmailPrompt/redux/actions';

import 'react-toastify/dist/ReactToastify.css';
import './App.styles.scss';
import { LoggedOutSwitchWithTransition } from 'modules/SignUp&Login/HomeRoutes/LoggedOutRoutes';
import { LoggedInSwitch } from 'modules/SignUp&Login/HomeRoutes/LoggedInRoutes';
import Helmet from 'react-helmet';
import OGimage from './images/OGimage.png';
import { useLocation } from 'react-router';

interface PropsFromState {
  isLoggedIn: boolean;
  me: User.Me;
  token: string;
  emailSent: boolean;
}

interface PropsFromDispatch {
  getInitialData(): void;
  sendVerifyEmail(email: string): void;
  navigateTo(path: string): ClickHandler;
}

interface Props extends PropsFromState, PropsFromDispatch {}

export const App = ({
  isLoggedIn,
  getInitialData,
  navigateTo,
  me,
  sendVerifyEmail,
  emailSent,
}: Props) => {
  const { isMobile } = withWindowSize();
  const url = window.location;
  const tokenUrlParams = new URLSearchParams(url.search).get('token');
  const location = useLocation();
  const path = location?.pathname;

  return (
    <div
      className={classnames({
        App: true,
        'App-logged-in': isLoggedIn,
        'App-logged-out': !isLoggedIn,
        'App-logged-out-single-task': tokenUrlParams,
      })}
    >
      <Helmet>
        <meta property="og:title" content="Task management simplified" />
        <meta property="og:image" content={OGimage} />
        <meta
          property="og:description"
          content="Ensure reliable and hassle-free task execution with NagNag's smart SMS notifications."
        />
      </Helmet>
      <Header />
      <div className="App-content">
        {isLoggedIn ? (
          <>
            <LoggedInSwitch
              isLoggedIn={isLoggedIn}
              getInitialData={getInitialData}
              navigateTo={navigateTo}
              me={me}
            />
            <PopupContainer />
            <Modal />
            <ToastContainer
              autoClose={6000}
              hideProgressBar={true}
              closeButton={<NotificationCloseIcon />}
              toastClassName={'custom-toast'}
              position={'bottom-left'}
              style={{ zIndex: 200, bottom: 10 }}
            />
            <VerifyBanner me={me} emailSent={emailSent} verify={() => sendVerifyEmail(me.email)} />
          </>
        ) : (
          <LoggedOutSwitchWithTransition navigateTo={navigateTo} />
        )}
      </div>
      {isLoggedIn && isMobile ? (
        <Navigation path={path} isMobile={true} navigateTo={navigateTo} />
      ) : null}
    </div>
  );
};

const mapStateToProps = (state: AppState): PropsFromState => ({
  isLoggedIn: state.token !== '',
  me: state.me,
  token: state.token,
  emailSent: state.verifyYourEmail.emailSent,
});

const mapDispatchToProps = (dispatch: Dispatch): PropsFromDispatch => ({
  getInitialData: () => dispatch(fetchInitialData.request({ loggedIn: false })),
  navigateTo: (path) => () => dispatch(navigateTo(path)),
  sendVerifyEmail: (email: string) => dispatch(resendEmailVerification.request(email)),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
