import React from 'react';
import styled from 'styled-components';

import Timestamp from '../../Timestamp';
import Initials from '../../initials/Initals';
import { Task } from '../../../types/task';
import { ReactComponent as Bell } from '../../../assets/graphics/bell.svg';

interface Props {
  me: User.Me;
  event: any;
}

interface EventProps {
  user: string;
  timestamp: Date;
  text: string;
}

interface CommentProps {
  event: Task.TaskTimeLineEvent;
  me: User.Me;
}

const FlexDiv = styled.div`
  display: flex;
  align-items: top;
`;

const EventDate = styled.div`
  color: rgba(56, 72, 92, 0.5);
  display: inline;
  font-size: 11px;
  line-height: 21px;
  width: 120px;
  text-align: right;
`;

const UserName = styled.span`
  color: #5d7289;
  font-weight: 800;
  font-size: 12px;
  line-height: 21px;
  font-family: 'Nunito Sans', sans-serif;
`;

const CommentData = styled.div`
  justify-content: space-between;
  display: flex;
  align-items: top;
`;

const CommentField = styled.div`
  width: 90%;
  height: auto;
  background-color: white;
  border-radius: 15px;
  padding: 4px 8px;
  margin-top: 8px;
  margin-left: 5%;
  color: #405267;
  line-height: 18px;
  font-size: 12px;
  font-family: 'Nunito Sans', sans-serif;
  overflow-x: hidden;
  word-break: break-word;
  border: 1px solid rgba(155, 163, 173, 0.3);
`;

const CommentEventData = styled.div`
    font-size: 12px;
    color: #888888};
    display: flex;
    align-items: top;
`;

const StatusEventData = styled.div`
    font-size: 12px;
    width: calc(100% - 120px);
    color: #888888};
    display: flex;  
    align-items: top;
    margin-left: 4px;
`;

const IconWrapper = styled.span`
  display: flex-inline;
  align-items: center;
`;

const iconStyle = {
  height: '18px',
};

const CommentEvent = ({ me, event }: CommentProps) => {
  const userName =
    me.id === event.user.id || (!event.user.firstName && !event.user.lastName) ? 'You'
    : `${event.user.firstName || ''} ${event.user.lastName || ''}`;
  return (
    <>
      <CommentData>
        <FlexDiv>
          <Initials
            firstName={event.user.firstName as string}
            lastName={event.user.lastName}
            style={{ marginRight: '5px' }}
          />
          <CommentEventData>
            <UserName>{userName}</UserName>
          </CommentEventData>
        </FlexDiv>
        <EventDate>
          <Timestamp time={event.created} />
        </EventDate>
      </CommentData>
      <CommentField>{event.comment}</CommentField>
    </>
  );
};

const StatusEvent = ({ user, text, timestamp }: EventProps) => (
  <FlexDiv>
    <IconWrapper>
      <Bell style={iconStyle} />
    </IconWrapper>
    <StatusEventData>
      <div>
        <UserName>{user}</UserName> <span style={{ color: 'rgba(56, 72, 92, 0.5)' }}>{text}</span>
      </div>
    </StatusEventData>
    <EventDate>
      <Timestamp time={timestamp} />
    </EventDate>
  </FlexDiv>
);

const TaskTimelineEvent = ({ me, event }: Props) => {
  const userName =
    me.id === event.user.id || (!event.user.firstName && !event.user.lastName) ? 'You'
    : event.reason === "DEADLINE_PASSED" ? "" : `${event.user.firstName || ''} ${event.user.lastName || ''}`;
  return (
    <>
      {event.comment ? (
        <CommentEvent me={me} event={event} />
      ) : (
        <StatusEvent user={userName} text={event.type} timestamp={event.created} />
      )}
    </>
  );
};

export default React.memo(TaskTimelineEvent);
