import { createAsyncAction } from 'typesafe-actions';

export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAILURE = 'RESET_PASSWORD_FAILURE';

interface IResetPassword {
  password: string;
  repeatedPassword: string;
  token: string;
}
export const resetPassword = createAsyncAction(
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILURE,
)<IResetPassword, never, never>();
