import React from 'react';
import styled from 'styled-components';
import withWindowSize from '../with-window-size/withWindowSize';
import { inputColors } from './input-colors';

interface IFieldWrapper {
  children: any;
  breakViewParent?: boolean;
  style?: React.CSSProperties;
}
export const FieldWrapper = ({ children, breakViewParent, style }: IFieldWrapper) => {
  const { isMobile } = withWindowSize();
  const breakView = breakViewParent || isMobile;
  return (
    <Wrapper isMobile={breakView} style={style}>
      {children}
    </Wrapper>
  );
};
interface ILabelProps {
  children: any;
  large?: boolean;
}
export const Label = ({ children, large }: ILabelProps) => {
  return <LabelWrapper large={!!large}>{children}</LabelWrapper>;
};
interface IWrapperProps {
  isMobile: boolean;
  height?: string;
}

export const Wrapper = styled.div<IWrapperProps>`
  display: ${(props) => (props.isMobile ? 'block' : 'flex')};
  align-content: center;
  justify-content: space-between;
  height: ${(props) => (props.height ? props.height : '50px')};
`;
interface ILabelStyledProps {
  large: boolean;
}
const LabelWrapper = styled.div<ILabelStyledProps>`
  display: inline;
  color: ${inputColors.labelColor};
  font-size: ${(props) => (props.large ? '14px' : '13px')};
  height: 28px;
  min-width: 90px;
  line-height: 28px;
`;

export const LoginFieldWrapper = styled.div`
  height: 50px;
`;
