import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import ButtonRound from '../buttons/ButtonRound';
import { ButtonType } from '../buttons/ButtonText';
import { CloseIcon } from '../icons/Icons';
import { Dispatch } from 'redux';
import { closeModal, ModalSettings, ModalType } from './redux/actions';
import { AppState } from '../../store';

interface ModalProps {
  openModal: boolean;
  modalSettings: ModalSettings;
  closeThisModal(): void;
}

interface PropsFromDispatch {
  closeThisModal(): void;
}

const Modal = ({ openModal, modalSettings, closeThisModal }: ModalProps) => {
  const handleOverlayClick = (event: React.MouseEvent<HTMLDivElement>) => {
    const clickedElement = event.target as HTMLElement;
    if (clickedElement.classList.contains('exclude')) {
      closeThisModal();
    }

    return;
  };

  const handleModalContentClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
  };

  if (!openModal) {
    return null;
  }

  return (
    <ModalOverlay onClick={handleOverlayClick} className="exclude">
      {modalSettings.template || (
        <ModalHolder>
          <div onClick={handleModalContentClick}>
            <Close onClick={() => closeThisModal()}>
              <CloseIcon />
            </Close>
            <ModalTitleHolder>
              <ModalTitle>{modalSettings.title}</ModalTitle>
            </ModalTitleHolder>
            <ModalContent>{modalSettings.content}</ModalContent>
            <ButtonHolder>
              <ButtonRound
                buttonType={
                  modalSettings.type === ModalType.WARNING
                    ? ButtonType.warning
                    : modalSettings.type === ModalType.DELETE
                    ? ButtonType.delete
                    : ButtonType.confirm
                }
                inverted={modalSettings.type === ModalType.DELETE}
                style={{ marginRight: 10 }}
              >
                {modalSettings.confirmButtonText || 'ok'}
              </ButtonRound>
              <ButtonRound inverted onClick={() => closeThisModal()} buttonType={ButtonType.cancel}>
                cancel
              </ButtonRound>
            </ButtonHolder>
          </div>
        </ModalHolder>
      )}
    </ModalOverlay>
  );
};

const mapStateToProps = (state: AppState) => ({
  openModal: state.modal.openModal,
  modalSettings: state.modal.modalSettings,
});

const mapDispatchToProps = (dispatch: Dispatch): PropsFromDispatch => ({
  closeThisModal: () => dispatch(closeModal()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Modal);

const ModalOverlay = styled.div`
  position: fixed;
  z-index: 20;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  &.exclude {
    align-items: center;
  }
`;
const ModalHolder = styled.div`
  width: 509px;
  min-height: 200px;
  background-color: white;
  position: relative;
  border-radius: 5px;
`;

const ModalTitleHolder = styled.div`
  height: 50px;
  width: 100%;
`;

const ModalTitle = styled.div`
  margin-top: 39px;
  text-transform: uppercase;
  font-size: 18px;
  text-align: center;
  color: #5e6a7a;
  letter-spacing: 2px;
  font-weight: 600;
`;

const ModalContent = styled.div`
  padding: 10px 25px;
  color: #5e6a7a;
  font-size: 14px;
  min-height: 70px;
  background-color: rgba(241, 242, 245, 0.5);
`;

const ButtonHolder = styled.div`
  background-color: rgba(241, 242, 245, 0.5);
  bottom: 0;
  width: 100%;
  padding-bottom: 14px;
  padding-right: 10px;
  display: flex;
  flex-display: row;
  align-items: flex-end;
  justify-content: flex-end;
`;

const Close = styled.div`
  position: absolute;
  cursor: pointer;
  top: 15px;
  right: 15px;
  &:hover {
    opacity: 0.5;
  }
`;
