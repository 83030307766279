import {
  FETCH_PROFILE_STATS_SUCCESS,
  PREFERENCE_UPDATED_EVENT,
  PROFILE_UPDATED_EVENT,
  UPDATE_USER_SETTINGS_SUCCESS,
} from '../../modules/UserSettings/redux/actions';
import {
  FETCH_INITIAL_DATA_SUCCESS,
  LOG_OUT,
} from '../../modules/SignUp&Login/Login/redux/actions';
import { AppAction } from '../actions';
import { EMAIL_VERIFIED_EVENT } from 'modules/SignUp&Login/VerifyEmailPrompt/redux/actions';

type State = User.Me;

const initialState: State = {
  id: '',
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  emailVerified: false,
  preference: {
    mobileNotifications: false,
    emailNotifications: false,
    dailyReport: false,
    language: '',
    timezone: '',
    dailyReportTime: '',
  },
  profileStatsData: {
    userStatistic: [],
    countOfSMSMessages: 0,
  },
};

const reducer = (state: State = initialState, action: AppAction): State => {
  switch (action.type) {
    case FETCH_INITIAL_DATA_SUCCESS:
      return action.payload;

    case FETCH_PROFILE_STATS_SUCCESS:
      return {
        ...state,
        ...{ profileStatsData: action.payload },
      };
    case UPDATE_USER_SETTINGS_SUCCESS:
      return {
        ...state,
        firstName: action.payload.firstName,
        lastName: action.payload.lastName,
        email: action.payload.email,
        preference: {
          mobileNotifications: action.payload.preference.mobileNotifications,
          emailNotifications: action.payload.preference.emailNotifications,
          timezone: action.payload.preference.timezone,
          dailyReport: action.payload.preference.dailyReport,
          dailyReportTime: action.payload.preference.dailyReportTime,
        },
      };
    case PROFILE_UPDATED_EVENT:
      return {
        ...state,
        firstName: action.payload.firstName,
        lastName: action.payload.lastName,
      }
    case PREFERENCE_UPDATED_EVENT:
      return {
        ...state,
        preference: {
          ...state.preference,
          mobileNotifications: action.payload.mobileNotifications
            ?? state.preference.mobileNotifications,
          emailNotifications: action.payload.emailNotifications
            ?? state.preference.emailNotifications,
          dailyReport: action.payload.emailNotifications === false ? false : state.preference.dailyReport
        }
    }
    case EMAIL_VERIFIED_EVENT:
      return { ...state, emailVerified: action.payload.email === state.email || state.emailVerified }
    case LOG_OUT:
      return {
        id: '',
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        emailVerified: false,
        preference: {
          mobileNotifications: false,
          emailNotifications: false,
          dailyReport: false,
          language: '',
          timezone: '',
        },
        profileStatsData: {
          userStatistic: [],
          countOfSMSMessages: 0,
        },
      };
    default:
      return state;
  }
};

export default reducer;
