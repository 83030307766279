import { mapContact, mapPublicProfile } from '../contacts/contact-mappers';
import { Task } from '../../types/task';

export const mapTimeline = (timelineEvent: Api.TaskTimelineEvent): Task.TaskTimeLineEvent => {
  return {
    id: timelineEvent.id,
    user:
      timelineEvent.creator.__typename === 'Contact'
        ? mapContact(timelineEvent.creator)
        : mapPublicProfile(timelineEvent.creator),
    created: timelineEvent.created,
    type: mapTimelineEventMessage(timelineEvent.kind, (timelineEvent as Api.TaskFailedTimelineEvent)?.reason),
    comment: timelineEvent.comment && timelineEvent.comment.content,
    reason: (timelineEvent as Api.TaskFailedTimelineEvent)?.reason || null,
  };
};

export const mapTimelineEventMessage = (message: string, reason?: 'DEADLINE_PASSED' | 'USER_FAILED') => {
  switch (message) {
    case 'NagSent':
      return 'nagged.';
    case 'TaskAccepted':
      return 'accepted the task.';
    case 'TaskArchived':
      return 'archived the task.';
    case 'TaskUnarchived':
      return 'unarchived the task.';
    case 'TaskAssigneeChanged':
      return 'changed the assignee.';
    case 'TaskCommented':
      return 'commented:';
    case 'TaskCreated':
      return 'created the task.';
    case 'TaskDeadlineChanged':
      return 'updated the deadline.';
    case 'TaskFailed':
      return reason === "DEADLINE_PASSED" ? 'Task failed due to missed deadline' : 'marked task as failed.';
    case 'TaskRedefined':
      return 'redefined the task.';
    case 'TaskRejected':
      return 'rejected the task.';
    case 'TaskSuccessful':
      return 'marked task as successful.';
    case 'TaskReadyForReview':
      return 'marked task as ready for review.';
    default:
      return 'updated the task.';
  }
};
