import React from 'react';
import styled from 'styled-components';

interface InitialsProps {
  firstName: string;
  lastName?: string;
  smallIcon?: boolean;
  style?: React.CSSProperties;
}

interface StyledProps {
  smallIcon: boolean;
}

const InitialsHolder = styled.div<StyledProps>`
  width: ${(props) => (props.smallIcon ? '22px' : '27px')};
  height: ${(props) => (props.smallIcon ? '22px' : '27px')};
  border-radius: 50%;
  border: 1px solid rgba(56, 72, 92, 0.5);
  font-size: ${(props) => (props.smallIcon ? '9px' : '11px')};
  color: #979797;
  text-transform: uppercase;
  background-color: white;
  font-weight: 600;
  cursor: default;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const Initials = ({ firstName, lastName, smallIcon = true, style }: InitialsProps) => {
  const initials = firstName.charAt(0) + (lastName ? lastName.charAt(0) : '');
  return (
    <InitialsHolder style={style} smallIcon={!!smallIcon}>
      {initials}
    </InitialsHolder>
  );
};
export default Initials;
