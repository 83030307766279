import React from 'react';
import './LoadingIndicator.styles.scss';

interface ILoading {
  style?: React.CSSProperties;
}
const LoadingIndicator = ({ style }: ILoading) => (
  <div className="LoadingIndicator" style={style}>
    <div className="LoadingIndicator-1" />
    <div className="LoadingIndicator-2" />
    <div className="LoadingIndicator-3" />
  </div>
);

export default LoadingIndicator;
