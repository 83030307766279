import React from 'react';
import styled from 'styled-components';
import { CloseIcon } from '../icons/Icons';
import TaskDetails from '../task-components/task-full-data/task-readonly-components/TaskDetails';
import withWindowSize from '../with-window-size/withWindowSize';
import { AppState } from '../../store';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { togglePanel } from './redux/action';

const PanelHolder = styled.div<any>`
  height: ${(props) => (props.isMobile ? '100vh' : 'calc(100vh - 50px)')};
  width: ${(props) => (props.isMobile ? 'calc(100% - 30px)' : '400px')};
  top: ${(props) => (props.isMobile ? '0' : '50px')};
  z-index: ${(props) => (props.isMobile ? '21' : '2')};
  transition: transform 0.3s;
  will-change: transform;
  position: fixed;
  right: 0;
`;

const PanelContent = styled.div`
  width: 100%;
  height: 100%;
  background-color: white;
  border-left: 1px solid #dedfe3;
`;

const Close = styled.div`
  color: #9ba3ad;
  cursor: pointer;
  padding: 3px 0px 5px 9px;
  position: absolute;
  z-index: 5;
  top: 10px;
  left: -28px;
  width: 30px;
  height: 26px;
  background-color: white;
  border-radius: 13px 0px 0px 13px;
  border: 1px solid #dedfe3;
  border-right: white;
`;

interface PanelProps {
  close: any;
  openPanel?: boolean;
  selectedTaskId?: string;
}

const Panel = ({ close, selectedTaskId, openPanel }: PanelProps) => {
  const { isMobile } = withWindowSize();
  return (
    <PanelHolder
      isMobile={isMobile}
      style={{ transform: openPanel ? 'translateX(0)' : 'translate(110%)' }}
      onClick={(e: Event) => e.stopPropagation()}
    >
      <Close onClick={close}>
        <CloseIcon style={{ width: 12, height: 12 }} />
      </Close>
      <PanelContent>{selectedTaskId ? <TaskDetails taskId={selectedTaskId} /> : null}</PanelContent>
    </PanelHolder>
  );
};

const mapStateToProps = (state: AppState) => ({
  selectedTaskId: state.selectedTask.id,
  openPanel: state.panel.openPanel,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  close: () => dispatch(togglePanel(false)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Panel);
