import React from 'react';
import ViewSwitcher from '../../buttons/view-switcher/ViewSwitcherButtons';
import { TaskFilter } from '../get-task-options';
import styled from 'styled-components';

interface TasksFilterSwitchProps {
  setFilter(filter: TaskFilter): void;
  filter: string;
  left?: boolean;
  isMobile: boolean;
}

const TasksFilterSwitch = ({ setFilter, filter, left, isMobile }: TasksFilterSwitchProps) => (
  <ViewSwitcherHolder left={!!left} isMobile={isMobile}>
    <ViewSwitcher onClick={() => setFilter(TaskFilter.ACTIVE)} active={filter === 'active'}>
      Active
    </ViewSwitcher>
    <ViewSwitcher onClick={() => setFilter(TaskFilter.COMPLETED)} active={filter === 'completed'}>
      Completed
    </ViewSwitcher>
  </ViewSwitcherHolder>
);

export default TasksFilterSwitch;

const ViewSwitcherHolder = styled.div<{ left: boolean; isMobile: boolean }>`
  display: flex;
  justify-content: ${(props) =>
    props.isMobile ? 'center' : props.left ? 'flex-start' : 'flex-end'};
  margin-top: ${(props) => (props.isMobile ? '0px' : props.left ? '10px' : '0')};
`;
