import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { CheckIcon } from '../icons/Icons';

export interface IVerifyBanner {
  verify(): void;
  me: User.Me;
  emailSent: boolean;
}

const VerifyBanner = ({ verify, me, emailSent }: IVerifyBanner) => {
  const [disabled, setDisabled] = useState(false);
  const [remainingTime, setRemainingTime] = useState(0);

  useEffect(() => {
    if (disabled) {
      const timer = setTimeout((): void => {
        setRemainingTime((prevTime) => prevTime - 1);
      }, 1000);

      return () => clearTimeout(timer);
    }
  }, [disabled, remainingTime]);

  const handleVerifyClick = (): void => {
    if (!disabled) {
      verify();
      setDisabled(true);
      setRemainingTime(30);
      setTimeout(() => setDisabled(false), 30000);
    }
  };

  if (me.id && me.emailVerified === false && emailSent) {
    return (
      <VerifyHolder onClick={handleVerifyClick} disabled={disabled}>
        Email sent!
        <Send>
          <CheckIcon color={'white'} style={{ height: 15, width: 15, margin: 5 }} />
        </Send>
      </VerifyHolder>
    );
  } else if (me.id && !me.emailVerified) {
    return (
      <VerifyHolder onClick={handleVerifyClick} disabled={disabled}>
        Verify email
        <Send>Send email</Send>
      </VerifyHolder>
    );
  } else {
    return null;
  }
};

export default VerifyBanner;

export const VerifyHolder = styled.button`
  width: 50px;
  position: fixed;
  right: 0;
  top: 45%;
  z-index: 200;
  background-color: #64ace2;
  opacity: 0.8;
  color: white;
  padding: 8px;
  font-size: 12px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-contents: center;
  border-radius: 4px 0 0 4px;
  cursor: pointer;
  transition: opacity 300ms ease, width 300ms ease;
  border-color: #64ace2;
  border-width: 0;

  &:hover {
    opacity: 1;
    width: 120px;
  }

  &:hover div {
    visibility: visible;
    width: 50px;
  }
  :disabled {
    background-color: gray;
  }
`;

export const Send = styled.div`
  color: white;
  text-transform: uppercase;
  font-size: 10px;
  line-height: 14px;
  font-weight: bold;
  visibility: hidden;
  transition: visibility 200ms ease, width 200 ease;
`;

// $green: #7ac142;
// $white: #fff;
//
// // Misc
// $curve: cubic-bezier(0.650, 0.000, 0.450, 1.000);
//
// body {
//     background-color: gray;
//     width: 100vw;
//     height: 100vh;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     animation-iteration-count:infinite;
//
// }
//
// .checkmark {
//     width: 56px;
//     height: 56px;
//     display: block;
//     stroke-width: 2;
//     stroke: $white;
//     stroke-miterlimit: 10;
//     animation-iteration-count:infinite;
//     animation: fill .8s infinite .8s infinite, scale .8s infinite .9s infinite;
// }
//
// .checkmark__check {
//     transform-origin: 50% 50%;
//     stroke-dasharray: 48;
//     stroke-dashoffset: 48;
//     animation-iteration-count:infinite;
//
//     animation: stroke 1.5s $curve 1.5s
//     infinite;
// }
//
// @keyframes stroke {
//     100% {
//         stroke-dashoffset: 0;
// }
// }

//<svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
//<path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/></svg>
