import React from 'react';
import styled from 'styled-components';
import { CloseIcon } from '../icons/Icons';

const NotificationClose = styled.div`
  svg {
    fill: white;
  }
  margin-left: 20px;
  cursor: pointer;
`;

interface INotificationClose {
  closeToast?(): void;
}

export const NotificationCloseIcon = ({ closeToast }: INotificationClose) => {
  return (
    <NotificationClose onClick={closeToast}>
      <CloseIcon color={'#fff'} style={{ color: 'white' }} />
    </NotificationClose>
  );
};
