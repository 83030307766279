import React from 'react';
import styled from 'styled-components';
import { getTextColor } from './get-button-color';
import ButtonBase from './ButtonBase';

interface TextButtonProps {
  buttonType?: ButtonType;
  children: string;
  onClick: ClickHandler;
  height?: string;
  disabled?: boolean;
  style?: React.CSSProperties;
}

const TextButtonHolder = styled(ButtonBase)<TextButtonProps>`
  height: ${(props) => (props.height ? `${props.height}` : '40px')};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  margin: 0;
  &:hover,
  &:active {
    background-color: rgba(0, 0, 0, 0.02);
  }
  color: ${(props) => getTextColor(props.buttonType)};
  border: none;
  width: 100%;
`;

export enum ButtonType {
  default,
  warning,
  confirm,
  cancel,
  disabled,
  delete,
}
const ButtonText = ({
  buttonType,
  children,
  onClick,
  disabled,
  height = '100%',
  style,
}: TextButtonProps) => (
  <TextButtonHolder
    style={style}
    disabled={disabled}
    height={height}
    onClick={onClick}
    buttonType={disabled ? ButtonType.disabled : buttonType}
  >
    {children}
  </TextButtonHolder>
);
export default ButtonText;
