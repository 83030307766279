import * as colors from '../../lib/colors';
import { ButtonType } from '../buttons/ButtonText';

export enum TaskStatusEnum {
  FAILED = 'FAILED',
  IN_PROGRESS = 'IN_PROGRESS',
  IN_REVIEW = 'IN_REVIEW',
  PENDING = 'PENDING',
  REJECTED = 'REJECTED',
  SUCCESSFUL = 'SUCCESSFUL',
}

export enum TaskFilter {
  ACTIVE = 'active',
  COMPLETED = 'completed',
}

export const isTaskActive = (status: TaskStatus) => {
  switch (status) {
    case TaskStatusEnum.IN_PROGRESS:
    case TaskStatusEnum.IN_REVIEW:
    case TaskStatusEnum.PENDING:
      return true;
    default:
      return false;
  }
};

export const getTaskStatusColor = (status: TaskStatus, iAmAuthor: boolean, archived: boolean) => {
  switch (status) {
    case TaskStatusEnum.IN_PROGRESS:
      return iAmAuthor ? colors.status.active.progress : colors.status.active.myProgress;
    case TaskStatusEnum.PENDING:
      return iAmAuthor ? colors.status.active.pending : colors.status.active.myPending;
    case TaskStatusEnum.IN_REVIEW:
      return colors.status.active.inReview;
    case TaskStatusEnum.SUCCESSFUL:
      return archived ? colors.status.archived.successful : colors.status.complete.successful;
    case TaskStatusEnum.FAILED:
      return archived ? colors.status.archived.failed : colors.status.complete.failed;
    case TaskStatusEnum.REJECTED:
      return archived ? colors.status.archived.rejected : colors.status.complete.rejected;
  }
};

export const getTaskActions = (status: TaskStatus, isAuthor: boolean, isArchived: boolean) => {
  switch (status) {
    case TaskStatusEnum.PENDING:
      return isAuthor ? [TasksActions.reassign] : [TasksActions.reject, TasksActions.accept];
    case TaskStatusEnum.IN_PROGRESS:
      return isAuthor ? [TasksActions.nag] : [TasksActions.markReady];
    case TaskStatusEnum.IN_REVIEW:
      return isAuthor ? [TasksActions.failed, TasksActions.completed] : [];
    case TaskStatusEnum.REJECTED:
    case TaskStatusEnum.FAILED:
    case TaskStatusEnum.SUCCESSFUL:
      return isAuthor && !isArchived ? [TasksActions.archive, TasksActions.delete] : isAuthor ? [TasksActions.delete] : [];
    default:
      return [];
  }
};

const TasksActions = {
  reject: {
    text: 'reject',
    buttonType: ButtonType.warning,
  },
  accept: {
    text: 'accept',
    buttonType: ButtonType.confirm,
  },
  reassign: {
    text: 'reassign',
    buttonType: ButtonType.default,
  },
  markReady: {
    text: 'mark ready',
    buttonType: ButtonType.confirm,
  },
  nag: {
    text: 'nag',
    buttonType: ButtonType.default,
  },
  failed: {
    text: 'failed',
    buttonType: ButtonType.warning,
  },
  completed: {
    text: 'completed',
    buttonType: ButtonType.confirm,
  },
  archive: {
    text: 'archive',
    buttonType: ButtonType.cancel,
  },
  delete: {
    text: 'delete',
    buttonType: ButtonType.delete,
  },
};
