import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import CenteringContainer from '../../../components/centering-container/CenteringContainer';
import LoggedOutFormContainer from '../../../forms/form-container/logged-out-container/LoggedOutFormContainer';
import { forgotPassword as forgotPasswordAction } from './ForgotPassword.actions';
import './ForgotPassword.styles.scss';
import Input from '../../../components/input/Text-Input';
import { LoginFieldWrapper } from '../../../components/input/input-components';
import forgotPassword from '../../../api/mutations/user/forgot-password';
import errorMapper from '../../../mappers/error-mapper';

interface PropsFromDispatch {
  forgotPasswordSuccess(email: string): void;
}

const mapDispatchToProps = (dispatch: Dispatch): PropsFromDispatch => ({
  forgotPasswordSuccess: (email) => dispatch(forgotPasswordAction.success(email)),
});

interface Props extends PropsFromDispatch {}

export const ForgotPassword = ({ forgotPasswordSuccess }: Props) => {
  const [email, setEmail] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [responseError, setResponseError] = useState('');

  const forgotPasswordFn = async () => {
    setIsSubmitting(true);
    const _response = await forgotPassword({ email: email });
    const response = _response as Api.GraphQL.Response<{ forgotPassword: boolean; }>;
    setIsSubmitting(false);
    if (response.data && response.data.forgotPassword) {
      forgotPasswordSuccess(email);
    } else {
      setResponseError(errorMapper(response as Api.GraphQL.Errors).message);
    }
  };

  return (
    <CenteringContainer>
      <LoggedOutFormContainer
        style={{ width: '280px' }}
        disabled={isSubmitting || email === ''}
        onSubmit={() => forgotPasswordFn()}
        submitButtonLabel="Send reset link"
        title="Forgot password"
      >
        <LoginFieldWrapper>
          <Input
            error={!!(email && !!responseError)}
            errorMessage={responseError}
            style={{ width: 280 }}
            onChange={(e) => {
              setEmail(e.target.value);
              setResponseError('');
            }}
            value={email}
            placeholder="Email"
          />
        </LoginFieldWrapper>
      </LoggedOutFormContainer>
    </CenteringContainer>
  );
};

export default connect(null, mapDispatchToProps)(ForgotPassword);
