import { getMutationResponse } from '../../client';
import { gql } from '@apollo/client';

interface Arguments {
  email: string;
}
const mutation = gql`
  mutation ForgotPassword($email: String!) {
    forgotPassword(email: $email)
  }
`;

type ForgotPasswordResponse = {
  forgotPassword: boolean;
};

const forgotPassword = async (args: Arguments): Promise<Api.GraphQL.Response<ForgotPasswordResponse> | Api.ApiError> => {
  const response = await getMutationResponse(mutation, args);
  return response as Api.GraphQL.Response<ForgotPasswordResponse> | Api.ApiError;
};

export default forgotPassword;
