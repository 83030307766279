import React, { useState } from 'react';
import Input from '../../components/input/Text-Input';
import styled from 'styled-components';
import InputPhone from '../input-phone/InputPhone';
import withWindowSize from '../with-window-size/withWindowSize';
import { AppState } from '../../store';
import { FieldWrapper, Label } from '../input/input-components';
import { addContact, fetchContactByNumber } from '../../modules/Contacts/redux/actions';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { closePopup } from '../popup/redux/actions';
import AddNewContactFooter from './AddNewContactFooter';

export const ButtonFooter = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  display: flex;
  height: 40px;
  justify-content: flex-end;
`;

export const FieldsWrapperPadding = styled.div`
  padding: 0 20px;
`;

export const Container = styled.div<{ isMobile: boolean }>`
  height: ${(props) => (props.isMobile ? 'calc(100% - 24px)' : '350px')};
  position: relative;
`;

interface IAddNewContactProps {
  createContact(obj: INewContact): void;
  popupId?: string;
  closePopup(id: string): void;
  me: User.Me;
  actionInProgress: string[];
  linkedContact: LinkedContact;
  findLinkedContact(number: string): void;
}

interface INewContact {
  mobile: string;
  firstName: string;
  lastName: string;
  email: string;
  popupId: string;
  userId: string;
}

const MAX_INPUT_LENGTH = 30;

const AddNewContact = ({
  createContact,
  popupId,
  closePopup,
  me,
  actionInProgress,
  linkedContact,
  findLinkedContact,
}: IAddNewContactProps) => {
  const { isMobile, screenWidth } = withWindowSize();
  const breakView = screenWidth < 750;
  const InputStyle = { width: isMobile ? '100%' : '245px' };
  const filedWrapperStyle = { height: !breakView ? '50px' : '65px' };

  const [phoneValue, setPhoneValue] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');

  const isContactLinked = linkedContact?.id && linkedContact?.number === phoneValue;

  const [emailError, setEmailError] = useState(false);
  const checkEmailError = () => {
    const emailRegex = /\S+@\S+\.\S+/;
    setEmailError(!!(email && !emailRegex.test(email)) && email !== '');
  };

  const mandatoryFields =
    !linkedContact?.id &&
    !(firstName && phoneValue && !emailError && !actionInProgress.find((a) => a === popupId));

  const newContact = linkedContact
    ? {
        popupId,
        mobile: phoneValue,
        firstName: linkedContact.firstName,
        lastName: linkedContact.lastName || '',
        userId: me.id,
      }
    : {
        popupId,
        mobile: phoneValue,
        firstName,
        lastName,
        email,
        userId: me.id,
      };

  const handleEnter = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && !mandatoryFields) {
      createContact(newContact as INewContact);
    }
  };

  return (
    <Container isMobile={isMobile}>
      <FieldsWrapperPadding>
        <FieldWrapper breakViewParent={breakView} style={filedWrapperStyle}>
          <Label>Phone:*</Label>
          <InputPhone
            setValue={(value: string) => setPhoneValue(value)}
            value={phoneValue}
            smallInput={!isMobile}
            style={InputStyle}
            onKeyDown={handleEnter}
            preferredCountries={['rs', 'ch']}
            country="rs"
            onBlur={() => {
              if (phoneValue.length >= 6 && linkedContact?.number !== phoneValue) {
                findLinkedContact(phoneValue);
              }
            }}
          />
        </FieldWrapper>
        <FieldWrapper breakViewParent={breakView} style={filedWrapperStyle}>
          <Label>First name:*</Label>
          <Input
            style={InputStyle}
            small
            onChange={(value) => !isContactLinked && setFirstName(value.target.value)}
            placeholder="First name"
            value={isContactLinked ? linkedContact.firstName : firstName}
            onKeyDown={handleEnter}
            editable={!isContactLinked}
            maxLength={MAX_INPUT_LENGTH}
          />
        </FieldWrapper>
        <FieldWrapper breakViewParent={breakView} style={filedWrapperStyle}>
          <Label>Last name:</Label>
          <Input
            style={InputStyle}
            small
            onChange={(value) => !isContactLinked && setLastName(value.target.value)}
            placeholder="Last name"
            value={isContactLinked ? linkedContact.lastName || '' : lastName}
            onKeyDown={handleEnter}
            editable={!isContactLinked}
            maxLength={MAX_INPUT_LENGTH}
          />
        </FieldWrapper>
        <FieldWrapper breakViewParent={breakView} style={filedWrapperStyle}>
          <Label>Email:</Label>
          <Input
            onChange={(value) => !isContactLinked && setEmail(value.target.value)}
            placeholder="Email"
            style={InputStyle}
            small
            error={emailError}
            onBlur={checkEmailError}
            errorMessage={'Please use valid email format'}
            value={isContactLinked ? 'private' : email}
            onKeyDown={handleEnter}
            editable={!isContactLinked}
          />
        </FieldWrapper>
      </FieldsWrapperPadding>
      <AddNewContactFooter
        isMobile={isMobile}
        popupId={popupId}
        closePopup={closePopup}
        mandatoryFields={mandatoryFields}
        createContact={() => createContact(newContact as INewContact)}
      />
    </Container>
  );
};

const mapStateToProps = (state: AppState) => ({
  me: state.me,
  actionInProgress: state.contacts.actionInProgress,
  linkedContact: state.contacts.linkedContact,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  createContact: (newContact: INewContact) => dispatch(addContact.request(newContact)),
  closePopup: (popupId: string) => dispatch(closePopup(popupId)),
  findLinkedContact: (number: string) => dispatch(fetchContactByNumber.request(number)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddNewContact);
