import { mapContact, mapPublicProfile } from '../contacts/contact-mappers';
import { mapTimeline } from './map-timeline';

export const mapTask = (task: Api.Task): Task => ({
  id: task.id,
  title: task.title,
  description: task.description || '',
  status: task.status,
  archived: task.archived,
  deadline: task.deadline,
  created: task.created,
  completed: task.completed,
  assignee:
    task.assignee.__typename === 'Contact'
      ? mapContact(task.assignee)
      : mapPublicProfile(task.assignee),
  author:
    task.author.__typename === 'Contact' ? mapContact(task.author) : mapPublicProfile(task.author),
  timeline: task.timeline && task.timeline.map((event) => mapTimeline(event)),
});
