import React from 'react';
import { TaskFooter } from './task-details-components';
import ButtonRound from '../../../buttons/ButtonRound';
import { ButtonType } from '../../../buttons/ButtonText';
import { isMobile } from 'react-device-detect';

interface FooterProps {
  setEditState(val: boolean): void;
  submitEditTask(): void;
  openEditAssignee(val: boolean): void;
}
const TaskDetailsFooter = ({ setEditState, submitEditTask, openEditAssignee }: FooterProps) => {
  return (
    <TaskFooter style={{
      bottom: isMobile ? 20 : 0
    }}>
      <ButtonRound
        inverted
        onClick={() => {
          setEditState(false);
          openEditAssignee(false);
        }}
        buttonType={ButtonType.cancel}
      >
        Cancel
      </ButtonRound>
      <ButtonRound
        style={{ width: 96 }}
        onClick={() => {
          submitEditTask();
          setEditState(false);
          openEditAssignee(false);
        }}
        buttonType={ButtonType.confirm}
      >
        Save
      </ButtonRound>
    </TaskFooter>
  );
};

export default TaskDetailsFooter;
