import React from 'react';
import * as colors from '../../../lib/colors';
import { PlusIcon } from '../../icons/Icons';
import styled from 'styled-components';
import { Dispatch } from 'redux';
import AddNewTask from '../../add-new-task-form/AddNewTask';
import AddNewContact from '../../add-new-contact-form/AddNewContact';
import { openPopup } from '../../popup/redux/actions';
import { connect } from 'react-redux';
import { IPopupSettings } from '../../popup/redux/actions';

interface Props {
  color: string;
  isMobile: boolean;
  path: string;
  openPopupWithSettings(path: string, isMobile: boolean): void;
}

interface PropsFromDispatch {
  openPopupWithSettings(path: string, isMobile: boolean): void;
}

interface StyleProps {
  isMobile: boolean;
  path: string;
}

const AddButtonHolder = styled.div<StyleProps>`
  border-radius: 50%;
  cursor: pointer;
  border: 2px solid;
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${(props) => (props.isMobile ? '36px' : '30px')};
  height: ${(props) => (props.isMobile ? '36px' : '30px')};
  border-color: ${(props) =>
    props.path.includes('/tasks')
      ? colors.blue
      : props.path === '/contacts'
      ? colors.red
      : 'transparent'};
  &:hover {
    opacity: 0.8;
  }
  background-color: ${colors.baseDarker};
`;

export const AddButton = ({ color, isMobile, path, openPopupWithSettings }: Props) => (
  <AddButtonHolder
    isMobile={isMobile}
    path={path}
    color={color}
    onClick={() => openPopupWithSettings(path, isMobile)}
  >
    <PlusIcon style={{ width: '11px' }} color={colors.white} />
  </AddButtonHolder>
);

let popupSettings: IPopupSettings = {
  id: '',
  limitToOne: false,
  title: '',
  children: null,
};

const mapDispatchToProps = (dispatch: Dispatch): PropsFromDispatch => ({
  openPopupWithSettings: (path, isMobile) => {
    if (path === '/contacts') {
      const popupId = new Date().toISOString();
      popupSettings = {
        id: popupId,
        limitToOne: isMobile,
        title: 'New contact',
        children: <AddNewContact popupId={popupId} />,
      };
    } else {
      const popupId = new Date().toISOString();
      popupSettings = {
        id: new Date().toISOString(),
        limitToOne: isMobile,
        title: 'New task',
        children: <AddNewTask popupId={popupId} />,
      };
    }
    return dispatch(openPopup(popupSettings));
  },
});
export default connect(null, mapDispatchToProps)(AddButton);
