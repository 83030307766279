import { getMutationResponse } from '../../client';
import { gql } from '@apollo/client';
import errorMapper from '../../../mappers/error-mapper';
import { taskFragment } from 'api/fragments';

const mutation = gql`
  mutation archiveTask($taskId: UUID!) {
    archiveTask(id: $taskId) {
      ...TaskFragment
    }
  }
  ${taskFragment}
`;

const archiveTask = async (args: string): Promise<boolean | Api.ApiError> => {
  const response = await getMutationResponse(mutation, { taskId: args });
  if (response.data !== null && response.data.archiveTask) {
    return response.data.archiveTask;
  }
  return errorMapper(response as Api.GraphQL.Errors);
};

export default archiveTask;
