import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const TaskHolder = styled.div`
  padding: 20px 0px;
  overflow-y: auto;
  background-color: white;
  margin-bottom: 20px;
`;

export const StatusTitle = styled.div`
  font-size: 14px;
  color: #9ba3ad;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 800;
  display: inline-block;
  margin-left: 20px;
`;

export const OpenInNewTab = styled(Link)`
  cursor: pointer;
  display: inline-block;
  margin-left: 10px;
  height: 20px;
`;

export const TaskOptions = styled.div`
  height: 20px;
  display: flex;
  margin-bottom: 20px;
  align-items: center;
`;

export const TaskOptionsHolder = styled.div`
  width: calc(100% - 30px);
  height: 20px;
  display: flex;
  align-items: flex-end;
`;

export const TaskFooter = styled.div`
  display: flex;
  z-index: 4;
  height: 70px;
  width: 100%;
  position: absolute;
  background-color: white;
  justify-content: flex-end;
  align-items: baseline;
  padding: 15px;
  border-top: 1px solid #dadada;
  * {
    margin: 0 7px;
  }
`;
