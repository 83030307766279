import React, { useEffect, useState } from 'react';
import TasksSection from '../tasks-section/TasksSection';
import { connect } from 'react-redux';
import { TaskGridFilter } from '../TaskGrid';
import { AppState } from 'store';
import { Dispatch } from 'redux';
import { fetchActiveTasks } from 'modules/Tasks/redux/actions';
import LoadMoreButton from '../tasks-section/LoadMoreButton';
import { inLast24h, inLast7Days } from '../tasks-section/metrics';
import { EmptyState, EmptyStateText } from 'modules/Contacts/Contacts';
import { sortActiveTasks } from 'lib/sort-tasks';

export interface SortedTasks {
  [key: string]: Task[];
}

interface IActiveGridProps {
  me: User.Me;
  fetchTasks: any;
  loadedAll: boolean;
  sortedTasks: SortedTasks;
  loadingSection: boolean;
  loadingPage: boolean;
  initiallyLoaded: boolean;
}

const ActiveGrid = ({
  me,
  fetchTasks,
  loadedAll,
  sortedTasks,
  loadingSection,
  loadingPage,
  initiallyLoaded,
}: IActiveGridProps) => {
  const [offset, setOffset] = useState(0);

  useEffect(() => {
    if (me?.id && !initiallyLoaded) {
      fetchTasks({
        offset,
        first: 50,
        loadedMore: false,
      });
    }
    // eslint-disable-next-line
  }, [me]);

  const loadMoreTasks = () => {
    const newOffset = offset + 12;
    setOffset(newOffset);
    fetchTasks({
      offset: newOffset,
      first: 50,
      loadedMore: true,
    });
  };

  const isEmptyTaskList =
    sortedTasks.assigned.length === 0 &&
    sortedTasks.next24h.length === 0 &&
    sortedTasks.next7days.length === 0 &&
    sortedTasks.upcoming.length === 0;

  return (
    <div>
      <TasksSection filter={TaskGridFilter.ASSIGNED} taskList={sortedTasks.assigned} />
      <TasksSection filter={TaskGridFilter.NEXT_24H} taskList={sortedTasks.next24h} />
      <TasksSection filter={TaskGridFilter.NEXT_7DAYS} taskList={sortedTasks.next7days} />
      <TasksSection filter={TaskGridFilter.UPCOMING} taskList={sortedTasks.upcoming} />
      {!loadedAll && !loadingPage && <LoadMoreButton onClick={() => loadMoreTasks()} loading={loadingSection} />}
      {!loadingPage && isEmptyTaskList && (
        <EmptyState>
          <EmptyStateText>
            Seems there are no active tasks! Feel free to assign new tasks to your contacts.
          </EmptyStateText>
        </EmptyState>
      )}
    </div>
  );
};

const sortTasks = (taskList: any, tasks: any, id: string) => {
  if (taskList.tasks) {
    Object.keys(taskList.tasks).forEach((key) => {
      if (
        (taskList.tasks[key].status === 'PENDING' ||
          taskList.tasks[key].status === 'IN_PROGRESS') &&
        taskList.tasks[key].assignee.id === id
      ) {
        tasks.assigned.push(taskList.tasks[key]);
      } else if (inLast24h(taskList.tasks[key].deadline)) {
        tasks.next24h.push(taskList.tasks[key]);
      } else if (inLast7Days(taskList.tasks[key].deadline)) {
        tasks.next7days.push(taskList.tasks[key]);
      } else {
        tasks.upcoming.push(taskList.tasks[key]);
      }
    });
  }
  return {
    assigned: sortActiveTasks(tasks.assigned),
    next24h: sortActiveTasks(tasks.next24h),
    next7days: sortActiveTasks(tasks.next7days),
    upcoming: sortActiveTasks(tasks.upcoming),
  };
};

let initTasksData = (): SortedTasks => ({
  assigned: [],
  next24h: [],
  next7days: [],
  upcoming: [],
});

const mapStateToProps = (state: AppState) => ({
  me: state.me,
  loadingPage: state.tasks.activeTasks.loadingPage,
  loadedAll: state.tasks.activeTasks.loadedAll,
  initiallyLoaded: state.tasks.activeTasks.initiallyLoaded,
  loadingSection: state.loadingSection,
  sortedTasks: sortTasks(state.tasks.activeTasks, initTasksData(), state.me.id),
});
const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchTasks: ({ first, offset, loadedMore }: any) =>
    dispatch(fetchActiveTasks.request({ first, offset, loadMore: loadedMore })),
});
export default connect(mapStateToProps, mapDispatchToProps)(ActiveGrid);
