import React, { useMemo, useCallback } from 'react';
import TaskCard from '../../task-card/TaskCard';
import styled from 'styled-components';
import isEmpty from 'lodash/isEmpty';
import { connect } from 'react-redux';
import { AppState } from '../../../../store';
import { Dispatch } from 'redux';
import { fetchSingleTask } from '../../../../modules/Tasks/redux/actions';

const TasksSection = ({ filter, taskList, selectedTaskId, fetchSingleTask, me }: any) => {
  const meMemo = useMemo(() => {
    return me;
  }, [me]);

  const onTaskClick = useCallback((e: Event, info: string) => {
    e.stopPropagation();
    fetchSingleTask(info);
    // eslint-disable-next-line
  }, []);

  return (
    <React.Fragment key={taskList}>
      {taskList && taskList && !isEmpty(taskList) && (
        <Holder>
          <SectionTitle>{filter.title}</SectionTitle>
          <TaskSectionHolder>
            {Object.keys(taskList).map((key) => (
              <TaskCard
                key={key}
                isSelected={selectedTaskId === taskList[key].id}
                onTaskClick={onTaskClick}
                me={meMemo}
                {...taskList[key]}
              />
            ))}
          </TaskSectionHolder>
        </Holder>
      )}
    </React.Fragment>
  );
};
const mapStateToProps = (state: AppState) => ({
  me: state.me,
});
const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchSingleTask: (id: string) => dispatch(fetchSingleTask.request({ id })),
});
export default connect(mapStateToProps, mapDispatchToProps)(TasksSection);

const SectionTitle = styled.div`
  font-size: 13px;
  font-weight: bold;
  text-transform: uppercase;
  color: #405267;
  letter-spacing: 2px;
  margin: 0px 0 10px 10px;
`;
const TaskSectionHolder = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin: 10px 0 30px 0;
`;

const Holder = styled.div``;
