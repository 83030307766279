import amplitude from 'amplitude-js';

const API_KEY: string = process.env.REACT_APP_AMPLITUDE_API_KEY!;

export const signupPagePhoneNumberEvent = () => {
  amplitude.getInstance().init(API_KEY);
  amplitude.getInstance().logEvent('Signup Page Phone Number');
};

export const signupPagePhoneNumberVerificationEvent = () => {
  amplitude.getInstance().init(API_KEY);
  amplitude.getInstance().logEvent('Signup Page Phone Number Verification');
};

export const signupPageNameEvent = () => {
  amplitude.getInstance().init(API_KEY);
  amplitude.getInstance().logEvent('Signup Page Name');
};

export const signupPageLoginDetailsEvent = () => {
  amplitude.getInstance().init(API_KEY);
  amplitude.getInstance().logEvent('Signup Page Login Details');
};

export const userSignedUpEvent = (id: string) => {
  amplitude.getInstance().init(API_KEY, id);
  amplitude.getInstance().logEvent('User Signed Up');
};

export const userSignedInEvent = (id: string) => {
  amplitude.getInstance().init(API_KEY, id);
  amplitude.getInstance().logEvent('User Signed In');
};

export const userLoggedOutEvent = (id: string) => {
  amplitude.getInstance().init(API_KEY, id);
  amplitude.getInstance().logEvent('User Logged Out');
};

export const navigationEvent = (id: string, path: string) => {
  amplitude.getInstance().init(API_KEY, id);
  if (path.includes('/tasks')) {
    amplitude.getInstance().logEvent('Tasks');
  } else if (path.includes('/contacts')) {
    amplitude.getInstance().logEvent('Contacts');
  }
};

export const profileSettingsEvent = (id: string) => {
  amplitude.getInstance().init(API_KEY, id);
  amplitude.getInstance().logEvent('Account Settings Profile');
};

export const notificationsSettingsEvent = (id: string) => {
  amplitude.getInstance().init(API_KEY, id);
  amplitude.getInstance().logEvent('Account Settings Notifications');
};

export const emailVerifiedEvent = (id: string) => {
  amplitude.getInstance().init(API_KEY, id);
  amplitude.getInstance().logEvent('Email Verified');
};

export const emailVerificationSentEvent = (id: string) => {
  amplitude.getInstance().init(API_KEY, id);
  amplitude.getInstance().logEvent('Email Verification Sent');
};

export const taskAddedEvent = (id: string, taskId: string, assigneeId: string) => {
  amplitude.getInstance().init(API_KEY, id);
  amplitude.getInstance().logEvent('Task Created', {
    'Task ID': taskId,
    'Author ID': id,
    'Assignee ID': assigneeId,
    Context: detectContext(),
  });
};
export const taskOpenedEvent = (
  id: string,
  taskId: string,
  assigneeId: string,
  authorId: string,
) => {
  amplitude.getInstance().init(API_KEY, id);
  if (window.location.pathname.includes('/tasks/')) {
    amplitude.getInstance().logEvent('Task Opened', {
      'Task ID': taskId,
      'Author ID': authorId,
      'Assignee ID': assigneeId,
      Context: detectContext(),
    });
  } else {
    amplitude.getInstance().logEvent('Task Expanded', {
      'Task ID': taskId,
      'Author ID': authorId,
      'Assignee ID': assigneeId,
      Context: detectContext(),
    });
  }
};

export const taskAcceptedEvent = (id: string, taskId: string, authorId: string) => {
  amplitude.getInstance().init(API_KEY, id);
  amplitude.getInstance().logEvent('Task Accepted', {
    'Task ID': taskId,
    'Author ID': authorId,
    'Assignee ID': id,
    Context: detectContext(),
  });
};

export const taskRejectedEvent = (id: string, taskId: string, authorId: string) => {
  amplitude.getInstance().init(API_KEY, id);
  amplitude.getInstance().logEvent('Task Rejected', {
    'Task ID': taskId,
    'Author ID': authorId,
    'Assignee ID': id,
    Context: detectContext(),
  });
};

export const taskMarkedAsReadyEvent = (id: string, taskId: string, authorId: string) => {
  amplitude.getInstance().init(API_KEY, id);
  amplitude.getInstance().logEvent('Task Marked Ready', {
    'Task ID': taskId,
    'Author ID': authorId,
    'Assignee ID': id,
    Context: detectContext(),
  });
};

export const taskMarkedAsCompletedEvent = (id: string, taskId: string, assigneeId: string) => {
  amplitude.getInstance().init(API_KEY, id);
  amplitude.getInstance().logEvent('Task Marked Completed', {
    'Task ID': taskId,
    'Author ID': id,
    'Assignee ID': assigneeId,
    Context: detectContext(),
  });
};

export const taskMarkedAsFailedEvent = (id: string, taskId: string, assigneeId: string) => {
  amplitude.getInstance().init(API_KEY, id);
  amplitude.getInstance().logEvent('Task Marked Failed', {
    'Task ID': taskId,
    'Author ID': id,
    'Assignee ID': assigneeId,
    Context: detectContext(),
  });
};

export const taskNaggedEvent = (id: string, taskId: string, assigneeId: string) => {
  amplitude.getInstance().init(API_KEY, id);
  amplitude.getInstance().logEvent('Task Nagged', {
    'Task ID': taskId,
    'Author ID': id,
    'Assignee ID': assigneeId,
    Context: detectContext(),
  });
};

export const taskCommentAddedEvent = (
  id: string,
  taskId: string,
  assigneeId: string,
  authorId: string,
  commentId: string,
  commenter: string,
) => {
  amplitude.getInstance().init(API_KEY, id);
  amplitude.getInstance().logEvent('Task Comment Added', {
    'Task ID': taskId,
    'Author ID': authorId,
    'Assignee ID': assigneeId,
    'Comment ID': commentId,
    Commenter: commenter,
    Context: detectContext(),
  });
};

export const taskEditedEvent = (id: string, taskId: string, assigneeId: string, changes: any) => {
  amplitude.getInstance().init(API_KEY, id);
  amplitude.getInstance().logEvent('Task Edited', {
    'Task ID': taskId,
    'Author ID': id,
    'Assignee ID': assigneeId,
    'Edited Fields': findEditedFields(changes),
  });
};

export const taskReassignedEvent = (
  id: string,
  taskId: string,
  assigneeId: string,
  newAssignee: string,
) => {
  amplitude.getInstance().init(API_KEY, id);
  amplitude.getInstance().logEvent('Task Reassigned', {
    'Task ID': taskId,
    'Author ID': id,
    'Old Assignee ID': assigneeId,
    'New Assignee ID': newAssignee,
    Context: detectContext(),
  });
};

export const contactOpenedEvent = (id: string, contactId: string) => {
  amplitude.getInstance().init(API_KEY, id);
  amplitude.getInstance().logEvent('Contact Opened', {
    'Contact ID': contactId,
  });
};

export const contactsSearchedEvent = (id: string) => {
  amplitude.getInstance().init(API_KEY, id);
  amplitude.getInstance().logEvent('Contacts Searched');
};

export const contacAddedEvent = (id: string, contactId: string) => {
  amplitude.getInstance().init(API_KEY, id);
  amplitude.getInstance().logEvent('Contact Added', {
    'Contact ID': contactId,
    Context: detectContext(),
  });
};

export const profileUpdatedEvent = (changes: any, me: User.Me) => {
  amplitude.getInstance().init(API_KEY, me.id);
  if (changes.firstName) {
    var changesList = ['First Name', 'Last Name', 'Email'];
    if (changes.firstName === me.firstName) {
      changesList = changesList.filter((obj) => obj !== 'First Name');
    }
    if (changes.lastName === me.lastName) {
      changesList = changesList.filter((obj) => obj !== 'Last Name');
    }
    if (changes.email === me.email) {
      changesList = changesList.filter((obj) => obj !== 'Email');
    }
    amplitude.getInstance().logEvent('Profile Updated', {
      Context: detectContext(),
      Changes: changesList,
    });
  } else if (!(changes.mobileNotifications === undefined)) {
    amplitude.getInstance().logEvent('SMS Notifications Updated', {
      'New State': changes.mobileNotifications,
    });
  } else if (!(changes.emailNotifications === undefined)) {
    amplitude.getInstance().logEvent('Email Notifications Updated', {
      'New State': changes.mobileNotifications,
    });
  }
};

const detectContext = () => {
  var context = 'Task Page';
  if (window.location.pathname.includes('/contacts')) {
    context = 'Contacts Page';
  } else if (window.location.pathname.includes('/tasks')) {
    context = 'Tasks Page';
  } else if (window.location.pathname.includes('/account')) {
    context = 'Settings Page';
  }
  return context;
};

const findEditedFields = (changes: any) => {
  var changesList = ['Title', 'Due Date', 'Description'];
  if (!changes.value.title) {
    changesList = changesList.filter((obj) => obj !== 'Title');
  }
  if (!changes.value.deadline) {
    changesList = changesList.filter((obj) => obj !== 'Due Date');
  }
  if (!changes.value.description) {
    changesList = changesList.filter((obj) => obj !== 'Description');
  }
  return changesList;
};
