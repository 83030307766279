import React, { useState } from 'react';
import { MoreIcon } from '../../../icons/Icons';
import { Option } from '../../../header/Header';
import styled from 'styled-components';

const MoreOptions = ({ optionsList }: any) => {
  const [menuOpen, toggleMenu] = useState(false);

  return (
    <MoreOptionsHolder open={menuOpen}>
      <TriggerHolder>
        <Trigger onClick={() => toggleMenu(!menuOpen)} onBlur={() => toggleMenu(false)} />
        <MoreIcon style={{ position: 'absolute', top: 5, left: 0, zIndex: 3 }} />
      </TriggerHolder>
      <ListOptions>
        {optionsList.map((option: any) => (
          <Option style={{ width: '100%' }} key={option.name} onMouseDown={option.cb}>
            {option.name}
          </Option>
        ))}
      </ListOptions>
    </MoreOptionsHolder>
  );
};
export default MoreOptions;

const MoreOptionsHolder = styled.div<{ open: boolean }>`
  overflow: ${(props) => (props.open ? 'visible' : 'hidden')};
  height: 20px;
  width: 30px;
  position: relative;
  margin-right: 10px;
`;

const Trigger = styled.input`
  cursor: pointer;
  width: 30px;
  height: 17px;
  border: none;
  outline: none;
  background-color: transparent;
  position: absolute;
  z-index: 4;
  caret-color: transparent;
`;

const TriggerHolder = styled.div`
  position: relative;
  height: 20px;
  width: 30px;
`;

const ListOptions = styled.div`
  position: absolute;
  right: 0;
  top: 21px;
  width: 120px;
  height: auto;
  background-color: white;
  border-radius: 5px;
  text-align: left;
  z-index: 3;
  box-shadow: 0 0 0 1px hsla(0, 0%, 0%, 0.1), 0 4px 11px hsla(0, 0%, 0%, 0.1);
`;
