import { getQueryResponse } from '../../client';
import { gql } from "@apollo/client";
import { FetchFullContact } from '../contacts/fetch-full-contact';
import { mapMeAsContact } from '../../../mappers/contacts/contact-mappers';
import errorMapper from '../../../mappers/error-mapper';

const queryFetchProfileStats = gql`
  query FetchProfileStats {
    me {
        id
        firstName
        lastName
        email
        mobile
    }
    userStatistic(statsType: PROFILE) {
        status
        count
    }
  }
`;

const fetchMyProfileData = async (): Promise<Contacts.FullContact | Api.ApiError> => {
  const response = await getQueryResponse<FetchFullContact>(queryFetchProfileStats);
  if (response?.data) {
    return mapMeAsContact(response.data);
  }
  return errorMapper(response);
};
export default fetchMyProfileData;
