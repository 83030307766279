import { createAsyncAction, createAction } from 'typesafe-actions';

export const TRIGGER_REASSIGN_TASK = 'TRIGGER_REASSIGN_TASK';

export const ACCEPT_TASK_REQUEST = 'ACCEPT_TASK_REQUEST';
export const ACCEPT_TASK_SUCCESS = 'ACCEPT_TASK_SUCCESS';
export const ACCEPT_TASK_FAILURE = 'ACCEPT_TASK_FAILURE';

export const REJECT_TASK_REQUEST = 'REJECT_TASK_REQUEST';
export const REJECT_TASK_SUCCESS = 'REJECT_TASK_SUCCESS';
export const REJECT_TASK_FAILURE = 'REJECT_TASK_FAILURE';

export const MARK_TASK_FAILED_REQUEST = 'MARK_TASK_FAILED_REQUEST';
export const MARK_TASK_FAILED_SUCCESS = 'MARK_TASK_FAILED_SUCCESS';
export const MARK_TASK_FAILED_FAILURE = 'MARK_TASK_FAILED_FAILURE';

export const MARK_TASK_SUCCESSFUL_REQUEST = 'MARK_TASK_SUCCESSFUL_REQUEST';
export const MARK_TASK_SUCCESSFUL_SUCCESS = 'MARK_TASK_SUCCESSFUL_SUCCESS';
export const MARK_TASK_SUCCESSFUL_FAILURE = 'MARK_TASK_SUCCESSFUL_FAILURE';

export const MARK_TASK_IN_REVIEW_REQUEST = 'MARK_TASK_IN_REVIEW_REQUEST';
export const MARK_TASK_IN_REVIEW_SUCCESS = 'MARK_TASK_IN_REVIEW_SUCCESS';
export const MARK_TASK_IN_REVIEW_FAILURE = 'MARK_TASK_IN_REVIEW_FAILURE';

export const NAG_REQUEST = 'NAG_REQUEST';
export const NAG_SUCCESS = 'NAG_SUCCESS';
export const NAG_FAILURE = 'NAG_FAILURE';

export const TASK_CREATED_EVENT = 'TASK_CREATED_EVENT';
export const TASK_ACCEPTED_EVENT = 'TASK_ACCEPTED_EVENT';
export const TASK_REJECTED_EVENT = 'TASK_REJECTED_EVENT';
export const TASK_MARKED_IN_REVIEW_EVENT = 'TASK_MARKED_IN_REVIEW_EVENT';
export const TASK_MARKED_AS_SUCCESSFUL_EVENT = 'TASK_MARKED_AS_SUCCESSFUL_EVENT';
export const TASK_MARKED_AS_FAILED_EVENT = 'TASK_MARKED_AS_FAILED_EVENT';

export const TASK_UPDATED_EVENT = 'TASK_UPDATED_EVENT';
export const TASK_ASSIGNED_EVENT = 'TASK_ASSIGNED_EVENT';
export const TASK_ASSIGNMENT_REMOVED_EVENT = 'TASK_ASSIGNMENT_REMOVED_EVENT';
export const TASK_DEADLINE_EXPIERED_EVENT = 'TASK_DEADLINE_EXPIERED_EVENT';
export const TASK_ARCHIVED_EVENT = 'TASK_ARCHIVED_EVENT';
export const TASK_DELETED_EVENT = 'TASK_DELETED_EVENT';

export const taskCreatedEvent = createAction(TASK_CREATED_EVENT)<Api.TaskEvent>();

export const taskAcceptedEvent = createAction(TASK_ACCEPTED_EVENT)<Api.TaskEvent>();

export const taskRejectedEvent = createAction(TASK_REJECTED_EVENT)<Api.TaskEvent>();

export const taskMarkedInReviewEvent = createAction(TASK_MARKED_IN_REVIEW_EVENT)<Api.TaskEvent>();

export const taskMarkedAsSuccessfulEvent = createAction(
  TASK_MARKED_AS_SUCCESSFUL_EVENT,
)<Api.TaskEvent>();

export const taskMarkedAsFailedEvent = createAction(TASK_MARKED_AS_FAILED_EVENT)<Api.TaskEvent>();

export const taskUpdatedEvent = createAction(TASK_UPDATED_EVENT)<Api.TaskUpdatedEvent>();

export const taskAssignedEvent = createAction(TASK_ASSIGNED_EVENT)<Api.TaskEvent>();

export const taskAssignmentRemovedEvent = createAction(
  TASK_ASSIGNMENT_REMOVED_EVENT,
)<Api.TaskEvent>();

export const taskDeadlineExpieredEvent = createAction(
  TASK_DEADLINE_EXPIERED_EVENT,
)<Api.TaskEvent>();

export const taskArchivedEvent = createAction(TASK_ARCHIVED_EVENT)<Api.TaskEvent>();

export const taskDeletedEvent = createAction(TASK_DELETED_EVENT)<Api.TaskDeletedEvent>();

export const triggerReassignTask = createAction(TRIGGER_REASSIGN_TASK)<string>();

export const acceptTask = createAsyncAction(
  ACCEPT_TASK_REQUEST,
  ACCEPT_TASK_SUCCESS,
  ACCEPT_TASK_FAILURE,
)<string, Task, { id: string }>();

export const rejectTask = createAsyncAction(
  REJECT_TASK_REQUEST,
  REJECT_TASK_SUCCESS,
  REJECT_TASK_FAILURE,
)<string, Task, { id: string }>();

export const markTaskFailed = createAsyncAction(
  MARK_TASK_FAILED_REQUEST,
  MARK_TASK_FAILED_SUCCESS,
  MARK_TASK_FAILED_FAILURE,
)<{ id: string; assignee: string }, Task, { id: string }>();

export const markTaskSuccessful = createAsyncAction(
  MARK_TASK_SUCCESSFUL_REQUEST,
  MARK_TASK_SUCCESSFUL_SUCCESS,
  MARK_TASK_SUCCESSFUL_FAILURE,
)<{ id: string; assignee: string }, Task, { id: string }>();

export const markTaskInReview = createAsyncAction(
  MARK_TASK_IN_REVIEW_REQUEST,
  MARK_TASK_IN_REVIEW_SUCCESS,
  MARK_TASK_IN_REVIEW_FAILURE,
)<string, Task, void>();

export const nag = createAsyncAction(NAG_REQUEST, NAG_SUCCESS, NAG_FAILURE)<
  { id: string; assignee: string },
  boolean,
  { id: string }
>();

export type TaskCardActions =
  | ReturnType<typeof triggerReassignTask>
  | ReturnType<typeof acceptTask.request>
  | ReturnType<typeof acceptTask.success>
  | ReturnType<typeof acceptTask.failure>
  | ReturnType<typeof rejectTask.request>
  | ReturnType<typeof rejectTask.success>
  | ReturnType<typeof rejectTask.failure>
  | ReturnType<typeof markTaskFailed.request>
  | ReturnType<typeof markTaskFailed.success>
  | ReturnType<typeof markTaskFailed.failure>
  | ReturnType<typeof markTaskSuccessful.request>
  | ReturnType<typeof markTaskSuccessful.success>
  | ReturnType<typeof markTaskSuccessful.failure>
  | ReturnType<typeof markTaskInReview.request>
  | ReturnType<typeof markTaskInReview.success>
  | ReturnType<typeof markTaskInReview.failure>
  | ReturnType<typeof nag.request>
  | ReturnType<typeof nag.success>
  | ReturnType<typeof nag.failure>
  | ReturnType<typeof taskCreatedEvent>
  | ReturnType<typeof taskAcceptedEvent>
  | ReturnType<typeof taskRejectedEvent>
  | ReturnType<typeof taskMarkedInReviewEvent>
  | ReturnType<typeof taskMarkedAsSuccessfulEvent>
  | ReturnType<typeof taskMarkedAsFailedEvent>
  | ReturnType<typeof taskUpdatedEvent>
  | ReturnType<typeof taskAssignedEvent>
  | ReturnType<typeof taskAssignmentRemovedEvent>
  | ReturnType<typeof taskDeadlineExpieredEvent>
  | ReturnType<typeof taskArchivedEvent>
  | ReturnType<typeof taskDeletedEvent>;
