import { getMutationResponse } from '../../client';
import { gql } from '@apollo/client';

interface VerificationEmailResponse {
  resendEmailVerification: boolean;
}

const mutation = gql`
  mutation ResendEmailVerification($email: String!) {
    resendEmailVerification(email: $email)
  }
`;

const resendEmailVerificationLink = async (args: string): Promise<boolean | Api.GraphQL.Errors> => {
  const response = await getMutationResponse(mutation, { email: args });
  if (response.data !== null) {
    const resp = response.data as VerificationEmailResponse;
    return resp.resendEmailVerification;
  }
  return response as Api.GraphQL.Errors;
};

export default resendEmailVerificationLink;
