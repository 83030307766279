import React from 'react';
import styled from 'styled-components';
import * as colors from '../../lib/colors';

interface IMyContactProps {
  me: User.Me;
  selectMe(): void;
  active: boolean;
}
const MyContact = ({ me, selectMe, active }: IMyContactProps) => {
  return (
    <Holder onClick={selectMe} active={active} key={me.id}>
      <Initials>{`${me.firstName.charAt(0)}${me.lastName.charAt(0)}`}</Initials>
      <div>
        {`${me.firstName} ${me.lastName}`}
        <Info>My card</Info>
      </div>
    </Holder>
  );
};
export default MyContact;

const Holder = styled.div<{ active: boolean }>`
  height: 36px;
  padding: 0px 20px 8px 20px;
  font-size: 12px;
  border-bottom: 1px solid ${colors.lightGray};
  cursor: pointer;
  display: flex;
  align-items: center;
  flex-direction: row;
  transition: color 300ms ease;
  color: ${(props) => props.active && colors.blue};
  div {
    color: ${(props) => props.active && colors.blue};
    border-color: ${(props) => props.active && colors.blue};
  }
  &:hover {
    color: ${colors.blue};
  }
  &:hover div {
    color: ${colors.blue};
    border-color: ${colors.blue};
  }
`;

const Initials = styled.div`
  height: 24px;
  width: 24px;
  font-size: 10px;
  border: 1px solid ${colors.baseDarker};
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  background-color: white;
  border-radius: 50%;
  color: ${colors.baseDarker};
  margin-right: 15px;
  transition: color 300ms ease, border-color 300ms ease;
`;

const Info = styled.div`
  font-size: 10px;
  margin-top: 4px;
  color: gray;
`;
