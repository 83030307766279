import {
  ApolloClient,
  DocumentNode,
  InMemoryCache,
  createHttpLink,
  FetchResult,
  ApolloError,
  ApolloLink,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

const getToken = (): string => {
  const state = localStorage.getItem('state');
  const urlToken = new URLSearchParams(window.location.search).get('token');
  if (state === null) {
    return urlToken ? `Bearer ${urlToken}` : '';
  } else {
    const jwtToken = JSON.parse(state).token;
    const accessToken = jwtToken.length > 0 ? jwtToken : urlToken;
    return accessToken ? `Bearer ${accessToken}` : '';
  }
};

const httpLink = createHttpLink({
  uri: '/graphql',
});

const authLink = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
      authorization: getToken() || '',
    },
  };
});

export const client = new ApolloClient({
  link: ApolloLink.from([authLink, httpLink]),
  cache: new InMemoryCache(),
});

const mapApolloError = (error: any): Api.GraphQL.Errors => {
  const apolloError = new ApolloError(error);
  const convertedError: Api.GraphQL.Errors = {
    data: null,
    errors: apolloError.graphQLErrors.map((e) => ({
      message: e.message,
      extensions: {
        code: e.extensions ? e.extensions['code'] : '',
        type: e.extensions ? e.extensions['type'] : '',
      },
    })),
  };
  return convertedError;
};

export const getQueryResponse = async <T>(
  query: DocumentNode,
  variables?: any,
): Promise<Api.GraphQL.Response<T>> => {
  try {
    const response = await client.query({
      query,
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
      variables,
    });
    return response;
  } catch (error) {
    return mapApolloError(error);
  }
};

export const getMutationResponse = async (
  mutation: DocumentNode,
  variables?: any,
): Promise<FetchResult<any, Record<string, any>, Record<string, any>> | Api.GraphQL.Errors> => {
  try {
    const response = await client.mutate({
      mutation,
      variables,
      errorPolicy: 'all',
    });
    return response;
  } catch (error) {
    return mapApolloError(error);
  }
};
