import { getMutationResponse } from '../../client';
import { gql } from '@apollo/client';
import errorMapper from '../../../mappers/error-mapper';

const mutation = gql`
  mutation VerifyEmail($token: String!) {
    verifyEmail(token: $token)
  }
`;

const verifyEmail = async (args: string): Promise<boolean | Api.ApiError> => {
  const response = await getMutationResponse(mutation, { token: args });
  if (response.data) {
    return response.data.verifyEmail;
  }
  return errorMapper(response as Api.GraphQL.Errors);
};

export default verifyEmail;
