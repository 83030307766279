import { getMutationResponse } from '../../client';
import { gql } from '@apollo/client';
import { mapTask } from '../../../mappers/tasks/map-task';
import errorMapper from '../../../mappers/error-mapper';
import { taskCardFragment } from '../../fragments';

const mutation = gql`
  mutation acceptTask($taskId: UUID!) {
    acceptTask(id: $taskId) {
      ...Task
    }
  }
  ${taskCardFragment}
`;

const acceptTask = async (args: string): Promise<Task | Api.ApiError> => {
  const response = await getMutationResponse(mutation, { taskId: args });
  if (response.data !== null) {
    return mapTask(response.data.acceptTask);
  }
  return errorMapper(response as Api.GraphQL.Errors);
};

export default acceptTask;
