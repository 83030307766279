import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import ButtonRound from '../buttons/ButtonRound';
import { ButtonType } from '../buttons/ButtonText';
import AddNewContact from '../add-new-contact-form/AddNewContact';

import { EmptyContacts } from './contact-list-components';
import { ContactListContent } from './ContactListContent';
import { checkForItemsInList } from './ContactList';
import { ContactListName } from './ContactListName';

export const ContactListFrame = (
  searchValue: string,
  contactList: any,
  isMobile: boolean,
  selectContactId: Function,
  openContactPopup: any,
  searchedContacts?: Contacts.ContactInList[],
  activeId?: string,
) => {
  const popupId = uuidv4();
  const popupSettings = {
    limitToOne: isMobile,
    title: 'New contact',
    id: popupId,
    children: <AddNewContact popupId={popupId} />,
  };
  if (searchValue && searchedContacts && searchedContacts.length) {
    return ContactListName(searchedContacts, isMobile, selectContactId, activeId);
  } else if (searchValue && (!searchedContacts || (searchedContacts && !searchedContacts.length))) {
    return (
      <EmptyContacts>
        <p>No results</p>
        <ButtonRound
          inverted
          onClick={() => openContactPopup(popupSettings)}
          buttonType={ButtonType.confirm}
        >
          Add contact
        </ButtonRound>
      </EmptyContacts>
    );
  } else if (!searchValue && checkForItemsInList(contactList)) {
    return ContactListContent(contactList, selectContactId, isMobile, activeId);
  } else {
    return (
      <EmptyContacts>
        <p>You have no contacts</p>
        <ButtonRound
          inverted
          onClick={() => openContactPopup(popupSettings)}
          buttonType={ButtonType.confirm}
        >
          Add contact
        </ButtonRound>
      </EmptyContacts>
    );
  }
};
