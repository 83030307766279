import React, { useState, useRef, useEffect, ClipboardEvent } from 'react';
import styled from 'styled-components';
import { ErrorFormat, ErrorMessage, InputComponent } from '../input/Text-Input';
import ButtonRound from '../buttons/ButtonRound';
import ButtonText from '../buttons/ButtonText';
import withWindowSize from '../with-window-size/withWindowSize';
import { ToastContainer } from 'react-toastify';
import '../../App.styles.scss';
import { NotificationCloseIcon } from '../toast-notifications/Notifications';

interface IVerifyPhone {
  onVerify(code: string): void;
  resendCode(): void;
  serverError: string;
}

export const checkCharCode = (evt: any) => {
  const codes = ['Shift', 'Alt', 'Meta', 'Control', 'CapsLock', 'Escape', 'Tab', 'Enter', 'Clear'];
  if (
    codes.indexOf(evt.target.value) > -1 ||
    evt.ctrlKey ||
    evt.shiftKey ||
    evt.altKey ||
    evt.metaKey
  ) {
    evt.preventDefault();
    return;
  } else {
    const validkeys = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0'];
    return validkeys.indexOf(evt.target.value) >= 0;
  }
};

export const validNumEvents = Array.from({ length: 10 }, (_, i) => i.toString());

const errorComp = (verifyErrorMessage: string) => (
  <ErrorMessage style={{ height: 16, marginRight: 10, marginTop: 8 }}>
    <ErrorFormat small={false}>{verifyErrorMessage}</ErrorFormat>
  </ErrorMessage>
);

const VerifyCode = ({ onVerify, serverError, resendCode }: IVerifyPhone) => {
  const { isMobile } = withWindowSize();
  const [verifyErrorMessage, setVerifyError] = useState(serverError);

  const [code1, changeCode1] = useState('');
  const [code2, changeCode2] = useState('');
  const [code3, changeCode3] = useState('');
  const [code4, changeCode4] = useState('');

  const field1 = useRef();
  const field2 = useRef();
  const field3 = useRef();
  const field4 = useRef();

  useEffect(() => {
    setVerifyError(serverError);
  }, [serverError]);

  const handlePasteAnywhere = (event: Event | ClipboardEvent) => {
    if ('clipboardData' in event) {
      const pastedString = event.clipboardData?.getData('text');
      if (Number(pastedString)) {
        changeCode1(pastedString?.charAt(0) || '');
        changeCode2(pastedString?.charAt(1) || '');
        changeCode3(pastedString?.charAt(2) || '');
        changeCode4(pastedString?.charAt(3) || '');
      } else {
        return;
      }
    }
  };

  useEffect(() => {
    window.addEventListener('paste', handlePasteAnywhere);

    return () => window.removeEventListener('paste', handlePasteAnywhere);
  }, []);

  const setFullCode = () => {
    const code = `${code1}${code2}${code3}${code4}`;
    onVerify(code);
  };

  const updateField1 = (e: React.ChangeEvent<HTMLInputElement>) => {
    setVerifyError('');
    if (e.target.value === '') {
      changeCode1('');
      return;
    } else if (checkCharCode(e)) {
      changeCode1(e.target.value);
      // @ts-ignore - check obj undefined
      field2.current.focus();
    }
  };

  const updateField2 = (e: React.ChangeEvent<HTMLInputElement>) => {
    setVerifyError('');
    if (e.target.value === '' && code2) {
      changeCode2('');
      return;
    } else if (e.target.value === '' && !code2) {
      // @ts-ignore - check obj undefined
      field1.current.focus();
      return;
    } else if (checkCharCode(e)) {
      changeCode2(e.target.value);
      // @ts-ignore - check obj undefined
      field3.current.focus();
    }
  };

  const updateField3 = (e: React.ChangeEvent<HTMLInputElement>) => {
    setVerifyError('');
    if (e.target.value === '' && code3) {
      changeCode3('');
      return;
    } else if (e.target.value === '' && !code3) {
      // @ts-ignore - check obj undefined
      field2.current.focus();
      return;
    } else if (checkCharCode(e)) {
      changeCode3(e.target.value);
      // @ts-ignore - check obj undefined
      field4.current.focus();
    }
  };

  const updateField4 = (e: React.ChangeEvent<HTMLInputElement>) => {
    setVerifyError('');
    if (e.target.value === '' && code4) {
      changeCode4('');
      return;
    } else if (e.target.value === '' && !code4) {
      // @ts-ignore - check obj undefined
      field3.current.focus();
      return;
    } else if (checkCharCode(e)) {
      changeCode4(e.target.value);
    } else if (e.target.value === 'Enter') {
      setFullCode();
    }
  };

  return (
    <>
      <FlexHolder isMobile={isMobile}>
        <InputsHolder>
          <CodeInput
            error={verifyErrorMessage}
            ref={field1}
            value={code1}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateField1(e)}
          />
          <CodeInput
            error={verifyErrorMessage}
            ref={field2}
            value={code2}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateField2(e)}
          />
          <CodeInput
            error={verifyErrorMessage}
            ref={field3}
            value={code3}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateField3(e)}
          />
          <CodeInput
            error={verifyErrorMessage}
            ref={field4}
            value={code4}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateField4(e)}
          />
        </InputsHolder>

        {isMobile && errorComp(verifyErrorMessage)}

        <ButtonRound
          disabled={!code1 || !code2 || !code3 || !code4}
          style={isMobile ? { margin: '30px 0 0 0', width: '100%' } : { margin: '0 0 0 20px' }}
          onClick={() => setFullCode()}
        >
          Verify
        </ButtonRound>
      </FlexHolder>

      {!isMobile && errorComp(verifyErrorMessage)}

      <div>
        <ButtonText style={{ width: '100px', marginTop: isMobile ? 30 : 10 }} onClick={resendCode}>
          resend code
        </ButtonText>
        <ToastContainer
          autoClose={6000}
          hideProgressBar
          closeButton={<NotificationCloseIcon />}
          toastClassName={'custom-toast'}
          position={'bottom-left'}
          style={{ zIndex: 200, bottom: 10 }}
        />
      </div>
    </>
  );
};
export default VerifyCode;
const InputsHolder = styled.div`
  height: 36px;
  display: flex;
  flex-direction: row;
`;
const CodeInput = styled(InputComponent as any)`
  width: 36px;
  border-radius: 8px;
  margin-right: 10px;
  font-size: 16px;
`;
const FlexHolder = styled.div<{ isMobile: boolean }>`
  display: flex;
  flex-direction: ${(props) => (props.isMobile ? 'column' : 'row')};
  justify-content: center;
  align-items: center;
`;
