import { getQueryResponse } from '../../client';
import { gql } from "@apollo/client";
import { taskFragment } from '../../fragments';
import { mapTask } from '../../../mappers/tasks/map-task';
import errorMapper from '../../../mappers/error-mapper';

interface Response {
  task: Api.Task | null;
}

const taskQuery = gql`
  query FetchTask($taskId: UUID!) {
    task(id: $taskId) {
      ...TaskFragment
    }
  }${taskFragment}
`;

const fetchTask = async (args: string): Promise<Task | null | Api.ApiError> => {
  const response = await getQueryResponse<Response>(taskQuery, { taskId: args });
  if (response?.data) {
    const task = response.data.task as Api.Task;
    return task ? mapTask(task) : null;
  }
  return errorMapper(response);
};

export default fetchTask;
