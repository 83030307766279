import React from 'react';
import ButtonText, { ButtonType } from '../buttons/ButtonText';
import { Divider } from '../task-components/task-card/task-actions/TaskActions';
import ButtonRound from '../buttons/ButtonRound';
import { ButtonFooter } from './AddNewContact';

interface AddNewContactFooterProps {
  isMobile: boolean;
  popupId?: string;
  closePopup(id: string): void;
  mandatoryFields: boolean;
  createContact(): void;
}

const AddNewContactFooter = ({
  isMobile,
  popupId,
  closePopup,
  mandatoryFields,
  createContact,
}: AddNewContactFooterProps) => {
  return (
    <>
      {isMobile ? (
        <ButtonFooter>
          {popupId && (
            <ButtonText onClick={() => closePopup(popupId)} buttonType={ButtonType.cancel}>
              cancel
            </ButtonText>
          )}
          <Divider />
          <ButtonText
            disabled={mandatoryFields}
            onClick={createContact}
            buttonType={ButtonType.confirm}
          >
            Create
          </ButtonText>
        </ButtonFooter>
      ) : (
        <ButtonFooter style={{ paddingRight: 20 }}>
          {popupId && (
            <ButtonRound
              style={{ marginRight: '15px' }}
              inverted
              buttonType={ButtonType.cancel}
              onClick={() => closePopup(popupId)}
            >
              cancel
            </ButtonRound>
          )}
          <ButtonRound
            disabled={mandatoryFields}
            buttonType={ButtonType.confirm}
            onClick={createContact}
          >
            Create
          </ButtonRound>
        </ButtonFooter>
      )}
    </>
  );
};

export default AddNewContactFooter;
