import { getMutationResponse } from '../../client';
import { gql } from '@apollo/client';

interface RegisterInput {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  mobile: string;
  code: string;
}

export type RegisterResponse = {
  register: {
    id: string;
  };
};

const mutation = gql`
  mutation Register($input: RegisterInput!) {
    register(input: $input) {
      id
    }
  }
`;

const register = async (
  args: RegisterInput,
): Promise<Api.GraphQL.Response<RegisterResponse> | Api.ApiError> => {
  const response = await getMutationResponse(mutation, {
    input: { ...args, mobile: `+${args.mobile}` },
  });
  return response as Api.GraphQL.Response<RegisterResponse> | Api.ApiError;
};

export default register;
