import React from 'react';
import { getTaskStatusColor } from '../get-task-options';
import styled from 'styled-components';

interface IStatusIndicatorProps {
  status: TaskStatus;
  isCard?: boolean;
  iAmAuthor: boolean;
  archived?: boolean;
  style?: React.CSSProperties;
}

const StatusLine = styled.div`
  width: ${(props: IStatusIndicatorProps) => (props.isCard ? '7px' : '100%')};
  height: ${(props: IStatusIndicatorProps) => (props.isCard ? '100%' : '5px')};
  border-radius: ${(props: IStatusIndicatorProps) => (props.isCard ? '4px 0 0 4px' : '0')};
  background-color: ${(props: IStatusIndicatorProps) =>
    getTaskStatusColor(props.status, props.iAmAuthor, !!props.archived)};
  position: absolute;
  left: 0;
  top: 0;
`;
const StatusIndicator = (props: IStatusIndicatorProps) => <StatusLine {...props} />;
export default StatusIndicator;
