import { format } from 'date-fns';

const DATE_FORMAT = 'yyyy-MM-dd';

export const checkDeadline = (
  time: any,
  dateValue: Date,
  setTimeError: (value: boolean) => void,
) => {
  const isToday = format(new Date(), DATE_FORMAT) === format(dateValue, DATE_FORMAT);
  const currentHour = format(new Date(), 'HH');
  const currentMinute = format(new Date(), 'm');
  if (isToday) {
    if (Number(currentHour) > Number(time.hours === '00' ? '24' : time.hours)) {
      setTimeError(true);
    } else if (
      Number(currentHour) === Number(time.hours) &&
      (Number(currentMinute) >= Number(time.minutes) ||
        Number(currentMinute) + 30 >= Number(time.minutes))
    ) {
      setTimeError(true);
    } else {
      setTimeError(false);
    }
  } else {
    setTimeError(false);
  }
};

export const createDateForTask = (date: Date, timeObj: { hours: string; minutes: string }) => {
  const dateResult = `${format(date, DATE_FORMAT)}T`;
  const dateWithHours = `${dateResult}${timeObj.hours}:${timeObj.minutes}`;

  return new Date(dateWithHours);
};
