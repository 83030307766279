import { AppEpic } from '../../../../store/epics';
import { ofType } from 'redux-observable';
import {
  ACCEPT_TASK_REQUEST,
  acceptTask,
  MARK_TASK_FAILED_REQUEST,
  MARK_TASK_IN_REVIEW_REQUEST,
  MARK_TASK_SUCCESSFUL_REQUEST,
  markTaskFailed,
  markTaskInReview,
  markTaskSuccessful,
  nag,
  NAG_REQUEST,
  REJECT_TASK_REQUEST,
  rejectTask,
  TRIGGER_REASSIGN_TASK,
} from './actions';
import { mergeMap } from 'rxjs/operators';
import { from, merge, of } from 'rxjs';
import { fetchSingleTask } from '../actions';
import api from '../../../../api';
import { isApiError } from '../../../Contacts/redux/epics';
import { toast } from 'react-toastify';
import {
  taskAcceptedEvent,
  taskMarkedAsCompletedEvent,
  taskMarkedAsFailedEvent,
  taskMarkedAsReadyEvent,
  taskNaggedEvent,
  taskRejectedEvent,
} from 'api/amplitude/events';

export const triggerReassignTaskEpic: AppEpic = (action$) =>
  action$.pipe(
    ofType(TRIGGER_REASSIGN_TASK),
    mergeMap((action) => {
      return of(
        fetchSingleTask.request({
          id: action.payload,
          triggerReassign: true,
        }),
      );
    }),
  );

export const acceptTaskEpic: AppEpic = (action$, state$) =>
  action$.pipe(
    ofType(ACCEPT_TASK_REQUEST),
    mergeMap((action) => {
      const id = action.payload;
      const userId = state$.value.me.id;
      return from(api.acceptTask(action.payload)).pipe(
        mergeMap((response) => {
          if (isApiError(response)) {
            toast(response.message, { type: 'error' });
            return of(acceptTask.failure({ id }));
          } else {
            toast('Task accepted.', { type: 'success' });
            taskAcceptedEvent(userId, id, response.author.id);
            return merge(of(acceptTask.success(response)));
          }
        }),
      );
    }),
  );

export const rejectTaskEpic: AppEpic = (action$, state$) =>
  action$.pipe(
    ofType(REJECT_TASK_REQUEST),
    mergeMap((action) => {
      const id = action.payload;
      const userId = state$.value.me.id;
      return from(api.rejectTask(action.payload)).pipe(
        mergeMap((response) => {
          if (isApiError(response)) {
            toast(response.message, { type: 'error' });
            return of(rejectTask.failure({ id }));
          } else {
            toast('Task rejected.', { type: 'warning' });
            taskRejectedEvent(userId, id, response.author.id);
            return merge(of(rejectTask.success(response)));
          }
        }),
      );
    }),
  );

export const markTaskInReviewEpic: AppEpic = (action$, state$) =>
  action$.pipe(
    ofType(MARK_TASK_IN_REVIEW_REQUEST),
    mergeMap((action) => {
      const id = action.payload;
      const userId = state$.value.me.id;
      return from(api.markInReview(action.payload)).pipe(
        mergeMap((response) => {
          if (isApiError(response)) {
            toast(response.message, { type: 'error' });
            return of(markTaskInReview.failure());
          } else {
            toast('Task submitted for review.', { type: 'success' });
            taskMarkedAsReadyEvent(userId, id, response.author.id);
            return merge(of(markTaskInReview.success(response)));
          }
        }),
      );
    }),
  );

export const markTaskSuccessfulEpic: AppEpic = (action$, state$) =>
  action$.pipe(
    ofType(MARK_TASK_SUCCESSFUL_REQUEST),
    mergeMap((action) => {
      const id = action.payload.id;
      const assigneeId = action.payload.assignee;
      const userId = state$.value.me.id;
      return from(api.markSuccessful(id)).pipe(
        mergeMap((response) => {
          if (isApiError(response)) {
            toast(response.message, { type: 'error' });
            return of(markTaskSuccessful.failure({ id }));
          } else {
            toast('Task set as successful.', { type: 'success' });
            taskMarkedAsCompletedEvent(userId, id, assigneeId);
            return merge(of(markTaskSuccessful.success(response)));
          }
        }),
      );
    }),
  );

export const markTaskFailedEpic: AppEpic = (action$, state$) =>
  action$.pipe(
    ofType(MARK_TASK_FAILED_REQUEST),
    mergeMap((action) => {
      const id = action.payload.id;
      const assigneeId = action.payload.assignee;
      const userId = state$.value.me.id;
      return from(api.markFailed(action.payload.id)).pipe(
        mergeMap((response) => {
          if (isApiError(response)) {
            toast(response.message, { type: 'error' });
            return of(markTaskFailed.failure({ id }));
          } else {
            toast('Task set as failed.', { type: 'warning' });
            taskMarkedAsFailedEvent(userId, id, assigneeId);
            return merge(of(markTaskFailed.success(response)));
          }
        }),
      );
    }),
  );

export const nagEpic: AppEpic = (action$, state$) =>
  action$.pipe(
    ofType(NAG_REQUEST),
    mergeMap((action) => {
      const id = action.payload.id;
      const assignee = action.payload.id;
      const userId = state$.value.me.id;
      return from(api.nag(id)).pipe(
        mergeMap((response) => {
          if (isApiError(response)) {
            toast(response.message, { type: 'error' });
            return of(nag.failure({ id }));
          } else if (response) {
            toast('Nag nag.', { type: 'success' });
            taskNaggedEvent(userId, id, assignee);
            return merge(of(nag.success(response)));
          } else {
            return of(nag.failure({ id }));
          }
        }),
      );
    }),
  );
