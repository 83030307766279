import { AppEpic } from '../../../../store/epics';
import { ofType } from 'redux-observable';
import { mergeMap } from 'rxjs/operators';
import { from, of, merge } from 'rxjs';
import api from '../../../../api';
import { isApiError } from '../epics';
import { FETCH_CONTACT_TASKS_REQUEST, fetchContactTasks, noMoreContactTasks } from './actions';
import isEmpty from 'lodash/isEmpty';

export const fetchContactTasksEpic: AppEpic = (action$, state$) =>
  action$.pipe(
    ofType(FETCH_CONTACT_TASKS_REQUEST),
    mergeMap((action) => {
      const { filter, id, first } = action.payload;
      const userId = state$.value.me.id;
      const request = id !== userId
      ? api.fetchContactTasks(action.payload)
      : api.fetchTasksByFilter(filter, action.payload);
      return from(request).pipe(
        mergeMap((response) => {
          if (isApiError(response)) {
            return of(fetchContactTasks.failure());
          } else {
            if (isEmpty(response) || Object.keys(response).length < first) {
              return merge(
                of(noMoreContactTasks(filter)),
                of(fetchContactTasks.success({ tasks: response, filter, id })),
              );
            } else {
              return of(fetchContactTasks.success({ tasks: response, filter, id }));
            }
          }
        }),
      );
    }),
  );
