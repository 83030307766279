import { getMutationResponse } from '../../client';
import { gql } from '@apollo/client';
import { mapContact } from '../../../mappers/contacts/contact-mappers';
import errorMapper from '../../../mappers/error-mapper';

interface IUpdateContactArguments {
  id: string;
  value: {
    firstName?: string;
    lastName?: string;
    email?: string;
  };
}

const mutation = gql`
  mutation updateAssignee(
    $assigneeId: UUID!
    $firstName: String
    $lastName: String
    $email: String
  ) {
    updateAssignee(
      assigneeId: $assigneeId
      firstName: $firstName
      lastName: $lastName
      email: $email
    ) {
      kind: __typename
      ... on Contact {
        id
        lastName
        firstName
        numberDetails {
          lastName
          firstName
          email
        }
      }
    }
  }
`;

type EditContactResponse = {
  updateAssignee: Api.Contacts.ContactRaw;
};

const editContact = async (
  args: IUpdateContactArguments,
): Promise<Contacts.ContactInList | Api.ApiError> => {
  const response = await getMutationResponse(mutation, { ...args.value, assigneeId: args.id });
  if (response.data) {
    const editContactResponse = response.data as EditContactResponse;
    return mapContact(editContactResponse.updateAssignee);
  }
  return errorMapper(response as Api.GraphQL.Errors);
};

export default editContact;
