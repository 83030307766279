import { getMutationResponse } from '../../client';
import { gql } from '@apollo/client';
import { assigneeFragment } from '../../fragments';
import { mapContact } from '../../../mappers/contacts/contact-mappers';
import errorMapper from '../../../mappers/error-mapper';

interface CreateAssigneeInput {
  firstName: string;
  mobile: string;
  lastName?: string;
  email?: string;
}
const mutation = gql`
  mutation CreateAssignee($input: CreateAssigneeInput!) {
    createAssignee(input: $input) {
      ...Assignee
    }
  }
  ${assigneeFragment}
`;

const createContact = async (
  args: CreateAssigneeInput,
): Promise<Contacts.ContactInList | Api.ApiError> => {
  let input: CreateAssigneeInput = {
    firstName: args.firstName,
    mobile: `+${args.mobile}`,
  };
  if (args.email && args.email?.length > 0) {
    input = { ...input, email: args.email };
  }
  if (args.lastName && args.lastName?.length > 0) {
    input = { ...input, lastName: args.lastName };
  }
  const response = await getMutationResponse(mutation, { input });
  if (response.data) {
    return mapContact(response.data.createAssignee);
  }
  return errorMapper(response as Api.GraphQL.Errors);
};

export default createContact;
