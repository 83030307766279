import React from 'react';
import { Routes, Route } from 'react-router';
import { AppState } from 'store';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { useLocation } from 'react-router';

// modules
import routes from '../../../lib/routes';
import EmailSent from '../EmailSent/EmailSent';
import ForgotPassword from '../ForgotPassword/ForgotPassword';
import LoggedOut404 from '../LoggedOut404/LoggedOut404';
import Login from '../Login/Login';
import PasswordChanged from '../PasswordChanged/PasswordChanged';
import ResetPassword from '../ResetPassword/ResetPassword';
import Register from '../Register/Register';
import VerifyEmail from '../VerifyEmailConfirmation/VerifyEmailConfirmation';

import '../../../App.styles.scss';
import SingleTaskPage from '../../Tasks/SingleTask';

import 'react-toastify/dist/ReactToastify.css';

interface LoggedOutSwitchProps {
  token: string;
  navigateTo(path: string): ClickHandler;
}

const LoggedOutSwitch = ({ token, navigateTo }: LoggedOutSwitchProps) => {
  const location = useLocation();
  const url = window.location;
  const tokenUrlParams = new URLSearchParams(url.search).get('token');

  return (
    <Routes location={location}>
      <Route path={routes.landing} element={<Register />} />
      <Route
        path={routes.login}
        element={
          <Login
            onForgotPasswordClick={navigateTo(routes.forgotPassword)}
            onSignUpClick={navigateTo(routes.landing)}
          />
        }
      />
      <Route path={routes.forgotPassword} element={<ForgotPassword />} />
      <Route path={routes.emailSent} element={<EmailSent />} />
      <Route
        path={routes.passwordChanged}
        element={<PasswordChanged onLogInClick={navigateTo(routes.login)} />}
      />
      <Route path={routes.resetPassword} element={<ResetPassword token={tokenUrlParams || ''} />} />
      <Route
        path={routes.verifyEmail}
        element={<VerifyEmail onLogInClick={navigateTo(routes.login)} />}
      />
      <Route
        path={`${routes.tasks}/:id`}
        element={
          tokenUrlParams || token ? (
            <SingleTaskPage />
          ) : (
            <Login
              onForgotPasswordClick={navigateTo(routes.forgotPassword)}
              onSignUpClick={navigateTo(routes.landing)}
            />
          )
        }
      />
      <Route
        path={routes.tasks}
        element={
          <Login
            onForgotPasswordClick={navigateTo(routes.forgotPassword)}
            onSignUpClick={navigateTo(routes.landing)}
          />
        }
      />
      <Route element={<LoggedOut404 onGoHomeClick={navigateTo(routes.landing)} />} />
    </Routes>
  );
};

const mapStateToProps = (state: AppState) => ({
  token: state.token,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(LoggedOutSwitch);
