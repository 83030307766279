import React from 'react';
import ButtonRound from '../../../components/buttons/ButtonRound';
import CenteringContainer from '../../../components/centering-container/CenteringContainer';
import { CenteredParagraph, Header, Superheader } from '../../Shared';

interface Props {
  onGoHomeClick: ClickHandler;
}

const LoggedOut404 = ({ onGoHomeClick }: Props) => (
  <CenteringContainer>
    <Superheader>Error 404</Superheader>
    <Header>Oops!</Header>
    <CenteredParagraph>We can't seem to find the page you're looking for!</CenteredParagraph>
    <ButtonRound onClick={onGoHomeClick}>Go home</ButtonRound>
  </CenteringContainer>
);

export default LoggedOut404;
