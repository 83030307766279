interface EditTaskData {
  title: string;
  deadline: string;
  assigneeId: string;
  description: string;
}
const reducer = (state: EditTaskData, action: any) => {
  if (action) {
    return {
      ...state,
      ...action,
    };
  }
  return state;
};
export default reducer;
