import { getMutationResponse } from '../../client';
import { gql } from '@apollo/client';
import errorMapper from '../../../mappers/error-mapper';

const mutation = gql`
  mutation removeAssignee($id: UUID!) {
    removeAssignee(id: $id)
  }
`;

const deleteAssignee = async (args: string): Promise<boolean | Api.ApiError> => {
  const response = await getMutationResponse(mutation, { id: args });
  if (response.data) {
    return true;
  }
  return errorMapper(response as Api.GraphQL.Errors);
};

export default deleteAssignee;
