import { createAsyncAction, createAction } from 'typesafe-actions';
import { IContactProfileSettings } from 'components/contact-detail/ContactDetail';

export const UPDATE_USER_SETTINGS_REQUEST = 'UPDATE_USER_SETTINGS_REQUEST';

export const UPDATE_USER_SETTINGS_SUCCESS = 'UPDATE_USER_SETTINGS_SUCCESS';

export const UPDATE_USER_SETTINGS_FAILURE = 'UPDATE_USER_SETTINGS_FAILURE';

export const FETCH_PROFILE_STATS_REQUEST = 'FETCH_PROFILE_STATS_REQUEST';

export const FETCH_PROFILE_STATS_SUCCESS = 'FETCH_PROFILE_STATS_SUCCESS';

export const FETCH_PROFILE_STATS_FAILURE = 'FETCH_PROFILE_STATS_FAILURE';

export const PROFILE_UPDATED_EVENT = 'PROFILE_UPDATED_EVENT';

export const PREFERENCE_UPDATED_EVENT = 'PREFERENCE_UPDATED_EVENT';

interface IProfileStats {
  userStatistic: Contacts.TaskStats[];
  countOfSMSMessages: number;
}

export const updateUserSettings = createAsyncAction(
  UPDATE_USER_SETTINGS_REQUEST,
  UPDATE_USER_SETTINGS_SUCCESS,
  UPDATE_USER_SETTINGS_FAILURE,
)<IContactProfileSettings, any, never>();

export const fetchProfileStats = createAsyncAction(
  FETCH_PROFILE_STATS_REQUEST,
  FETCH_PROFILE_STATS_SUCCESS,
  FETCH_PROFILE_STATS_FAILURE,
)<void, IProfileStats, never>();

export const profileUpdatedEvent = createAction(PROFILE_UPDATED_EVENT)<Api.ProfileUpdatedNotification>();

export const preferenceUpdatedEvent = createAction(PREFERENCE_UPDATED_EVENT)<Api.PreferenceUpdatedNotification>();

export type UserSettingsActions =
  | ReturnType<typeof updateUserSettings.request>
  | ReturnType<typeof updateUserSettings.success>
  | ReturnType<typeof updateUserSettings.failure>
  | ReturnType<typeof fetchProfileStats.request>
  | ReturnType<typeof fetchProfileStats.success>
  | ReturnType<typeof fetchProfileStats.failure>
  | ReturnType<typeof profileUpdatedEvent>
  | ReturnType<typeof preferenceUpdatedEvent>;
