const routes = {
  emailSent: '/email-sent',
  forgotPassword: '/forgot-password',
  landing: '/',
  login: '/login',
  passwordChanged: '/password-changed',
  resetPassword: '/reset-password',
  signup: '/signup',
  verifyEmail: '/verify-email',
  verifyYourEmail: '/verify-your-email',

  account: '/account',
  contacts: '/contacts',
  tasks: '/tasks',
};

export default routes;
