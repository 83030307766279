import React, { useState, useReducer } from 'react';
import styled from 'styled-components';
import * as colors from '../../../lib/colors';
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import Step4 from './Step4';

export interface IUserRegisterData {
  mobile: string;
  code: string;
  firstName: string;
  lastName: string;
  email: string;
  password: string;
}

const reducer = (state: IUserRegisterData, action: any) => {
  if (action) {
    return {
      ...state,
      ...action,
    };
  }
  return state;
};

const initialState: IUserRegisterData = {
  mobile: '',
  code: '',
  firstName: '',
  lastName: '',
  email: '',
  password: '',
};

const Register = () => {
  const [userData, setUserData] = useReducer(reducer, initialState);
  const [activeStep, setActiveStep] = useState(0);
  const leftVisible = '0';
  const leftNext = '100%';
  const leftHidden = '-100%';

  const getLeftPosition = (step: number): React.CSSProperties => {
    if (step === activeStep) {
      return { left: leftVisible };
    } else if (step < activeStep) {
      return { left: leftHidden };
    } else {
      return { left: leftNext };
    }
  };

  return (
    <RegisterContainer>
      <Step1
        getLeftPosition={getLeftPosition}
        setActiveStep={setActiveStep}
        setUserData={setUserData}
      />

      <Step2
        getLeftPosition={getLeftPosition}
        setActiveStep={setActiveStep}
        setUserData={setUserData}
        userData={userData}
      />

      <Step3
        getLeftPosition={getLeftPosition}
        setActiveStep={setActiveStep}
        setUserData={setUserData}
      />

      <Step4 getLeftPosition={getLeftPosition} setUserData={setUserData} userData={userData} />
    </RegisterContainer>
  );
};
export default Register;

export const RegisterContainer = styled.div`
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
`;

export const Step = styled.div<{ style: React.CSSProperties }>`
  padding: 0 20px 50px 20px;
  text-align: center;
  position: absolute;

  width: 100%;
  transition: all 600ms ease;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const LargeTitle = styled.div`
  color: ${colors.baseDarker};
  font-size: 42px;
  font-weight: 900;
  margin-bottom: 30px;
`;

export const ThinTitle = styled.div`
  text-transform: uppercase;
  font-size: 16px;
  font-weight: light;
  letter-spacing: 2.08px;
  margin-bottom: 30px;
`;

export const InputHolder = styled.div<{ isMobile: boolean }>`
  height: ${(props) => (props.isMobile ? '100%' : '40px')};
  display: flex;
  flex-direction: ${(props) => (props.isMobile ? 'column' : 'row')};
  align-items: center;
  justify-content: center;
`;
