import { LOG_IN_SUCCESS } from '../../modules/SignUp&Login/Login/redux/actions';
import { LOG_OUT } from '../../modules/SignUp&Login/Login/redux/actions';
import { AppAction } from '../actions';

type State = string;

const initialState: State = '';

const reducer = (state: State | undefined = initialState, action: AppAction): State => {
  switch (action.type) {
    case LOG_IN_SUCCESS:
      return action.payload;
    case LOG_OUT:
      return "";
    default:
      return state;
  }
};

export default reducer;
