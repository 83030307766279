export const assigneeFragment = `
  fragment Assignee on Assignee {
    kind: __typename
    ... on Contact {
      id
      firstName
      lastName
      numberDetails {
        mobile
        firstName
        lastName
        email
      }
    }
  }
`;

export const taskUser = `
  id
  __typename 
  ... on Contact { 
    id
    firstName
    lastName 
    numberDetails {
        firstName
        lastName
      }
  }
  ... on PublicProfile {
    id
    _firstName: firstName
    _lastName: lastName
  }
`;

export const taskCardFragment = `fragment Task on Task {
  id
  title
  deadline
  status
  created
  completed
  archived
  assignee {${taskUser}}
  author {${taskUser}}
}`;

export const timelineFragment = ` 
  kind: __typename
  id
  creator {
    id
    __typename
    ... on Contact {
      firstName
      lastName
      numberDetails {
        firstName
        lastName
        }
      }
      ... on PublicProfile {
      _firstName: firstName
      _lastName: lastName
      }
    }
    created
    ... on TaskFailed {
      reason
    }
    ... on TaskCommented {
      comment {
        content
      }
    }
`;

export const taskFragment = `fragment TaskFragment on Task {
      id
      title
      description
      status
      archived
      deadline
      created
      completed
      assignee {
        ${taskUser}
      }
      author {
        ${taskUser}
      }
}`;

export const contactFragment = `
  fragment ContactFragment on Contact {
    id
    firstName
    lastName
    __typename
    numberDetails {
      firstName
      lastName
      mobile
      email
    }
  }
`;

export const publicProfileFragment = `
  fragment PublicProfileFragment on PublicProfile {
    id
    _firstName: firstName
    _lastName: lastName
    __typename
  }
`;

export const tasksFragment = `
  fragment TasksFragment on Task {
    id
    title
    description
    status
    archived
    deadline
    created
    completed
    author {
      id
      ... PublicProfileFragment
      ... ContactFragment
    }
    assignee {
      id
      ... PublicProfileFragment
      ... ContactFragment
    }
  }
  ${contactFragment}
  ${publicProfileFragment}
`;

export const assigneeDisplayFragment = `{
  kind: __typename
  ... on Contact {
    id
    firstName
    lastName
    numberDetails {
        firstName
        lastName
    }
  }
}`;
