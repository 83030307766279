import React from 'react';
import { Transition, TransitionGroup } from 'react-transition-group';
import { Route, Routes, useLocation } from 'react-router';

import '../../../App.styles.scss';
import LoggedOutSwitch from './LoggedOutSwitch';

import 'react-toastify/dist/ReactToastify.css';
interface LoggedOutSwitchWithTransitionProps {
  navigateTo(path: string): ClickHandler;
}

const DURATION = 100;
const defaultStyles: React.CSSProperties = {
  position: 'absolute',
  transition: `opacity ${DURATION}ms linear`,
  opacity: 0,
  height: 'calc(100% - 50px)',
  top: '50px',
  width: '100%',
};

const transitionStyles: TransitionStyles = {
  entered: { opacity: 1 },
  exiting: { opacity: 0 },
};

export const LoggedOutSwitchWithTransition = (props: LoggedOutSwitchWithTransitionProps) => {
  const location = useLocation();

  return (
    <Routes>
      <Route
        path="*"
        element={
          <TransitionGroup component={null}>
            <Transition key={location.key} timeout={DURATION}>
              {(state) => (
                <div
                  style={{
                    ...defaultStyles,
                    ...transitionStyles[state],
                  }}
                >
                  <LoggedOutSwitch {...props} />
                </div>
              )}
            </Transition>
          </TransitionGroup>
        }
      />
    </Routes>
  );
};
