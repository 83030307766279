import React from 'react';
import Popup, { PopupProps } from './Popup';
import { AppState } from '../../store';
import { connect } from 'react-redux';
import styled from 'styled-components';
import withWindowSize from '../with-window-size/withWindowSize';

interface PropsFromState {
  popupList: PopupProps[];
}

interface StyleProps {
  fullScreen: boolean;
}
const PopupContainerHolder = styled.div<StyleProps>`
  position: fixed;
  display: flex;
  align-content: flex-start;
  width: ${(props) => (props.fullScreen ? '100%' : 'auto')};
  height: ${(props) => (props.fullScreen ? 'calc(100% - 10px)' : '440px')};
  bottom: ${(props) => (props.fullScreen ? '1px' : '10px')};
  z-index: 20;
  right: 0;
`;

const PopupContainer = ({ popupList }: PropsFromState) => {
  const { isMobile } = withWindowSize();
  return popupList.length > 0 ? (
    <PopupContainerHolder fullScreen={isMobile}>
      {popupList.map((single: PopupProps) => (
        <Popup key={single.id} {...single} width={isMobile ? '100%' : ''} />
      ))}
    </PopupContainerHolder>
  ) : null;
};

const mapStateToProps = (state: AppState): PropsFromState => ({
  popupList: state.popup.popupList,
});

export default connect(mapStateToProps)(PopupContainer);
