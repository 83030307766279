import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Tooltip from 'rc-tooltip';
import InputPhone from '../input-phone/InputPhone';
import Input from '../../components/input/Text-Input';
import Graph from './Graph';
import withWindowSize from '../with-window-size/withWindowSize';
import { FieldWrapper, Label } from '../input/input-components';
import { connect } from 'react-redux';
import { AppState } from '../../store';
import { Dispatch } from 'redux';
import { deleteContact, editContact } from '../../modules/Contacts/redux/actions';
import { updateUserSettings } from '../../modules/UserSettings/redux/actions';
import ContactDetailTaskList from '../contact-detail-task-list/ContactDetailTaskList';
import { RegisteredIcon } from '../contact-list/contact-list-components';
import { CheckIcon } from '../icons/Icons';
import { AssignTaskButton } from '../buttons/assign-button/AssignButton';
import { openPopup } from '../popup/redux/actions';
import { IPopupSettings } from '../popup/redux/actions';
import AddNewTask from '../add-new-task-form/AddNewTask';
import ButtonRound from 'components/buttons/ButtonRound';
import { ButtonType } from 'components/buttons/ButtonText';
import { ModalType, openModal } from 'components/modal/redux/actions';
import { DeleteModalContent } from 'components/modal/DeleteModal';
import { v4 as uuidv4 } from 'uuid';

const emailRegex = /\S+@\S+\.\S+/;

interface IContactDetailProps {
  selectedContact?: Contacts.FullContact;
  me: User.Me;
  editContactFn(id: string, value: IContactProfileSettings, oldValue?: string): void;
  editMyProfileFn(settings: IContactProfileSettings): void;
  openTaskPopup(id: string, name: string): void;
  openDeleteModal(id: string, firstName: string, lastName: string): void;
}

export interface IContactProfileSettings {
  firstName?: string;
  lastName?: string;
  email?: string;
  dailyReportTime?: string;
  timezone?: string;
}

const ContactDetail = ({
  selectedContact,
  me,
  editContactFn,
  editMyProfileFn,
  openTaskPopup,
  openDeleteModal,
}: IContactDetailProps) => {
  const { isMobile, screenWidth } = withWindowSize();
  const breakView = screenWidth < 750;
  const InputStyle = { width: breakView ? '100%' : '250px' };
  const filedWrapperStyle = { height: !breakView ? '50px' : '60px' };

  const [firstNameValue, setFirstName] = useState('');
  const [lastNameValue, setLastName] = useState('');
  const [emailValue, setEmail] = useState('');
  const [phoneValue, setPhone] = useState('');
  const [wrongEmailFormat, checkEmail] = useState(false);
  const [emptyError, setEmptyError] = useState(false);

  useEffect(() => {
    if (selectedContact) {
      setFirstName(selectedContact.firstName);
      setLastName(selectedContact.lastName);
      setPhone(selectedContact.phone);
      setEmail(selectedContact.id === me.id ? me.email || '' : selectedContact.email || '');
      setEmptyError(false);
      checkEmail(false);
    }
  }, [selectedContact, me]);

  const updateFirstName = () => {
    if (selectedContact) {
      if (
        firstNameValue &&
        firstNameValue !== selectedContact.firstName &&
        selectedContact.id === me.id
      ) {
        editMyProfileFn({ firstName: firstNameValue });
      } else if (firstNameValue && firstNameValue !== selectedContact.firstName) {
        editContactFn(selectedContact.id, { firstName: firstNameValue });
      } else {
        setFirstName(selectedContact.firstName);
        setEmptyError(false);
      }
    }
  };

  const updateLastName = () => {
    if (selectedContact) {
      if (
        lastNameValue &&
        lastNameValue !== selectedContact.lastName &&
        selectedContact.id === me.id
      ) {
        editMyProfileFn({ lastName: lastNameValue });
      } else if (lastNameValue && lastNameValue !== selectedContact.lastName) {
        editContactFn(selectedContact.id, { lastName: lastNameValue }, selectedContact.lastName);
      } else {
        setLastName(selectedContact.lastName);
        setEmptyError(false);
      }
    }
  };

  const updateEmail = () => {
    if (selectedContact) {
      if (
        selectedContact.id === me.id &&
        emailValue &&
        emailRegex.test(emailValue) &&
        emailValue !== me.email
      ) {
        editMyProfileFn({ email: emailValue });
        checkEmail(false);
      } else if (selectedContact.id !== me.id && !selectedContact.registeredUser && !emailValue) {
        checkEmail(false);
      } else if (
        selectedContact.id !== me.id &&
        emailValue &&
        emailRegex.test(emailValue) &&
        emailValue !== selectedContact.email
      ) {
        editContactFn(selectedContact.id, { email: emailValue });
        checkEmail(false);
      } else if (!emailRegex.test(emailValue)) {
        checkEmail(true);
      }
    }
  };

  if (!selectedContact) {
    return null;
  }
  return (
    <div>
      <Wrapper smallScreen={screenWidth < 1030} isMobile={isMobile}>
        <ContactData isMobile={breakView}>
          <Name>
            {selectedContact.firstName} {selectedContact.lastName}
            {selectedContact.registeredUser ? (
              <TooltipsRelative>
                <Tooltip
                  placement="bottom"
                  trigger={'hover'}
                  overlayInnerStyle={{ backgroundColor: '#38485C' }}
                  overlay={
                    <div>
                      This contact is <BlueSpan>Nag</BlueSpan>
                      <RedSpan>Nag</RedSpan> member and you cannot edit their profile data.
                    </div>
                  }
                >
                  <RegisteredIcon key={selectedContact.id}>
                    <CheckIcon key={selectedContact.id} color={'white'} style={{ width: '8px' }} />
                  </RegisteredIcon>
                </Tooltip>
              </TooltipsRelative>
            ) : null}
          </Name>
          <FieldWrapper breakViewParent={breakView} style={filedWrapperStyle}>
            <Label>Phone:</Label>
            <InputPhone disabled value={phoneValue} style={InputStyle} smallInput />
          </FieldWrapper>
          <FieldWrapper breakViewParent={breakView} style={filedWrapperStyle}>
            <Label>First name:</Label>
            <Input
              disabled={selectedContact.registeredUser}
              error={!firstNameValue && emptyError}
              errorMessage={'First name is mandatory'}
              style={InputStyle}
              small
              onChange={(e) => {
                setFirstName(e.target.value);
                setEmptyError(!e.target.value);
              }}
              onBlur={updateFirstName}
              placeholder="First name"
              value={firstNameValue}
            />
          </FieldWrapper>
          <FieldWrapper breakViewParent={breakView} style={filedWrapperStyle}>
            <Label>Last name:</Label>
            <Input
              disabled={selectedContact.registeredUser}
              error={!lastNameValue && emptyError}
              style={InputStyle}
              small
              onChange={(e) => {
                setLastName(e.target.value);
                setEmptyError(!e.target.value);
              }}
              onBlur={updateLastName}
              placeholder="Last name"
              value={lastNameValue == null ? '' : lastNameValue}
            />
          </FieldWrapper>
          <FieldWrapper breakViewParent={breakView} style={filedWrapperStyle}>
            <Label>Email: </Label>
            <Input
              disabled={selectedContact.registeredUser}
              placeholder="Email"
              error={wrongEmailFormat}
              errorMessage={'Email format is not correct'}
              style={InputStyle}
              small
              onChange={(e) => setEmail(e.target.value)}
              onBlur={updateEmail}
              value={selectedContact.registeredUser ? 'private' : emailValue}
            />
          </FieldWrapper>
        </ContactData>

        <TaskData smallScreen={screenWidth < 1030}>
          <Title>Summary</Title>
          {/*re-render graph on id (key) change to trigger animation*/}
          <Graph key={selectedContact.id} stats={selectedContact.userStatistic} />
          <ButtonWrapper isMobile={isMobile}>
            {selectedContact.id === me.id ? null : (
              <>
                <AssignTaskButton
                  id={selectedContact.id}
                  openPopupWithSettings={() =>
                    openTaskPopup(
                      selectedContact.id,
                      selectedContact.firstName + ' ' + (selectedContact.lastName ?? ''),
                    )
                  }
                  disabled={wrongEmailFormat}
                />
                <ButtonRound
                  onClick={() => {
                    openDeleteModal(
                      selectedContact.id,
                      selectedContact.firstName,
                      selectedContact.lastName,
                    );
                  }}
                  buttonType={ButtonType.delete}
                  inverted
                >
                  Delete contact
                </ButtonRound>
              </>
            )}
          </ButtonWrapper>
        </TaskData>
      </Wrapper>
      <ContactDetailTaskList />
    </div>
  );
};

let popupSettings: IPopupSettings = {
  id: '',
  limitToOne: true,
  title: 'New Task',
  children: <AddNewTask popupId={uuidv4()} />,
};

const mapStateToProps = (state: AppState) => ({
  selectedContact: state.contacts.selectedContact,
  me: state.me,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  editContactFn: (id: string, value: IContactProfileSettings, oldValue?: string) =>
    dispatch(
      editContact.request({
        id,
        value,
        oldValue,
      }),
    ),
  editMyProfileFn: (settings: IContactProfileSettings) =>
    dispatch(updateUserSettings.request(settings)),
  openTaskPopup: (id: string, name: string) => {
    const popupId = new Date().toISOString();
    popupSettings = {
      id: popupId,
      limitToOne: true,
      title: 'New task',
      children: <AddNewTask popupId={popupId} userId={id} userName={name} />,
    };
    dispatch(openPopup(popupSettings));
  },
  openDeleteModal: (id: string, firstName: string, lastname: string) => {
    dispatch(
      openModal({
        title: 'permanently delete contact',
        content: DeleteModalContent,
        type: ModalType.DELETE,
        onConfirm: () => dispatch(deleteContact.request({ id, firstName, lastname })),
        confirmButtonText: 'Delete',
      }),
    );
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ContactDetail);

interface IWrapperProps {
  smallScreen: boolean;
  isMobile?: boolean;
}

const Wrapper = styled.div<IWrapperProps>`
  min-height: 'auto';
  padding: ${(props) => (props.isMobile ? '10px 25px' : '30px 30px 20px 30px')};
  background-color: white;
  display: flex;
  width: 100%;
  flex-direction: ${(props) => (props.smallScreen ? 'column' : 'row')};
`;

const ContactData = styled.div<{ isMobile: boolean }>`
  width: ${(props) => (props.isMobile ? '100%' : '350px')};
`;

const TaskData = styled.div<IWrapperProps>`
  margin: ${(props) => (props.smallScreen ? '30px 0 0 0' : '0 0 0 50px')};
`;

export const Title = styled.div`
  color: #9ba3ad;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 2px;
`;

const Name = styled.div`
  color: #38485c;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 18px;
  letter-spacing: 1px;
  font-weight: 800;
  text-transform: uppercase;
  min-height: 20px;
  margin-bottom: 30px;
`;

const TooltipsRelative = styled.div`
  position: relative;
  display: inline;
  justify-content: flex-end;
  margin-left: 30px;
`;

const RedSpan = styled.span`
  color: #f24b3f;
`;

const BlueSpan = styled.span`
  color: #3281f0;
`;

const ButtonWrapper = styled.div<{ isMobile: boolean }>`
  display: flex;
  justify-content: ${(props) => (props.isMobile ? 'center' : 'flex-end')};
  margin-top: 40px;
  width: 100%;
`;
