import {
  RESEND_EMAIL_VERIFICATION_FAILURE,
  RESEND_EMAIL_VERIFICATION_REQUEST,
  RESEND_EMAIL_VERIFICATION_SUCCESS,
} from './actions';
import { AppAction } from '../../../../store/actions';

type State = {
  isSubmitting: boolean;
  emailSent: boolean;
};

const initialState = {
  isSubmitting: false,
  emailSent: false,
};

const reducer = (state = initialState, action: AppAction): State => {
  switch (action.type) {
    case RESEND_EMAIL_VERIFICATION_REQUEST:
      return {
        isSubmitting: true,
        emailSent: false,
      };
    case RESEND_EMAIL_VERIFICATION_FAILURE:
      return {
        isSubmitting: false,
        emailSent: false,
      };
    case RESEND_EMAIL_VERIFICATION_SUCCESS:
      return {
        isSubmitting: false,
        emailSent: true,
      };
    default:
      return state;
  }
};

export default reducer;
