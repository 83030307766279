import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router';
import { Dispatch } from 'redux';
import ButtonRound from '../../../components/buttons/ButtonRound';
import CenteringContainer from '../../../components/centering-container/CenteringContainer';
import { AppState } from '../../../store';
import { CenteredParagraph, Header } from '../../Shared';
import { verifyEmail } from './redux/actions';

interface PropsFromState {
  isSubmitting: boolean;
}

const mapStateToProps = (state: AppState): PropsFromState => ({
  isSubmitting: state.verifyEmail.isSubmitting,
});

interface PropsFromDispatch {
  verifyEmail(token: string): void;
}

const mapDispatchToProps = (dispatch: Dispatch): PropsFromDispatch => ({
  verifyEmail: (token) => dispatch(verifyEmail.request(token)),
});

interface Props extends PropsFromState, PropsFromDispatch {
  onLogInClick: ClickHandler;
}

export const VerifyEmailConfirmation = ({
  isSubmitting,

  verifyEmail,

  onLogInClick,
}: Props) => {
  let location = useLocation();
  const token = new URLSearchParams(location.search).get('token') || ''

  useEffect(() => {
    verifyEmail(token);
    // eslint-disable-next-line
  }, []);

  return (
    <CenteringContainer>
      {isSubmitting ? (
        <Header>Verifying...</Header>
      ) : (
        <React.Fragment>
          <Header>Email verified</Header>
          <CenteredParagraph>Continue to login by clicking the button below.</CenteredParagraph>
          <ButtonRound onClick={onLogInClick}>Log in</ButtonRound>
        </React.Fragment>
      )}
    </CenteringContainer>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(VerifyEmailConfirmation);
