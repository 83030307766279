import styled from 'styled-components';

export const ContactListHolder = styled.div<IPropsActive>`
  min-width: ${(props) => (props.isMobile ? '100%' : '280px')};
  background-color: white;
  height: ${(props) => (props.isMobile ? 'calc(100vh - 90px)' : 'calc(100vh - 50px)')};
`;

export const ContactHolder = styled.div`
    width: 100%;
    height: calc(100% - 95px);
    display:flex;
    overflow: hidden
    padding-bottom:10px;
    position: relative;
`;

export const SearchHolder = styled.div`
  height: 60px;
  padding: 15px 20px 10px 20px;
  position: relative;
`;

export const SearchInput = styled.input`
  color: #5d7289;
  &::placeholder {
    color: #919090;
  }
  width: 100%;
  height: 30px;
  border-radius: 15px;
  background-color: #eeeff0;
  outline: none;
  padding: 0 10px 0 30px;
  border: 1px solid transparent;
  font-size: 12px;
  transition: border-color 200ms linear;
  &:hover,
  &:active {
    border: 1px solid rgba(155, 163, 173, 0.5);
  }
`;

export const List = styled.div`
  width: calc(100% - 20px);
  height: 100%;
  overflow-y: auto;
`;

export const Alphabet = styled.div`
  text-transform: uppercase;
  font-weight: 600;
  font-size: 11px;
  height: 100%;
  display: flex;
  width: 20px;
  align-items: center;
  justify-content: space-evenly;
  flex-basis: auto;
  flex-direction: column;
  overflow-y: auto;
`;

export const Letter = styled.a`
  text-decoration: none;
  cursor: pointer;
  color: #5d7289;
  &:hover {
    color: #64ace2;
  }
`;

export const ListHeader = styled.div`
  height: 18px;
  background-color: #f1f2f5;
  font-size: 11px;
  color: #5d7289;
  padding: 2px 15px;
  font-weight: bold;
`;

export interface IPropsActive {
  active?: boolean;
  lastItem?: boolean;
  isMobile?: boolean;
}
export const SingleContact = styled.div<IPropsActive>`
  height: ${(props) => (props.isMobile ? '44px' : '34px')};
  line-height: ${(props) => (props.isMobile ? '44px' : '34px')};
  font-family: 'Nunito Sans', sans-serif;
  margin: 0 10px 0 15px;
  font-size: ${(props) => (props.isMobile ? '16px' : '13px')};
  border-bottom: ${(props) => (props.lastItem ? '1px solid transparent' : '1px solid #DEDFE3')};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${(props) => (props.active ? '#64ACE2' : 'rgba(93, 114, 137, 0.85)')};
  font-weight: ${(props) => (props.active ? '700' : '400')};
  cursor: pointer;
  transition: color 0.2s linear;
  &:hover {
    color: ${(props) => (props.active ? '#97c5e2' : 'rgba(118,143,166,0.7)')};
  }
  position: relative;
`;

export const EmptyContacts = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: rgba(93, 114, 137, 0.85);
  * {
    margin: 10px 0;
  }
`;

export const Loading = styled.div`
    position: absolute
    top: 100px;
    width: 100%
    display: flex;
    align-items: center;
`;

export const RegisteredIcon = styled.div`
  position: absolute;
  background-color: #64ace2;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  right: 8px;
  top: calc(50% - 7px);
  display: flex;
  align-items: center;
  justify-content: center;
`;
