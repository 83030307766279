import { getQueryResponse } from '../../client';
import { gql } from "@apollo/client";
import { mapFullContactData } from '../../../mappers/contacts/contact-mappers';
import errorMapper from '../../../mappers/error-mapper';

interface Arguments {
  id: string;
}

const queryFetchContactData = gql`
  query FetchContactData($id: [UUID!]!, $assigneeId: UUID) {
    users(userIds: $id) {
      kind: __typename,
      id
      ... on Contact {
        id
        firstName
        lastName
        numberDetails {
            firstName
            lastName
            email
            mobile
        }
      }
    }
    userStatistic(assigneeId: $assigneeId, statsType: ASSIGNEE) {
      status
      count
    }
  }
`;

export type FetchFullContact = {
  users: Api.Contacts.FullContactRaw[];
  userStatistic: Contacts.TaskStats[];
};

const fetchFullContactData = async (
  args: Arguments,
): Promise<Contacts.FullContact | Api.ApiError> => {
  const response = await getQueryResponse<FetchFullContact>(queryFetchContactData, { ...args, assigneeId: args.id });
  if (response?.data) {
    return mapFullContactData(response.data);
  }
  return errorMapper(response);
};
export default fetchFullContactData;
