import format from 'date-fns/format';
import React from 'react';

interface Props {
  time: string | number | Date;
  year?: boolean;
}

const Timestamp = ({ time, year }: Props) => {
  if (time === 'Unknown') {
    return <React.Fragment>Unknown</React.Fragment>;
  }

  const formattedTime = typeof time === 'string' ? new Date(time) : time;

  if (year) {
    return <React.Fragment>{format(formattedTime, 'eee, MMM d yyyy h:mm a')}</React.Fragment>;
  } else {
    return <React.Fragment>{format(formattedTime, 'eee, MMM d h:mm a')}</React.Fragment>;
  }
};

export default Timestamp;
