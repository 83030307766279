import React from 'react';
import ButtonRound from '../../../components/buttons/ButtonRound';
import CenteringContainer from '../../../components/centering-container/CenteringContainer';
import { CenteredParagraph, Header } from '../../Shared';

interface Props {
  onLogInClick: ClickHandler;
}

const PasswordChanged = ({ onLogInClick }: Props) => (
  <CenteringContainer>
    <Header>Password successfully changed</Header>
    <CenteredParagraph>Continue to login by clicking the button below.</CenteredParagraph>
    <ButtonRound onClick={onLogInClick}>Log in</ButtonRound>
  </CenteringContainer>
);

export default PasswordChanged;
