import { listOfAvailableLetters } from '../../api/queries/contacts/fetch-contact-list';

export const scrollToLetter = (letter: string, indexOfSelectedLetter: number) => {
  let itemToScrollTo = '';
  if (listOfAvailableLetters.indexOf(letter) > -1) {
    itemToScrollTo = letter;
  } else {
    if (indexOfSelectedLetter > 14) {
      itemToScrollTo = listOfAvailableLetters[listOfAvailableLetters.length - 1];
    } else {
      itemToScrollTo = listOfAvailableLetters[0];
    }
  }
  const elementToScroll = document.getElementById(itemToScrollTo);
  elementToScroll && elementToScroll.scrollIntoView({ behavior: 'smooth' });
};
