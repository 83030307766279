import { getMutationResponse } from '../../client';
import { gql } from '@apollo/client';

interface ResetPasswordInput {
  password: string;
  repeatedPassword: string;
  token: string;
}

export interface ResetPasswordResponse {
  resetPassword: boolean;
}

const mutation = gql`
  mutation ResetPassword($password: String!, $token: String!, $repeatedPassword: String!) {
    resetPassword(password: $password, token: $token, repeatedPassword: $repeatedPassword)
  }
`;

const resetPassword = async (
  args: ResetPasswordInput,
): Promise<Api.GraphQL.Response<ResetPasswordResponse> | Api.ApiError> => {
  const response = await getMutationResponse(mutation, args);
  return response as Api.GraphQL.Response<ResetPasswordResponse> | Api.ApiError;
};

export default resetPassword;
