import { ofType } from 'redux-observable';
import { mergeMap } from 'rxjs/operators';
import { from, of, empty } from 'rxjs';
import api from '../../../api';
import { toast } from 'react-toastify';
import {
  UPDATE_USER_SETTINGS_REQUEST,
  FETCH_PROFILE_STATS_REQUEST,
  fetchProfileStats,
  updateUserSettings,
} from './actions';
import { profileUpdatedEvent } from 'api/amplitude/events';

export const editUserSettingsEpic = (action$: any, state$: any) => {
  return action$.pipe(
    ofType(UPDATE_USER_SETTINGS_REQUEST),
    mergeMap((action: any) => {
      return from(api.updateProfile(state$.value.token, action.payload)).pipe(
        mergeMap((response: any) => {
          if (response.error) {
            toast(response.message, { type: 'error' });
            return empty();
          } else {
            profileUpdatedEvent(action.payload, state$.value.me);
            toast('Profile updated', { type: 'success' });
            return of(updateUserSettings.success(response));
          }
        }),
      );
    }),
  );
};

export const fetchProfileStatsEpic = (action$: any, state$: any) => {
  return action$.pipe(
    ofType(FETCH_PROFILE_STATS_REQUEST),
    mergeMap(() => {
      return from(api.fetchLoggedInUserProfileStats()).pipe(
        mergeMap((response: any) => {
          if (response.error) {
            toast('Oops something went wrong while fetching your data.', { type: 'error' });
            return empty();
          } else {
            return of(fetchProfileStats.success(response));
          }
        }),
      );
    }),
  );
};
