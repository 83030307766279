export const base = '#38485c';
export const baseBrighter = '#5f6d7c';
export const baseDarker = '#2d3a4a';
export const blue = '#64ace2';
export const darkGray = '#9ba3ad';
export const lightGray = '#cad5e2';
export const gray = '#f1f2f5';
export const green = '#9fd592';
export const red = '#f97878';
export const redBrighter = '#f89392';
export const redDarker = '#e67374';
export const teal = '#92d5d5';
export const tealBrighter = '#bee6e6';
export const white = '#ffffff';
export const yellow = '#fad77d';
export const textLink = '#5E6A7A';

export const inputActive = '#9BA3AD';
export const inputError = '#F97878';
export const inputDefault = '#DADADA';

export const status = {
  active: {
    myPending: '#ffc38b',
    myProgress: '#64ACE2',
    inProgress: '#64ACE2',
    pending: '#D6ABE6',
    progress: '#92D5D5',
    inReview: '#FAD77D',
  },
  complete: {
    successful: 'rgba(159, 213, 146, 0.6)',
    failed: 'rgba(249, 120, 120, 0.8)',
    rejected: 'rgba(155, 163, 173, 1)',
  },
  archived: {
    successful: 'rgba(159, 213, 146, 0.40)',
    failed: 'rgba(249, 120, 120, 0.5)',
    rejected: 'rgba(155, 163, 173, 0.5)',
  },
};
