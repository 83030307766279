import React from 'react';
import { ListHeader } from './contact-list-components';
import { ContactListName } from './ContactListName';

export const ContactListContent = (
  list: any,
  setActiveContact: Function,
  isMobile: boolean,
  activeId?: string,
) =>
  Object.keys(list).map(
    (keyName) =>
      list[keyName].length > 0 && (
        <span key={keyName}>
          <ListHeader key={keyName} id={keyName}>
            {keyName}
          </ListHeader>
          {ContactListName(list[keyName], isMobile, setActiveContact, activeId)}
        </span>
      ),
  );
