import React, { useEffect, useState } from 'react';
import { ThinTitle, LargeTitle, Step } from './Register';
import Input from '../../../components/input/Text-Input';
import ButtonRound from '../../../components/buttons/ButtonRound';
import { signupPageNameEvent } from '../../../api/amplitude/events';

interface IStep3 {
  getLeftPosition(step: number): React.CSSProperties;
  setActiveStep(step: number): void;
  setUserData(data: any): void;
}

const Step3 = ({ getLeftPosition, setActiveStep, setUserData }: IStep3) => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');

  useEffect(() => {
    return signupPageNameEvent();
  });

  const setUserName = () => {
    setUserData({ firstName: firstName, lastName: lastName });
    setActiveStep(3);
  };

  return (
    <Step style={getLeftPosition(2)}>
      <ThinTitle>3 OUT OF 4</ThinTitle>
      <LargeTitle>Name details</LargeTitle>
      <div style={{ width: '280px' }}>
        <Input
          value={firstName}
          placeholder={'First name'}
          onChange={(e) => setFirstName(e.target.value)}
        />

        <Input
          value={lastName}
          placeholder={'Last name'}
          onChange={(e) => setLastName(e.target.value)}
          style={{ marginTop: 20 }}
          onKeyDown={(e) => {
            e.nativeEvent.key === 'Enter' && firstName && lastName && setUserName()
          }}
        />

        <ButtonRound
          style={{ marginTop: 30, width: '100%' }}
          disabled={!firstName || !lastName}
          onClick={() => setUserName()}
        >
          Next
        </ButtonRound>
      </div>
    </Step>
  );
};
export default Step3;
