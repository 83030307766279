import React from 'react';
import styled from 'styled-components';
import { CloseIcon } from '../icons/Icons';
import { Dispatch } from 'redux';
import { closePopup } from './redux/actions';
import { connect } from 'react-redux';

interface StyledProps {
  width?: string;
}

export interface PopupProps {
  width?: string;
  children: any;
  title?: string;
  id: string;
  closeThisPopup?(id: string): void;
}

interface PropsFromDispatch {
  closeThisPopup(id: string): void;
}
const PopupHolder = styled.div<StyledProps>`
  width: ${(props) => props.width || '365px'};
  background-color: white;
  border: 1px solid #dadada;
  min-height: 350px;
  padding: 20px 0;
  position: relative;
  margin: 0 6px;
`;

const Close = styled.div`
  color: #9ba3ad;
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 15px;
  width: 20px;
  height: 20px;
  &:hover {
    opacity: 0.7;
  }
`;

const PopupTitle = styled.div`
  font-size: 14px;
  text-transform: uppercase;
  color: #38485c;
  letter-spacing: 2px;
  font-weight: 600;
  padding-left: 20px;
  margin-bottom: 30px;
  width: calc(100% - 40px);
`;

const Popup = ({ children, title, width, id, closeThisPopup }: PopupProps) => (
  <PopupHolder width={width}>
    <Close onClick={() => closeThisPopup && closeThisPopup(id)}>
      <CloseIcon />
    </Close>
    {title && <PopupTitle>{title}</PopupTitle>}
    {children}
  </PopupHolder>
);

const mapDispatchToProps = (dispatch: Dispatch): PropsFromDispatch => ({
  closeThisPopup: (id: string) => dispatch(closePopup(id)),
});
export default connect(null, mapDispatchToProps)(Popup);
