import React from 'react';
import { CheckIcon } from '../icons/Icons';
import Tooltip from 'rc-tooltip';
import { SingleContact, RegisteredIcon } from './contact-list-components';

export const ContactListName = (
  contacts: any,
  isMobile: boolean,
  setActiveContact: Function,
  activeId?: string,
) => {
  return contacts.map((user: Contacts.ContactInList, index: number) => (
    <SingleContact
      key={user.id}
      isMobile={isMobile}
      id={user.id}
      onClick={() => setActiveContact(user.id)}
      active={user.id === activeId}
      lastItem={index === contacts.length - 1}
    >
      {user.firstName} {user.lastName !== null ? user.lastName : ''}
      {user.registeredUser && (
        <Tooltip
          placement="bottom"
          trigger={'hover'}
          overlayInnerStyle={{ backgroundColor: '#38485C' }}
          overlay="nagnag member"
        >
          <RegisteredIcon key={user.id}>
            <CheckIcon key={user.id} color={'white'} style={{ width: '8px' }} />
          </RegisteredIcon>
        </Tooltip>
      )}
    </SingleContact>
  ));
};
