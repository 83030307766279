import styled from 'styled-components';

export interface ButtonBaseProps {
  disabled?: boolean;
}

const ButtonBase = styled.div<ButtonBaseProps>`
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 1px;
  cursor: pointer;
  transition: opacity 0.4s;
  outline: none;
  margin: 2px;
  font-size: 12px;
  height: 36px;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  pointer-events: ${(props) => (props.disabled ? 'none' : 'default')};
`;

export default ButtonBase;
