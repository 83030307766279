import React from 'react';
import styled from 'styled-components';
import * as colors from '../../../../lib/colors';
import LoadingIndicator from '../../../loading/LoadingIndicator';

interface LoadMoreButtonProps {
  loading: boolean;
  onClick(): void;
}

const LoadMoreButton = ({ loading, onClick }: LoadMoreButtonProps) => {
  return (
    <LoadMoreHolder>
      <LoadButton onClick={onClick}>
        Load more tasks
        {loading && <LoadingIndicator style={{ position: 'absolute', bottom: '-20px' }} />}
      </LoadButton>
    </LoadMoreHolder>
  );
};

export default LoadMoreButton;

const LoadButton = styled.div`
  position: relative;
  width: 200px;
  height: 40px;
  color: ${colors.blue};
  border: 1px solid;
  border-color: transparent;
  border-radius: 40px;
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: 0.75;
  cursor: pointer;
  background-color: transparent;
  margin-bottom: 20px;
  transition: opacity 0.4s ease, background-color 0.4s ease, border-color 0.4s ease;
  &:hover {
    border-color: rgba(100, 172, 226, 0.2);
    opacity: 1;
    background-color: rgba(100, 172, 226, 0.1);
  }
`;

const LoadMoreHolder = styled.div`
  display: flex;
  margin: 10px auto;

  justify-content: center;
  align-items: center;
`;
