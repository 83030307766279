import React from 'react';
import CenteringContainer from '../../../components/centering-container/CenteringContainer';
import { CenteredParagraph, Header } from '../../Shared';

const EmailSent = () => (
  <CenteringContainer>
    <Header>Email sent</Header>
    <CenteredParagraph>
      Reset email sent! Please check your inbox for instructions on how to change it.
    </CenteredParagraph>
  </CenteringContainer>
);

export default EmailSent;
