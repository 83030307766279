import acceptTask from './mutations/tasks/accept-task';
import archiveTask from './mutations/tasks/archive-task';
import createContact from './mutations/contacts/create-contact';
import createComment from './mutations/tasks/create-comment';
import createTask from './mutations/tasks/create-task';
import deleteTask from './mutations/tasks/delete-task';
import deleteAssignee from './mutations/contacts/delete-contact';
import editTask from './mutations/tasks/edit-task';
import forgotPassword from './mutations/user/forgot-password';
import markFailed from './mutations/tasks/mark-failed';
import markSuccessful from './mutations/tasks/mark-successful';
import nag from './mutations/tasks/nag';
import register from './mutations/user/register';
import rejectTask from './mutations/tasks/reject-task';
import resendEmailVerification from './mutations/user/resend-email-verification';
import resetPassword from './mutations/user/reset-password';
import markInReview from './mutations/tasks/mark-in-review';
import updateProfile from './mutations/user/update-profile';
import verifyEmail from './mutations/user/verify-email';
import fetchTask from './queries/tasks/fetch-task';
import {
  fetchActiveTasks,
  fetchCompletedTasks,
  fetchTasksByFilter,
} from './queries/tasks/fetch-tasks';
import fetchTaskTimeline from './queries/tasks/fetch-task-timeline';
import getInitialData from './queries/user/get-initial-data';
import login from './queries/user/login';
import fetchContactList from './queries/contacts/fetch-contact-list';
import fetchContactByNumber from './queries/contacts/fetch-contact-by-number';
import fetchFullContactData from './queries/contacts/fetch-full-contact';
import searchContacts from './queries/contacts/search-contacts';
import editContact from './mutations/contacts/edit-contact';
import fetchLoggedInUserProfileStats from './queries/user/fetch-user-profile-data';
import fetchMyProfileData from './queries/user/get-my-stats';
import fetchContactTasks from './queries/contacts/fetch-contact-tasks';

const exports = {
  acceptTask,
  archiveTask,
  createContact,
  createComment,
  createTask,
  deleteTask,
  deleteAssignee,
  editTask,
  forgotPassword,
  markFailed,
  markSuccessful,
  nag,
  register,
  rejectTask,
  resendEmailVerification,
  resetPassword,
  markInReview,
  updateProfile,
  verifyEmail,
  fetchTask,
  fetchActiveTasks,
  fetchCompletedTasks,
  fetchTaskTimeline,
  getInitialData,
  login,
  fetchContactList,
  fetchContactByNumber,
  fetchFullContactData,
  searchContacts,
  editContact,
  fetchLoggedInUserProfileStats,
  fetchMyProfileData,
  fetchContactTasks,
  fetchTasksByFilter,
};

export default exports;
