import { getQueryResponse } from '../../client';
import { gql } from "@apollo/client";
import errorMapper from '../../../mappers/error-mapper';

const getUserByMobile = gql`
  query userByMobile($mobile: String!) {
    userByMobile(mobile: $mobile) {
      id
      firstName
      lastName
      email
    }
  }
`;

type ContactByMobileResponse = {
  userByMobile: LinkedContact;
};

const fetchContactByNumber = async (args: string): Promise<LinkedContact | Api.ApiError | null> => {
  const response = await getQueryResponse<ContactByMobileResponse>(getUserByMobile, { mobile: `+${args}` });
    if (response?.data) {
      return response.data?.userByMobile;
    }
    return errorMapper(response as Api.GraphQL.Errors);
};

export default fetchContactByNumber;
